import { AssetDto } from "./asset.model";
import { MMSGroupSharedEmailDto } from "./mms-group-shared-email.model";
import { MMSSubGroupDto } from "./mms-sub-group.model";

export class MMSGroupDto {
    id!: number | undefined;
    label: string | undefined;
    order?: number | undefined;
    isActive?: boolean | undefined;
    isIRIS: boolean = true;
    businessUnitId: number | undefined;
    mmsSubGroups?: MMSSubGroupDto[] | null;
    assetList?: AssetDto[] | null;
    mmsGroupSharedEmails?: MMSGroupSharedEmailDto[] | null;
    createdBy: string | undefined;
    modifiedBy: string | undefined;
    createdDateTime: Date | undefined;
    modifiedDateTime: Date | undefined | null;
}
