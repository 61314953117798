<div class="add_bulk_item_container">
  <div class="content-top-action d-flex a-center j-c-s jcsb mb-20">
    <h1 class="d-flex"><a class="c-left"><span class="fa fa-fw fa-angle-left"
          (click)="commonService.goBack()"></span></a>
      IRIS Request {{trackingNumber}}
      <span class="fa fa-fw fa-angle-right mt-4"></span>
      <div class="title">Bulk Upload</div>
    </h1>
    <!-- Action buttons -->
    <div class="float-right">
      <button kendoButton class="border-button ml-20 f-size-150" (click)="commonService.goBack()">
        Cancel
      </button>
      <button kendoButton class="primary-button ml-20 f-size-150" (click)="Save()">
        Save and Exit
      </button>
    </div>
  </div>
  <div class="d-flex">
    <div>
      <kendo-label>Item Number</kendo-label><br>
      <kendo-textarea [rows]="7" [cols]="40" [(ngModel)]="ItemNumbers"></kendo-textarea>
    </div>
    <div class="mt-145 ml-10 mr-10">
      <button kendoButton class="secondary-border-button f-size-150" [disabled]="ItemNumbers.length<3" (click)="validateItemNumber()">Add
        Item</button>
    </div>
    <div *ngIf="validItemNumbers.length>0">
      <kendo-label>Valid Item Numbers</kendo-label><br>
      <kendo-textarea [readonly]="true" [rows]="7" [cols]="40" [(ngModel)]="validItemNumbers"></kendo-textarea>
    </div>
    <div class="mt-145 ml-10 mr-10" *ngIf="validItemNumbers.length>0">
      <button kendoButton class="secondary-border-button f-size-150" (click)="generateRequestLines()">Generate Request Lines</button>
    </div>
  </div>
  <section class="mt-20 m-container-2 requestGrid">
    <kendo-grid [data]="gridData" #grid="kendoGrid" id="lineDetailsGrid">

      <kendo-grid-column field="index" title="Line Number" [width]="7"
        [style]="{ 'text-align': 'right', 'padding-right': '50px' }"></kendo-grid-column>
      <kendo-grid-column field="itemNumber" title="Item Number" [width]="11"
        [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
      <kendo-grid-column field="itemDescription" title="Item Description" [width]="14"
        [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
      <kendo-grid-column field="manufacturerName" title="Manufacturer Name" [width]="13"
        [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
      <kendo-grid-column field="manufacturerPartNumber" title="Manufacturer Part Number" [width]="12"
        [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
      <kendo-grid-column field="unitCost" title="Unit Cost" [width]="9" [style]="{ 'padding-left': '12px' }">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-numerictextbox [spinners]="false" [min]="0" format="n3" [scrollLeft]="false" [(ngModel)]="dataItem.unitCost" (valueChange)="calculateEstimatedCost(dataItem)"></kendo-numerictextbox>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="itemQty" title="Qty To Dispose" [width]="9" [style]="{ 'padding-left': '12px' }">
        <ng-template kendoGridCellTemplate let-dataItem>
          <kendo-numerictextbox [spinners]="false" [min]="0" format="##" [(ngModel)]="dataItem.itemQty" (valueChange)="calculateEstimatedCost(dataItem)"></kendo-numerictextbox>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="estimatedCost" title="Estimated Cost" [width]="9"
        [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
        <kendo-grid-column field="icon" title="" [width]="4" [style]="{ 'padding-left': '12px' }">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div kendoPopoverAnchor [popover]="myPopover">
                  <span class="k-icon k-i-more-vertical"></span>
              </div>
              <kendo-popover #myPopover position="left" class="edit-pop">
                  <ng-template kendoPopoverBodyTemplate>
                      <div class="popover-content">
                          <hr class="separator ml-5 mr-5" />
                          <a (click)="deleteLineItem(dataItem)">
                              <span class="material-icons md-18">delete_forever</span>
                              <span style="margin-left: 10px">Delete Line Item</span>
                          </a>
                      </div>
                  </ng-template>
              </kendo-popover>
          </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </section>
</div>