<div class="asset-location-container j-c-s m-container">
  <h1 class="mb-20 setup-heading">Asset Locations</h1>
  <div class="mr-20 float-right">
    <button kendoButton class="border-button ml-20 f-size-150" (click)="assetActions('import')">
      Import
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="assetActions('createNewAssetLocation')">
      Create a new Asset Location
    </button>
  </div>

  <!-- Filter actions -->
  <section class="manage-contentbox">
    <section class="mt-20 d-flex">
      <div class="mt-20 pr-20 d-flex w-55">
        <kendo-textbox [(ngModel)]="searchedText" (click)="textSearched($event)"></kendo-textbox>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="searchResetGridActions('search')">
          Search
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="searchResetGridActions('reset')">
          Reset
        </button>
      </div>
    </section>

    <!-- toggle active/inactive assets-->
    <section class="toggle-active-inactive">
      <div class="d-flex j-c-s w-45 mt-10 a-center">
        <p class="mt-10">Show Inactive Asset Locations</p>
        <kendo-switch [(ngModel)]="checked" class="mt-10 mr-40" (click)="showHideActiveNInactiveassets($event)">
        </kendo-switch>
      </div>
    </section>

    <!-- Table data -->
    <section class="mt-10">
      <kendo-grid [kendoGridBinding]="gridData" #grid="kendoGrid" [sortable]="true" [resizable]="true" 
      [pageable]="gridData.length > 50 ? true : false" [pageSize]="50">
        <!-- Location Name -->
        <kendo-grid-column field="locationName" title="Location Name" [width]="43">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="Javascript:void(0);" (click)="assetLocationDetails(dataItem)">
              {{ dataItem.locationName }}
            </a>
          </ng-template>
        </kendo-grid-column>
        <!-- Is Active -->
        <kendo-grid-column field="businessUnitName" title="Business Unit" [width]="20" [style]="{ 'padding-left': '30px' }">
        </kendo-grid-column>
        <kendo-grid-column field="isActive" title="Is Active" [width]="14" [style]="{ 'padding-left': '30px' }">
        </kendo-grid-column>
        <!-- Order -->
        <kendo-grid-column field="order" title="Order" [width]="12" [style]="{ 'padding-left': '40px' }">
        </kendo-grid-column>
        <!-- Created By -->
        <kendo-grid-column field="createdBy" title="Created By" [width]="21" [style]="{ 'padding-left': '30px' }">
        </kendo-grid-column>
      </kendo-grid>

      <!-- total data count -->
      <p class="grid-length mt-20">{{ gridData.length }} records</p>
    </section>
  </section>
</div>