<div class="iris-departmentDetail-container j-c-s m-container main-container-padding">
  <h1 class="mb-20 setup-heading" *ngIf="isFormValid">{{ label }}</h1>
  <h1 class="mb-20 setup-heading" *ngIf="!isFormValid">{{ detailsHeading }}</h1>
  <div class="mr-20 float-right">
    <button kendoButton class="primary-button ml-20 f-size-150" [disabled]="!isFormValid" (click)="action('save')">
      Save
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">
      Cancel
    </button>
  </div>

  <section class="mt-20">
    <div class="mt-20 w-40">
      <kendo-label> Label </kendo-label>
      <kendo-textbox [maxlength]="150" (keyup)="addEditDepartmentName($event)"
        [(ngModel)]="label"></kendo-textbox>
    </div>
    <div class="mt-10 w-25">
      <kendo-label>Order </kendo-label>
      <kendo-textbox [maxlength]="10" [(ngModel)]="order"></kendo-textbox>
    </div>
    <div class="mt-10 w-25">
      <kendo-label class="k-checkbox-label" [for]="select" text="Is Active"></kendo-label>
      <br />
      &nbsp;&nbsp;<input type="checkbox" [(ngModel)]="isActive" #select kendoCheckBox />
    </div>
  </section>
</div>