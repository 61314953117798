import { LineDetailsDto } from "./line-item.model";

export class IRISRequestDto {
  id?: number;
  label?: string;
  order?: number | undefined;
  isActive?: boolean | undefined;
  modifiedBy?: string | undefined;
  modifiedDateTime?: string | undefined;
  createdBy?: string | undefined;
  createdDateTime?: string | undefined;
  trackingNumber?: string | undefined = '';
  requestType?: number | undefined;
  requestStateId?: number | undefined;
  justification?: string | undefined;
  description?: string | undefined;
  title?: string | undefined;
  departmentId?: number | undefined;
  assetLocationId?: number | undefined;
  assetId?: number | undefined;
  assetName?: string;
  purchaseOrderNumber?: any;
  supplier?: string;
  supplierNumber?: number;
  orderCompany?: string;
  ownerId?: number;
  branchPlant?: any;
  buyerNumber?: any;
  chevronReferenceNumber?: any;
  cmrnumber?: number;
  vendorReferenceNumber?: number;
  packingSlipNumber?: any;
  priority?: any;
  userOwner?: any;
  stotNumber?: number;
  tareNumber?: number;
  packNumber?: number;
  shutDownDefinitionCode?: any;
  recoveryPercentage?: any;
  recoveredAmount?: number;
  afecostCode?: number;
  totalEstimatedCost?: number;
  salesInvoiceNumber?: number;
  fileStagingId?: number;
  userOwnerGroupId?: number;
  isNewsArreQuestFlow?: any;
  requestStateName?: string;
  userId?: number;
  requestApproverAction? : number;
  requestId?: number;
  requestIssueId?: number;
  requestLineId?: number;
  comment?: string;
  assignedGroupId?: number;
  assignedUserId?: number;
  requestIssues? : any;
}

export class MaterialType {
  createdBy?: string;
  createdDateTime?: string;
  exampleText?: string;
  id?: number;
  inputLabel?: string;
  isActive?: boolean;
  label?: string;
  modifiedBy?: string;
  modifiedDateTime?: string;
  order?: number;
}

export class DisposalMethod {
  createdBy?: string;
  createdDateTime?: string;
  id?: number;
  isActive?: boolean;
  isFinacialWriteOff?: boolean;
  label?: string;
  modifiedBy?: string;
  modifiedDateTime?: string;
  order?: number;
}

export class InventoryConditionDto {
  id?: number;
  label?: string;
}

export class TechnicalEndorserDto {
  id?: number;
  username?: string;
}