import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { IrisDepartmetsService } from '../../../services/iris-department/iris-departmets.service';
import { IRISDepartmentsGridDTO } from '../../../models/iris-departments.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InputFieldsConstants } from 'src/app/core/constants/input-fields.constants';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-irisdepartment-detail',
  templateUrl: './irisdepartment-detail.component.html',
  styleUrls: ['./irisdepartment-detail.component.scss'],
})
export class IRISDepartmentDetailComponent implements OnInit, OnDestroy {
  detailsHeading: string = 'New Department';
  departmentId!: number;
  label: string = '';
  order!: number;
  isActive!: boolean;
  createdBy!: string;
  modifiedBy!: string;
  buttonAction: string = '';
  prevLabel: string = '';
  isDepartmentNameError: boolean = false;
  isOrderError: boolean = false;
  isFormValid: boolean = false;
  departmentLabelList: string[] = []
  constructor(
    private router: Router,
    private commonService: CommonService,
    private location: Location,
    private irisDepartmetsService: IrisDepartmetsService,
    private titleService: Title
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.buttonAction = stateData['action'];
      this.departmentId = stateData['id'];
      this.label = stateData['label'];
      this.order = stateData['order'];
      this.isActive = stateData['isActive'];
      this.createdBy = stateData['createdBy'];
      this.modifiedBy = stateData['modifiedBy'];
      sessionStorage.setItem('IRISDepartmentsDetails', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('IRISDepartmentsDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.buttonAction = sessionState.action;
        this.departmentId = sessionState.id;
        this.label = sessionState.label;
        this.order = sessionState.order;
        this.isActive = sessionState.isActive;
        this.createdBy = sessionState.createdBy;
        this.modifiedBy = sessionState.modifiedBy;
      }
    }
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('IRISDepartmentsDetails');
  }

  ngOnInit() {
    if (this.buttonAction === 'edit') {
      this.titleService.setTitle(this.label);
    }
    if (this.label) {
      this.isFormValid = true;
    }
    this.departmentsRecords();
    if (this.buttonAction === 'edit') {
      this.prevLabel = this.label;
    }
  }

  departmentsRecords() {
    this.irisDepartmetsService.getIrisDepartmentRecord()
      .subscribe((data: IRISDepartmentsGridDTO[]) => {
        this.departmentLabelList = data.map((department: IRISDepartmentsGridDTO) => { return department.label!; })
      });
  }

  // validate the department from input
  addEditDepartmentName(event: any) {
    if (event.target.value.trim()) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  // save changes or go back to previous page
  action(actionType: string) {
    switch (actionType) {
      case 'cancel': {
        this.location.back();
        break;
      }
      case 'save': {
        if (this.isDuplicateDepartement(this.label)) {
          this.commonService.showNotificationMessage('Department already exists', 'error', 'center');
        }
        else {
          this.addNewDepartment();
          this.location.back();
          break;
        }
      }
    }
  }
  isDuplicateDepartement(label: string) {
    let labels: string[] = this.departmentLabelList.filter((dep: string) => (dep.toLowerCase() === label.toLowerCase()));
    if (labels.length > 0 && this.buttonAction === 'edit' && this.prevLabel.toLowerCase() !== this.label.toLowerCase()) {
      return true;
    }
    if (labels.length > 0 && this.buttonAction === 'create') {
      return true;
    }
    return false;
  }
  // create payload and call API to create||edit actions
  addNewDepartment() {
    const userName = this.commonService.getUserFullName();
    const department = this.label;
    const order = this.order;
    const isActive = this.isActive;
    const payload: IRISDepartmentsGridDTO = {
      id: this.departmentId,
      label: department,
      order: Number(order ? order : 0),
      isActive: isActive,
      createdBy: this.createdBy ? this.createdBy : userName,
      modifiedBy: userName,
    };
    switch (this.buttonAction) {
      case 'create': {
        this.removeKey(payload);
        this.irisDepartmetsService
          .createNewIrisDepartment(payload)
          .subscribe((data: IRISDepartmentsGridDTO) => {
            if (data) {
              this.successUpdateMSG();
            }
          });
        break;
      }
      case 'edit': {
        this.irisDepartmetsService
          .editIrisDepartment(payload, this.departmentId)
          .subscribe((data: IRISDepartmentsGridDTO) => {
            if (data) {
              this.successUpdateMSG();
            }
          });
        break;
      }
    }
  }

  // remove ID only when new department is created
  removeKey(object: any) {
    for (let key in object) {
      if (key.match('id')) {
        delete object[key];
      }
    }
    return object;
  }

  successUpdateMSG() {
    const successMSG = `Department ${InputFieldsConstants.successMSG}`;
    const updateMSG = `Department ${InputFieldsConstants.updateMSG}`;
    const msg = this.buttonAction == 'create' ? successMSG : updateMSG;
    this.commonService.showNotificationMessage(msg, 'success', 'center');
  }
}
