import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
// import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
// import { ConfigRoleComponent } from '../config-role/config-role.component';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { ProfileType } from '../../model/graph.models';
import { CommonService } from '../../services/common.service';
import { GlobalAuthService } from '../../services/global-auth.service';
import { LoadingService } from '../../services/loading-progress-bar.service';
import { UserService } from '../../services/user.service';
import { UserDto } from '../../model/mmsUser/user.model';
import { RolesDto } from '../../model/mmsUser/roles.model';
import { RoutingConstant } from '../../constants/modules-routing.constants';
import { RoleConstant } from '../../constants/role.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('anchor', { read: ElementRef }) public anchor: | ElementRef | undefined;
  @ViewChild('popup', { read: ElementRef }) public popup: | ElementRef | undefined;
  @Output() isMMSUser = new EventEmitter<boolean>();
  @Output() isAdmin = new EventEmitter<boolean>();
  @Output() loadContent = new EventEmitter<boolean>();
  profile!: ProfileType | null;
  setLoginDisplay!: boolean | null;
  userRole: string | undefined;
  userEmailId: string = '';
  showHeaderPopup: boolean = false;
  // userBUName: string | undefined;
  public value = 0;
  public running!: any;
  public profileShortName: string = '';
  public show = false;
  public labelSettings: LabelSettings = { visible: false };
  public progressStyles: { [key: string]: string } = {
    color: 'white',
    background: '#596EE4',
  };

  constructor(
    private globalAuthService: GlobalAuthService,
    private dialogService: DialogService,
    public commonService: CommonService,
    public loadingService: LoadingService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.loadingService.isLoading$.subscribe((value) => {
      if (value) {
        this.startProgress();
      } else {
        this.resetProgress();
      }
    });
  }

  ngOnInit(): void {
    this.globalAuthService.profile.subscribe((profile) => {
      this.profile = profile;
      this.loggedInUserShortName();
      this.userEmailId = this.profile?.userPrincipalName!;
      sessionStorage.setItem('userEmailID', JSON.stringify(profile?.userPrincipalName));
      sessionStorage.setItem('userId', JSON.stringify(profile?.id));
      sessionStorage.setItem('firstName', JSON.stringify(profile?.givenName));
      sessionStorage.setItem('lastName', JSON.stringify(profile?.surname));
      sessionStorage.setItem('CAI', profile?.extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11!);
      this.loadContent.emit(true);
      this.userService.getUserByCAI(profile?.extension_39c7d3e68666465dab296ee0fc538118_extensionAttribute11!).subscribe(
        (user: UserDto) => {
          sessionStorage.setItem('loggedUserID', <any>user.id)
          if (user.isActive === null || user.isActive === false) {
            this.emit_InvalidMMSUser();
          }
          else if (user.rolesDto && user.rolesDto.length > 0) {
            let userRoles: string[] = user.rolesDto.map((role: RolesDto) => { return role.name! });
            sessionStorage.setItem('userRoles', userRoles.join(','));
            this.checkUser(userRoles);
            this.checkAdmin(userRoles);
          }
          else {
            this.emit_InvalidMMSUser();
          }
          if (!this.commonService.isNullOrEmptyOrUndefinedOnly(user.businessUnitName!)) {
            sessionStorage.setItem('BU', user.businessUnitName!);
          }
          else {
            sessionStorage.setItem('BU', user.businessUnitName!);
          }
        }, (error) => {
          this.emit_InvalidMMSUser();
        })
      // if (this.commonService.isNullOrEmptyOrUndefined(this.commonService.getUserRoleName())) {
      //   // this.assignRole();
      //   return;
      // } else {
      //   this.userRole = this.commonService.getUserRoleName();
      //   // this.userBUName = this.commonService.getUserBUName();
      //   this.loadContent.emit(true);
      // }
    });
    this.globalAuthService.loginDisplay.subscribe((setLoginDisplay) => (this.setLoginDisplay = setLoginDisplay));
    this.showHideHeaderPopup();
  }
  emit_InvalidMMSUser() {
    sessionStorage.setItem('userRoles', '');
    this.isMMSUser.emit(false);
    this.isAdmin.emit(false);
  }
  checkUser(roles: string[]) {
    let user_roles: string[] = roles.filter((role: string) => role === RoleConstant.User);
    if (user_roles.length > 0)
      this.isMMSUser.emit(true);
    else
      this.isMMSUser.emit(false);
  }
  checkAdmin(roles: string[]) {
    let user_roles: string[] = roles.filter((role: string) => role === RoleConstant.Admin);
    if (user_roles.length > 0)
      this.isAdmin.emit(true);
    else
      this.isAdmin.emit(false);
  }

  loggedInUserShortName() {
    if (this.profile?.givenName && this.profile?.surname) {
      const fName = this.profile?.givenName.toUpperCase().toString().charAt(0);
      const lName = this.profile?.surname.toUpperCase().toString().charAt(0);
      this.profileShortName = `${fName}${lName}`;
    }
  }

  showHideHeaderPopup() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        switch (event.url) {
          case '/MMS': {
            this.showHeaderPopup = true;
            break;
          }
          default: {
            this.showHeaderPopup = false;
            break;
          }
        }
      }
    });
  }

  logout(): void {
    this.globalAuthService.logout();
    this.commonService.clearSession();
  }

  public onToggle(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
  }

  // public assignRole(): void {
  //   const dialog: DialogRef = this.dialogService.open({
  //     content: ConfigRoleComponent
  //   });

  //   dialog.result.subscribe((r: any) => {
  //     if (r.value.roleName === '') {
  //       this.loadContent.emit(false);
  //       return false;
  //     }
  //     this.userRole = r.value.roleName;
  //     this.userBUName = r.value.BUName;
  //     sessionStorage.setItem('roleName', r.value.roleName)
  //     sessionStorage.setItem('userBUName', r.value.BUName)
  //     this.loadContent.emit(true);
  //     this.reload();
  //   });
  // }

  reload() {
    window.location.reload();
  }

  @HostListener('document:keydown', ['$event'])
  public keydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.onToggle(false);
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.onToggle(false);
    }
  }
  private contains(target: EventTarget | null): boolean {
    return (
      this.anchor!.nativeElement.contains(target) ||
      (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  public startProgress(): void {
    this.running = setInterval(() => {
      if (this.loadingService.isLoading$) {
        this.value++;
        if (this.value == 99) {
          this.value = 0;
        }
      } else {
        this.resetProgress();
      }
    }, 500);
  }

  public stopProgress(): void {
    if (this.running) {
      clearInterval(this.running);
      this.running = null;
    }
  }

  public resetProgress(): void {
    this.value = 0;
    this.stopProgress();
  }
  navMyProfile() {
    this.router.navigate(['./' + RoutingConstant.profile])
  }
}
