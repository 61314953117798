export const IRISRequestStateID = {
  openStateID: 1,
  closedStateID: 2,
  cancelledStateID: 3,
  draftStateID: 6,
  approvedStateID: 7,
  pendingApprovalStateID: 8,
  rejectedStateID: 10,
  completedStateID: 11,
  pendingCommercialReviewStateID: 12,
  pendingReviewStateID: 13,
  pendingDOAReviewStateID: 14,
  pendingEndorsementStateID: 15,
  pendingSupplyBaseReviewStateID: 16,
  pendingAuctionStateID: 17,
  disposedStateID: 18,
  pendingFinanceUpdateStateID: 19,
  recalledStateID: 20,
};
