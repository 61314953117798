import { Component } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss']
})
export class ConfirmationBoxComponent {

  headerOption: string = ''
  confirmMessage: string = '';
  isRejectedCancel?: boolean = false;
  constructor(public dialog: DialogRef) { }

  close(actionName: string) {
    this.dialog.close({ action: actionName });

  }
}
