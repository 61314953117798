<div class="asset-location-container j-c-s m-container">
    <h1 class="mb-20 setup-heading">IRIS DOA Levels</h1>
    <div class="mr-20 float-right">
        <button kendoButton class="border-button ml-20 f-size-150" (click)="exportExcel(grid)">
            Export
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="importExcel()">
            Import
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="addEdiDOALevel(true,0,'',0,'','',true)">
            Add new Level
        </button>
    </div>

    <!-- Filter actions -->
    <section class="manage-contentbox">
        <section class="mt-20 d-flex">
            <div class="mt-20 pr-20 d-flex w-55">
                <kendo-textbox [(ngModel)]="searchedText" (keydown.enter)="getGridData(checked)"></kendo-textbox>
                <button kendoButton class="primary-button ml-20 f-size-150" (click)="getGridData(checked)">
                    Search
                </button>
                <button kendoButton class="border-button ml-20 f-size-150" (click)="resetGrid()">
                    Reset
                </button>
            </div>
        </section>

        <!-- toggle active/inactive doa levels-->
        <section class="toggle-active-inactive">
            <div class="d-flex j-c-s w-45 mt-10 a-center">
                <p class="mt-10">Show Inactive DOA Level Types</p>
                <kendo-switch [(ngModel)]="checked" class="mt-10 mr-30" (valueChange)="getGridData($event)">
                </kendo-switch>
            </div>
        </section>

        <!-- Table data -->
        <section class="mt-15">
            <kendo-grid [kendoGridBinding]="gridData" [resizable]="true" #grid="kendoGrid">
                <!-- Excel -->
                <kendo-grid-excel [fetchData]="commonService.exportGridData(this.gridData, '', 'asc')"
                    [fileName]="fileName">
                    <kendo-excelexport-column field="businessUnitName" title="BU Name">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="label" title="Label">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="doaLevel" title="DOA Level">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="currancy" title="Currency">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="isActive" title="Is Active">
                    </kendo-excelexport-column>
                </kendo-grid-excel>
                <!-- BU Name-->
                <kendo-grid-column title="BU Name" field="businessUnitName" [width]="10">
                </kendo-grid-column>
                <!-- DOA Level Label -->
                <kendo-grid-column field="label" title="Label" [width]="15">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a href="Javascript:void(0);"
                            (click)="addEdiDOALevel(false,dataItem.id,dataItem.label,dataItem.doaLevel,dataItem.currancy,dataItem.businessUnitName,dataItem.isActive)">
                            {{ dataItem.label }}
                        </a>
                    </ng-template>
                </kendo-grid-column>
                <!-- DOA Level -->
                <kendo-grid-column [width]="20">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="header-doaL">
                            DOA Level
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="centered">
                            ${{dataItem.doaLevel}} {{dataItem.currancy}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- DOA Level Range -->
                <kendo-grid-column [width]="50">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="header-doaLR">
                            DOA Level Range
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="centered">
                            {{dataItem.doaLevelRangeLeft}} : {{dataItem.doaLevelRangeRight}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- Is Active -->
                <kendo-grid-column field="isActive" title="Is Active" [width]="5">
                </kendo-grid-column>
            </kendo-grid>

            <!-- total data count -->
            <p class="grid-length mt-20">{{ gridData.length }} records</p>
        </section>
    </section>
</div>