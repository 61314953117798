import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BusinessUnitService } from './services/business-unit-service/business-unit.service';
import { BusinessUnitDto } from './models/bussinessunits.model';

@Component({
  selector: 'app-setup-dashboard',
  templateUrl: './setup-dashboard.component.html',
  styleUrls: ['./setup-dashboard.component.scss'],
})
export class SetupDashboardComponent {
  constructor(private route: Router, private BUService: BusinessUnitService) {
    this.BUService.getAllBUNames().subscribe((data: BusinessUnitDto[]) => {
      if (data) {
        sessionStorage.setItem('BUList', JSON.stringify(data));
      }
    });
  }

  navigate(url: string) {
    this.route.navigate([url]);
  }
}
