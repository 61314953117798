import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { MMSGroupDto } from '../../models/mms-group.model';
import { Observable } from 'rxjs';
import { MMSGroupConst } from "../../../../core/constants/MMS_BackOffice_Constants/mms-group/mms-group.constants"

@Injectable({
  providedIn: 'root'
})
export class MmsGroupService {

  constructor(private utilityService: HttpUtilityService) {
  }
  getMMSGroupList(): Observable<MMSGroupDto[]> {
    return this.utilityService.get(MMSGroupConst.apiPath);
  }

  getMMSGroupbyId(id: number): Observable<MMSGroupDto> {
    return this.utilityService.get(MMSGroupConst.apiPath + `/${id}`);
  }

  importExcelData(data: MMSGroupDto[]): Observable<any> {
    return this.utilityService.post(MMSGroupConst.apiPath + MMSGroupConst.import, data);
  }
  
  addNewGroup(group: MMSGroupDto): Observable<MMSGroupDto> {
    return this.utilityService.post(MMSGroupConst.apiPath, group);
  }

  updateGroup(group: MMSGroupDto): Observable<MMSGroupDto> {
    return this.utilityService.put(MMSGroupConst.apiPath + `/${group.id}`, group);
  }
}
