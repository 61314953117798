<div class="TitleContainer">
    <div>
        <h1>Hello there, Welcome to MMS IRIS Support</h1>
        <h1>How can we help you today?</h1>
    </div>
    <div>
        <img src="./../../../../../assets/Images/Header.svg" alt="">
    </div>
</div>

<div class="card">
    <a (click)="navCreateNewIRISRequestGuide()">
        <div class="LinkCard">
            <img src="./../../../../../assets/Images/Createirisrequest.svg" alt="">
            <h4>How to create an IRIS Request</h4>
        </div>
    </a>
    <a (click)="navTrackRequestGuide()">
        <div class="LinkCard">
            <img src="./../../../../../assets/Images/TrackIrisRequest.svg" alt="">
            <h4>How to track your Request</h4>
        </div>
    </a>
</div>
