<div class="asset-code-function-container j-c-s m-container">
  <h1 class="mb-20 setup-heading">Asset Code Functions</h1>
  <div class="mr-20 float-right">
    <button kendoButton class="border-button ml-20 f-size-150" (click)="codeFunctionActions('export', grid)">
      Export
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="codeFunctionActions('import', [])">
      Import
    </button>
    <button kendoButton class="border-button ml-20 f-size-150"
      (click)="codeFunctionActions('createNewAssetCodeFunction', [])">
      Create a new Asset Code Function
    </button>
  </div>

  <!-- Filter actions -->
  <section class="manage-contentbox">
    <section class="mt-20 d-flex">
      <div class="mt-20 pr-20 d-flex w-55">
        <kendo-textbox [(ngModel)]="searchedText" (click)="textSearched($event)"></kendo-textbox>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="searchResetGridActions('search')">
          Search
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="searchResetGridActions('reset')">
          Reset
        </button>
      </div>
    </section>

    <!-- Table data -->
    <section class="mt-10">
      <kendo-grid [kendoGridBinding]="gridData" [sortable]="true" [resizable]="true" #grid="kendoGrid" [pageable]="true"
        [pageSize]="50">
        <!-- download columns to excel -->
        <kendo-grid-excel [fetchData]="commonService.exportGridData(this.gridData, '', 'asc')" [fileName]="fileName">
          <kendo-excelexport-column field="branch" title="Branch"> </kendo-excelexport-column>
          <kendo-excelexport-column field="branchDescription" title="Branch Description"> </kendo-excelexport-column>
          <kendo-excelexport-column field="company" title="Company"> </kendo-excelexport-column>
          <kendo-excelexport-column field="location" title="Location"> </kendo-excelexport-column>
          <kendo-excelexport-column field="asset" title="Asset"> </kendo-excelexport-column>
          <kendo-excelexport-column field="assetDescription" title="Asset Description"> </kendo-excelexport-column>
          <kendo-excelexport-column field="assetFunction" title="Asset Function"> </kendo-excelexport-column>
          <kendo-excelexport-column field="assetGroups" title="Asset Groups"> </kendo-excelexport-column>
        </kendo-grid-excel>
        <!-- Branch -->
        <kendo-grid-column field="branch" title="Branch" [width]="11">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="Javascript:void(0);" (click)="navigateToCodeFunctionDetails(dataItem)">
              {{ dataItem.branch }}
            </a>
          </ng-template>
        </kendo-grid-column>
        <!-- Branch Description -->
        <kendo-grid-column field="branchDescription" title="Branch Description" [width]="20"> </kendo-grid-column>
        <!-- Company -->
        <kendo-grid-column field="company" title="Company" [width]="10"> </kendo-grid-column>
        <!-- Location -->
        <kendo-grid-column field="location" title="Location" [width]="9"> </kendo-grid-column>
        <!-- Asset -->
        <kendo-grid-column field="asset" title="Asset" [width]="8"> </kendo-grid-column>
        <!-- Asset Description -->
        <kendo-grid-column field="assetDescription" title="Asset Description" [width]="15"> </kendo-grid-column>
        <!-- Asset Function -->
        <kendo-grid-column field="assetFunction" title="Asset Function" [width]="12"> </kendo-grid-column>
        <!-- Asset Groups -->
        <kendo-grid-column field="assetNames" title="Asset Groups" [width]="14"> </kendo-grid-column>
      </kendo-grid>
    </section>
  </section>
</div>