<div class="iris-locationDetail-container j-c-s main-container-padding">
    <h1 class="mb-20 setup-heading">{{heading}}</h1>
    <br />
    <section class="mt-20">
        <!-- Label -->
        <div class="mt-20 w-50">
            <div class="w-15"><kendo-label>Label<span style="color:red"> *</span></kendo-label></div>
            <div class="w-75"><kendo-textbox [required]="true" [maxlength]="50" [(ngModel)]="label"></kendo-textbox>
            </div>
            <kendo-formerror *ngIf="requiredLabel">Required field!</kendo-formerror>
        </div>
        <!-- DOA Amount & Currency-->
        <div class="mt-10 w-100 d-flex">
            <!-- DOA Amount -->
            <div class="w-50">
                <div class="w-15"><kendo-label>DOA Amount<span style="color:red"> *</span></kendo-label></div>
                <div class="w-75"><kendo-numerictextbox [min]="0" [format]="'0'" [decimals]="0" [(ngModel)]="doaLevel"
                        [spinners]="false" (change)="onAmmountChange()"></kendo-numerictextbox></div>
            </div>
            <!-- Currency-->
            <div class="w-50">
                <div class="w-15"><kendo-label>Currency<span style="color:red"> *</span></kendo-label></div>
                <div class="w-50"><kendo-dropdownlist [data]="currencyList" [(ngModel)]="currency"
                        [defaultItem]="defaulCurrencytItem" [textField]="'key'" [valueField]="'key'"
                        [valuePrimitive]="true" required>
                    </kendo-dropdownlist></div>
                <kendo-formerror *ngIf="requiredCurrency">Required Currency!</kendo-formerror>
            </div>
        </div>
        <!-- BU List-->
        <div class="mt-10 w-50">
            <div class="w-15"><kendo-label>BU Name<span style="color:red"> *</span></kendo-label></div>
            <div *ngIf='!isNew' class="w-75"><kendo-textbox [required]="true" [ngModel]="buName"
                    [disabled]="true"></kendo-textbox></div>
            <div *ngIf='isNew' class="w-75"><kendo-dropdownlist [data]="buList" [(ngModel)]="BUId"
                    [defaultItem]="defaulBUtItem" [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true"
                    required="requiredBU">
                </kendo-dropdownlist></div>
            <kendo-formerror *ngIf="requiredBU">Required Business Unit!</kendo-formerror>
        </div>
        <!-- Is Active -->
        <div class="mt-10 w-100">
            <div class="w-15"><kendo-label [for]="select"> Is Active </kendo-label></div>
            <div class="ml-20 w-15"><input type="checkbox" [(ngModel)]="isActive" #select kendoCheckBox /></div>
        </div>
    </section>
    <br />
    <div class="ml-300">
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="action('save')">Save</button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">Cancel</button>
    </div>
</div>