
export const allIrisMockData ={
    "data": [
        {
            "TrackingNumber": "IRIS000893",
            "RequestState": "Pending Endorsement",
            "Asset": "Gorgon",
            "Title": "Test Draft and Open - Updated new",
            "Justification": "Test Draft and Open",
            "Description": "Test Draft and Open",
            "DisposalMethod": "Disposal via Auction",
            "DepartmentName": "ETC",
            "AssetLocation": "Barrow Island",
            "CreatedByName": "Zoaibuddin Kazi",
            "AssignedTo": "Kirankumar Bakwad, ",
            "CreatedDateTime": "2023-12-05T10:18:39Z",
            "RequestId": "893",
            "RequestIssueId": "1088",
            "IRISMaterialType": "General Material"
        },
        {
            "TrackingNumber": "IRIS000892",
            "RequestState": "Pending Endorsement",
            "Asset": "WA Oil",
            "Title": "Test-Submit-endoser",
            "Justification": "Test-Submit-endoser",
            "Description": "Test-Submit-endoser",
            "DisposalMethod": "Disposal via Scrap",
            "DepartmentName": "ETC",
            "AssetLocation": "Barrow Island",
            "TotalEstimatedCost": 1476.00000000,
            "CreatedByName": "Kirankumar Bakwad",
            "AssignedTo": "Kirankumar Bakwad, ",
            "CreatedDateTime": "2023-12-05T10:04:17Z",
            "RequestId": "892",
            "RequestIssueId": "1087",
            "IRISMaterialType": "General Material"
        },
        {
            "TrackingNumber": "IRIS000891",
            "RequestState": "Cancelled",
            "Asset": "WA Oil",
            "Title": "test",
            "Justification": "test",
            "Description": "test",
            "DisposalMethod": "Disposal via Scrap",
            "DepartmentName": "ETC",
            "AssetLocation": "Barrow Island",
            "TotalEstimatedCost": 7326.00000000,
            "CreatedByName": "Kirankumar Bakwad",
            "CreatedDateTime": "2023-12-04T15:05:48Z",
            "RequestId": "891",
            "RequestIssueId": "1086",
            "IRISMaterialType": "General Material"
        },
        {
            "TrackingNumber": "IRIS000890",
            "RequestState": "Disposed",
            "Asset": "WA Oil",
            "Title": "Request-submitFlow-1000",
            "Justification": "Request-submitFlow-1000",
            "Description": "Request-submitFlow-1000",
            "DisposalMethod": "Disposal via Auction",
            "DepartmentName": "ETC",
            "AssetLocation": "Barrow Island",
            "TotalEstimatedCost": 5658.00000000,
            "CreatedByName": "Kirankumar Bakwad",
            "AssignedTo": "Kirankumar Bakwad, ",
            "CreatedDateTime": "2023-12-04T12:44:50Z",
            "RequestId": "890",
            "RequestIssueId": "1085",
            "IRISMaterialType": "General Material",
            "AuctioneerName": "Ross Auctions"
        },
        {
            "TrackingNumber": "IRIS000889",
            "RequestState": "Draft",
            "Asset": "WA Oil",
            "Title": "test",
            "Justification": "test",
            "Description": "test",
            "DisposalMethod": "Disposal via Scrap",
            "DepartmentName": "ETC",
            "AssetLocation": "Barrow Island",
            "TotalEstimatedCost": 44.00000000,
            "CreatedByName": "Kirankumar Bakwad",
            "CreatedDateTime": "2023-12-04T07:56:18Z",
            "RequestId": "889",
            "RequestIssueId": "1084",
            "IRISMaterialType": "General Material"
        },
        {
            "TrackingNumber": "IRIS000888",
            "RequestState": "Pending Endorsement",
            "Asset": "WA Oil",
            "Title": "Test-Cancelled-Request",
            "Justification": "Test-Cancelled-Request",
            "Description": "Test-Cancelled-Request",
            "DisposalMethod": "Disposal via Scrap",
            "DepartmentName": "ETC",
            "AssetLocation": "Barrow Island",
            "TotalEstimatedCost": 5380.00000000,
            "CreatedByName": "Kirankumar Bakwad",
            "AssignedTo": "Kirankumar Bakwad, ",
            "CreatedDateTime": "2023-12-04T07:27:13Z",
            "RequestId": "888",
            "RequestIssueId": "1083",
            "IRISMaterialType": "General Material"
        },
        {
            "TrackingNumber": "IRIS000887",
            "RequestState": "Open",
            "Asset": "WA Oil",
            "Title": "test-documentNumber",
            "Justification": "test-documentNumber",
            "Description": "test-documentNumber",
            "DisposalMethod": "Expired or Damaged",
            "DepartmentName": "ETC",
            "AssetLocation": "Barrow Island",
            "CreatedByName": "Kirankumar Bakwad",
            "CreatedDateTime": "2023-12-04T07:01:13Z",
            "RequestId": "887",
            "RequestIssueId": "1082",
            "IRISMaterialType": "Financial and JDE Material Adjustments",
            "JDEDocumentNumber": "1111"
        },
        {
            "TrackingNumber": "IRIS000886",
            "RequestState": "Open",
            "Asset": "Gorgon",
            "Title": "JDE Test",
            "Justification": "JDE Test",
            "Description": "JDE Test",
            "DepartmentName": "1234",
            "AssetLocation": "Barrow Island",
            "CreatedByName": "Pradeep Erumalla",
            "CreatedDateTime": "2023-12-04T06:29:48Z",
            "RequestId": "886",
            "RequestIssueId": "1081"
        },
        {
            "TrackingNumber": "IRIS000885",
            "RequestState": "Open",
            "Asset": "Gorgon",
            "Title": "JDE Test",
            "Justification": "JDE Test",
            "Description": "JDE Test",
            "DisposalMethod": "Expired or Damaged",
            "DepartmentName": "1234",
            "AssetLocation": "Barrow Island",
            "CreatedByName": "Pradeep Erumalla",
            "CreatedDateTime": "2023-12-04T06:29:48Z",
            "RequestId": "885",
            "RequestIssueId": "1080",
            "IRISMaterialType": "Financial and JDE Material Adjustments",
            "JDEDocumentNumber": "12222312"
        }

],
"groups": null,
"aggregates": null,
"total": 10780,
"errors": null
}