<div class="SummaryContainer">
    <!-- <div class="panelbar-wrapper">
        <kendo-panelbar>
            <kendo-panelbar-item>
                <ng-template kendoPanelBarItemTitle class="SubHeader">Available files</ng-template>
                <kendo-panelbar-item title="Getting Started With IRIS"
                    (click)="navGetStartedGuide()"></kendo-panelbar-item>
                <kendo-panelbar-item title="Creating a new IRIS Request"
                    (click)="navCreateNewIRISRequestGuide()"></kendo-panelbar-item>
                <kendo-panelbar-item title="Tracking your Request"
                    (click)="navTrackRequestGuide()"></kendo-panelbar-item>
            </kendo-panelbar-item>
        </kendo-panelbar>
    </div> -->
    <section class="mt-20">
        <kendo-panelbar>
            <kendo-panelbar-item>
                <ng-template kendoPanelBarItemTitle>
                    <div class="title" (click)="navGetStartedGuide('')">Getting Started with IRIS</div>
                </ng-template>
                <ng-template kendoPanelBarContent>
                    <div class="pl-35">
                        <div class="subTitle" (click)="navGetStartedGuide('about_iris')">About IRIS</div>
                        <div class="subTitle" (click)="navGetStartedGuide('accessing_tool')">Accessing the Tool</div>
                        <div class="subTitle" (click)="navGetStartedGuide('how_does_iris_work')">How Does IRIS Work
                        </div>
                        <div class="subTitle" (click)="navGetStartedGuide('my_iris')">My IRIS</div>
                    </div>
                </ng-template>
            </kendo-panelbar-item>
            <kendo-panelbar-item>
                <ng-template kendoPanelBarItemTitle>
                    <div class="title" (click)="navCreateNewIRISRequestGuide('')">Creating a new IRIS Request</div>
                </ng-template>
                <ng-template kendoPanelBarContent>
                    <div class="pl-35">
                        <div class="subTitle" (click)="navCreateNewIRISRequestGuide('process_overflow')">Process
                            Overview</div>
                        <div class="subTitle" (click)="navCreateNewIRISRequestGuide('filling_details')">Filling in the
                            Details</div>
                        <div class="subTitle" (click)="navCreateNewIRISRequestGuide('material_type')">Selecting the
                            Material Type</div>
                        <div class="subTitle" (click)="navCreateNewIRISRequestGuide('disposal_method')">Proposing a
                            Disposal Method</div>
                        <div class="subTitle" (click)="navCreateNewIRISRequestGuide('adding_attachments')">Adding
                            Attachments</div>
                        <div class="subTitle" (click)="navCreateNewIRISRequestGuide('technical_endorser')">Selecting
                            Technical Reviewer and <br>
                            Technical Endorser</div>
                    </div>
                </ng-template>
            </kendo-panelbar-item>
            <kendo-panelbar-item>
                <ng-template kendoPanelBarItemTitle>
                    <div class="title" (click)="navTrackRequestGuide('')">Tracking your Request</div>
                </ng-template>
                <ng-template kendoPanelBarContent>
                    <div class="pl-35">
                        <div class="subTitle" (click)="navTrackRequestGuide('iris_screen')">My IRIS Screen</div>
                        <div class="subTitle" (click)="navTrackRequestGuide('request_status')">Request Status</div>
                        <div class="subTitle" (click)="navTrackRequestGuide('approval_flow')">Approval Flow</div>
                    </div>
                </ng-template>
            </kendo-panelbar-item>
        </kendo-panelbar>
    </section>
</div>