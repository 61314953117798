import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { IRISAuctioneersConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/IRIS-Auctioneer/iris-auctioneer.constants';
import { IRISAuctioneersGridDTO } from '../../models/iris-auctioneers.model';

@Injectable({
  providedIn: 'root',
})
export class IrisAuctioneersService extends HttpAbstractService<IRISAuctioneersGridDTO> {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    super(http);
  }

  // to get the IRIS Auctioneers Grid data
  getIRISAuctioneersGrid(): Observable<IRISAuctioneersGridDTO[]> {
    return this.httpUtilityService.get(`${IRISAuctioneersConstants.irisAuctioneers}`);
  }

  // to create new IRIS Auctioneers
  createIRISAuctioneers(data: IRISAuctioneersGridDTO): Observable<IRISAuctioneersGridDTO> {
    return this.httpUtilityService.post(`${IRISAuctioneersConstants.irisAuctioneers}`, data);
  }

  // to edit existing IRIS Auctioneers
  editIRISAuctioneers(data: IRISAuctioneersGridDTO, id: number): Observable<IRISAuctioneersGridDTO> {
    return this.httpUtilityService.put(`${IRISAuctioneersConstants.irisAuctioneers}/${id}`, data);
  }
}
