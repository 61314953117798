<div class="iris-locationDetail-container j-c-s main-container-padding">
    <h1 class="mb-20 setup-heading">My Profile</h1>
    <div class="mr-20 float-right">
        <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">Cancel</button>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="action('save')">Save</button>
    </div>
    <section class="mt-20">
        <kendo-label>Email Manager</kendo-label>
        <div class="content-border">
            <!-- Email ID -->
            <div class="w-100 d-flex">
                <div class="w-10">
                    <kendo-label>Email ID:</kendo-label>
                    <br />
                </div>
                <div class="w-50"><kendo-textbox [required]="true"
                        placeholder="Please provide Email Id here for notifications" [maxlength]="256"
                        [(ngModel)]="emailId"></kendo-textbox>
                        <kendo-formerror *ngIf="!isValidEmailId">Email expected!</kendo-formerror>
                </div>
            </div>
            <!-- Email Toggle -->
            <div class="mt-10 w-100 d-flex">
                <div class="w-10"><kendo-label [for]="select">Email Toggle:</kendo-label></div>
                <div class="w-50"><input type="checkbox" [(ngModel)]="emailToggle" #select kendoCheckBox /></div>
            </div>
        </div>
    </section>
</div>