import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { DisposalMethod, IRISRequestDto, MaterialType, TechnicalEndorserDto } from '../../models/iris-request-workflow/iris-request.model';
import { IRISRequestConstants } from 'src/app/core/constants/IRIS_Constants/iris-requester/iris-requester.constants';
import { LineDetailsDto } from '../../models/iris-request-workflow/line-item.model';
import { RequestIssueDTO } from '../../models/iris-request-workflow/request-issue/request-issue.model';
import { MaterialManagementInventoryDto } from '../../models/iris-request-workflow/material-management-inventory.model';

@Injectable({
  providedIn: 'root',
})
export class IrisRequestService extends HttpAbstractService<IRISRequestDto> {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    super(http);
  }

  // create new iris-request
  createIRISRequest(data: IRISRequestDto): Observable<IRISRequestDto> {
    return this.httpUtilityService.post(`${IRISRequestConstants.requestAPI}`, data);
  }

  // to get list of IRIS-Requests
  getRequest(): Observable<IRISRequestDto[]> {
    return this.httpUtilityService.get(`${IRISRequestConstants.requestAPI}`);
  }

  // get request by ID
  getRequestByID(id: number): Observable<IRISRequestDto> {
    return this.httpUtilityService.get(`${IRISRequestConstants.requestAPI}/${id}`);
  }

  // to edit|update existing IRIS-Request
  editupdateRequest(data: IRISRequestDto, id: number): Observable<IRISRequestDto> {
    return this.httpUtilityService.put(`${IRISRequestConstants.requestAPI}/${id}`, data);
  }

  // to get material-type list for IRIS-Request
  getMaterialType(): Observable<MaterialType[]> {
    return this.httpUtilityService.get(`${IRISRequestConstants.materialTypeAPI}`);
  }

  // to get disposal-method list for IRIS-Request
  getDisposalMethod(): Observable<DisposalMethod[]> {
    return this.httpUtilityService.get(`${IRISRequestConstants.disposalMethodAPI}`);
  }

  // to add new item in line details
  addNewItem(data: LineDetailsDto): Observable<LineDetailsDto> {
    return this.httpUtilityService.post(`${IRISRequestConstants.addItemAPI}`, data);
  }

  // to edit|update existing line item details
  editLineItem(data: LineDetailsDto, id: number): Observable<LineDetailsDto> {
    return this.httpUtilityService.put(`${IRISRequestConstants.addItemAPI}/${id}`, data);
  }

  deleteLineItem(id: number): Observable<any> {
    return this.httpUtilityService.delete(`${IRISRequestConstants.addItemAPI}/${id}`);
  }

  // to get details of line details
  getRequestLine(): Observable<LineDetailsDto> {
    return this.httpUtilityService.get(`${IRISRequestConstants.addItemAPI}`);
  }

  // to get details of line details by Id
  getRequestLineByID(id: number): Observable<LineDetailsDto[]> {
    return this.httpUtilityService.get(`${IRISRequestConstants.requestLineByIdAPI}/${id}`);
  }

  // to create new request-issue
  createRequestIssue(data: RequestIssueDTO): Observable<RequestIssueDTO> {
    return this.httpUtilityService.post(`${IRISRequestConstants.requestIssueAPI}`, data);
  }

  uploadLineItemsExcel(data: LineDetailsDto[]): Observable<LineDetailsDto[]> {
    return this.httpUtilityService.post(`${IRISRequestConstants.lineItemsImportAPI}`, data);
  }

  // to get request details Id
  getRequestIssueByID(id: number): Observable<RequestIssueDTO> {
    return this.httpUtilityService.get(`${IRISRequestConstants.requestIssueByIdAPI}/${id}`);
  }

  // to search particular request by tracking number
  searchRequest(name: string): Observable<LineDetailsDto> {
    return this.httpUtilityService.get(`${IRISRequestConstants.searchTrackingNumberAPI}/${name}`);
  }

  // to get list of technical endorsers to create IRIS request
  getTechnicalEndorserUsers(): Observable<TechnicalEndorserDto[]> {
    return this.httpUtilityService.get(`${IRISRequestConstants.technicalEndorserAPI}`);
  }

  // download all the Line Items Attachments for IRIS Request
  downloadAllAttachments(id: number): Observable<any> {
    return this.httpUtilityService.get(`${IRISRequestConstants.downloadLineItemsAttachmentsAPI}/${id}`, { responseType: 'arraybuffer' });
  }

  // to change the Endorser of the IRIS_Request
  updateEndorser(id: number, data: LineDetailsDto): Observable<LineDetailsDto> {
    return this.httpUtilityService.put(`${IRISRequestConstants.createRequestIssueAPI}/${id}`, data);
  }

  // to submit the IRIS_Request
  submitIRISRequest(id: number, data: LineDetailsDto): Observable<LineDetailsDto> {
    return this.httpUtilityService.put(`${IRISRequestConstants.requestIssueAPI}/${id}`, data);
  }

  // to delete IRIS_Request 
  deleteIRISRequest(id: number,): Observable<IRISRequestDto> {
    return this.httpUtilityService.delete(`${IRISRequestConstants.requestAPI}/${id}`);
  }

  // to Approve or Reject IRIS_Request
  approveRejectRequest(data: IRISRequestDto): Observable<IRISRequestDto> {
    return this.httpUtilityService.post(`${IRISRequestConstants.approveRejectRequestAPI}`, data);
  }

  getAllMaterialNumbers(): Observable<string[]> {
    return this.httpUtilityService.getAll(IRISRequestConstants.getAllMaterialNumbers)
  }

  getMaterialInfo(itemNumber: string): Observable<MaterialManagementInventoryDto> {
    return this.httpUtilityService.get(`${IRISRequestConstants.getMaterialInfo}/${itemNumber}`)
  }
}
