export const RequestFlowStepsConst = {
    Created: 0,
    Endorse: 1,
    Finance_Update: 2,
    Finance_Review: 3,
    DOA_Approval: 4,
    JOA_Approval: 5,
    Transfer_in_SAP: 6,
    Disposed: 7,
    Bill_of_Sale: 8,
    Removal_Assignment: 9,
    Removed_from_SAP: 10
}

export const StepsStatusConst = {
    Approved: 'Approved',
    Rejected: 'Rejected',
    Recalled: 'Recalled'
}