import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { AssetLocationGridDTO } from '../../models/asset-locations.model';
import { AssetLocationService } from '../../services/asset-locations/asset-location.service';
import { CommonService } from 'src/app/core/services/common.service';
import { InputFieldsConstants } from 'src/app/core/constants/input-fields.constants';

@Component({
  selector: 'app-asset-locations',
  templateUrl: './asset-locations.component.html',
  styleUrls: ['./asset-locations.component.scss'],
})
export class AssetLocationsComponent implements OnInit {
  checked: boolean = false;
  gridData: AssetLocationGridDTO[] = [];
  searchedText: string = '';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private dialogService: DialogService,
    private locationService: AssetLocationService,
    public commonService: CommonService
  ) {}

  ngOnInit() {
    this.getAssetLocationGridData();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('assetsLocationDetails');
  }

  // get asset location records
  getAssetLocationGridData() {
    this.locationService
      .getAssetLocationGrid()
      .subscribe((data: AssetLocationGridDTO[]) => {
        this.gridData = data;
        if (this.checked === false) {
          this.showActiveLocations();
        }
      });
  }

  // filter data for active locations
  showActiveLocations() {
    const filteredData = this.gridData.filter((ele: AssetLocationGridDTO) => {
      return ele.isActive === true;
    });
    this.gridData = filteredData;
  }
  
  // to show active and inactive assets records
  showHideActiveNInactiveassets(event: any) {
    if (this.checked == false) {
      this.getAssetLocationGridData();
    } else {
      this.showActiveLocations();
    }
  }

  // navigate to asset-detail page
  assetLocationDetails(data: AssetLocationGridDTO) {
    this.navigateToCreateUpdateLocation('edit', data);
  }

  // navigate to Create | Edit asset locations; status: 0 for create and 1 for edit
  navigateToCreateUpdateLocation(status: string, records: AssetLocationGridDTO) {
    const id = status === 'create' ? 0 : records['id'];
    const locationName = status === 'create' ? '' : records['locationName'];
    const isActive = status === 'create' ? false : records['isActive'];
    const order = status === 'create' ? 0 : records['order'];
    const createdBy = status === 'create' ? '' : records['createdBy'];
    const modifiedBy = status === 'create' ? '' : records['modifiedBy'];
    const businessUnitId = status === 'create' ? '' : records['businessUnitId'];
    const url = BackOfficeRoutingConstants.assetLocationDetailPath;
    this.router.navigate([`../${url}`], {
      state: {
        actionName: status,
        id: id,
        locationName: locationName,
        isActive: isActive,
        order: order,
        createdBy: createdBy,
        modifiedBy: modifiedBy,
        businessUnitId: businessUnitId,
      },
      relativeTo: this.route,
    });
  }

  // search-box
  textSearched(event: any) {
    if (event.target.value) {
      this.gridFilter(event.target.value);
    } else {
      this.getAssetLocationGridData();
    }
  }

  // to perform actions like import excel and add new assets
  assetActions(actionName: string) {
    switch (actionName) {
      case 'import': {
        this.importExcel();
        break;
      }
      case 'createNewAssetLocation': {
        this.navigateToCreateUpdateLocation('create', {});
        break;
      }
    }
  }

  // search the asset from grid and to clear searched filters
  searchResetGridActions(actionSeletcted: string) {
    switch (actionSeletcted) {
      case 'search': {
        this.gridFilter(this.searchedText);
        break;
      }
      case 'reset': {
        this.searchedText = '';
        this.getAssetLocationGridData();
        break;
      }
    }
  }

  // filter grid-data
  gridFilter(text: string) {
    const filteredItems = this.gridData.filter((ele: AssetLocationGridDTO) => {
      return ele?.locationName?.toLowerCase().includes(text.toLowerCase());
    });
    this.gridData = filteredItems;
  }

  // upload location from the excel file
  importExcel() {
    const userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
    const fName = JSON.parse(sessionStorage.getItem('firstName')!);
    const lName = JSON.parse(sessionStorage.getItem('lastName')!);
    const userName = `${fName} ${lName}`;
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent,
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === 'Save') {
        let arr = JSON.parse(r.value).Sheet1 as Array<AssetLocationGridDTO>;
        let locationExcelData: AssetLocationGridDTO[] = arr.map((item: any) => {
          return {
            locationName: item['Location Name'].toString(),
            isActive: item['Is Active'],
            order: 0,
            businessUnitId: 1,
            createdBy: userName,
            modifiedBy: userMailID,
          };
        });
        this.locationService.importExcelData(locationExcelData)
          .subscribe((response: AssetLocationGridDTO[]) => {
            if (response) {
              this.getAssetLocationGridData();
              const msg = `Location ${InputFieldsConstants.importMSG}`;
              this.commonService.showNotificationMessage(msg, 'success', 'center');
            }
          });
      }
    });
  }
}
