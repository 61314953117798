<div class="content-top-action d-flex a-center jcsb">
    <h1>
        <a class="c-left"><span class="fa fa-fw fa-angle-left" (click)="goBackToOverviewPage('back')"></span></a>
        IRIS Request {{ trackingNumber }}
        <span class="fa fa-fw fa-angle-right"></span>
    </h1>
    <!-- Action buttons -->
    <div class="mr-20 float-right">
        <button kendoButton class="border-button ml-20 f-size-150" (click)="goBackToOverviewPage('cancel')">
            Cancel
        </button>
        <button kendoButton class="ml-20 f-size-150" [disabled]="!isItemsGridData"
            [ngClass]="isItemsGridData ? 'primary-button' : 'disabled-button'" (click)="action('save', [])">
            Save
        </button>
    </div>
</div>

<!-- Form fields -->
<form [formGroup]="requestIssueForm">
    <section class="iris-item-action-contentbox" id="ApprovalPage">
        <section class="mt-10 d-flex">
            <!-- Material Type -->
            <div class="mt-10 w-50 pr-20">
                <kendo-label [for]="materialType" text="Select Material Type"><span
                        class="mandatory">*</span></kendo-label>
                <kendo-dropdownlist class="mt-5" #materialType formControlName="materialType" [data]="materialTypeList"
                    [defaultItem]="materialTypeDefaultValue" textField="label" valueField="id" [valuePrimitive]="true"
                    required (valueChange)="materialSelected($event)"
                    [ngClass]="{'disable-dropdown': requestIssueForm.controls.materialType.status == 'DISABLED'}">
                </kendo-dropdownlist>
            </div>
            <!-- Disposal Method -->
            <div class="mt-10 w-50 pl-20">
                <kendo-label [for]="disposalMethod" text="Proposed Disposal Method"></kendo-label>
                <kendo-dropdownlist class="mt-5" #disposalMethod formControlName="disposalMethod"
                    [data]="disposalMethodList" [defaultItem]="bindDisposalMethodValue" textField="label"
                    valueField="id" [valuePrimitive]="true" required (valueChange)="disposalSelected($event)"
                    [ngClass]="{'disable-dropdown': requestIssueForm.controls.disposalMethod.status == 'DISABLED'}">
                </kendo-dropdownlist>
            </div>
        </section>
        <!-- JDE Number -->
        <section class="mt-10 d-flex" *ngIf="isJDEMaterialSelected">
            <div class="w-30">
                <kendo-label>JDE Document Number <span class="mandatory"> *</span></kendo-label>
                <br />
                <kendo-textbox [maxlength]="200" class="mt-5" value="0" formControlName="JDENo"></kendo-textbox>
            </div>
        </section>

        <!-- add-item and excel buttons  -->
        <div class="d-flex j-c-b a-center flex mb-10" style="margin-top: 32px">
            <h6 class="font-size-h6">Line Item Table</h6>
            <div class="d-flex j-c-e">
                <button kendoButton class="ml-20 f-size-150 btn_fs_12"
                    *ngIf="!isJDEMaterialSelected || isOther" (click)="action('download', grid)"
                    [disabled]="!isButtonEnabled"
                    [ngClass]="isButtonEnabled ? 'secondary-border-button' : 'disabled-button'">
                    Download Item template
                </button>
                <button kendoButton class="ml-20 f-size-150 btn_fs_12" *ngIf="isJDEMaterialSelected && isCycleCount"
                    [disabled]="!isButtonEnabled" (click)="exportToExcel(grid_cycleCount)"
                    [ngClass]="isButtonEnabled ? 'secondary-border-button' : 'disabled-button'">
                    Download Item template
                </button>
                <button kendoButton class="ml-20 f-size-150 btn_fs_12" (click)="action('upload', [])"
                    *ngIf="!isJDEMaterialSelected || isOther" [disabled]="!isButtonEnabled"
                    [ngClass]="isButtonEnabled ? 'secondary-border-button' : 'disabled-button'">
                    Upload New Item
                </button>
                <button kendoButton class="ml-20 f-size-150 btn_fs_12" *ngIf="isJDEMaterialSelected && isCycleCount"
                    [disabled]="!isButtonEnabled"
                    [ngClass]="isButtonEnabled ? 'secondary-border-button' : 'disabled-button'">
                    Bulk Upload Items
                </button>
                <!-- <button kendoButton class="secondary-border-button ml-20 f-size-150 btn_fs_12"> Bulk Addition </button> -->
                <button kendoButton class="ml-20 f-size-150 btn_fs_12" (click)="action('bulkAddition', [])"
                    [disabled]="!isButtonEnabled" *ngIf="isJDEMaterialSelected && isBulkAddition"
                    [ngClass]="isButtonEnabled ? 'secondary-border-button' : 'disabled-button'">
                    Bulk Addition
                </button>
                <button kendoButton class="ml-20 f-size-150 btn_fs_12" (click)="action('addNewItem', [])"
                    *ngIf="!isCycleCount" [disabled]="!isButtonEnabled"
                    [ngClass]="isButtonEnabled ? 'secondary-border-button' : 'disabled-button'">
                    Add New items
                </button>
            </div>
        </div>

        <!-- Table data -->
        <section class="mt-20 m-container-2 requestGrid">
            <kendo-grid *ngIf="!isCycleCount" [data]="gridData" #grid="kendoGrid" id="lineDetailsGrid">
                <!-- To download the grid in excel form -->
                <kendo-grid-excel [fetchData]="commonService.exportGridData(this.gridData, '', 'asc')"
                    [fileName]="fileName">
                    <kendo-excelexport-column field="index" title="Line Items">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="itemNumber" title="Item Number">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="itemDescription" title="Item Description">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="manufacturerName" title="Manufacturer Name">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="manufacturerPartNumber" title="Manufacturer Part Number">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="inventLabel" title="Inventory Condition">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="unitCost" title="Unit Cost">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="itemQty" title="Disposal Quantity">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="estimatedCost" title="Estimated Cost">
                    </kendo-excelexport-column>
                </kendo-grid-excel>

                <kendo-grid-column field="index" title="Line Items" [width]="7"
                    [style]="{ 'text-align': 'right', 'padding-right': '50px' }"></kendo-grid-column>
                <kendo-grid-column field="itemNumber" title="Item Number" [width]="11"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="itemDescription" title="Item Description" [width]="14"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="manufacturerName" title="Manufacturer Name" [width]="13"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="manufacturerPartNumber" title="Manufacturer Part Number" [width]="12"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="inventLabel" title="Inventory Condition" [width]="12"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="unitCost" title="Unit Cost" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="itemQty" title="Disposal Quantity" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="estimatedCost" title="Estimated Cost" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>

                <kendo-grid-column field="icon" title="" [width]="4" [style]="{ 'padding-left': '12px' }">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div kendoPopoverAnchor [popover]="myPopover">
                            <span class="k-icon k-i-more-vertical"></span>
                        </div>
                        <kendo-popover #myPopover position="left" class="edit-pop">
                            <ng-template kendoPopoverBodyTemplate>
                                <div class="popover-content">
                                    <a (click)="editLineItem(dataItem)">
                                        <span class="material-icons md-18"> create </span>
                                        <span style="margin-left: 10px"> Edit Line Item </span>
                                    </a>
                                    <hr class="separator ml-5 mr-5" />
                                    <a (click)="deleteLineItem(dataItem)">
                                        <span class="material-icons md-18">delete_forever</span>
                                        <span style="margin-left: 10px">Delete Line Item</span>
                                    </a>
                                </div>
                            </ng-template>
                        </kendo-popover>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>

            <kendo-grid *ngIf="isCycleCount"  #grid_cycleCount="kendoGrid" id="lineDetailsGrid_cycleCount">
                <!-- To download the grid in excel form -->
                <kendo-grid-excel [fetchData]="commonService.exportGridData([], '', 'asc')"
                    [fileName]="fileName">
                    <kendo-excelexport-column field="itemNumber" title="Item Number">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="itemDescription" title="Description">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="qtyOnHand" title="Quantity On Hand">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="qtyCounted" title="Quantity Counted">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="countDate" title="Count Date">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="qtyVariance" title="Quantity Variance">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="amountVariance" title="Amount Variance">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="branch_Plant" title="Branch/Plant">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="location" title="Location">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="lot_Serial" title="Lot/Serial">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="cycleNumber" title="Cycle Number">
                    </kendo-excelexport-column>
                </kendo-grid-excel>

                <kendo-grid-column field="index" title="Line Items" [width]="7"
                    [style]="{ 'text-align': 'right', 'padding-right': '50px' }"></kendo-grid-column>
                <kendo-grid-column field="itemNumber" title="Item Number" [width]="11"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="itemDescription" title="Description" [width]="14"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="qtyOnHand" title="Quantity On Hand" [width]="13"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="qtyCounted" title="Quantity Counted" [width]="12"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="countDate" title="Count Date" [width]="12"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="qtyVariance" title="Quantity Variance" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="amountVariance" title="Amount Variance" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="branch_Plant" title="Branch/Plant" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                    <kendo-grid-column field="location" title="Location" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                    <kendo-grid-column field="lot_Serial" title="Lot/Serial" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                    <kendo-grid-column field="cycleNumber" title="Cycle Number" [width]="9"
                    [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
                <kendo-grid-column field="icon" title="" [width]="4" [style]="{ 'padding-left': '12px' }">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div kendoPopoverAnchor [popover]="myPopover">
                            <span class="k-icon k-i-more-vertical"></span>
                        </div>
                        <kendo-popover #myPopover position="left" class="edit-pop">
                            <ng-template kendoPopoverBodyTemplate>
                                <div class="popover-content">
                                    <hr class="separator ml-5 mr-5" />
                                    <a (click)="deleteLineItem_CycleCount(dataItem)">
                                        <span class="material-icons md-18">delete_forever</span>
                                        <span style="margin-left: 10px">Delete Line Item</span>
                                    </a>
                                </div>
                            </ng-template>
                        </kendo-popover>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </section>

        <kendo-gridlayout class="w-50 mt-20" [rows]="['auto']" [cols]="[180, 260]" [gap]="5" *ngIf="isItemsGridData">
            <kendo-gridlayout-item [col]="1" [row]="1">
                <p class="head-data">Number of Line Items</p>
                <p class="deatil-data">{{ gridData.length }}</p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [col]="2" [row]="1">
                <p class="head-data">Total Estimated Stock Value</p>
                <p class="deatil-data">{{ totalestimatedCost }}</p>
            </kendo-gridlayout-item>
        </kendo-gridlayout>

        <section *ngIf="isItemsGridData">
            <div class="mt-20 d-flex j-c-b flex">
                <div class="pr-10">
                    <fieldset class="mb-20 fieldset">
                        <legend class="font-size-xs">Attachments</legend>
                        <div class="fileuploadContainer">
                            <p class="text">Drop Files or Click to Upload</p>
                            <input class="fileupload" type="file" #fileInput (change)="onFilesUpload($event)"
                                accept="accptedFilesStr" formControlName="attachment" />
                        </div>
                        <!--  file details grid -->
                        <kendo-grid *ngIf="attachmentFileDetails && attachmentFileDetails.length" #myGrid
                            [data]="attachmentFileDetails" [hideHeader]="true" class="list-container" scrollable="none"
                            [style.width.px]="510">
                            <kendo-grid-column>
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                    <button kendoButton (click)="downloadFile(rowIndex)" look="flat" [icon]="'download'"
                                        class="download-button"></button>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column [width]="400">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span>{{ dataItem.fileName }}</span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column>
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                    <button (click)="removeFile(rowIndex)" class="actionBTN">
                                        <span class="material-icons md-18">delete_forever</span>
                                    </button>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid>
                    </fieldset>
                </div>
                <!--  -->
                <div class="pl-10">
                    <fieldset class="mb-20 fieldset">
                        <legend class="font-size-xs">Resolution Details</legend>
                        <div>
                            <kendo-label text="Comment"></kendo-label>
                        </div>
                        <kendo-textarea class="c-textarea" [rows]="3" formControlName="comment"></kendo-textarea>
                    </fieldset>
                </div>
            </div>
            <!--  -->
            <div class="mt-10 mb-20 w-50 pr-20">
                <kendo-label [for]="endorser" text="Select Technical Endorser"><span class="mandatory">
                        *</span></kendo-label>
                <kendo-dropdownlist class="mt-5" #endorser formControlName="endorser" [data]="endorserList"
                    [defaultItem]="endorserDefaultValue" textField="username" valueField="id" [valuePrimitive]="true"
                    required>
                </kendo-dropdownlist>
            </div>
        </section>
    </section>
</form>