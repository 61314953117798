export const BackOfficeRoutingConstants = {
    IRISDepartmentPath: 'IRISDepartments',
    IRISDepartmentDetailPath: 'IRISDepartmentDetail',
    assetLocationsPath: 'AssetLocations',
    assetLocationDetailPath: 'AssetLocationDetail',
    IRISAuctioneersPath: 'IRISAuctioneers',
    IRISAuctioneerDetailPath: 'IRISAuctioneerDetail',
    IRISSupplySpecialistPath: 'IRISAssetMaterialCoOrdinator',
    IRISSupplySpecialistDetailPath: 'IRISAssetMaterialCoOrdinatorDetail',
    IRISAssetJoaThresholdLimitPath: 'IRISAssetJOAThreshold',
    IRISAssetJoaThresholdLimitDetailPath: 'IRISAssetJOAThresholdDetail',
    AssetCodeFunctionPath: 'AssetCodeFunctions',
    AssetCodeFunctionDetailPath: 'AssetCodeFunctionDetail',
    GroupPath: 'MMSGroup',
    MMSGroupDetails: 'MMSGroupDetails',
    ManageNotificationsPath: 'ManageNotifications',
    IRISDOALevelsPath: 'IRISDOALevels',
    IRISDOALevelDetailPath: 'IRISDOALevelDetail',
    IRISDOAApprovalPath: 'IRISDOAApproval'
}