<div class="iris-JOAThresholdDetail-container m-container j-c-s main-container-padding">
  <h1 class="mb-20 setup-heading">{{heading}}</h1>

  <div class="mr-20 float-right">
    <button kendoButton class="primary-button ml-20 f-size-150" (click)="action('save')">
      Save
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">
      Cancel
    </button>
  </div>

  <section class="mt-20">
    <!-- BU List-->
    <div class="mt-10 w-35" *ngIf="selectedThresholdID===0">
      <div class="w-100"><kendo-label>BU Name<span style="color:red"> *</span></kendo-label></div>
      <div class="w-100"><kendo-dropdownlist [data]="buList" [(ngModel)]="BUId" [defaultItem]="defaulBUtItem"
          [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true" required="requiredBU"
          (valueChange)="onBuChange()">
        </kendo-dropdownlist></div>
      <kendo-formerror *ngIf="requiredBU">Required Business Unit!</kendo-formerror>
    </div>
    <!-- Asset Group List-->
    <div class="mt-10 w-35"*ngIf="selectedThresholdID===0">
      <div class="w-100"><kendo-label>Asset Groups<span style="color:red"> *</span></kendo-label></div>
      <div class="w-100"><kendo-dropdownlist [data]="assetGroupsbyBUList" [(ngModel)]="AssetId" [defaultItem]="defaulAssettItem"
          [textField]="'label'" [valueField]="'id'" [valuePrimitive]="true" required="requiredAsset"
          [disabled]="BUId===0">
        </kendo-dropdownlist></div>
      <kendo-formerror *ngIf="requiredAsset">Required Asset Group!</kendo-formerror>
    </div>
    <div class="mt-10 w-100 d-flex">
      <!-- JOA Amount -->
      <div class="w-20">
        <div class="w-100"><kendo-label> JOA Amount</kendo-label></div>
        <div class="w-100"><kendo-numerictextbox [min]="0" [format]="'0'" [decimals]="0" [(ngModel)]="joaAmount"
            [spinners]="false" (change)="onAmmountChange()" [maxlength]="21"></kendo-numerictextbox></div>
      </div>
      <div class="w-10"></div>
      <!-- Currency-->
      <div class="w-20">
        <div class="w-100"><kendo-label>Currency<span style="color:red"> *</span></kendo-label></div>
        <div class="w-100"><kendo-dropdownlist [data]="currencyList" [(ngModel)]="currency"
            [defaultItem]="defaulCurrencytItem" [textField]="'key'" [valueField]="'key'" [valuePrimitive]="true"
            required>
          </kendo-dropdownlist></div>
        <kendo-formerror *ngIf="requiredCurrency">Required Currency!</kendo-formerror>
      </div>
    </div>
    <!-- commercial approver input field -->
    <div class="mt-10 w-35">
      <div class="w-100"><kendo-label> Commercial Approver </kendo-label></div>
      <div class="w-100"><kendo-dropdownlist class="mt-5" [data]="commercialApproverList"
          [defaultItem]="defaultApprover" textField="username" valueField="id" [(ngModel)]="commercialApproverDto"
          (valueChange)="approverSelected($event)" required="requiredApprover">
        </kendo-dropdownlist></div>
        <kendo-formerror *ngIf="requiredApprover">Required Commercial Approver!</kendo-formerror>
    </div>
    <!-- waiver input field -->
    <div class="mt-10 w-25">
      <kendo-label class="k-checkbox-label" [for]="select" text="Is Waiver In Place"> </kendo-label>
      <br />
      &nbsp;<input type="checkbox" [(ngModel)]="isWaiver" #select kendoCheckBox />
    </div>
  </section>
</div>