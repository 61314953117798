import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpUtilityService } from "src/app/core/services/http.utility.service";
import { Observable } from 'rxjs';
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { AllIrisConst } from "src/app/core/constants/ALL_IRIS_Constants/AllIris.constants";
@Injectable({
    providedIn: 'root'
})
export class AllIrisService{
    constructor(private http: HttpClient, public httpUtilityService: HttpUtilityService) {
    }

    getAllRequestsData(data : any): Observable<DataBindingDirective> {
        return this.httpUtilityService.post<DataBindingDirective>(AllIrisConst.apiName + AllIrisConst.getAllRequests, data,);
    }
}
