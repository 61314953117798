import { BaseDto } from "./base.model";

export class ManageNotificationDto extends BaseDto {
    id !: number;
    emailId?: string;
    mmsuserId?: number;
    receivEmail?: boolean;
    secondaryEmailId?: string;
    delegateUseId?: number;
    isDelegationActive?: boolean;
}