<div class="d-flex a-center j-c-s MMS_dashboard">
  <h1 class="font-size-h6 pl-40 pt-15 mb-15 secondary-color">
    Welcome Back, {{ firstName }} {{ lastName }}
  </h1>
  <div class="w-25 pr-40 mr-20 mms-search">
    <kendo-textbox style="font-size: 12px !important;"
      class="font-size-base"
    >
      <ng-template kendoTextBoxSuffixTemplate>
        <i class="fa fa-search pr-10"></i>
      </ng-template>
    </kendo-textbox>
  </div>
</div>

<section class="mms-contentBox">
  <div class="MMSDetails">
    <h1 class="font-size-h6 mms-item active cursor" (click)="navigateToIRIS()">
      Investment Recovery And Inventory Solutions
    </h1>
    <h1 class="font-size-h6 BBMB">IRIS</h1>
    <div class="top-line mt-10 pt-10">
      <strong>Open IRIS</strong> <span class="mms-tip">0</span>
      <!-- <div class="mt-10">
        <p>No items to show...</p>
      </div> -->
    </div>
  </div>
</section>
