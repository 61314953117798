import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';
import { InputFieldsConstants } from 'src/app/core/constants/input-fields.constants';
import { AssetLocationGridDTO } from '../../../models/asset-locations.model';
import { AssetLocationService } from '../../../services/asset-locations/asset-location.service';
import { BusinessUnitDto } from '../../../models/bussinessunits.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-asset-locations-details',
  templateUrl: './asset-locations-details.component.html',
  styleUrls: ['./asset-locations-details.component.scss'],
})
export class AssetLocationsDetailsComponent implements OnInit, OnDestroy {
  heading: string = 'New Asset Location'
  locationID!: number;
  locationName!: string;
  isActive!: boolean;
  createdBy!: string;
  modifiedBy!: string;
  businessUnitId!: number;
  order!: number;
  actionStatus!: string;
  selectedBUId!: number;
  isFormValid: boolean = false;
  isLineSelected: boolean = true;
  BULists: BusinessUnitDto[] = [];
  public defaultAssignBUName: BusinessUnitDto = {
    id: 0,
    name: '',
  };

  constructor(
    private router: Router,
    private location: Location,
    private commonService: CommonService,
    private locationService: AssetLocationService,
    private titleService: Title
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.heading = stateData['locationName'] ? stateData['locationName'] : this.heading;
      this.actionStatus = stateData['actionName'];
      this.locationID = stateData['id'] ? stateData['id'] : 0;
      this.locationName = stateData['locationName'] ? stateData['locationName'] : '';
      this.isActive = stateData['isActive'] ? stateData['isActive'] : false;
      this.createdBy = stateData['createdBy'] ? stateData['createdBy'] : '';
      this.modifiedBy = stateData['modifiedBy'] ? stateData['modifiedBy'] : '';
      this.businessUnitId = stateData['businessUnitId'] ? stateData['businessUnitId'] : 1;
      this.order = stateData['order'] ? stateData['order'] : 0;
      sessionStorage.setItem('assetsLocationDetails', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('assetsLocationDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.actionStatus = sessionState?.actionName;
        this.locationID = sessionState?.locationID;
        this.locationName = sessionState?.locationName;
        this.isActive = sessionState?.isActive;
        this.createdBy = sessionState?.createdBy;
        this.modifiedBy = sessionState?.modifiedBy;
        this.businessUnitId = sessionState?.businessUnitId;
        this.order = sessionState?.order;
      }
    }
  }

  ngOnInit() {
    if (this.actionStatus === 'edit') {
      this.titleService.setTitle(this.locationName);
    }
    if (this.locationName) {
      this.isFormValid = true;
    }
    this.BULists = JSON.parse(sessionStorage.getItem('BUList')!);
    this.selectedLocationBUName();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('assetsLocationDetails');
  }

  // to map the location business unit with dropdown
  selectedLocationBUName(): void {
    let BURecords: BusinessUnitDto[] = JSON.parse(sessionStorage.getItem('BUList')!);
    for (let x of BURecords) {
      if (x['id'] == this.businessUnitId) {
        this.defaultAssignBUName = {
          id: x['id'],
          name: String(x['name']),
        };
      }
    }
  }

  // validate the department from input
  addEditDepartmentName(event: any) {
    if (event.target.value) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  // change business unit of the location
  selectAssignToGroup(event: any) {
    this.selectedBUId = event['id'];
  }

  // to save your changes
  action(actionType: string) {
    switch (actionType) {
      case 'cancel': {
        this.location.back();
        break;
      }
      case 'save': {
        this.addNewlocation();
        this.location.back();
        break;
      }
    }
  }

  // to Create | Edit asset location
  addNewlocation() {
    const userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
    const fName = JSON.parse(sessionStorage.getItem('firstName')!);
    const lName = JSON.parse(sessionStorage.getItem('lastName')!);
    const userName = `${fName} ${lName}`;
    const createdBy = this.createdBy ? this.createdBy : userName
    const businessUnitId = this.selectedBUId ? this.selectedBUId : this.businessUnitId;
    const order = this.order ? this.order : 0;
    const payload: AssetLocationGridDTO = {
      id: this.locationID,
      locationName: this.locationName,
      isActive: this.isActive,
      order: Number(order),
      createdBy: createdBy,
      modifiedBy: userMailID,
      businessUnitId: businessUnitId,
    };
    switch (this.actionStatus) {
      case 'create': {
        this.commonService.removeKey(payload, 'id');
        this.locationService.createNewAssetLocation(payload)
          .subscribe((data: AssetLocationGridDTO) => {
            if (data) { this.successUpdateMSG(); }
          });
        break;
      }
      case 'edit': {
        this.locationService.editAssetLocation(payload, this.locationID)
          .subscribe((data: AssetLocationGridDTO) => {
            if (data) { this.successUpdateMSG(); }
          });
      }
    }
  }

  successUpdateMSG() {
    const successMSG = `Location ${InputFieldsConstants.successMSG}`;
    const updateMSG = `Location ${InputFieldsConstants.updateMSG}`;
    const msg = this.actionStatus == 'create' ? successMSG : updateMSG;
    this.commonService.showNotificationMessage(msg, 'success', 'center');
  }
}
