export const SharedConst = {
  empty: '',
  isActive: 'isActive',
  validationMessage: 'Please review the highlighted fields before proceeding',
  fileRequired: 'File is required',
  fileFormat: 'Please select the valid file format',
  importApiName: '/Import',
  dateFormatWithTime: 'dd-MM-YYYY HH:mm:ss',
  dateFormat: 'dd-MMM-yyyy',
  deleteLineItem: 'Request line(s) are succesfully deleted',
  cancelLineItem: 'Request line(s) are succesfully cancelled',  
  editRequestMSG: 'updated successfully',
  createRequestMSG: 'created successfully'
};
