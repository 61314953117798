<div class="iris-SupplySpecialist-container m-container j-c-s main-container-padding">
  <h1 class="mb-20 setup-heading">
    Supply Base Specialist for {{detailsHeading}}
  </h1>
<!-- Actions buttons -->
  <div class="mr-20 float-right">
    <button kendoButton class="primary-button ml-20 f-size-150" (click)="action('save')">
      Save
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">
      Cancel
    </button>
  </div>
  <br>
  <!-- forms fields -->
  <section class="mt-20">
    <div class="mt-10 mx-5 w-40">
      <kendo-label text="Please select Supply Base Specialist"></kendo-label>
      <kendo-multiselect [filterable]="true" #multiselect id="multiSelect-dropdown"
        [data]="specialistDropdownData?.supplyBaseCoordinator" textField="username" valueField="id"
        (valueChange)="selectSpecialist($event)" [(ngModel)]="bindSpecialist">
        <ng-template kendoMultiSelectItemTemplate let-dataItem>
          <button id="multiDropdown" class="autoCompleteItem mb-5">
            {{ dataItem.username }}
          </button>
        </ng-template>
      </kendo-multiselect>
      <i class="fa fa-times close-icon dropdown-add-icon" aria-hidden="true"></i>
    </div>
  </section>
</div>