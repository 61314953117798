<h2 class="heading fixed-top"></h2>
<section class="userguide-content-box">
    <app-user-guide-header></app-user-guide-header>
    <div class="UsageGuideHeader">
        System Usage Guide
    </div>
    <div class="ContentParentContainer">

        <!-- Summary Container -->
        <app-system-usage-guide  (HelpCreateRequest)="scrollTo_HelpCreateRequest($event)"></app-system-usage-guide>

        <!-- Content Container -->
        <div class="ContentContainer">
            <div class="Header">Creating a New IRIS Request</div>
            <div class="SubHeader" #process_overflow>Process Overview</div>
            <p>We have simplified the process of creating a IRIS request for you. The illustration below provides a view
                of the steps involved.</p>
            <div>
                <img src="./../../../../../assets/Images/process_steps.PNG" alt="">
            </div>
            <p>
                Each step is further explained in detail in the sections below.<br>
                Note: All the fields marked with ‘*’ are mandatory and must be filled. If you miss to fill any of the
                fields and try to proceed the system will notify you about the missing details.
            </p>
            <div class="SubHeader" #filling_details>Filling in the Details</div>
            <p>
                Let’s start by creating a New IRIS Request. Click on ‘New IRIS Request’ Button and you will be
                redirected to a new screen.
            </p>
            <br>
            <div>
                <img src="./../../../../../assets/Images/filling_new_IRIS_details.PNG" alt="">
            </div>
            <p>
                Start filling in and selecting all the required deatils on this page. Here are the explanations for some
                of the fields:<br>
                <b>Title of your request</b> - Give a two to three word title to name your request.<br>
                <b>Request Description</b> - Brief description of the materials and where they originated from.<br>
                <b>Justification</b> - Justification as to why we no longer need to hold onto these materials.<br>
                <b>Asset ID</b> - Select which asset the items belong to.<br>
                <b>Department</b> - Select which asset the items belong to.
            </p>
            <div class="SubHeader" #material_type>Selecting the Material Type</div>
            <div>
                <img src="./../../../../../assets/Images/material_type.PNG" alt="">
            </div>
            <p>
                Here are the explanations to the fields:<br>
                <b>General Material</b> - Any items that do not have a JDE Number and do not appear on a Fixed Asset
                Register -
                If you are unsure, please reach out to the Fixed Asset Team for assistance to
                <a href="IUASJDEFAandWIP@chevron.com">IUASJDEFAandWIP@chevron.com</a><br>
                <b>JDE Material</b> - Any Item being disposed of that has a JDE Number.<br>
                <b>Fixed Asset</b> - Any Item that exists on a Fixed Asset Register – for example a Vehicle, Plant and
                Equipment etc. If you are unsure, please reach out to the Fixed Asset Team for assistance to
                <a href="IUASJDEFAandWIP@chevron.com">IUASJDEFAandWIP@chevron.com</a><br>
                <b>Financial Write Off</b> - Finance Specific Transaction to write items off the Chevron Books
            </p>
            <div class="SubHeader" #disposal_method>Proposing a Disposal Method</div>
            <div>
                <img src="./../../../../../assets/Images/disposal_method.PNG" alt="">
            </div>
            <p>
                Propose how you would like to dispose of these items.<br>
                <b>Disposal Via Auction </b>- Supply Chain will coordinate the sale with our Disposals Business
                Partners.<br>
                <b>Disposal Via Scrap</b> - Supply Chain will coordinate the sale with our Disposals Business
                Partners.<br>
                <b>Vendor Buy Back Via IMA</b> - Requestor to coordinate this activity with Supply Chain Contracts Team
                and International Master Agreement Vendor.<br>
                <b>Vendor Buy Back Via Sales Agreement</b> - Requestor to coordinate this activity with Supply Chain
                Contracts Team before generating Sales Invoice and selling items.<br>
                <b>Donation</b> - Requestor to coordinate this activity with Finance/Legal/Tax and Compliance including
                obtaining <a
                    href="https://chevron.sharepoint.com/sites/abuiwayinternalcontrolcompliance/SitePages/Donations.aspx">STARS</a>
                Approval.
            </p>
            <div class="SubHeader" #adding_attachments>Adding Attachments</div>
            <div>
                <img src="./../../../../../assets/Images/Adding_attachment.PNG" alt="">
            </div>
            <p>Drag and Drop attachments such as photos, supporting documents into the drop box that may assist with the
                approval of this request.</p>
            <div class="SubHeader" #technical_endorser>Selecting Technical Endorser</div>
            <div>
                <img src="./../../../../../assets/Images/Technical_Endorser.PNG" alt="">
            </div>
            <p>Please select a Technical Endorser from the respective Drop-Down Menus.<br>
                Once this is all complete you can click submit and the approval flow will begin and the IRIS Tool will
                notify the next approver at each step of the process.
            </p>
        </div>

        <!-- Contact Parent Container -->
        <app-contact-support></app-contact-support>
    </div>
</section>