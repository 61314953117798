import { InventoryConditionDto, TechnicalEndorserDto } from 'src/app/feature/iris/models/iris-request-workflow/iris-request.model';

export const IRISRequestConstants = {
  requestAPI: 'api/Request',
  materialTypeAPI: 'api/MaterialType',
  disposalMethodAPI: 'api/DisposalMethod',
  addItemAPI: 'api/RequestLine',
  requestLineByIdAPI: 'api/RequestLine/GetByRequestId',
  lineItemsImportAPI: 'api/RequestLine/Import',
  requestIssueAPI: 'api/RequestIssue',
  createRequestIssueAPI: 'api/RequestIssue/UpdateEndoser',
  requestIssueByIdAPI: 'api/RequestIssue/GetByRequestId',
  searchTrackingNumberAPI: '/api/Request/GetByTrackingNumber',
  technicalEndorserAPI: 'api/RequestIssue/GetTechnicalEndorserUser',
  downloadLineItemsAttachmentsAPI: 'api/Request/DownLoadAllAttachment',
  deleteRequestAPI: 'api/RequestApproverAction',
  approveRejectRequestAPI: 'api/RequestApproverAction/ApproveOrRejectRequestItem',
  getAllMaterialNumbers: 'api/SAPMaterialInfo/GetAllMaterialNumbers',
  getMaterialInfo: 'api/SAPMaterialInfo/GetMaterialInfo',

  // Constants text messages 
  itemFormsError: 'Please review the highlighted fields before proceeding',
  downloadItem: '_Add_Items',
  fileFormatErrorMSG: 'Please select a valid file',
  endorserEmptyMSG: 'Please Select Endorser before proceeding',
  goBackWithoutSavingMSG: 'If you cancel, changes to this action will not be saved. Cancel?',
  emptyExcelRowMsg: 'No rows were found in the Excel spreadsheet',
  excelCommonErrorMSG: 'Excel read error in row',
  itemNoRequiredMSG: 'Item Number required.',
  itemNoDuplicateInTable: 'Item Number already exists in table.',
  itemNoDuplicateInExcel: 'Item Number already exists in Excel File.',
  descriptionRequiredMSG: 'Item Description required.',
  manufacturerRequiredMSG: 'Manufacturer Name required.',
  manufacturerPartRequiredMSG: 'Manufacturer Part Number required.',
  inventoryConditionRequiredMSG: 'Inventory Condition required.',
  unitCostRequiredMSG: 'Unit Cost field is required.',
  disposalQuantityRequiredMSG: 'Disposal Quantity field is required.',
  estimatedCostRequiredMSG: 'Estimated Cost field is required.',
  calculatedEstimatedCostErrorMSG: 'Calculated Estimated Cost is wrong.',
  generalMaterialHeading: 'General Material',
  fixedAssetHeading: 'Fixed Asset Number',
  fixedAssetPlaceholder: 'Vehicles, Trailers, Containers, Buildings etc',
  financialAdjustmentHeading: 'Financial Adjustment',
  financialAdjustmentPlaceholder: 'Financial Write Off, Cycle Count Variances etc',
  itemNumberHeading: 'Item Number',
  itemNumberPlaceholder: 'Type 3 or more digits',
  endorserApproveHeading: 'Approving',
  endorserRejectHeading: 'Rejecting',
  endorserRecallHeading: 'Recalling',
  emptyCommentErrorMSG: 'Please Enter comment'
};

export const InventoryConditionConstants: InventoryConditionDto[] = [
  { id: 1, label: 'New' },
  { id: 2, label: 'Used' },
  { id: 3, label: 'Not Serviceable' },
];

export const DisposalMethodDefaultValue: InventoryConditionDto = {
  id: 0,
  label: 'Select appropriate disposal Method',
};

export const MaterialTypeDefaultValue: InventoryConditionDto = {
  id: 0,
  label: 'Select Material Type',
};

export const EndorserDefaultValue: TechnicalEndorserDto = {
  id: 0,
  username: '-',
};