import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { MMSGroupSharedEmailDto } from '../models/mms-group-shared-email.model';
import { Observable } from 'rxjs';
import { MMSGroupSharedEmailConst } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-group/mms-group-shared-email.constants';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroupSharedEmailService {

  constructor(private utilityService: HttpUtilityService) {
  }

  getGroupSharedEmails(): Observable<MMSGroupSharedEmailDto[]> {
    return this.utilityService.get(MMSGroupSharedEmailConst.apiPath);
  }

  getGroupSharedEmailById(id: number): Observable<MMSGroupSharedEmailDto[]> {
    return this.utilityService.get(MMSGroupSharedEmailConst.apiPath + `/${id}`);
  }

  getGroupSharedEmailByGroupId(groupId: number): Observable<MMSGroupSharedEmailDto[]> {
    const params = new HttpParams().set('id', groupId.toString());
    return this.utilityService.get(MMSGroupSharedEmailConst.apiPath + MMSGroupSharedEmailConst.getByGroupId, { params });
  }

  addNewGroupSharedEmail(groupSharedEmail: MMSGroupSharedEmailDto): Observable<MMSGroupSharedEmailDto> {
    return this.utilityService.post(MMSGroupSharedEmailConst.apiPath, groupSharedEmail);
  }

  deleteGroupSharedEmail(id: number): Observable<any> {
    return this.utilityService.delete(MMSGroupSharedEmailConst.apiPath + `/${id}`);
  }
}
