<div class="iris-supply-specialist-container j-c-s m-container">
  <h1 class="setup-heading">IRIS Supply Base Specialist</h1>
  <br />

  <section class="manage-contentbox">
    <!-- Filter actions -->
    <section class="mt-10 d-flex">
      <div class="mt-20 pr-20 d-flex w-55">
        <kendo-textbox [(ngModel)]="searchedText" (click)="textSearched($event)"></kendo-textbox>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="searchResetGridActions('search')">
          Search
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="searchResetGridActions('reset')">
          Reset
        </button>
      </div>
    </section>

    <!-- Table data -->
    <section class="mt-10">
      <kendo-grid [kendoGridBinding]="gridData" #grid="kendoGrid" [sortable]="true" [resizable]="true">
        <!-- label -->
        <kendo-grid-column field="label" title="Label" [width]="70">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="Javascript:void(0);" (click)="supplySpecialistDetails(dataItem)">
              {{ dataItem.label }}
            </a>
          </ng-template>
        </kendo-grid-column>
        <!-- specialist -->
        <kendo-grid-column field="coordinatorUser" title="Supply Base Specialist" [width]="30" class="a-center"
          [style]="{ 'text-align': 'right', 'padding-right': '1.75rem' }">
        </kendo-grid-column>
      </kendo-grid>
      <!-- total data count -->
      <p class="grid-length mt-20">{{ gridData.length }} records</p>
    </section>
  </section>
</div>
