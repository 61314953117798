import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { IRISSupplySpecialistGridDTO } from '../../models/iris-supply-base-specialist.model';
import { IrisSupplyBaseSpecialistService } from '../../services/supply-base-specialist/iris-supply-base-specialist.service';

@Component({
  selector: 'app-iris-supply-base-specialist',
  templateUrl: './iris-supply-base-specialist.component.html',
  styleUrls: ['./iris-supply-base-specialist.component.scss'],
})
export class IRISSupplyBaseSpecialistComponent implements OnInit {
  gridData: IRISSupplySpecialistGridDTO[] = [];
  searchedText: string = '';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private supplyBaseService: IrisSupplyBaseSpecialistService
  ) {}

  ngOnInit() {
    this.getSupplySpecialistGridData();
  }

  getSupplySpecialistGridData() {
    this.supplyBaseService
      .getSupplyBaseSpecialistGridData()
      .subscribe((data: IRISSupplySpecialistGridDTO[]) => {
        if (data) {
          this.gridData = data;
        }
      });
  }

  // navigate to Supply Specialist detail page
  supplySpecialistDetails(data: any) {
    const url = BackOfficeRoutingConstants.IRISSupplySpecialistDetailPath;
    this.router.navigate([`../${url}`], {
      state: { id: data['id'], label: data['label'], coordinatorUser: data['coordinatorUser'] },
      relativeTo: this.route,
    });
  }

  // search the Supply Specialist from grid and to clear searched filters
  searchResetGridActions(actionSeletcted: string) {
    switch (actionSeletcted) {
      case 'search': {
        this.gridFilter(this.searchedText);
        break;
      }
      case 'reset': {
        this.searchedText = '';
        this.getSupplySpecialistGridData();
        break;
      }
    }
  }

  // filter grid-data
  gridFilter(text: string) {
    const filteredItems = this.gridData.filter((ele: any) => {
      return ele?.label?.toLowerCase().includes(text.toLowerCase());
    });
    this.gridData = filteredItems;
  }

  // search-box
  textSearched(event: any) {
    if (event.target.value) {
      this.gridFilter(event.target.value);
    } else {
      this.getSupplySpecialistGridData();
    }
  }
}
