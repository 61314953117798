<h2 class="heading fixed-top"></h2>
<section class="userguide-content-box">
    <app-user-guide-header></app-user-guide-header>
    <div class="UsageGuideHeader">
        System Usage Guide
    </div>
    <div class="ContentParentContainer">

        <!-- Summary Container -->
        <app-system-usage-guide (HelpTrackRequest)="scrollTo_HelpTrackRequest($event)"></app-system-usage-guide>

        <!-- Content Container -->
        <div class="ContentContainer">
            <div class="Header">Tracking your Request</div>
            <p>Once a request is submitted, you can track it on MMS IRIS Screen</p>
            <div class="SubHeader" #iris_screen>My IRIS Screen</div>
            <p>You can keep up to date with the progress of your request via the Main IRIS Page.</p>
            <div>
                <img src="./../../../../../assets/Images/My_IRIS.PNG" alt="">
            </div>
            <div class="SubHeader" #request_status>Request Status</div>
            <p>The request status indicates at which stage your request is</p>
            <div>
                <img src="./../../../../../assets/Images/Request_Status.PNG" alt="">
            </div>
            <p>Here are the explanation to the di!erent kinds of status</p>
            <div class="SubSubHeader">Open</div>
            <p>Once request details are entered and request header is created and user yet to submit Request, the status
                of the request is open.</p>
            <div class="SubSubHeader">Rejected</div>
            <p>When a teachnical aprrover or an endorser rejects a request the status will change to rejected.</p>
            <div class="SubSubHeader">Completed</div>
            <p>Once the request has been aproved by all the technical approvers and endorsers in the supply chain the
                status will change to
                completed.</p>
            <div class="SubSubHeader">Cancelled</div>
            <p>This shows IRIS requests that were cancelled by you.</p>
            <div class="SubHeader" #approval_flow>Approval Flow</div>
            <div>
                <img src="./../../../../../assets/Images/Approval_Flow.PNG" alt="">
            </div>
            <div class="SubSubHeader">User Types:</div>
            <div class="SubSubHeader">Requestor</div>
            <p>Anyone who has identified an item with remaining value as surplus, excess or obsolete. The Requestor is
                responsible for raising an
                Investment Recovery Request to initiate the disposal process. The MMS IRIS Tool allows the requestor to
                track the End to End Investment Recovery Process.The Requestor is also responsible for recommending the
                proposed disposal method, the simplest being the Disposal via Auction where we leverage our Business
                partners expertise to realise the greatest return. In the event the Requestor wishes to sell materials
                directly to a Third Party they will need to Liaise with Commercial / Legal and Contracts to draft up a
                Sales Agreement and have this Signed before disposing of an item. In the event the Requestor wishes to
                sell materials back to the Supplier under provision set out in the IMA Agreement, they will then need to
                Liaise with their Contract Advisor to Draft a Sales Agreement referencing this provision and to issue
                the supplier with an Invoice for Payment. Finally if the Requestor wishes to Gift an item, they are
                responsible for submitting a STARS Request for the Compliance Team to review and signoff before an item
                can be Gifted.</p>
            <div class="SubSubHeader">Endorser</div>
            <p>Staff identified as reviewers who are responsible for reviewing the business need related to the PP&E or
                M&S raised on the request and approving the disposal of those items. Items must not be disposed of
                without DOA Approval.</p>
            <div class="SubSubHeader">DOA Approver</div>
            <p>Staff identified as DOA approvers (as per the TOCA) who are responsible for approving the disposal
                related to the PP&E or M&S
                raised on the request. Items must not be disposed of without DOA Approval. The Delegation of Authority
                for disposal of company property is outlined in the ABU Policy 190c Table of Commitment Authority
                (TOCA). Please refer to ABU-SSU-00283 - ABU Manage Investment Recovery Procedure.</p>
            <div class="SubSubHeader">Company Finance</div>
            <p>Responsible for seeking additional finance approvals, maintaining asset registers and allocating sales
                funds.</p>
            <div class="SubSubHeader">Commercial Advisors</div>
            <p>Responsible for liaising with JVP’s on applicable disposals.</p>
            <div class="SubSubHeader">Material Coordinator</div>
            <p>Responsible for Coordinating Material disposal and managing poropsed disposal method.</p>
        </div>

        <!-- Contact Parent Container -->
        <app-contact-support></app-contact-support>
    </div>
</section>