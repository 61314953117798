import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { IrisRequestService } from '../../services/IRIS-Request/iris-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LineDetailsDto } from '../../models/iris-request-workflow/line-item.model';
import { forkJoin } from 'rxjs';
import { MaterialManagementInventoryDto } from '../../models/iris-request-workflow/material-management-inventory.model';

@Component({
  selector: 'app-bulk-item-addition',
  templateUrl: './bulk-item-addition.component.html',
  styleUrls: ['./bulk-item-addition.component.scss']
})
export class BulkItemAdditionComponent implements OnInit {
  requestId!: number;
  trackingNumber!: string;
  selectedMaterialType!: number;
  selectedDisposalMethod!: number;
  ItemNumbers: string = '';
  validItemNumbers: string = '';
  validItemNumbersList: string[] = [];
  gridData: LineDetailsDto[] = [];
  materialNumberList: string[] = [];
  constructor(
    public commonService: CommonService,
    private irisRequestService: IrisRequestService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.requestId = stateData['requestId'];
      this.selectedMaterialType = stateData['selectedMaterialTypeID'];
      this.selectedDisposalMethod = stateData['selectedDisposalMethodID'];
      this.trackingNumber = stateData['trackingNumber'];
    }
  }
  ngOnInit(): void {
    this.getMaterialNumbers();
    this.getRequestLineGridData();
  }
  getMaterialNumbers() {
    this.irisRequestService.getAllMaterialNumbers().subscribe((materialNumberList: string[]) => {
      this.materialNumberList = materialNumberList
    })
  }

  // to get request line items data, to show in grid
  getRequestLineGridData() {
    this.irisRequestService.getRequestLineByID(this.requestId).subscribe((data: LineDetailsDto[]) => {
      let i = 1;
      if (data) {
        data.map((ele: LineDetailsDto) => {
          ele.index = i++;
        });
        this.gridData = data;
      }
    })
  }

  validateItemNumber() {
    let itemNumbersList: string[] = this.ItemNumbers.trim().split(/[\n \s]+/);
    let validNumbers: string[] = [];
    let invalidNumberTotal: number = 0;
    for (let item of itemNumbersList) {
      if (this.materialNumberList.indexOf(item) > -1) {
        if (validNumbers.indexOf(item) === -1) {
          validNumbers.push(item);
        }
      }
      else {
        invalidNumberTotal++;
      }
    }
    if (validNumbers.length > 0) {
      if (invalidNumberTotal > 0) {
        this.commonService.showNotificationMessage(`${validNumbers.length} item` + (validNumbers.length === 1 ? '' : 's') + ` found, but ${invalidNumberTotal} ` + (invalidNumberTotal === 1 ? 'entry' : 'entries') + ` could not be found and ` + (invalidNumberTotal === 1 ? 'has' : 'have') + ` been ignored`, 'warning', 'center');
      }
      else {
        this.commonService.showNotificationMessage(`${validNumbers.length} items found`, 'success', 'center');
      }
      if (this.validItemNumbersList.length === 0) {
        this.validItemNumbersList = validNumbers;
      }
      else {
        let filteredArray: string[] = validNumbers.filter((str: string) => { return this.validItemNumbersList.indexOf(str) === -1 });
        this.validItemNumbersList = this.validItemNumbersList.concat(filteredArray);
      }
      this.validItemNumbers = this.validItemNumbersList.join("\r\n");
    }
    else {
      this.commonService.showNotificationMessage(`No item numbers were found out of ${itemNumbersList.length} entries`, 'error', 'center');
    }
  }

  generateRequestLines() {
    let newLineNumbers: string[] = [];
    let existingLineNumbers: string[] = [];
    if (this.gridData.length > 0) {
      let addedItemNumbers: (string | undefined)[] = this.gridData.map((item: LineDetailsDto) => { return item.itemNumber });
      existingLineNumbers = this.validItemNumbersList.filter((itemNum: string) => (addedItemNumbers.indexOf(itemNum) !== -1));
      newLineNumbers = this.validItemNumbersList.filter((itemNum: string) => (addedItemNumbers.indexOf(itemNum) === -1));
      if (existingLineNumbers.length > 0) {
        this.commonService.showNotificationMessage(existingLineNumbers.join(', ') + ' item(s) already exist in the Request', 'error', 'center')
      }
    }
    else {
      newLineNumbers = this.validItemNumbersList;
    }
    console.log(newLineNumbers);
    let APIRequest: any[] = [];
    for (let lineNum of newLineNumbers) {
      APIRequest.push(this.irisRequestService.getMaterialInfo(lineNum));
    }
    let requestLinesAPIs: any[] = [];
    forkJoin(APIRequest).subscribe((APIData) => {
      for (let data of APIData) {
        let item = this.createLineItem(data);
        requestLinesAPIs.push(this.irisRequestService.addNewItem(item))
      }
      forkJoin(requestLinesAPIs).subscribe((requestLinesData) => {
        this.getRequestLineGridData();
      })
    })
  }

  createLineItem(data: MaterialManagementInventoryDto) {
    let formData: any = new FormData();
    formData.append('requestId', this.requestId!);
    formData.append('itemNumber', data.materialNumber);
    formData.append('itemDescription', data.materialDescription!);
    formData.append('manufacturerName', '');
    formData.append('manufacturerPartNumber', data.materialPartNumber);
    formData.append('inventoryConditionId', 1);
    formData.append('itemQty', 0);
    formData.append('unitCost', Number(data.unitPrice));
    formData.append('estimatedCost', Number(0));
    formData.append('irismaterialTypeId', Number(this.selectedMaterialType));
    formData.append('disposalMethodId', Number(this.selectedDisposalMethod));
    formData.append('createdBy', this.commonService.getUserEmailId());
    formData.append('modifiedBy', this.commonService.getUserEmailId());
    return formData;
  }

  deleteLineItem(dataItem: LineDetailsDto) {
    this.irisRequestService
      .deleteLineItem(dataItem.id!)
      .subscribe((data: any) => {
        this.getRequestLineGridData();
      });
  }

  calculateEstimatedCost(dataItem: LineDetailsDto) {
    dataItem.estimatedCost = dataItem.itemQty! * dataItem.unitCost!;
  }

  Save() {
    if (this.gridData.length > 0) {
      let lineItemAPIs: any[] = [];
      for (let item of this.gridData) {
        let payload: any = this.createItemPayload(item);
        lineItemAPIs.push(this.irisRequestService.editLineItem(payload, item.id!))
      }
      forkJoin(lineItemAPIs).subscribe((data) => {
        this.commonService.goBack();
      })
    }
    else {
      this.commonService.goBack();
    }
  }

  createItemPayload(item: LineDetailsDto) {
    let formData: any = new FormData();
    formData.append('id', item.id);
    formData.append('requestId', this.requestId!);
    formData.append('itemNumber', item.itemNumber);
    formData.append('itemDescription', item.itemDescription!);
    formData.append('manufacturerName', '');
    formData.append('manufacturerPartNumber', item.manufacturerPartNumber);
    formData.append('inventoryConditionId', 1);
    formData.append('itemQty', item.itemQty);
    formData.append('unitCost', Number(item.unitCost));
    formData.append('estimatedCost', Number(item.estimatedCost));
    formData.append('irismaterialTypeId', Number(this.selectedMaterialType));
    formData.append('disposalMethodId', Number(this.selectedDisposalMethod));
    formData.append('createdBy', this.commonService.getUserEmailId());
    formData.append('modifiedBy', this.commonService.getUserEmailId());
    return formData;
  }
}
