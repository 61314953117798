import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { IrisSupplyBaseSpecialistService } from '../../../services/supply-base-specialist/iris-supply-base-specialist.service';
import { AssetMaterialCoordinatorsDTO } from '../../../models/asset-Material-Coordinators.model';
import { BaseSpecialistDropdownDTO } from '../../../models/base-specialist-dropDown.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-iris-supply-base-specialist-detail',
  templateUrl: './iris-supply-base-specialist-detail.component.html',
  styleUrls: ['./iris-supply-base-specialist-detail.component.scss'],
})
export class IRISSupplyBaseSpecialistDetailComponent implements OnInit, OnDestroy {
  specialistName: string = '';
  specialistDropdownData!: any;
  specialistSelected: BaseSpecialistDropdownDTO[] = [];
  baseSpecialistID!: number;
  detailsHeading: string = '';
  coordinatorUser: string = '';
  bindSpecialist: BaseSpecialistDropdownDTO[] = [];

  constructor(
    private router: Router,
    private commonService: CommonService,
    private location: Location,
    private baseSpecialistService: IrisSupplyBaseSpecialistService,
    private titleService: Title
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    const heading = 'New Supply Base Specialist';
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.baseSpecialistID = stateData['id'];
      this.detailsHeading = stateData['label'];
      this.coordinatorUser = stateData['coordinatorUser'];
      sessionStorage.setItem('IRISSupplySpecialistDetails', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('IRISSupplySpecialistDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.baseSpecialistID = sessionState.id;
        this.detailsHeading = sessionState.label;
        this.coordinatorUser = sessionState.coordinatorUser;
      }
    }
  }

  ngOnInit() {
    if (this.baseSpecialistID > 0) {
      this.titleService.setTitle(this.detailsHeading);
    }
    this.baseSpecialistService
      .getSupplyBaseSpecialistByID(this.baseSpecialistID)
      .subscribe((data: AssetMaterialCoordinatorsDTO[]) => {
        this.specialistDropdownData = data;
        if (this.coordinatorUser) {
          this.bindJOAThresholdValue(data, this.coordinatorUser);
        }
      });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('IRISSupplySpecialistDetails');
  }

  // to bind specialist name
  bindJOAThresholdValue(records: any, name: string) {
    if (records) {
      records['supplyBaseCoordinator'].filter((ele: any) => {
        name.split(',').forEach((n: string) => {
          if (
            ele['username'].toLowerCase().toString() ===
            n.toLowerCase().toString()
          ) {
            this.bindSpecialist.push(ele);
          }
        });
      });
    } else {
      this.bindSpecialist = [];
    }
  }

  // action-buttons: Save|Cancel
  action(actionType: string) {
    switch (actionType) {
      case 'cancel': {
        this.location.back();
        break;
      }
      case 'save': {
        this.createEditBaseSpecialist();
        break;
      }
    }
  }

  // get selected specialist form dropdown 
  selectSpecialist(event: BaseSpecialistDropdownDTO[]) {
    this.specialistSelected = [];
    this.specialistSelected = event;
  }

  // to create|edit base specialist
  createEditBaseSpecialist() {
    const data: any = this.specialistDropdownData;
    const Tdate = new Date();
    const currentDate: any = new Date(Tdate + 'Z');
    const userName = this.commonService.getUserFullName();
    const selectedBaseID: any = this.specialistSelected.map(
      (ele: BaseSpecialistDropdownDTO) => {
        return Number(ele.id);
      }
    );
    const payload: AssetMaterialCoordinatorsDTO = {
      assetGroupId: this.baseSpecialistID,
      createdBy: data['createdBy'],
      createdDateTime: data['createdDateTime'],
      id: data['id'],
      isActive: data['isActive'],
      materialCoordinatorId: 0,
      modifiedBy: userName,
      modifiedDateTime: currentDate,
      order: data['order'],
      supplyBaseCoordinator: data['supplyBaseCoordinator'],
      userList: selectedBaseID,
    };
    this.baseSpecialistService
      .editSupplyBaseSpecialist(payload, this.baseSpecialistID)
      .subscribe((data: AssetMaterialCoordinatorsDTO) => {
        if (data) {
          const msg = `Material Co Ordinator for Asset ${this.detailsHeading}`;
          this.commonService.successUpdateMSG(msg, 'edit');
          this.location.back();
        }
      });
  }
}
