import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { LineDetailsDto } from '../../../models/iris-request-workflow/line-item.model';

@Component({
  selector: 'app-item-approval',
  templateUrl: './item-approval.component.html',
  styleUrls: ['./item-approval.component.scss'],
})
export class ItemApprovalComponent {
  gridData: LineDetailsDto[] = [];
  trackingNumber!: string;
  totalestimatedCost: number = 0;

  constructor(private router: Router, public commonService: CommonService) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.gridData = stateData['lineItemsGridData'];
      this.trackingNumber = stateData['trackingNumber'];
      sessionStorage.setItem('requestLineItemsRecord', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('requestLineItemsRecord')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.gridData = sessionState?.lineItemsGridData;
        this.trackingNumber = sessionState?.trackingNumber;
      }
    }
  }

  ngOnInit() {
    let estiCost: any = [];
    this.gridData.map((ele: LineDetailsDto) => {
      estiCost.push(ele.estimatedCost);
      this.totalestimatedCost = estiCost.reduce((acc: any, current: any) => acc + current, 0);
    });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('requestLineItemRecords');
  }
}
