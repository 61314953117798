import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'age'
})
export class AgePipe implements PipeTransform {

  transform(value: Date): number {
    const oneDay = 24 * 60 * 60 * 1000;
    const today = new Date();
    const ageInDays = Math.round(Math.abs(today.getTime() - new Date(value).getTime()) / oneDay)
    return ageInDays;
  }
}
