<div class="iris-auctioneersDetail-container j-c-s main-container-padding m-container">
  <h1 class="mb-20 setup-heading">
    {{ detailsHeading }}
  </h1>

  <!-- Action buttons -->
  <div class="mr-20 float-right">
    <button kendoButton class="primary-button ml-20 f-size-150" (click)="action('save')">
      Save
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">
      Cancel
    </button>
  </div>

  <!-- Auctioneer forms -->
  <form [formGroup]="auctioneerForm">
    <section class="mt-20">
      <!-- auctioneer-name -->
      <div class="mt-20 w-30">
        <kendo-label text="Auctioneer Name">
          <span class="mandatoryIcon">*</span>
        </kendo-label>
        <kendo-textbox formControlName="auctioneerName" [(ngModel)]="auctioneerName"
          [required]="hasAuctioneerNameError"></kendo-textbox>
        <div *ngIf="hasAuctioneerNameError">
          <kendo-formerror>Required field!</kendo-formerror>
        </div>
      </div>
      <!-- contact-name -->
      <div class="mt-5 w-30">
        <kendo-label text="Contact Name">
          <span class="mandatoryIcon">*</span>
        </kendo-label>
        <kendo-textbox formControlName="contactName" [(ngModel)]="contactName"
          [required]="hasContactNameError"></kendo-textbox>
        <div *ngIf="hasContactNameError">
          <kendo-formerror error="required">Required field!</kendo-formerror>
        </div>
      </div>
      <!-- email -->
      <div class="mt-5 w-30">
        <kendo-label text="Email">
          <span class="mandatoryIcon">*</span>
        </kendo-label>
        <kendo-textbox formControlName="email" [(ngModel)]="email"
          [required]="hasEmailError || hasInvalidEmailError"></kendo-textbox>
        <div *ngIf="hasEmailError">
          <kendo-formerror error="required">Required field!</kendo-formerror>
        </div>
        <div *ngIf="hasInvalidEmailError">
          <kendo-formerror error="pattern">Invalid Email Address</kendo-formerror>
        </div>
      </div>
      <!-- auctioneer address -->
      <div class="mt-5 w-30">
        <kendo-label text="Auctioneer Address"></kendo-label>
        <kendo-textbox formControlName="auctioneerAddress" [(ngModel)]="auctioneerAddress"></kendo-textbox>
      </div>
    </section>
  </form>
</div>
