import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { KendoModule } from 'src/app/modules/kendo.module';
import { AssetLocationsDetailsComponent } from '../components/asset-locations/asset-locations-details/asset-locations-details.component';
import { AssetLocationsComponent } from '../components/asset-locations/asset-locations.component';
import { IRISAssetJoaThresholdLimitDetailComponent } from '../components/iris-asset-joa-threshold-limit/iris-asset-joa-threshold-limit-detail/iris-asset-joa-threshold-limit-detail.component';
import { IRISAssetJOAThresholdLimitComponent } from '../components/iris-asset-joa-threshold-limit/iris-asset-joa-threshold-limit.component';
import { IRISAuctioneersDetailsComponent } from '../components/iris-auctioneers/iris-auctioneers-details/iris-auctioneers-details.component';
import { IRISAuctioneersComponent } from '../components/iris-auctioneers/iris-auctioneers.component';
import { IRISDepartmentComponent } from '../components/iris-department/iris-department.component';
import { IRISDepartmentDetailComponent } from '../components/iris-department/irisdepartment-detail/irisdepartment-detail.component';
import { IRISSupplyBaseSpecialistDetailComponent } from '../components/iris-supply-base-specialist/iris-supply-base-specialist-detail/iris-supply-base-specialist-detail.component';
import { IRISSupplyBaseSpecialistComponent } from '../components/iris-supply-base-specialist/iris-supply-base-specialist.component';
import { SetupDashboardComponent } from '../setup-dashboard.component';
import { AssetCodeFunctionsComponent } from '../components/asset-code-functions/asset-code-functions.component';
import { AssetCodeFunctionDetailsComponent } from '../components/asset-code-functions/asset-code-function-details/asset-code-function-details.component';
import { GroupComponent } from '../components/group/group.component';
import { GroupModule } from '@progress/kendo-angular-grid';
import { GroupDetailsComponent } from '../components/group/group-details/group-details.component';
import { IrisDoaApprovalComponent } from '../components/iris-doa-approval/iris-doa-approval.component';
import { ManageNotificationsComponent } from '../components/manage-notifications/manage-notifications.component';
import { IrisDoaLevelsComponent } from '../components/iris-doa-levels/iris-doa-levels.component';
import { IRISDOALevelDetailComponent } from '../components/iris-doa-levels/iris-doa-level-detail/iris-doa-level-detail.component';

@NgModule({
  declarations: [
    SetupDashboardComponent,
    IRISDepartmentComponent,
    IRISDepartmentDetailComponent,
    AssetLocationsComponent,
    AssetLocationsDetailsComponent,
    IRISAuctioneersComponent,
    IRISAuctioneersDetailsComponent,
    IRISSupplyBaseSpecialistComponent,
    IRISSupplyBaseSpecialistDetailComponent,
    IRISAssetJOAThresholdLimitComponent,
    IRISAssetJoaThresholdLimitDetailComponent,
    AssetCodeFunctionsComponent,
    AssetCodeFunctionDetailsComponent,
    GroupComponent,
    GroupDetailsComponent,
    IrisDoaApprovalComponent,
    ManageNotificationsComponent,
    IrisDoaLevelsComponent,
    IRISDOALevelDetailComponent
  ],
  imports: [
    BrowserModule,
    KendoModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    SetupDashboardComponent,
    IRISDepartmentComponent,
    IRISDepartmentDetailComponent,
    AssetLocationsComponent,
    AssetLocationsDetailsComponent,
    IRISAuctioneersComponent,
    IRISAuctioneersDetailsComponent,
    IRISSupplyBaseSpecialistComponent,
    IRISSupplyBaseSpecialistDetailComponent,
    IRISAssetJOAThresholdLimitComponent,
    IRISAssetJoaThresholdLimitDetailComponent,
    AssetCodeFunctionsComponent,
    AssetCodeFunctionDetailsComponent,
    GroupModule,
    GroupDetailsComponent,
    IrisDoaApprovalComponent,
    ManageNotificationsComponent,
    IrisDoaLevelsComponent,
    IRISDOALevelDetailComponent
  ],

  providers: [],
})
export class SetupModule { }
