import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-help-create-request',
  templateUrl: './help-create-request.component.html',
  styleUrls: ['./help-create-request.component.scss']
})
export class HelpCreateRequestComponent implements AfterViewInit {
  @ViewChild('process_overflow') process_overflowElement!: ElementRef;
  @ViewChild('filling_details') filling_detailsElement!: ElementRef;
  @ViewChild('material_type') material_typeElement!: ElementRef;
  @ViewChild('disposal_method') disposal_methodElement!: ElementRef;
  @ViewChild('adding_attachments') adding_attachmentsElement!: ElementRef;
  @ViewChild('technical_endorser') technical_endorserElement!: ElementRef;
  fragment: string = '';
  constructor(private router: Router, private commonService: CommonService) {
    let state: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(state)) {
      this.fragment = state['fragment'];
    }
  }

  ngAfterViewInit() {
    if (this.fragment !== '') {
      this.scrollTo_HelpCreateRequest(this.fragment);
      this.fragment = '';
    }
  }
  
  scrollTo_HelpCreateRequest(fragment: string) {
    switch (fragment) {
      case 'process_overflow': {
        if (this.process_overflowElement && this.process_overflowElement.nativeElement) {
          this.process_overflowElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'filling_details': {
        if (this.filling_detailsElement && this.filling_detailsElement.nativeElement) {
          this.filling_detailsElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'material_type': {
        if (this.material_typeElement && this.material_typeElement.nativeElement) {
          this.material_typeElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'disposal_method': {
        if (this.disposal_methodElement && this.disposal_methodElement.nativeElement) {
          this.disposal_methodElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'adding_attachments': {
        if (this.adding_attachmentsElement && this.adding_attachmentsElement.nativeElement) {
          this.adding_attachmentsElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'technical_endorser': {
        if (this.technical_endorserElement && this.technical_endorserElement.nativeElement) {
          this.technical_endorserElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
    }
  }
}
