import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { ProfileType } from '../model/graph.models';
import { GraphService } from './graph.service';

@Injectable({ providedIn: 'root' })
export class GlobalAuthService {
  public profile = new Subject<ProfileType | null>();
  public loginDisplay = new Subject<boolean | null>();

  constructor(
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private garphService: GraphService
  ) {
    this.getUserProfile();
    this.setLoginDisplay();
  }

  getUserProfile(): void {
    this.garphService
      .getAdUserProfile()
      .subscribe((userprofile) => this.profile.next(userprofile));
  }

  getUserProfileDetails(): Observable<ProfileType> {
    return this.garphService.getAdUserProfile();
  }
  setLoginDisplay(): void {
    this.loginDisplay.next(
      this.msalService.instance.getAllAccounts().length > 0
    );
  }

  logout(): void {
    if (this.msalGuardConfig.interactionType == InteractionType.Popup) {
      this.msalService.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect({
        postLogoutRedirectUri: '/',
      });
    }
    this.profile.next(null);
    this.loginDisplay.next(null);
    // this.router.navigate(['/logout']);
    // this.router.navigateByUrl('/logout')
  }
}
