import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { IRISGroups } from 'src/app/core/constants/MMS_BackOffice_Constants/groups/group.constants';
import { BackOfficeAssetDto } from '../../models/asset-group.model';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(
    public http: HttpClient,
    public httpUtilityService: HttpUtilityService
  ) {}

  // to get the departments list
  getIrisGroupsList(): Observable<BackOfficeAssetDto[]> {
    return this.httpUtilityService.get(`${IRISGroups.getGroupList}`);
  }
}
