import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { InputFieldsConstants } from 'src/app/core/constants/input-fields.constants';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { IRISDepartmentsGridDTO } from '../../models/iris-departments.model';
import { IrisDepartmetsService } from '../../services/iris-department/iris-departmets.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-iris-department',
  templateUrl: './iris-department.component.html',
  styleUrls: ['./iris-department.component.scss'],
  providers: [DatePipe]
})
export class IRISDepartmentComponent implements OnInit {
  isInactiveChecked: boolean = false;
  gridData: IRISDepartmentsGridDTO[] = [];
  searchedText: string = '';
  fileName: string = '';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private dialogService: DialogService,
    private irisDepartmetsService: IrisDepartmetsService,
    public commonService: CommonService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.departmentsRecords();
  }

  // to get the list of departments
  departmentsRecords() {
    this.irisDepartmetsService.getIrisDepartmentRecord()
      .subscribe((data: any) => {
        this.gridData = data;
        if (this.isInactiveChecked === false) {
          this.showActiveDepartments();
        }
      });
  }

  showActiveDepartments() {
    const filteredData = this.gridData.filter((ele: IRISDepartmentsGridDTO) => {
      return ele.isActive === true;
    });
    this.gridData = filteredData;
  }

  // navigate to department-detail page
  departmentDetails(data: any) {
    this.navigateToDetailPage('edit', data);
  }

  // to perform actions like export/import excel and add new departments
  departmentActions(actionName: string, data: any) {
    switch (actionName) {
      case 'export': {
        this.exportToExcel(data);
        break;
      }
      case 'import': {
        this.importExcel();
        break;
      }
      case 'addNewDepartment': {
        this.navigateToDetailPage('create', {});
        break;
      }
    }
  }

  navigateToDetailPage(status: string, records: IRISDepartmentsGridDTO) {
    const actionName = status;
    const id = status === 'create' ? 0 : records['id'];
    const label = status === 'create' ? '' : records['label'];
    const isActive = status === 'create' ? false : records['isActive'];
    const order = status === 'create' ? null : records['order'];
    const createdBy = status === 'create' ? '' : records['createdBy'];
    const modifiedBy = status === 'create' ? '' : records['modifiedBy'];
    const url = BackOfficeRoutingConstants.IRISDepartmentDetailPath;
    this.router.navigate([`../${url}`], {
      state: {
        id: id,
        label: label,
        isActive: isActive,
        order: order,
        createdBy: createdBy,
        modifiedBy: modifiedBy,
        action: actionName,
      },
      relativeTo: this.route,
    });
  }

  // to download the grid in excel
  public exportToExcel(grid: GridComponent): void {
    let currentDate = new Date;
    const _time: any =  this.datepipe.transform(new Date(currentDate + 'Z'), 'YYYY-MM-dd-hh-mm-ss');
    this.fileName = `IRIS Departments-${_time}.xlsx`;
    grid.saveAsExcel();
  }

  // search the department from grid and to clear searched filters
  searchResetGridActions(actionSeletcted: string) {
    switch (actionSeletcted) {
      case 'search': {
        this.gridFilter(this.searchedText);
        break;
      }
      case 'reset': {
        this.searchedText = '';
        this.departmentsRecords();
        break;
      }
    }
  }

  // filter grid-data
  gridFilter(text: string) {
    const filteredItems = this.gridData.filter((ele: any) => {
      return ele?.label?.toLowerCase().includes(text.toLowerCase());
    });
    this.gridData = filteredItems;
  }

  // search-box
  textSearched(event: any) {
    if (event.target.value) {
      this.gridFilter(event.target.value);
    } else {
      this.departmentsRecords();
    }
  }

  // to show active and inactive departments records
  showHideActiveNInactiveDepartments() {
    if (this.isInactiveChecked == false) {
      this.departmentsRecords();
    } else {
      this.showActiveDepartments();
    }
  }

  // import/upload the excel file
  importExcel() {
    let userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent,
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === 'Save') {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let department: IRISDepartmentsGridDTO[] = arr.map((item: any) => {
          return {
            label: item['Label'].toString(),
            isActive: item['Is Active'],
            order: 0,
            createdBy: userMailID,
            modifiedBy: userMailID,
          };
        });
        this.irisDepartmetsService
          .importExcelData(department)
          .subscribe((response: IRISDepartmentsGridDTO[]) => {
            if (response) {
              this.departmentsRecords();
              const msg = `Department ${InputFieldsConstants.importMSG}`;
              this.commonService.showNotificationMessage(msg, 'success', 'center' );
            }
          });
      }
    });
  }
}
