import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { AssetCodeFunctionService } from '../../services/asset-code-functions/asset-code-function.service';
import { AssetCodeFunctionDto } from '../../models/code-functions.model';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CommonService } from 'src/app/core/services/common.service';
import { InputFieldsConstants } from 'src/app/core/constants/input-fields.constants';
import { BackOfficeAssetDto } from '../../models/asset-group.model';
import { GroupService } from '../../services/groups/group.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-asset-code-functions',
  templateUrl: './asset-code-functions.component.html',
  styleUrls: ['./asset-code-functions.component.scss'],
  providers: [DatePipe]
})
export class AssetCodeFunctionsComponent implements OnInit {
  gridData: AssetCodeFunctionDto[] = [];
  searchedText: string = '';
  fileName!: string;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private dialogService: DialogService,
    public commonService: CommonService,
    private codeFunctionService: AssetCodeFunctionService,
    private groupService: GroupService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.getAssetCodeFunctionGridData();
    this.getGroupList();
  }

  // get code function grid data
  getAssetCodeFunctionGridData(): void {
    this.codeFunctionService
      .getAssetCodeFunctionGrid()
      .subscribe((data: AssetCodeFunctionDto[]) => {
        this.gridData = data;
      });
  }

  // navigate to code-function-detail page
  navigateToCodeFunctionDetails(data: AssetCodeFunctionDto): void {
    this.bindDataInDetailPage('edit', data);
  }

  // data binding code-function-detail page
  bindDataInDetailPage(status: string, data: AssetCodeFunctionDto) {
    const url = BackOfficeRoutingConstants.AssetCodeFunctionDetailPath;
    const id = data['id'];
    const branch = status === 'edit' ? data['branch'] : '';
    const branchDescription = status === 'edit' ? data['branchDescription'] : '';
    const company = status === 'edit' ? data['company'] : 0;
    const location = status === 'edit' ? data['location'] : '';
    const asset = status === 'edit' ? data['asset'] : '';
    const assetDescription = status === 'edit' ? data['assetDescription'] : '';
    const assetFunction = status === 'edit' ? data['assetFunction'] : '';
    const assetNames = status === 'edit' ? data['assetNames'] : '';
    const isActive = status === 'edit' ? data['isActive'] : true;
    const businessUnitId = status === 'edit' ? data['businessUnitId'] : 1;
    const order = status === 'edit' ? data['order'] : 0;
    const createdBy = status === 'edit' ? data['createdBy'] : '';
    const createdDateTime = status === 'edit' ? data['createdDateTime'] : '';
    const modifiedBy = status === 'edit' ? data['modifiedBy'] : '';
    const modifiedDateTime = status === 'edit' ? data['modifiedDateTime'] : '';
    this.router.navigate([`../${url}`], {
      state: {
        actionName: status,
        id: id,
        branch: branch,
        branchDescription: branchDescription,
        company: company,
        location: location,
        asset: asset,
        assetDescription: assetDescription,
        assetFunction: assetFunction,
        assetGroup: assetNames,
        isActive: isActive,
        businessUnitId: businessUnitId,
        order: order,
        createdBy: createdBy,
        createdDateTime: createdDateTime,
        modifiedBy: modifiedBy,
        modifiedDateTime: modifiedDateTime,
        assetGroupAPI: this.assetGroupList
      },
      relativeTo: this.route,
    });
  }

  // to perform actions:: import | excel | add new code function
  codeFunctionActions(actionName: string, data: any) {
    switch (actionName) {
      case 'export': {
        this.exportDataToExcel(data);
        break;
      }
      case 'import': {
        this.importExcel();
        break;
      }
      case 'createNewAssetCodeFunction': {
        this.bindDataInDetailPage('create', {});
        break;
      }
    }
  }

  // download grid data in excel-file
  public exportDataToExcel(grid: GridComponent): void {
    let currentDate = new Date;
    const _time: any =  this.datepipe.transform(new Date(currentDate + 'Z'), 'YYYY-MM-dd-hh-mm-ss');
    this.fileName = `Asset Code Functions-${_time}.xlsx`;
    grid.saveAsExcel();
  }

  // search the asset from grid and to clear searched filters
  searchResetGridActions(actionSeletcted: string) {
    switch (actionSeletcted) {
      case 'search': {
        this.gridFilter(this.searchedText);
        break;
      }
      case 'reset': {
        this.searchedText = '';
        this.getAssetCodeFunctionGridData();
        break;
      }
    }
  }

  // filter grid-data
  gridFilter(text: string) {
    const filteredItems = this.gridData.filter((ele: any) => {
      return ele?.branch?.toLowerCase().includes(text.toLowerCase());
    });
    this.gridData = filteredItems;
  }

  // search-box
  textSearched(event: any) {
    if (event.target.value) {
      this.gridFilter(event.target.value);
    } else {
      this.getAssetCodeFunctionGridData();
    }
  }

  // import/upload the excel file
  importExcel() {
    let userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent,
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === 'Save') {
        let arr = JSON.parse(r.value).Sheet1 as Array<AssetCodeFunctionDto>;
        let codeFunctionRecords: AssetCodeFunctionDto[] = arr.map(
          (item: any) => {
            return {
              branch: item['Branch'].toString(),
              branchDescription: item['Branch Description'].toString(),
              company: item['Company'],
              location: item['Location'].toString(),
              asset: item['Asset'].toString(),
              assetDescription: item['Asset Description'].toString(),
              assetFunction: item['Asset Function'].toString(),
              assetGroup: item['Asset Groups'],
              isActive: true,
              businessUnitId: 1,
              order: 0,
              createdBy: userMailID,
              modifiedBy: userMailID,
            };
          }
        );
        this.codeFunctionService
          .importExcelData(codeFunctionRecords)
          .subscribe((response: AssetCodeFunctionDto[]) => {
            if (response) {
              this.getAssetCodeFunctionGridData();
              const msg = `Asset Code Function ${InputFieldsConstants.importMSG}`;
              this.commonService.showNotificationMessage(msg, 'success', 'center');
            }
          });
      }
    });
  }

  // get the list of groups
  assetGroupList: any = [];
  getGroupList() {
    this.groupService.getIrisGroupsList().subscribe((list: BackOfficeAssetDto[]) => {
      if(list) {
        list.forEach((ele:BackOfficeAssetDto) => {
          const data:any = {
            id:ele.id,
            label: ele.label
          }
          this.assetGroupList.push(data);
        })
      }
    });
  }
}
