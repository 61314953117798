<h1 class="font-size-h5 pl-40 pt-15 secondary-color">Investment Recovery And Inventory Solutions(IRIS)</h1>
<section class="iris-container d-flex mt-10">
    <!-- Left Part -->
    <section class="in-side-nav">
        <button kendoButton class="primary-button w-100 mb-5" (click)="manageIris()">
            <b class="font-size-xs"> Create New IRIS Request </b></button>

        <div class="mt-15">
            <h6 class="mt-15 ">IRIS CREATED BY ME</h6>

            <!-- <div *ngFor="let records of createdByMeRecords">
                <div class="sar-icon">
                    <img src="{{records.isconFalse}}" alt="">
                    <p>
                        <span>{{records.name}}</span>
                        <span>{{records.count}}</span>
                    </p>
                </div>
            </div> -->

            <div>
                <a class="l-item selected">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="true ? 'assets/icons/draftselected.svg' : 'assets/icons/draftdeselected.svg'" />
                        <p> Draft</p>
                    </div>
                    <p>0</p>
                </a>
                <a class="l-item">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="false ? '../../../../../assets/icons/openselected.svg' : 
                            '../../../../../assets/icons/opendeselected.svg'" />
                        <p> Submitted</p>
                    </div>
                    <p>0</p>
                </a>
                <a class="l-item">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="false ?  '../../../../../assets/icons/completeselected.svg' :
                             '../../../../../assets/icons/completedeselected.svg'" />
                        <p> Pending Auction</p>
                    </div>
                    <p>0</p>
                </a>

                <a class="l-item">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="false ?  '../../../../../assets/icons/completeselected.svg' : 
                            '../../../../../assets/icons/completedeselected.svg'" />
                        <p> Disposed</p>
                    </div>
                    <p>0</p>
                </a>

                <a class="l-item">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="false ?  '../../../../../assets/icons/completeselected.svg' : '../../../../../assets/icons/completedeselected.svg'" />
                        <p> Completed</p>
                    </div>
                    <p>0</p>
                </a>
                <a class="l-item">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="false ? '../../../../../assets/icons/cancelselected.svg' : '../../../../../assets/icons/canceldeselected.svg'" />
                        <p> Rejected</p>
                    </div>
                    <p>0</p>
                </a>
                <a class="l-item">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="false ?  '../../../../../assets/icons/allselected.svg' : '../../../../../assets/icons/alldeselected.svg'" />
                        <p> All</p>
                    </div>
                    <p> {{gridData.length}} </p>
                </a>
            </div>
        </div>
        <div class="mt-15">
            <h6 class="mt-15">IRIS ASSIGNED TO ME</h6>
            <div>
                <a class="l-item">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="false ?  '../../../../../assets/icons/completeselected.svg' : '../../../../../assets/icons/completedeselected.svg'" />
                        <p> Pending Review</p>
                    </div>
                    <p>0</p>
                </a>
                <a class="l-item">
                    <div class="sar-icon">
                        <img class="icon-size" alt="icons"
                            [src]="false ?  '../../../../../assets/icons/completeselected.svg' : '../../../../../assets/icons/completedeselected.svg'" />
                        <p> Reviewed</p>
                    </div>
                    <p>0</p>
                </a>
            </div>
        </div>

    </section>

    <!-- Right Part -->
    <section class="in-side-main custom-kendo-css">
        <div class="d-flex j-c-e">
            <div class="ml-5 w-25 mms-search">
                <kendo-textbox placeholder="Filter IRIS Requests" [(ngModel)]="searchedText">
                    <ng-template kendoTextBoxSuffixTemplate>
                        <i class="fa fa-search pr-10" (click)="searchByTrackingNumber(searchedText)"></i>
                    </ng-template>
                </kendo-textbox>
            </div>
        </div>

        <!-- table -->
        <div class="grid-box">
            <kendo-grid [kendoGridBinding]="gridData" #grid="kendoGrid" id="lineDetailsGrid" [pageable]="true" [pageSize]="15">
                <!-- tracking Number  -->
                <kendo-grid-column field="trackingNumber" title="Line Items" [width]="12"
                    [style]="{'padding-left': '.75rem' }">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a (click)="irisDetails(dataItem)">
                            {{dataItem.trackingNumber}}
                        </a>
                    </ng-template>
                </kendo-grid-column>
                <!-- title -->
                <kendo-grid-column field="title" title="Title" [width]="17">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <div *ngIf="dataItem.item ==='' ">-</div>
                        <div *ngIf="dataItem.item !=='' ">
                            <a (click)="irisDetails(dataItem)">
                                {{dataItem.title}}
                            </a>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- status  -->
                <kendo-grid-column field="status" title="Status" [width]="13">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div *ngIf="dataItem.status ==='' ">-</div>
                        <div class="status-btn completedbttn" *ngIf="dataItem.status !=='' "
                        [ngClass]="dataItem.requestStateName == 'Open' ? 'colorGreen' : 
                         (dataItem.requestStateName == 'Draft' || dataItem.requestStateName == 'Rejected') ? 'colorOrange' : 'colorBlack'"
                        > 
                            {{dataItem.requestStateName}} 
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- modified By -->
                <kendo-grid-column field="modifiedBy" title="Assigned To" [width]="20">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div *ngIf="dataItem.assignedToUserName !== '' ">{{dataItem.userName}}
                            <span *ngIf="dataItem.assignedToGroup !== '' ">,
                            </span>
                        </div>
                        <div *ngIf="dataItem.assignedToGroup !== '' ">{{dataItem.modifiedBy}}</div>
                    </ng-template>
                </kendo-grid-column>
                <!-- created By -->
                <kendo-grid-column field="createdBy" title="Created By" [width]="20">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.userName}}
                        <br />
                        {{dataItem.createdBy}}
                    </ng-template>
                </kendo-grid-column>
                <!-- created Date Time -->
                <kendo-grid-column field="createdDateTime" title="Created On" [width]="12">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.createdDateTime | date:'yyyy-MM-dd HH:mm:ss' }}
                    </ng-template>
                </kendo-grid-column>
                <!-- Age -->
                <kendo-grid-column field="age" title="Age" [width]="6">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.createdDateTime | age}} Days
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </section>
</section>
