<div class="create_iris_form_container">
    <div class="content-top d-flex j-c-s ">
        <h1> <a class="c-left">
                <span class="fa fa-fw fa-angle-left" (click)="this.commonService.goBack();"></span>
            </a> {{header}} </h1>
        <div class="mr-20">
            <button kendoButton class="border-button f-size-150" (click)="actions('cancel')"> Cancel </button>
            <button kendoButton class="ml-20 f-size-150" (click)="actions('create')"
                [disabled]="!createIRISForm.valid || !isValidForm()"
                [ngClass]="(createIRISForm.valid && isValidForm()) ? 'primary-button' : 'disabled-button'"
                *ngIf="actionName==='create'">
                Create IRIS
            </button>
            <button kendoButton class="ml-20 f-size-150" (click)="actions('edit')"
                [disabled]="!createIRISForm.valid || !isValidForm()" *ngIf="actionName==='edit'"
                [ngClass]="(createIRISForm.valid && isValidForm()) ? 'primary-button' : 'disabled-button'">
                Update IRIS
            </button>
        </div>
    </div>
    <section class="manage-contentbox w-75">
        <form [formGroup]="createIRISForm">
            <section class="mt-10 d-flex">
                <div class="mt-10 w-50 pr-20">
                    <kendo-label>Title <span class="mandatory"> *</span></kendo-label><br>
                    <kendo-textbox [maxlength]="200" placeholder="Please enter a title for this IRIS Request"
                        formControlName="title" required [(ngModel)]="title"></kendo-textbox>
                    <p class="Character-count">Character(s) left: {{ 200 - title?.length}}</p>
                </div>
            </section>
            <section class="mt-10 d-flex">
                <div class="w-50 pr-20">
                    <kendo-label class="mt-10">Request Description<span class="mandatory"> * </span></kendo-label><br>
                    <kendo-textarea [rows]="5" placeholder="Please enter a Description for this IRIS request"
                        [maxlength]="500" formControlName="description" required [(ngModel)]="description"></kendo-textarea>
                    <p class="Character-count">Character(s) left:{{ 500 - description?.length}}</p>
                </div>
                <div class="w-50 pl-20">
                    <kendo-label class="mt-10">Justification for Disposal <span class="mandatory"> * </span>
                    </kendo-label><br>
                    <kendo-textarea [rows]="5" placeholder="Please enter a brief justification for this IRIS Request" rez
                    [maxlength]="750" formControlName="justification" required
                        [(ngModel)]="justification"></kendo-textarea>
                    <p class="Character-count">Character(s) left:{{ 750 - justification?.length}}</p>
                </div>
            </section>
            <!--  -->
            <section class="d-flex">
                <div class="mt-10 w-50 pr-20">
                    <kendo-label class="mt-10"> Asset Group<span class="mandatory"> *</span></kendo-label>
                    <kendo-dropdownlist [data]="listOfGroups" required [defaultItem]="{ label: 'Select Group', id: null }"
                        textField="label" valueField="id" formControlName="assetGroup" [(ngModel)]="groupSelected">
                    </kendo-dropdownlist>
                </div>
                <div class="mt-10 w-50 pl-20">
                    <kendo-label class="mt-10"> Department<span class="mandatory"> * </span></kendo-label>
                    <kendo-dropdownlist [data]="listOfDepartments"
                        [defaultItem]="{ label: 'Select Department', id: null }" [textField]="'label'" [valueField]="'id'"
                        formControlName="department" required [(ngModel)]="departmentSelected">
                    </kendo-dropdownlist>
                </div>
            </section>
            <section class="mt-10 d-flex">
                <div class="mt-10 w-50 pr-20">
                    <kendo-label class="mt-10"> Asset Location<span class="mandatory"> * </span></kendo-label>
                    <kendo-dropdownlist [data]="listOfLocations" [defaultItem]="{ locationName: 'Select Location', id: null }"
                        [textField]="'locationName'" [valueField]="'id'" formControlName="locations" required
                        [(ngModel)]="locationSelected">
                    </kendo-dropdownlist>
                </div>
                <!-- <div class="mt-10 w-50 pl-20">
                    <kendo-label>AFE /Cost Code</kendo-label> <br>
                    <kendo-textbox [maxlength]="200" [disabled]="true"></kendo-textbox>
                </div> -->
            </section>
        </form>
    </section>
</div>