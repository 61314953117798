import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserGuideRoutingConstants } from 'src/app/core/constants/User_Guide_Constants/user-guide-routing.constants';

@Component({
  selector: 'app-system-usage-guide',
  templateUrl: './system-usage-guide.component.html',
  styleUrls: ['./system-usage-guide.component.scss']
})
export class SystemUsageGuideComponent {
  UserGuideRoutingConstants = UserGuideRoutingConstants;
  @Output() HelpGettingStarted = new EventEmitter<string>();
  @Output() HelpCreateRequest = new EventEmitter<string>();
  @Output() HelpTrackRequest = new EventEmitter<string>();
  currentPath: string = window.location.href;
  constructor(private router: Router, private route: ActivatedRoute) {
  }

  navGetStartedGuide(fragment?: string) {
    let isCurrentPage = this.currentPath.includes(UserGuideRoutingConstants.HelpGettingStarted);
    if (isCurrentPage) {
      this.HelpGettingStarted.emit(fragment);
    }
    else{
      this.router.navigate(['../' + UserGuideRoutingConstants.HelpGettingStarted], { relativeTo: this.route, state: { fragment: fragment } });
    }
  }
  navCreateNewIRISRequestGuide(fragment?: string) {
    let isCurrentPage = this.currentPath.includes(UserGuideRoutingConstants.HelpCreateRequest);
    if (isCurrentPage) {
      this.HelpCreateRequest.emit(fragment);
    }
    else {
      this.router.navigate(['../' + UserGuideRoutingConstants.HelpCreateRequest], { relativeTo: this.route, state: { fragment: fragment } });
    }
  }
  navTrackRequestGuide(fragment?: string) {
    let isCurrentPage = this.currentPath.includes(UserGuideRoutingConstants.HelpTrackRequest);
    if (isCurrentPage)
      this.HelpTrackRequest.emit(fragment);
    else {
      this.router.navigate(['../' + UserGuideRoutingConstants.HelpTrackRequest], { relativeTo: this.route, state: { fragment: fragment } });
    }
  }
}
