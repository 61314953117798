import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { IRISDOALevelDto } from '../../models/iris-doa-Level.model';
import { Observable } from 'rxjs';
import { IRISDOALevelsConst } from 'src/app/core/constants/MMS_BackOffice_Constants/iris-doa-levels/iris-doa-levels.constants';

@Injectable({
  providedIn: 'root'
})
export class IRISDOALevelsService {

  constructor(private utilityService: HttpUtilityService) {
  }
  getIRISDOALevelList(): Observable<IRISDOALevelDto[]> {
    return this.utilityService.get(IRISDOALevelsConst.ApiPath);
  }

  getIRISDOALevelbyId(id: number): Observable<IRISDOALevelDto> {
    return this.utilityService.get(IRISDOALevelsConst.ApiPath + `/${id}`);
  }

  importExcelData(data: IRISDOALevelDto[]): Observable<any> {
    return this.utilityService.post(IRISDOALevelsConst.ApiPath + IRISDOALevelsConst.Import, data);
  }

  addNewIRISDOALevel(doaLevel: IRISDOALevelDto): Observable<IRISDOALevelDto> {
    return this.utilityService.post(IRISDOALevelsConst.ApiPath, doaLevel);
  }

  updateIRISDOALevel(doaLevel: IRISDOALevelDto): Observable<IRISDOALevelDto> {
    return this.utilityService.put(IRISDOALevelsConst.ApiPath + `/${doaLevel.id}`, doaLevel);
  }
}
