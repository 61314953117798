import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { KendoModule } from 'src/app/modules/kendo.module';
import { MmsDashboardComponent } from '../components/mms-dashboard/mms-dashboard.component';

@NgModule({
  declarations: [
    MmsDashboardComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    KendoModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [
    MmsDashboardComponent
  ],
  providers: [],
})
export class MMSModule {}
