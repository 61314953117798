import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { AttachmentsDTO } from '../../models/common_constants/attachments.constants';
import { IRISRequestConstants } from 'src/app/core/constants/IRIS_Constants/iris-requester/iris-requester.constants';
import { FileConstant } from '../../models/common_constants/file-upload-type.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IrisRequestService } from '../../services/IRIS-Request/iris-request.service';
import { IRISRequestDto } from '../../models/iris-request-workflow/iris-request.model';
import { IRISRequestStateID } from "src/app/core/constants/Request_State_Constants_ID's/requestStateID.constants";

@Component({
  selector: 'app-reject-approve-recall-popup',
  templateUrl: './reject-approve-recall-popup.component.html',
  styleUrls: ['./reject-approve-recall-popup.component.scss'],
})

export class RejectApproveRecallPopupComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<any> | any;
  acceptedFiles: string[] = FileConstant.acceptedFiles;
  accptedFilesStr: string = this.acceptedFiles.join(',');
  attachmentFileDetails: any[] = [];
  trackingNumber: string = '';
  endorserActionName: string = '';
  stateId!:number;
  requestDetails!: any;
  userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
  endorserActionsForm = new FormGroup({
    comment: new FormControl(<string | undefined>'', Validators.required),
    attachment: new FormControl([]),
  });

  constructor(
    public dialog: DialogRef,
    public commonService: CommonService,
    private irisRequestService: IrisRequestService
  ) {}

  ngOnInit(): void {
    this.showHeadingBasedOnEndorserAction();
    this.requestDetails = JSON.parse(sessionStorage.getItem('requestDetails')!);
  }

  showHeadingBasedOnEndorserAction() {
    switch (this.endorserActionName) {
      case 'reject': {
        this.endorserActionName = IRISRequestConstants.endorserRejectHeading;
        break;
      }
      case 'approve': {
        this.endorserActionName = IRISRequestConstants.endorserApproveHeading;
        break;
      }
      case 'recall': {
        this.endorserActionName = IRISRequestConstants.endorserRecallHeading;
        break;
      }
    }
  }

  // to submit endorser actions: Approve, Reject, Recall
  submit() {
    switch (this.endorserActionName) {
      case 'Rejecting': {
        this.ApproveRejectRequest('yes', IRISRequestStateID.rejectedStateID, false);
        break;
      }
      case 'Approving': {
        if(this.stateId===IRISRequestStateID.pendingFinanceUpdateStateID)
        {
          this.ApproveRejectRequest('yes', IRISRequestStateID.pendingDOAReviewStateID, true);
          return;
        }
        else 
        {
          this.ApproveRejectRequest('yes', IRISRequestStateID.pendingFinanceUpdateStateID, true);
          return;
        }     
        break;
      }
      case 'Recalling': {
        this.ApproveRejectRequest('yes', IRISRequestStateID.draftStateID, false);
        break;
      }
    }
  }

  ApproveRejectRequest(dialogAction: string, requestStateId: number, isApprove: boolean) {
    let msg = IRISRequestConstants.emptyCommentErrorMSG;
    if (this.endorserActionsForm.status === 'INVALID') {
      this.commonService.showNotificationMessage(msg, 'error', 'center');
    } else {
      let requestIssueId = JSON.parse(sessionStorage.getItem('requestIssueId')!);
      let loggedUserID = JSON.parse(sessionStorage.getItem('loggedUserID')!);
      let comment = this.endorserActionsForm.controls['comment'].value;
      let formData: any = new FormData();
      formData.append('requestId', this.requestDetails.requestID!);
      formData.append('isApproved', isApprove);
      formData.append('comment', comment!);
      formData.append('requestIssueId', requestIssueId!);
      formData.append('requestStateId', requestStateId);
      formData.append('userId', loggedUserID);
      formData.append('createdBy', this.requestDetails.createdBy!);
      formData.append('modifiedBy', this.userMailID!.toString());
      if (this.attachmentFileDetails.length) {
        for (let attachment of this.attachmentFileDetails!) {
          formData.append('attachments', attachment.file);
        }
      }
      this.irisRequestService
        .approveRejectRequest(formData)
        .subscribe((data: IRISRequestDto) => {
          this.dialog.close({ action: dialogAction });
        });
    }
  }

  // to uplaod an attachemnts
  onFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `${IRISRequestConstants.fileFormatErrorMSG} (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AttachmentsDTO = this.setNewAttachment(files[0]);
        this.attachmentFileDetails.push(newAttachment);
        this.fileInput.nativeElement.value = '';
      } else {
        this.commonService.showNotificationMessage(errorMessage, 'warning', 'center');
      }
    }
  }

  // to validate the uploaded file
  isValidFile(file: File): boolean {
    const name: string = file.name;
    const extension: string = '.' + name.split('.')[1];
    return this.acceptedFiles.includes(extension.toLocaleLowerCase());
  }

  // set the details to the attachments
  setNewAttachment(file: File): AttachmentsDTO {
    const newAttachment: AttachmentsDTO = new AttachmentsDTO();
    newAttachment.attachmentId = 0;
    newAttachment.file = file;
    newAttachment.fileName = file.name;
    newAttachment.blobUrI = '';
    newAttachment.operation = 1;
    return newAttachment;
  }

  // to remove particular attached file
  removeFile(index: number) {
    if (this.attachmentFileDetails && this.attachmentFileDetails[index]) {
      this.attachmentFileDetails!.splice(index, 1);
    }
  }

  // to download particular attached file
  downloadFile(index: number) {
    let attachment: any = this.attachmentFileDetails[index];
    const blob = new Blob([attachment.file!], { type: attachment.file!?.type });
    this.commonService.downloadFile(blob, attachment.name!);
  }

  close(actionName: string) {
    this.dialog.close({ action: actionName });
  }
}
