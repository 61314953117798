import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { MMSGroupDto } from '../../../models/mms-group.model';
import { MmsGroupService } from '../../../services/mms-group/mms-group.service';
import { MMSGroupConst } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-group/mms-group.constants';
import { MMSSubGroupDto } from '../../../models/mms-sub-group.model';
import { GroupService } from '../../../services/groups/group.service';
import { GroupsDTO } from '../../../models/groups.model';
import { forkJoin } from 'rxjs';
import { MMSGroupSharedEmailConst } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-group/mms-group-shared-email.constants';
import { GroupSharedEmailService } from '../../../services/group-shared-email.service';
import { MMSGroupSharedEmailDto } from '../../../models/mms-group-shared-email.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit {
  isNew: boolean = true;
  id: number = 0;
  label: string = '';
  order: number = 0;
  isActive: boolean = true;
  heading: string = 'New Group';
  userMailID: string = JSON.parse(sessionStorage.getItem('userEmailID')!);
  BUId: number = 1; //mock-data
  mmsGroup: MMSGroupDto = new MMSGroupDto();
  searchedText: string = '';
  groupLabels: string[] = [];
  requiredLabel: boolean = false;
  subGroups: MMSSubGroupDto[] = [];
  isOnEdit: boolean = false;
  selectedSubGroup: number = -1;
  tempSubGroup: number = -1;
  prevSubGroupLabel: string = '';
  prevSubGroupActive: boolean = true;
  prevSubGroupOrder: number = 0;
  onEditTimes: number = 0;
  groupSharedEmail: MMSGroupSharedEmailDto[] = [];
  assetList: GroupsDTO[] = [];
  public assetsSharedEmails: any[] = [];
  constructor(private commonService: CommonService, private router: Router, private route: ActivatedRoute, private mmsGroupService: MmsGroupService,
    private groupService: GroupService, private groupSharedEmailService: GroupSharedEmailService, private titleService: Title) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.isNew = stateData['isNew'];
      this.id = stateData['id'];
      if (!this.isNew) {
        this.label = stateData['label'];
        this.order = stateData['order'];
        this.isActive = stateData['isActive'];
      }
      this.searchedText = stateData['searchedText'];
      sessionStorage.setItem('MMSGroupDetails', JSON.stringify(stateData));
    }
    else {
      let sessionData: any = JSON.parse(sessionStorage.getItem('MMSGroupDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionData)) {
        this.isNew = sessionData['isNew'];
        this.id = sessionData['id'];
        if (!this.isNew) {
          this.label = sessionData['label'];
          this.order = sessionData['order'];
          this.isActive = sessionData['isActive'];
        }
        this.searchedText = sessionData['searchedText'];
      }
    }
  }

  ngOnInit(): void {
    if (!this.isNew) {
      this.titleService.setTitle(this.label);
    }
    this.getGroupList();
    if (!this.isNew) {
      this.heading = this.label;
      let mmsGroupAPI = this.mmsGroupService.getMMSGroupbyId(this.id);
      let assetsAPI = this.groupService.getIrisGroupsList();
      forkJoin([mmsGroupAPI, assetsAPI]).subscribe(([currentGroup, assetsData]) => {
        this.mmsGroup = { ...currentGroup };
        this.assetList = assetsData;
        for (let asset of this.assetList) {
          let item = {
            title: asset.label,
            assetId: asset.id,
            sharedEmail: '',
            isValidEmail: true,
            sharedEmails: []
          }
          this.assetsSharedEmails.push(item);
        };
        this.getGroupSharedEmails();
      });
    }
  }
  getGroupList() {
    this.mmsGroupService.getMMSGroupList().subscribe((groups: MMSGroupDto[]) => {
      if (groups.length > 0) {
        this.groupLabels = groups.map((group: MMSGroupDto) => { return group.label; }) as string[];
      }
    })
  }

  getGroupSharedEmails() {
    this.groupSharedEmailService.getGroupSharedEmailByGroupId(this.mmsGroup.id!).subscribe((sharedEmails: MMSGroupSharedEmailDto[]) => {
      this.groupSharedEmail = sharedEmails;
      for (let item of this.assetsSharedEmails) {
        let sharedEmailsbyAsset = this.groupSharedEmail.filter((i: MMSGroupSharedEmailDto) => (i.assetGroupId === item.assetId));
        item.sharedEmails = [...sharedEmailsbyAsset];
      }
    })
  }

  action(actionName: string) {
    this.requiredLabel = false;
    if (actionName === 'cancel') {
      this.navBack();
    }
    else if (actionName === 'save') {
      if (this.label === '') {
        this.requiredLabel = true;
        return;
      }
      let labels: string[] = this.groupLabels.filter((label: string) => (label.toLowerCase() === this.label.toLowerCase()));
      if (this.isNew) {
        if (labels.length > 0) {
          this.commonService.showNotificationMessage(MMSGroupConst.groupExistsMsg, 'error', 'center');
        }
        else {
          this.saveNewGroup();
        }
      }
      else {
        if (labels.length > 0 && this.label !== this.mmsGroup.label) {
          this.commonService.showNotificationMessage(MMSGroupConst.groupExistsMsg, 'error', 'center');
        }
        else {
          this.saveExistingGroup();
        }
      }
    }
  }
  saveNewGroup() {
    this.mmsGroup = this.setDefaultGroup();
    this.mmsGroup.label = this.label;
    this.mmsGroup.order = this.order;
    this.mmsGroup.isActive = this.isActive;
    this.mmsGroup.mmsSubGroups = [...this.subGroups];
    this.mmsGroupService.addNewGroup(this.mmsGroup).subscribe((result: MMSGroupDto) => {
      this.commonService.showNotificationMessage(MMSGroupConst.addedGroupMsg, 'success', 'center');
      this.navBack();
    });

  }
  saveExistingGroup() {
    this.mmsGroup.label = this.label;
    this.mmsGroup.order = this.order;
    this.mmsGroup.isActive = this.isActive;
    this.mmsGroup.modifiedBy = this.userMailID;
    this.mmsGroup.modifiedDateTime = new Date;
    this.mmsGroupService.updateGroup(this.mmsGroup).subscribe((result: MMSGroupDto) => {
      this.commonService.showNotificationMessage(MMSGroupConst.updatedGroupMsg, 'success', 'center');
      this.navBack();
    });
  }
  navBack() {
    this.router.navigate([`../${BackOfficeRoutingConstants.GroupPath}`], { state: { searchedText: this.searchedText }, relativeTo: this.route });
  }
  setDefaultGroup(): MMSGroupDto {
    let group: MMSGroupDto = new MMSGroupDto();
    group.id = 0;
    group.isIRIS = true;
    group.businessUnitId = this.BUId;
    group.mmsSubGroups = [];
    group.assetList = [];
    group.mmsGroupSharedEmails = [];
    group.modifiedBy = this.userMailID;
    group.createdBy = this.userMailID;
    group.createdDateTime = new Date;
    return group;
  }
  addSubGroup() {
    this.isOnEdit = true;
    let newSubGr: MMSSubGroupDto = this.setDefaulSubGroup();
    this.subGroups.push(newSubGr);
    this.selectedSubGroup = this.subGroups.length - 1;
    this.tempSubGroup = this.subGroups.length - 1;
  }
  submitSubGroup(index: number) {
    let label: string = this.subGroups[index].label!;
    let labels: MMSSubGroupDto[] = this.subGroups.filter(x => (x.label?.toLowerCase() === label.toLowerCase()));
    if (labels.length > 1) {
      this.commonService.showNotificationMessage(MMSGroupConst.subGroupExistsMsg, 'error', 'center');
    }
    else {
      this.selectedSubGroup = -1;
      this.isOnEdit = false;
      this.tempSubGroup = -1;
    }
    this.initializePrevRecords();
  }
  exitSubGroup(index: number) {
    this.isOnEdit = false;
    this.selectedSubGroup = -1;
    if (index === this.tempSubGroup) {
      this.subGroups.pop();
      this.tempSubGroup = -1;
    }
    else {
      this.subGroups[index].label = this.prevSubGroupLabel;
      this.subGroups[index].isActive = this.prevSubGroupActive;
      this.subGroups[index].order = this.prevSubGroupOrder;
    }
    this.initializePrevRecords();
  }
  initializePrevRecords() {
    this.onEditTimes = 0;
    this.prevSubGroupLabel = '';
    this.prevSubGroupActive = true;
    this.prevSubGroupOrder = 0;
  }
  deleteSubGroup(index: number) {
    if (this.tempSubGroup == -1) {
      this.selectedSubGroup = -1;
      this.isOnEdit = false;
      this.subGroups.splice(index, 1);
    }
    this.initializePrevRecords();
  }
  setDefaulSubGroup() {
    let subGroup: MMSSubGroupDto = new MMSSubGroupDto();
    subGroup.id = 0;
    subGroup.mmsGroupId = this.mmsGroup.id ? this.mmsGroup.id : 0;
    subGroup.label = '';
    subGroup.isActive = true;
    subGroup.order = 0;
    subGroup.createdBy = this.userMailID;
    subGroup.createdDateTime = new Date;
    return subGroup;
  }
  onEditSubGroup(index: number) {
    if (this.tempSubGroup === -1) {
      this.onEditTimes++;
      if (this.onEditTimes === 1) {
        this.prevSubGroupLabel = this.subGroups[index].label!;
        this.prevSubGroupActive = this.subGroups[index].isActive!;
        this.prevSubGroupOrder = this.subGroups[index].order!;
      }
    }
    this.isOnEdit = true;
    this.selectedSubGroup = index;

  }

  AddSharedEmail(index: number, assetId: number, sharedEmail: string) {
    let isValidEmail = MMSGroupSharedEmailConst.emailPattern.test(sharedEmail.toString());
    if (!isValidEmail) {
      this.assetsSharedEmails[index].isValidEmail = false;
    }
    else if (this.isDuplicateEmail(index, sharedEmail.toString())) {
      this.commonService.showNotificationMessage(MMSGroupSharedEmailConst.SharedEmailExistsMsg, 'error', 'center');
    }
    else {
      let groupSharedEmail: MMSGroupSharedEmailDto = this.setDefaultGroupSharedEmail();
      groupSharedEmail.sharedEmail = sharedEmail.toString();
      groupSharedEmail.assetGroupId = assetId;
      this.groupSharedEmailService.addNewGroupSharedEmail(groupSharedEmail).subscribe((data: MMSGroupSharedEmailDto) => {
        this.commonService.showNotificationMessage(MMSGroupSharedEmailConst.AddedSharedEmailMsg, 'success', 'center');
        this.assetsSharedEmails[index].isValidEmail = true;
        this.assetsSharedEmails[index].sharedEmail = '';
        this.getGroupSharedEmails();
      })
    }
  }

  isDuplicateEmail(itemIndex: number, enteredEmail: string) {
    let existingEmails: MMSGroupSharedEmailDto[] = this.assetsSharedEmails[itemIndex].sharedEmails.filter((email: MMSGroupSharedEmailDto) => (email.sharedEmail!.toLowerCase() === enteredEmail.toLowerCase()));
    if (existingEmails.length > 0) {
      return true;
    }
    return false;
  }
  setDefaultGroupSharedEmail() {
    let groupSharedEmail: MMSGroupSharedEmailDto = new MMSGroupSharedEmailDto();
    groupSharedEmail.id = 0;
    groupSharedEmail.mmsGroupId = this.mmsGroup.id;
    groupSharedEmail.createdBy = this.userMailID;
    groupSharedEmail.modifiedBy = this.userMailID;
    return groupSharedEmail;
  }
  deleteEmail(emailId: number) {
    this.groupSharedEmailService.deleteGroupSharedEmail(emailId).subscribe((data: any) => {
      this.getGroupSharedEmails();
    })
  }
}
