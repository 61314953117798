import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { IrisAssetJoaThresholdLimitService } from '../../services/asset-joa-threshold-limit/iris-asset-joa-threshold-limit.service';
import { JOAThresholGridDTO } from '../../models/JOA-threshold-limit.model';

@Component({
  selector: 'app-iris-asset-joa-threshold-limit',
  templateUrl: './iris-asset-joa-threshold-limit.component.html',
  styleUrls: ['./iris-asset-joa-threshold-limit.component.scss'],
})
export class IRISAssetJOAThresholdLimitComponent implements OnInit {
  gridData: JOAThresholGridDTO[] = [];
  searchedText: string = '';
  checked: boolean = false;
  isAddNewBTNDisabled: boolean = false;
  originData: JOAThresholGridDTO[] = [];

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private joaThresholdService: IrisAssetJoaThresholdLimitService,
  ) { }

  ngOnInit() {
    this.getJOAThresholdGridData();
  }

  getJOAThresholdGridData(): void {
    this.joaThresholdService
      .getJOAThresholdLimitGrid()
      .subscribe((data: JOAThresholGridDTO[]) => {
        if (data) {
          this.gridData = data;
          this.originData = this.gridData;
          if (this.checked === false) {
            this.showActiveDOALevels();
          }
        }
      });
  }
  showActiveDOALevels() {
    const filteredData = this.gridData.filter((ele: JOAThresholGridDTO) => {
      return ele.isActive === true;
    });
    this.gridData = filteredData;
  }

  // navigate to JOA threshold detail page
  joaThresholdDetails(data: JOAThresholGridDTO) {
    this.bindDataInDetailPage('edit', data);
  }

  // to add new JOA threshold records
  addNewLavel() {
    const url = BackOfficeRoutingConstants.IRISAssetJoaThresholdLimitDetailPath;
    this.router.navigate([`../${url}`], {
      state: {
        id: 0,
        label: '',
        joaAmount: '',
        commercialApprover: '',
        waiver: false,
      },
      relativeTo: this.route,
    });
  }

  // data binding JOA Threshold detail page
  bindDataInDetailPage(status: string, data: JOAThresholGridDTO) {
    const url = BackOfficeRoutingConstants.IRISAssetJoaThresholdLimitDetailPath;
    const id = data.id;
    const label = status === 'edit' ? data['label'] : '';
    const commercialUser = status === 'edit' ? data['commercialUser'] : ''
    this.router.navigate([`../${url}`], {
      state: {
        id: id,
        label: label,
        businessUnitId: data.businessUnitId,
        commercialUser: commercialUser
      },
      relativeTo: this.route,
    });
  }

  // search the JOA threshold from grid and to clear searched filters
  searchResetGridActions(actionSeletcted: string) {
    switch (actionSeletcted) {
      case 'search': {
        this.gridFilter(this.searchedText);
        break;
      }
      case 'reset': {
        this.searchedText = '';
        this.getJOAThresholdGridData();
        break;
      }
    }
  }

  // filter grid-data
  gridFilter(text: string) {
    const filteredItems = this.originData.filter((ele: JOAThresholGridDTO) => {
      return ele?.label?.toLowerCase().includes(text.toLowerCase());
    });
    this.gridData = filteredItems;
  }

  // search-box
  textSearched(event: any) {
    if (event.target.value) {
      this.gridFilter(event.target.value);
    } else {
      this.getJOAThresholdGridData();
    }
  }

  // to show active and inactive records
  showHideActiveNInactiveJOA(event: any) {
    if (!this.checked) {
      this.getJOAThresholdGridData();
    } else {
      this.showActiveDOALevels();
    }
  }
}
