import { Component, OnInit } from '@angular/core';
import { RoutingConstant } from '../../constants/modules-routing.constants';
import { CommonService } from '../../services/common.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent implements OnInit {
  RoutingConstant = RoutingConstant;
  activeTab: string = 'MMS';
  is_User: boolean = true;
  is_Admin: boolean = true;
  isAuthenticated: boolean = true;

  constructor() { }

  ngOnInit() {
    let splittedUrl = window.location.href.split('/');
    let url = splittedUrl[splittedUrl.length - 1];
    if (
      (url !== '' && url === 'MMS') ||
      url === 'MMS_IRIS' ||
      url === 'AllIRIS' ||
      url === 'MMS_BackOffice'
    ) {
      this.activeTab = url;
    } else {
      this.activeTab = splittedUrl[splittedUrl.length - 2];
    }
  }

  loadApplication(value: boolean) {
    this.isAuthenticated = value;
  }

  isMMSUSer(value: boolean) {
    this.is_User = value;
  }

  isAdmin(value: boolean) {
    this.is_Admin = value;
  }
  navigateToMMSPortal() {
    window.location.href = environment.MMS.portalUrl;
  }
}
