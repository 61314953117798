import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRISDashboardGridDTO } from '../../models/iris-dashboard.constants';
import { IRISRoutingConstants } from 'src/app/core/constants/IRIS_Constants/IRIS-components-routing-constants';
import { IrisRequestService } from '../../services/IRIS-Request/iris-request.service';
import { IRISCreatedByMeStatusRecords } from 'src/app/core/constants/dashboard_Constants/dashboard_Constants.constants';
import { IRISCreatedStatus } from 'src/app/core/model/common/dashboard-iris_created_status.model';
import { CommonService } from 'src/app/core/services/common.service';
import { InputFieldsConstants } from 'src/app/core/constants/input-fields.constants';
import { IRISRequestDto } from '../../models/iris-request-workflow/iris-request.model';

@Component({
  selector: 'iris-dashboard',
  templateUrl: './iris-dashboard.component.html',
  styleUrls: ['./iris-dashboard.component.scss'],
})
export class IrisDashboardComponent implements OnInit {
  gridData: IRISRequestDto[] = [];
  createdByMeRecords: IRISCreatedStatus[] = IRISCreatedByMeStatusRecords;
  condTrue: boolean = true;
  searchedText: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private irisRequestService: IrisRequestService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.irisRequestService.getRequest().subscribe((data: IRISRequestDto[]) => {
      let sortedData: IRISRequestDto[] = Object.assign([], data);
      sortedData = sortedData.sort((req1: IRISRequestDto, req2: IRISRequestDto) => ((new Date(req2.createdDateTime!).getTime()) - (new Date(req1.createdDateTime!).getTime())))
      this.gridData = sortedData;
    });
  }

  searchByTrackingNumber(name: string) {
    this.irisRequestService
      .searchRequest(name.trim())
      .subscribe((data: any) => {
        if (data) {
          this.gridData = [];
          this.gridData.push(data);
        }
      });
  }

  // navigate to mange-iris page
  manageIris() {
    if (this.isIRES_Requestor()) {
      const url = IRISRoutingConstants.IRISCreate;
      this.router.navigate([`../${url}`], {
        state: {
          actionToDo: 'toCreate',
          isCreate: true,
          id: '',
          trackNo: '',
        },
        relativeTo: this.route,
      });
    } else {
      this.commonService.showNotificationMessage(
        InputFieldsConstants.invalidPermission,
        'error',
        'center'
      );
    }
  }

  isIRES_Requestor(): boolean {
    let roles: string = !this.commonService.isNullOrEmptyOrUndefined(
      this.commonService.getUserRoles()
    )
      ? this.commonService.getUserRoles()
      : '';
    if (roles !== '') {
      let index = roles.split(',').indexOf('IRISRequestor');
      if (index >= 0) {
        return true;
      }
    }
    return false;
  }
  // navigate to iris-overview page
  irisDetails(data: any) {
    const url = IRISRoutingConstants.IRISOverview;
    this.router.navigate([`../${url}`], {
      state: {
        actionToDo: 'toViewEdit',
        requestID: data.id,
        trackingNumber: data.trackingNumber,
        createdBy: data.createdBy,
        createdDateTime: data.createdDateTime,
      },
      relativeTo: this.route,
    });
  }
}
