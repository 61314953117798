<div class="asset-location-container j-c-s m-container">
    <h1 class="mb-20 setup-heading">MMS Groups</h1>
    <div class="mr-20 float-right">
        <button kendoButton class="border-button ml-20 f-size-150" (click)="exportExcel(grid)">
            Export
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="importExcel()">
            Import
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="addEdiGroup(true,0,'',0,true)">
            Add new Group
        </button>
    </div>

    <!-- Filter actions -->
    <section class="manage-contentbox">
        <section class="mt-20 d-flex">
            <div class="mt-20 pr-20 d-flex w-55">
                <kendo-textbox [(ngModel)]="searchedText" (keydown.enter)="getGridData(checked)"></kendo-textbox>
                <button kendoButton class="primary-button ml-20 f-size-150" (click)="getGridData(checked)">
                    Search
                </button>
                <button kendoButton class="border-button ml-20 f-size-150" (click)="resetGrid()">
                    Reset
                </button>
            </div>
        </section>

        <!-- toggle active/inactive groups-->
        <section class="toggle-active-inactive">
            <div class="d-flex j-c-s w-45 mt-10 a-center">
                <p class="mt-10">Show Inactive Groups</p>
                <kendo-switch [(ngModel)]="checked" class="mt-10" (valueChange)="getGridData($event)">
                </kendo-switch>
            </div>
        </section>

        <!-- Table data -->
        <section class="mt-15">
            <kendo-grid [kendoGridBinding]="gridData" [resizable]="true" [pageSize]="50" #grid="kendoGrid"
                [pageable]="true" [sortable]="true">
                <!-- Excel -->
                <kendo-grid-excel [fetchData]="commonService.exportGridData(this.mmsGroupList, '', 'asc')"
                    [fileName]="fileName">
                    <kendo-excelexport-column field="label" title="Group">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="isActive" title="Active">
                    </kendo-excelexport-column>
                    <kendo-excelexport-column field="order" title="Display Order">
                    </kendo-excelexport-column>
                </kendo-grid-excel>
                <!-- Group Label -->
                <kendo-grid-column field="label" title="Label" [width]="53">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a href="Javascript:void(0);"
                            (click)="addEdiGroup(false,dataItem.id,dataItem.label,dataItem.order,dataItem.isActive)">
                            {{ dataItem.label }}
                        </a>
                    </ng-template>
                </kendo-grid-column>
                <!-- Order -->
                <kendo-grid-column field="order" title="Order" [width]="12" [style]="{ 'padding-left': '40px' }">
                </kendo-grid-column>
                <!-- Is Active -->
                <kendo-grid-column field="isActive" title="Is Active" [width]="14" [style]="{ 'padding-left': '30px' }">
                </kendo-grid-column>

                <!-- IsIRIS -->
                <kendo-grid-column field="isIRIS" title="Is IRIS" [width]="21" [style]="{ 'padding-left': '30px' }">
                </kendo-grid-column>
            </kendo-grid>

            <!-- total data count -->
            <p class="grid-length mt-20">{{ gridData.length }} records</p>
        </section>
    </section>
</div>