export const RequestStatesConstants = {
    Open: 1,
    Closed: 2,
    Cancelled: 3,
    Ready_For_Close_Out: 4,
    Pending_Collection: 5,
    Draft: 6,
    Approved: 7,
    Pending_Approval: 8,
    System_Updates: 9,
    Rejected: 10,
    Completed: 11,
    Pending_Commercial_Review: 12,
    Pending_Review: 13,
    Pending_DOA_Review: 14,
    Pending_Endorsement: 15,
    Pending_Supply_Base_Review: 16,
    Pending_Auction: 17,
    Disposed: 18,
    Pending_Finance_Update: 19,
    Recalled: 20,
    Send_to_Disposal: 21,
    Pending_Credit_Note: 22,
    Pending_Resupply: 23,
    Pending_SME_Review: 24,
    Pending_Stocking__Approval: 25,
    Pending_MMD: 26
}