import { AfterViewChecked, AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-help-track-request',
  templateUrl: './help-track-request.component.html',
  styleUrls: ['./help-track-request.component.scss']
})
export class HelpTrackRequestComponent implements AfterViewInit {
  @ViewChild('iris_screen') iris_screenElement!: ElementRef;
  @ViewChild('request_status') request_statusElement!: ElementRef;
  @ViewChild('approval_flow') approval_flowElement!: ElementRef;
  fragment: string = '';
  constructor(private router: Router, private commonService: CommonService) {
    let state: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(state)) {
      this.fragment = state['fragment'];
    }
  }
  
  ngAfterViewInit() {
    if (this.fragment !== '') {
      this.scrollTo_HelpTrackRequest(this.fragment);
      this.fragment = '';
    }
  }

  scrollTo_HelpTrackRequest(fragment: any) {
    switch (fragment) {
      case 'iris_screen': {
        if (this.iris_screenElement && this.iris_screenElement.nativeElement) {
          this.iris_screenElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'request_status': {
        if (this.request_statusElement && this.request_statusElement.nativeElement) {
          this.request_statusElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'approval_flow': {
        if (this.approval_flowElement && this.approval_flowElement.nativeElement) {
          this.approval_flowElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
    }
  }
}
