import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { IrisRequestService } from '../../../services/IRIS-Request/iris-request.service';
import { DisposalMethod, InventoryConditionDto, MaterialType, TechnicalEndorserDto } from '../../../models/iris-request-workflow/iris-request.model';
import { IRISRoutingConstants } from 'src/app/core/constants/IRIS_Constants/IRIS-components-routing-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { LineDetailsDto } from '../../../models/iris-request-workflow/line-item.model';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DisposalMethodDefaultValue, EndorserDefaultValue, IRISRequestConstants, InventoryConditionConstants, MaterialTypeDefaultValue } from 'src/app/core/constants/IRIS_Constants/iris-requester/iris-requester.constants';
import { AttachmentsDTO } from '../../../models/common_constants/attachments.constants';
import { FileConstant } from '../../../models/common_constants/file-upload-type.constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IDLabelArrayDTO } from 'src/app/core/model/common/id-label.model';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { IRISRequestStateID } from 'src/app/core/constants/Request_State_Constants_ID\'s/requestStateID.constants';

@Component({
  selector: 'app-iris-action',
  templateUrl: './iris-action.component.html',
  styleUrls: ['./iris-action.component.scss'],
  providers: [DatePipe],
})
export class IrisActionComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<any> | any;
  @ViewChild('grid', { static: false }) grid: ElementRef<GridComponent> | any;
  @ViewChild('grid_cycleCount', { static: false }) grid_cycleCount: ElementRef<GridComponent> | any;
  acceptedFiles: string[] = FileConstant.acceptedFiles;
  accptedFilesStr: string = this.acceptedFiles.join(',');
  attachmentFileDetails: any[] = [];
  gridData: LineDetailsDto[] = [];
  importedItemsList: LineDetailsDto[] = [];
  materialTypeList: MaterialType[] = [];
  disposalMethodList: DisposalMethod[] = [];
  materialTypeByDefault!: number;
  isJDEMaterialSelected: boolean = false;
  isBulkAddition: boolean = false;
  isOther: boolean = false;
  isCycleCount: boolean = false;
  fileName: string = '';
  estiCost: any = [];
  totalestimatedCost!: number;
  isButtonEnabled!: boolean;
  isItemsGridData: boolean = false;
  inventoryConditionRecords: InventoryConditionDto[] = InventoryConditionConstants;
  bindDisposalMethodValue: InventoryConditionDto = DisposalMethodDefaultValue;
  materialTypeDefaultValue: InventoryConditionDto = MaterialTypeDefaultValue;
  endorserDefaultValue: TechnicalEndorserDto = EndorserDefaultValue;
  currentDate: any;
  requestID!: number;
  selectedDisposalMethod!: number | undefined;
  selectedMaterialType!: number | undefined;
  selectedJDENumber!: string;
  selectedEndorser!: number;
  requestIssueID!: number;
  trackingNumber!: string;
  endorserList: TechnicalEndorserDto[] = [];
  disposalMethodSelected: IDLabelArrayDTO = {};
  materialTypeSelected: IDLabelArrayDTO = {};
  userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
  requestIssueForm = new FormGroup({
    materialType: new FormControl(1, Validators.required),
    disposalMethod: new FormControl(0, Validators.required),
    JDENo: new FormControl(<number | undefined>0),
    attachment: new FormControl([], Validators.required),
    comment: new FormControl(<string | undefined>'', Validators.required),
    endorser: new FormControl(0, Validators.required),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public commonService: CommonService,
    private irisRequestService: IrisRequestService,
    public datepipe: DatePipe,
    private dialogService: DialogService
  ) {
    const Tdate = new Date();
    this.currentDate = new Date(Tdate + 'Z');
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.trackingNumber = stateData['trackingNumber'];
      this.requestID = stateData['requestID'];
      sessionStorage.setItem('requestIssueDetails', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('requestIssueDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.trackingNumber = sessionState?.trackingNumber;
        this.requestID = sessionState?.requestID;
      }
    }
    //this.getMaterialTypeDisposalMethodRecords();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('requestIssueDetails');
    sessionStorage.removeItem('disposalMethodRecord');
  }

  ngOnInit() {
    this.getRequestLineGridData();
    this.getRequestDetailsByID_And_MaterialTypeDisposalMethodRecords();
    this.getTechnicalEndorserUsers();
  }

  // request details by ID
  getRequestDetailsByID() {
    this.irisRequestService
      .getRequestIssueByID(this.requestID)
      .subscribe((data: any) => {
        this.requestIssueID = data?.id;
        this.selectedMaterialType = data?.irismaterialTypeId;
        this.selectedDisposalMethod = data?.disposalMethodId;
        this.selectedJDENumber = data.jdedocumentNumber;
        this.selectedEndorser = data.technicalEndorserId;
        if (this.selectedDisposalMethod || this.selectedMaterialType) {
          this.bindDisposalAndMaterialDropdownValues();
        }
      });
  }

  getRequestDetailsByID_And_MaterialTypeDisposalMethodRecords() {
    let materialType = this.irisRequestService.getMaterialType();
    let disposalMethod = this.irisRequestService.getDisposalMethod();
    let requestIssue = this.irisRequestService.getRequestIssueByID(this.requestID);
    forkJoin([materialType, disposalMethod, requestIssue]).subscribe(
      ([materialRecord, disposalRecord, requestIssueRecord]) => {
        this.requestIssueID = requestIssueRecord?.id!;
        this.selectedMaterialType = requestIssueRecord?.irismaterialTypeId;
        this.selectedDisposalMethod = requestIssueRecord?.disposalMethodId;
        this.selectedJDENumber = requestIssueRecord.jdedocumentNumber!;
        this.selectedEndorser = requestIssueRecord.technicalEndorserId!;
        if (materialRecord && disposalRecord) {
          sessionStorage.setItem('disposalMethodRecord', JSON.stringify(disposalRecord));
          if (
            materialRecord[0].id == 1 &&
            materialRecord[0].label == 'General Material'
          ) {
            this.materialTypeByDefault = materialRecord[0].id;
          }
          this.materialTypeList = materialRecord;
          if (this.selectedMaterialType === 4) {
            this.isJDEMaterialSelected = true;
            this.disposalMethodList = this.showActiveDisposalMethods('showActive');
          }
          else {
            this.disposalMethodList = this.showActiveDisposalMethods('');
          }
          if (this.selectedDisposalMethod && this.selectedMaterialType) {
            this.bindDisposalAndMaterialDropdownValues();
          }
          if (this.selectedMaterialType === 4) {
            this.displayFinancialButtons(this.selectedDisposalMethod!)
          }
        }
      }
    );
  }

  // set input fields value for IRIS request line details
  bindDisposalAndMaterialDropdownValues() {
    this.requestIssueForm.get('disposalMethod')?.setValue(<number>this.selectedDisposalMethod);
    this.requestIssueForm.get('materialType')?.setValue(<number>this.selectedMaterialType);
    this.requestIssueForm.get('endorser')?.setValue(<number>this.selectedEndorser);
    this.requestIssueForm.get('endorser')?.setValue(<number>this.selectedEndorser);
    this.isButtonEnabled = true;
  }

  // to get request line items data, to show in grid
  getRequestLineGridData() {
    let i = 1;
    this.estiCost = [];
    this.irisRequestService
      .getRequestLineByID(this.requestID)
      .subscribe((data: LineDetailsDto[]) => {
        if (data) {
          data.map((ele: LineDetailsDto) => {
            ele.index = i++;
            this.estiCost.push(ele.estimatedCost);
            this.totalestimatedCost = this.estiCost.reduce((acc: any, current: any) => acc + current, 0);
            this.inventoryConditionRecords.filter((invet: any) => {
              if (invet['id'] === ele.inventoryConditionId) {
                return (ele.inventLabel = invet['label']);
              }
            });
          });
          this.gridData = data;
        }
        if (this.gridData.length) {
          this.isItemsGridData = true;
          this.requestIssueForm.get('disposalMethod')?.disable();
          this.requestIssueForm.get('materialType')?.disable();
        } else {
          this.isItemsGridData = false;
          this.requestIssueForm.get('disposalMethod')?.enable();
          this.requestIssueForm.get('materialType')?.enable();
        }
      });
  }

  // get material-type, disposal-method records to bind in dropdown
  getMaterialTypeDisposalMethodRecords() {
    let materialType = this.irisRequestService.getMaterialType();
    let disposalMethod = this.irisRequestService.getDisposalMethod();
    forkJoin([materialType, disposalMethod]).subscribe(
      ([materialRecord, disposalRecord]) => {
        if (materialRecord && disposalRecord) {
          sessionStorage.setItem('disposalMethodRecord', JSON.stringify(disposalRecord));
          if (
            materialRecord[0].id == 1 &&
            materialRecord[0].label == 'General Material'
          ) {
            this.materialTypeByDefault = materialRecord[0].id;
          }
          this.materialTypeList = materialRecord;
          this.disposalMethodList = this.showActiveDisposalMethods('');
        }
      }
    );
  }

  // get the list of endorsers
  getTechnicalEndorserUsers() {
    this.irisRequestService
      .getTechnicalEndorserUsers()
      .subscribe((data: TechnicalEndorserDto[]) => {
        if (data) {
          this.endorserList = data;
        }
      });
  }

  // to validate and show dynamic Disposal_Response based upon selected Material_Type.
  showActiveDisposalMethods(state: string) {
    let disposal = JSON.parse(sessionStorage.getItem('disposalMethodRecord')!);
    switch (state) {
      // showActive: only if user selected, 'Financial and JDE Material Adjustments', filter response by key, isFinacialWriteOff==true
      case 'showActive': {
        const activeDisposalLists = disposal.filter((ele: DisposalMethod) => {
          return ele.isFinacialWriteOff === true;
        });
        return activeDisposalLists;
      }
      case '': {
        const inActiveDisposalLists = disposal.filter((ele: DisposalMethod) => {
          return ele.isFinacialWriteOff === false;
        });
        return inActiveDisposalLists;
      }
    }
  }

  // to select any material value from Material_Response
  materialSelected(id: number) {
    this.requestIssueForm.get('disposalMethod')?.setValue(<number>0);
    if (id === 4) {
      this.isJDEMaterialSelected = true;
      this.disposalMethodList = this.showActiveDisposalMethods('showActive');
    } else {
      this.isJDEMaterialSelected = false;
      this.isCycleCount = false;
      this.isBulkAddition = false;
      this.disposalMethodList = this.showActiveDisposalMethods('');
    }
    this.handleButtonActivation();
  }

  // to select any disposal from disposal_Response and make buttons enable
  disposalSelected(id: number) {
    this.handleButtonActivation();
    if (this.requestIssueForm.controls['materialType'].value === 4) {
      if (this.requestIssueForm.controls['disposalMethod'].value === 0) {
        this.isBulkAddition = false;
      }
      else {
        this.displayFinancialButtons(this.requestIssueForm.controls['disposalMethod'].value!)
      }
    }
  }

  displayFinancialButtons(dispodalMethodId: number) {
    let label: string | undefined = this.disposalMethodList.filter((disposalMeth: DisposalMethod) => (disposalMeth.id === dispodalMethodId))[0].label;
    switch (label) {
      case 'Expired or Damaged': {
        this.isBulkAddition = true;
        this.isOther = false;
        this.isCycleCount = false;
        break;
      }
      case 'Cycle Count': {
        this.isBulkAddition = false;
        this.isOther = false;
        this.isCycleCount = true;
        break;
      }
      case 'Other': {
        this.isBulkAddition = false;
        this.isOther = true;
        this.isCycleCount = false;
        break;
      }
      case 'Inventory Optimisation': {
        this.isBulkAddition = true;
        this.isOther = false;
        this.isCycleCount = false;
        break;
      }
    }
  }

  handleButtonActivation() {
    if (this.requestIssueForm.controls['materialType'].value !== 0 && this.requestIssueForm.controls['disposalMethod'].value !== 0) {
      this.isButtonEnabled = true;
    }
    else {
      this.isButtonEnabled = false;
    }
  }
  // action buttons save || cancel
  action(actionType: string, data: any) {
    switch (actionType) {
      case 'save': {
        this.saveRequestIssue('');
        break;
      }
      case 'addNewItem': {
        this.addNewItem();
        break;
      }
      case 'bulkAddition': {
        this.bulkAddition();
        break;
      }
      case 'download': {
        this.exportToExcel(data);
        break;
      }
      case 'upload': {
        this.importLineItems();
        break;
      }
    }
  }

  editLineItem(records: any) {
    const url = IRISRoutingConstants.AddNewItemURL;
    let materialType = <any>(this.requestIssueForm?.controls['materialType'].value);
    let disposalMethod: any = this.requestIssueForm?.controls['disposalMethod'].value;
    this.router.navigate([`../${url}`], {
      state: {
        actionStatus: 'edit',
        estimatedCost: records.estimatedCost,
        requestId: records.requestId,
        requestLineID: records.id,
        inventoryConditionId: records.inventoryConditionId,
        itemDescription: records.itemDescription,
        itemNumber: records.itemNumber,
        itemQty: records.itemQty,
        manufacturerName: records.manufacturerName,
        manufacturerPartNumber: records.manufacturerPartNumber,
        unitCost: records.unitCost,
        selectedMaterialTypeID: materialType,
        selectedDisposalMethodID: disposalMethod
      },
      relativeTo: this.route,
    });
  }

  deleteLineItem(records: any) {
    this.irisRequestService
      .deleteLineItem(records.id)
      .subscribe((data: any) => {
        this.getRequestLineGridData();
      });
  }

  // to create new item in line details
  addNewItem() {
    let materialType = <any>(this.requestIssueForm?.controls['materialType'].value);
    let disposalMethod: any = this.requestIssueForm?.controls['disposalMethod'].value;
    let disposalMethodName: any = this.disposalMethodList.filter((dm: DisposalMethod) => dm.id === disposalMethod)[0].label;
    const url = IRISRoutingConstants.AddNewItemURL;
    this.router.navigate([`../${url}`], {
      state: {
        actionStatus: 'create',
        requestId: this.requestID,
        trackingNumber: this.trackingNumber,
        selectedMaterialTypeID: materialType,
        selectedDisposalMethodID: disposalMethod,
        selectedDisposalMethodName: disposalMethodName
      },
      relativeTo: this.route,
    });
  }

  bulkAddition() {
    let materialType = <any>(this.requestIssueForm?.controls['materialType'].value);
    let disposalMethod: any = this.requestIssueForm?.controls['disposalMethod'].value;
    const url = IRISRoutingConstants.bulkItemsAdditionURL;
    this.router.navigate([`../${url}`], {
      state: {
        requestId: this.requestID,
        trackingNumber: this.trackingNumber,
        selectedMaterialTypeID: materialType,
        selectedDisposalMethodID: disposalMethod,
      },
      relativeTo: this.route,
    });
  }
  saveRequestIssue(state: string) {
    const url = IRISRoutingConstants.IRISOverview;
    let materialType = <any>(this.requestIssueForm?.controls['materialType'].value);
    let disposalMethod: any = this.requestIssueForm?.controls['disposalMethod'].value;
    let JDENo = this.requestIssueForm?.controls['JDENo'].value;
    let comment = <string>this.requestIssueForm?.controls['comment'].value;
    let endorser: any = this.requestIssueForm?.controls['endorser'].value;
    let formData: any = new FormData();
    formData.append('id', this.requestIssueID!);
    formData.append('irismaterialTypeId', materialType!);
    formData.append('disposalMethodId', disposalMethod!);
    formData.append('comment', comment!.toString());
    formData.append('technicalEndorserId', endorser!);
    formData.append('jdedocumentNumber', JDENo);
    formData.append('requestStateId', IRISRequestStateID.draftStateID);
    formData.append('createdBy', this.userMailID!.toString());
    formData.append('modifiedBy', this.userMailID!.toString());
    if (this.attachmentFileDetails.length) {
      for (let attachment of this.attachmentFileDetails!) {
        formData.append('attachments', attachment.file);
      }
    }
    if (!endorser) {
      const endorserEmptyMSG = IRISRequestConstants.endorserEmptyMSG;
      this.commonService.showNotificationMessage(endorserEmptyMSG, 'error', 'center');
    } else {
      this.irisRequestService
        .createRequestIssue(formData)
        .subscribe((data: any) => {
          if (data) {
            this.router.navigate([`../${url}`], {
              state: {
                actionToDo: '',
                requestID: this.requestID,
                trackingNumber: this.trackingNumber
              },
              relativeTo: this.route,
            });
          }
        });
    }
  }

  // go back to overview page
  goBackToOverviewPage(state: string) {
    // const url = IRISRoutingConstants.IRISOverview;
    const msg = IRISRequestConstants.goBackWithoutSavingMSG;
    switch (state) {
      case 'cancel': {
        this.commonService
          .showConfirmation('Cancel', msg)
          .subscribe((res: any) => {
            if (res.action === 'yes') {
              this.setStatus();
            }
          });
        break;
      }
      case 'back': {
        this.setStatus();
        break;
      }
    }
  }

  // set status to validate status UI on overview page
  setStatus() {
    const url = IRISRoutingConstants.IRISOverview;
    this.router.navigate([`../${url}`], {
      state: {
        actionToDo: 'toViewEdit',
        requestID: this.requestID,
        trackNo: this.trackingNumber
      },
      relativeTo: this.route,
    });
  }

  // to download grid line items
  public exportToExcel(grid: GridComponent): void {
    const name = IRISRequestConstants.downloadItem;
    this.fileName = `${this.trackingNumber}${name}.xlsx`;
    grid.saveAsExcel();
  }

  // to import line items from excel
  importLineItems() {
    this.importedItemsList = [];
    let emptyExcelErrorMsg = IRISRequestConstants.emptyExcelRowMsg;
    let rowNum: number = 1;
    let errorMsg: string = '';
    let validRecords: boolean = true;
    let excelCommonMSG: string = IRISRequestConstants.excelCommonErrorMSG;
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent,
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === 'Save') {
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        if (!arr.length) {
          this.commonService.showNotificationMessage(
            emptyExcelErrorMsg,
            'error',
            'center'
          );
        } else {
          for (let row of arr) {
            let items: LineDetailsDto = this.setDefaultItemsValue();
            let unitCost = row['Unit Cost'];
            let disposalQty = row['Disposal Quantity'];
            let estimateCost = row['Estimated Cost'];
            rowNum++;
            // Item Number
            if (this.commonService.isEmptyOrUndefined(row['Item Number'])) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.itemNoRequiredMSG}`;
              validRecords = false;
              break;
            } else if (this.isduplicateItemNumber(row['Item Number'].toString())) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.itemNoDuplicateInTable}`;
              validRecords = false;
              break;
            } else if (this.isduplicateItemNumberInExcel(row['Item Number'].toString())) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.itemNoDuplicateInExcel}`;
              validRecords = false;
              break;
            } else {
              items.itemNumber = row['Item Number'].toString();
            }
            // Item Description
            if (this.commonService.isEmptyOrUndefined(row['Item Description'])) {
              errorMsg = `${excelCommonMSG} ${rowNum}:  ${IRISRequestConstants.descriptionRequiredMSG}`;
              validRecords = false;
              break;
            } else {
              items.itemDescription = row['Item Description'].toString();
            }
            // Manufacturer Name
            if (this.commonService.isEmptyOrUndefined(row['Manufacturer Name'])) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.manufacturerRequiredMSG}`;
              validRecords = false;
              break;
            } else {
              items.manufacturerName = row['Manufacturer Name'].toString();
            }
            // Manufacturer Part Number
            if (this.commonService.isEmptyOrUndefined(row['Manufacturer Part Number'])) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.manufacturerPartRequiredMSG}`;
              validRecords = false;
              break;
            } else {
              items.manufacturerPartNumber = row['Manufacturer Part Number'].toString();
            }
            // Manufacturer Part Number
            if (this.commonService.isEmptyOrUndefined(row['Inventory Condition'])) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.inventoryConditionRequiredMSG}`;
              validRecords = false;
              break;
            } else {
              let inventoryID: number | undefined = this.getInventoryConditionID(row['Inventory Condition']);
              items.inventoryConditionId = inventoryID;
            }
            // Unit Cost
            if (this.commonService.isEmptyOrUndefined(row['Unit Cost'])) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.unitCostRequiredMSG}`;
              validRecords = false;
              break;
            } else {
              items.unitCost = row['Unit Cost'];
            }
            // Disposal Quantity
            if (this.commonService.isEmptyOrUndefined(row['Disposal Quantity'])) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.disposalQuantityRequiredMSG}`;
              validRecords = false;
              break;
            } else {
              items.itemQty = row['Disposal Quantity'];
            }
            // Estimated Cost
            if (this.commonService.isEmptyOrUndefined(row['Estimated Cost'])) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.estimatedCostRequiredMSG}`;
              validRecords = false;
              break;
            } else if (this.isEstimatedCostValid(unitCost, disposalQty, estimateCost) == false) {
              errorMsg = `${excelCommonMSG} ${rowNum}: ${IRISRequestConstants.calculatedEstimatedCostErrorMSG}`;
              validRecords = false;
            } else {
              items.estimatedCost = row['Estimated Cost'];
            }
            this.importedItemsList.push(items);
          }
          if (!validRecords) {
            this.commonService.showNotificationMessage(errorMsg, 'error', 'center');
          } else {
            this.irisRequestService
              .uploadLineItemsExcel(this.importedItemsList)
              .subscribe((response: LineDetailsDto[]) => {
                if (response) {
                  this.getRequestLineGridData();
                }
              });
          }
        }
      }
    });
  }

  // to set default value in form, to add new items
  setDefaultItemsValue(): LineDetailsDto {
    let item: LineDetailsDto = new LineDetailsDto();
    item.index = 0;
    item.itemNumber = '';
    item.itemDescription = '';
    item.unitCost = 0;
    item.itemQty = 0;
    item.estimatedCost = '';
    item.manufacturerPartNumber = '';
    item.manufacturerName = '';
    item.inventoryConditionId = 0;
    item.manufacturerPartNumber = '';
    item.createdBy = this.userMailID;
    item.modifiedBy = this.userMailID;
    item.createdDateTime = this.currentDate;
    item.modifiedDateTime = this.currentDate;
    item.requestId = this.requestID;
    return item;
  }

  // to uplaod an attachemnts
  onFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `${IRISRequestConstants.fileFormatErrorMSG} (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AttachmentsDTO = this.setNewAttachment(files[0]);
        this.attachmentFileDetails.push(newAttachment);
        this.fileInput.nativeElement.value = '';
      } else {
        this.commonService.showNotificationMessage(
          errorMessage,
          'warning',
          'center'
        );
      }
    }
  }

  // to validate the uploaded file
  isValidFile(file: File): boolean {
    const name: string = file.name;
    const extension: string = '.' + name.split('.')[1];
    return this.acceptedFiles.includes(extension.toLocaleLowerCase());
  }

  // set the details to the attachments
  setNewAttachment(file: File): AttachmentsDTO {
    const newAttachment: AttachmentsDTO = new AttachmentsDTO();
    newAttachment.attachmentId = 0;
    newAttachment.file = file;
    newAttachment.fileName = file.name;
    newAttachment.blobUrI = '';
    newAttachment.operation = 1;
    return newAttachment;
  }

  // to remove particular attached file
  removeFile(index: number) {
    if (this.attachmentFileDetails && this.attachmentFileDetails[index]) {
      this.attachmentFileDetails!.splice(index, 1);
    }
  }

  // to download particular attached file
  downloadFile(index: number) {
    let attachment: any = this.attachmentFileDetails[index];
    const blob = new Blob([attachment.file!], { type: attachment.file!?.type });
    this.commonService.downloadFile(blob, attachment.name!);
  }

  isduplicateItemNumber(inputValue: string) {
    let itemNumber: LineDetailsDto[] = this.gridData.filter(
      (x: LineDetailsDto) =>
        x.itemNumber?.toLowerCase() === inputValue.toLowerCase()
    );
    if (itemNumber.length > 0) {
      return true;
    }
    return false;
  }

  isduplicateItemNumberInExcel(inputValue: string) {
    let itemNumber: LineDetailsDto[] = this.importedItemsList.filter(
      (x: LineDetailsDto) =>
        x.itemNumber?.toLowerCase() === inputValue.toLowerCase()
    );
    if (itemNumber.length > 0) {
      return true;
    }
    return false;
  }

  selectedInventoryID!: number | undefined;
  getInventoryConditionID(inputValue: string) {
    this.inventoryConditionRecords.filter((x: InventoryConditionDto) => {
      if (x.label?.toLowerCase() === inputValue.toLowerCase()) {
        this.selectedInventoryID = x.id;
        return this.selectedInventoryID;
      }
      return this.selectedInventoryID;
    });
    return this.selectedInventoryID;
  }

  isEstimatedCostValid(unitCost: number, disposalQty: number, estimateCost: number): any {
    const Tcost = Number(unitCost * disposalQty);
    if (Tcost === estimateCost) {
      return true;
    } else {
      return false;
    }
  }

  deleteLineItem_CycleCount(records: any) { }
}
