export const environment = {
  production: true,
  apiUrl: 'https://iris-api-test-cvx.azurewebsites.net/',
  azureAd: {
    clientId: 'ab253506-7c41-4f3f-b9f6-bdff58bb28b7',
    redirectUri: 'https://iris-test.azure.chevron.com/',
    authority:
      'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    postLogoutRedirectUri: 'https://iris-test.azure.chevron.com/logout',
    graphApis: {
      users: 'https://graph.microsoft.com/v1.0/users',
      me: 'https://graph.microsoft.com/v1.0/me',
    },
    downStreamApis: {
      baseUri: 'https://iris-api-test-cvx.azurewebsites.net/',
      scope: 'https://iris-api-dev.azure.chevron.com/user_impersonation'
    },
  },
  MMS: {
    portalUrl: 'https://wombatmms-test-cvx.azurewebsites.net/',
    apiUrl: 'https://wombatmms-api-test.azure.chevron.com/',
    scope: 'https://wombatmms-api-dev.azure.chevron.com/user_impersonation'
  }
};
