import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserGuideComponent } from './feature/userguide/components/user-guide/user-guide.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { InvalidPermissionsComponent } from './core/components/invalid-permissions/invalid-permissions.component';
import { LogoutComponent } from './core/components/logout/logout.component';
import { RoutingConstant } from './core/constants/modules-routing.constants';
import { ProfileComponent } from './core/components/profile/profile.component';

const routes: Routes = [
  // {
  //   path: RoutingConstant.MMS,
  //   loadChildren: () =>
  //     import('./feature/mms/module/mms-routing.module').then(
  //       (m) => m.MMSRoutingModule),
  //   canActivate: [MsalGuard],
  // },
  {
    path: RoutingConstant.IRIS,
    loadChildren: () =>
      import('./feature/iris/module/iris-routing.module').then(
        (m) => m.IRISRoutingModule),
    canActivate: [MsalGuard],
    title: 'My IRIS'
  },
  {
    path: RoutingConstant.All_IRIS,
    loadChildren: () =>
      import('./feature/all-iris/module/allIris-routing.module').then(
        (m) => m.ALLIRISRoutingModule),
    canActivate: [MsalGuard],
    title: 'All IRIS'
  },
  {
    path: RoutingConstant.Setup,
    loadChildren: () =>
      import('./feature/setup/modules/setup-routing.module').then(
        (m) => m.SetupRoutingModule),
    canActivate: [MsalGuard],
    title: "List Manager"
  },
  {
    path: RoutingConstant.User_Guide,
    loadChildren: () => import('./feature/userguide/modules/userguide-routing.module').then((m) => m.UserGuideRoutingModule),
    canActivate: [MsalGuard],
    title: "Help Getting Started"
  },
  {
    path: RoutingConstant.InvalidPermissions,
    component: InvalidPermissionsComponent,
    title: 'Invalid Permissions'
  },
  {
    path: RoutingConstant.Logout,
    component: LogoutComponent,
    title: 'Logout'
  },
  {
    path: RoutingConstant.profile,
    component: ProfileComponent,
    title: 'MyProfile'
  },
  {
    path: '',
    redirectTo: RoutingConstant.IRIS,
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
