import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { ManageNotificationsService } from '../../services/manage-notifications/manage-notifications.service';
import { ManageNotificationDto } from '../../models/manage-notification.model';
import { ManageNotificationsConst } from 'src/app/core/constants/MMS_BackOffice_Constants/manage-notifications/manage-notifications.constants';
import { UserService } from 'src/app/core/services/user.service';
import { UserDto } from 'src/app/core/model/mmsUser/user.model';

@Component({
  selector: 'app-manage-notifications',
  templateUrl: './manage-notifications.component.html',
  styleUrls: ['./manage-notifications.component.scss']
})
export class ManageNotificationsComponent implements OnInit {
  item?: ManageNotificationDto;
  emailId: string = '';
  emailToggle: boolean = true;
  isValidEmailId: boolean = true;
  userId: number = 0;
  isNew: boolean = false;
  constructor(private commonService: CommonService, private router: Router, private route: ActivatedRoute, private manageNotificationsService: ManageNotificationsService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.getManageNotificationItem();
  }

  getManageNotificationItem() {
    this.userService.getUserByCAI(this.commonService.getUserCAI()).subscribe(
      (user: UserDto) => {
        this.userId = user.id;
        this.manageNotificationsService.getManageNotifocationItemId(user.id).subscribe((item: ManageNotificationDto) => {
          this.item = item;
          if (this.item) {
            this.emailId = this.item.emailId!;
            this.emailToggle = this.item.receivEmail!;
          }
          else {
            this.isNew = true;
            this.item = new ManageNotificationDto();
            this.emailId = this.commonService.getUserEmailId();
            this.emailToggle = true
          }
        })
      })
  }

  action(actionName: string) {
    if (actionName === 'cancel') {
      this.navBack();
    }
    else if (actionName === 'save') {
      this.isValidEmailId = true;
      if (this.emailId === '') {
        this.emailId = this.item!.emailId!;
        this.commonService.showNotificationMessage(ManageNotificationsConst.InvalidEmailMsg, 'error', 'center')
      }
      else if (!ManageNotificationsConst.emailPattern.test(this.emailId.toString())) {
        this.isValidEmailId = false;
        this.emailId = this.item!.emailId!;
        this.commonService.showNotificationMessage(ManageNotificationsConst.InvalidEmailMsg, 'error', 'center')
      }
      else {
        this.item!.emailId = this.emailId;
        this.item!.receivEmail = this.emailToggle;
        if (this.isNew) {
          this.item!.order = 0;
          this.item!.isActive = true;
          this.item!.createdDateTime = new Date;
          this.item!.createdBy = this.commonService.getUserEmailId();
        }
        else {
          this.item!.modifiedDateTime = new Date;
          this.item!.modifiedBy = this.commonService.getUserEmailId();
        }
        this.manageNotificationsService.updateManageNotification(this.userId, this.item!).subscribe((data: any) => {
          this.commonService.showNotificationMessage(ManageNotificationsConst.savedProfileMsg, 'success', 'center')
          this.navBack();
        })
      }
    }
  }
  navBack() {
    this.router.navigate([`./`], { relativeTo: this.route.parent });
  }
}
