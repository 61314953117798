import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserGuideRoutingConstants } from 'src/app/core/constants/User_Guide_Constants/user-guide-routing.constants';

@Component({
  selector: 'app-user-guide-header',
  templateUrl: './user-guide-header.component.html',
  styleUrls: ['./user-guide-header.component.scss']
})
export class UserGuideHeaderComponent {
  UserGuideRoutingConstants = UserGuideRoutingConstants;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  navCreateNewIRISRequestGuide() {
    this.router.navigate(['../' + UserGuideRoutingConstants.HelpCreateRequest], { relativeTo: this.route });
  }
  navTrackRequestGuide() {
    this.router.navigate(['../' + UserGuideRoutingConstants.HelpTrackRequest], { relativeTo: this.route });
  }
}
