import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { IRISAuctioneersGridDTO } from '../../models/iris-auctioneers.model';
import { IrisAuctioneersService } from '../../services/iris-auctioneer/iris-auctioneers.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-iris-auctioneers',
  templateUrl: './iris-auctioneers.component.html',
  styleUrls: ['./iris-auctioneers.component.scss'],
  providers: [DatePipe],
})
export class IRISAuctioneersComponent implements OnInit {
  gridData: IRISAuctioneersGridDTO[] = [];
  searchedText: string = '';

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private auctioneersService: IrisAuctioneersService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.auctioneersRecords();
  }

  auctioneersRecords() {
    this.auctioneersService
      .getIRISAuctioneersGrid()
      .subscribe((data: IRISAuctioneersGridDTO[]) => {
        if (data) {
          data.map((ele: any) => {
            ele.createdDateTime = this.datepipe.transform(
              ele.createdDateTime, 'dd-MM-YYYY HH:mm:ss'
            );
            this.gridData = data;
          });
        }
      });
  }

  // navigate to Auctioneers-detail page
  auctioneersDetails(data: any) {
    this.bindDataInDetailPage('edit', data);
  }

  // to add new Auctioneers
  createNewAuctioneers() {
    this.bindDataInDetailPage('create', {});
  }

  // search the auctioneers from grid and to clear searched filters
  searchResetGridActions(actionSeletcted: string) {
    switch (actionSeletcted) {
      case 'search': {
        this.gridFilter(this.searchedText);
        break;
      }
      case 'reset': {
        this.searchedText = '';
        this.auctioneersRecords();
        break;
      }
    }
  }

  // filter grid-data
  gridFilter(text: string) {
    const filteredItems = this.gridData.filter((ele: any) => {
      return ele?.auctioneerName?.toLowerCase().includes(text.toLowerCase());
    });
    this.gridData = filteredItems;
  }

  // search-box
  textSearched(event: any) {
    if (event.target.value) {
      this.gridFilter(event.target.value);
    } else {
      this.auctioneersRecords();
    }
  }

  // data binding to detail page
  bindDataInDetailPage(status: string, data: IRISAuctioneersGridDTO) {
    const url = BackOfficeRoutingConstants.IRISAuctioneerDetailPath;
    const auctioneerAddress = status === 'edit' ? data['auctioneerAddress'] : '';
    const auctioneerName = status === 'edit' ? data['auctioneerName'] : '';
    const contactName = status === 'edit' ? data['contactName'] : '';
    const email = status === 'edit' ? data['email'] : '';
    const isActive = status === 'edit' ? data['isActive'] : true;
    const order = status === 'edit' ? data['order'] : 0;
    const createdBy = status === 'edit' ? data['createdBy'] : '';
    const createdDate = status === 'edit' ? data['createdBy'] : '';
    const modifiedBy = status === 'edit' ? data['modifiedBy'] : '';
    this.router.navigate([`../${url}`], {
      state: {
        actionName: status,
        id: data['id'],
        auctioneerAddress: auctioneerAddress,
        auctioneerName: auctioneerName,
        contactName: contactName,
        email: email,
        isActive: isActive,
        order: order,
        createdBy: createdBy,
        createdDateTime: createdDate,
        modifiedBy: modifiedBy,
      },
      relativeTo: this.route,
    });
  }
}
