<div class="content-top d-flex a-center">
    <h1>
        <a class="c-left">
            <span class="fa fa-fw fa-angle-left" (click)="goBackToDashboard()"></span>
        </a>
        IRIS Request {{ requestDetails?.trackingNumber }}
    </h1>
    <div class="status-defult status-btn"
        [ngClass]="requestDetails.requestStateName == 'Open' ? 'colorGreen' : 
    (requestDetails.requestStateName == 'Draft' || requestDetails.requestStateName == 'Rejected') ? 'colorOrange' : 'colorBlack'">
        {{requestDetails.requestStateName}}
    </div>
    <p class="status-Details">
        Updated at {{ requestModifiedDateTime | date : dateFormatWithTime }}
        <i>by
            <b> {{ requestDetails?.userName }} </b>
        </i>
    </p>
</div>

<section class="iris-overview-contentbox">
    <!-- Seperation Line -->
    <div class="seperationLine" style="height: 2px !important;"></div>

    <!-- Download Request in PDF format -->
    <div class="buttons mr-10">
        <button kendoButton class="secondary-border-button ml-20 f-size-150">
            <i aria-hidden="true" class="fa fa-download mr-5"></i> IRIS Summary
        </button>
    </div>
    <div class="overview-box">
        <div style="background: #e6e6e6;">
            <div class="overview-box-title">
                {{ requestDetails?.title }}
            </div>
            <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto', 'auto']" [cols]="['auto', 'auto']"
                [gap]="5">
                <kendo-gridlayout-item [col]="1" [row]="1">
                    <p class="head-data">Asset</p>
                    <p class="deatil-data">{{ requestDetails?.assetName }}</p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [col]="2" [row]="1">
                    <p class="head-data">Asset Location</p>
                    <p class="deatil-data">{{ requestDetails?.locationName }}</p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [col]="1" [row]="2">
                    <p class="head-data">Department</p>
                    <p class="deatil-data">{{ requestDetails?.departmentName }}</p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [col]="2" [row]="2">
                    <p class="head-data">AFE Code</p>
                    <p class="deatil-data">{{ requestDetails?.afecostCode }}</p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [col]="1" [row]="3">
                    <p class="head-data">Disposal Method</p>
                    <p class="deatil-data">
                        <span
                            *ngIf="requestDetails?.disposalMethodName ==='' || !requestDetails?.disposalMethodName ">-</span>
                        <span *ngIf="requestDetails?.disposalMethodName !=='' ">{{ requestDetails?.disposalMethodName }}
                        </span>
                    </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [col]="2" [row]="3">

                    <div class="d-flex j-c-b" *ngIf='requestDetails.documentNumber > 0'>
                        <p class="head-data">JDE Document Number</p>
                        <i style="flex: 1" aria-hidden="true"
                            class="material-icons  text-right primary-color cursor font-size-h4">create</i>
                    </div>
                    <p class="deatil-data" *ngIf='requestDetails.documentNumber > 0'>
                        {{requestDetails?.documentNumber }}
                    </p>

                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>
        <div class="pb-20 pl-20 pr-10">

            <div class="buttons">
                <!-- For the space we are added this div do not remove -->
            </div>

            <div class="justification-details">
                <div class="head">
                    <p class="m-0">DESCRIPTION DETAILS</p>
                </div>
                <div class="body">
                    {{ requestDetails?.description }}
                </div>
            </div>
        </div>
        <div class="pl-10 pr-20 pb-20">
            <div class="buttons mr-10">
                <button kendoButton class="secondary-border-button ml-20 f-size-150" *ngIf="!opened" (click)="open()">
                    View Header
                </button>
                <button kendoButton class="primary-button ml-20 f-size-150" *ngIf="!hideBTNOnCancelledState"
                    (click)="editHeader()">
                    <i aria-hidden="true" class="fa fa-pencil mr-5"></i>
                    Edit Header
                </button>
            </div>
            <div class="justification-details">
                <div class="head">
                    <p class="m-0">JUSTIFICATION DETAILS</p>
                </div>
                <div class="body">
                    {{ requestDetails?.justification }}
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex j-c-b a-center flex mb-10" style="margin-top: 32px">
        <h6 class="font-size-s">Line Item Table</h6>
        <div class="d-flex j-c-e">
            <button kendoButton class="secondary-border-button ml-20 f-size-150" (click)="itemApproval()"
                *ngIf="gridData.length">
                View All Line Items
            </button>
            <button kendoButton class="primary-button ml-20 f-size-150" *ngIf="!hideBTNOnCancelledState"
                (click)="addItemsDetail()">
                Add/Amend Line Details
            </button>
        </div>
    </div>

    <!-- Table data -->
    <section class="mt-20 m-container requestGrid" id="overviewPage" *ngIf="gridData.length">
        <kendo-grid [data]="gridData" #grid="kendoGrid">
            <kendo-grid-column field="index" title="Line Items" [width]="10"
                [style]="{ 'text-align': 'right', 'padding-right': '25px' }"></kendo-grid-column>
            <kendo-grid-column field="itemNumber" title="Item Number" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="itemDescription" title="Item Description" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="manufacturerName" title="Manufacturer Name" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="inventLabel" title="Inventory Condition" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="itemQty" title="Quantity" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="estimatedCost" title="Total  Cost" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="wbscode" title="WBS Code" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="comment" title="Comment" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column *ngIf="requestDetails?.requestStateId=== RequestStatesConstants.Pending_Finance_Update"
                title="" [width]="10" [style]="{ 'text-align': 'right', 'padding-right': '30px' }">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button *ngIf="dataItem.wbscode===null || dataItem.wbscode===''" kendoButton class="blue-button"
                        (click)="updateWBSCode(dataItem.id)">
                        Update WBS Code
                    </button>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </section>

    <!-- Total Estimated Cost -->
    <div class="total-cost font-size-xs secondary-color" *ngIf="gridData.length">
        <div><b>Total Estimated Cost</b></div>
        <div><b> {{totalestimatedCost}} </b></div>
    </div>

    <!-- Seperation Line -->
    <div class="seperationLine"></div>

    <!-- Actions buttons list -->
    <div class="buttons mr-5">
        <button kendoButton class="rf-button red-button f-size-150" *ngIf="hideRejectApproveRecallBTN"
            (click)="rejectRequest()"> Reject </button>
        <button kendoButton class="rf-button red-button f-size-150" *ngIf="!hideDeleteSumbmitBTN && gridData.length"
            (click)="deleteRequest()"> Delete </button>
        <button kendoButton class="rf-button green-button f-size-150" *ngIf="!hideDeleteSumbmitBTN && gridData.length"
            (click)=submitRequest()> Submit</button>
        <button kendoButton class="rf-button green-button f-size-150"
            [disabled]="requestDetails?.requestStateId=== RequestStatesConstants.Pending_Finance_Update && !this.canApproveFinanceUpdate"
            *ngIf="hideRejectApproveRecallBTN" (click)="approveRequest()"> Approve
        </button>
        <button kendoButton class="rf-button blue-button f-size-150"
            *ngIf="hideRejectApproveRecallBTN && requestDetails?.requestStateId !== RequestStatesConstants.Pending_Finance_Update"
            (click)="recallRequest()"> Recall
        </button>
    </div>


    <!-- Download All Attachments -->
    <div class="mt-10" *ngIf="requestDetails.attachmentExist">
        <button kendoButton class="secondary-border-button ml-20 f-size-150 floatRYT" (click)="downloadAll()">
            Download All Attachments
        </button>
    </div>
    <!-- update Technical Endorser field -->
    <form [formGroup]="requestIssueForm" *ngIf="hideEndorserInputField">
        <kendo-label [for]="endorser" text="Select Technical Endorser">
            <span class="mandatory"> * </span>
        </kendo-label>
        <div class="d-flex a-center w-40">
            <kendo-dropdownlist class="mt-5" #endorser [data]="endorserList" formControlName="endorser"
                [valuePrimitive]="true" valueField="id" textField="username" [defaultItem]="endorserDefaultValue">
            </kendo-dropdownlist>
            <button kendoButton class="rf-button green-button f-size-150 mt-5" (click)="updateEndorser()">
                Update
            </button>
        </div>
    </form>

    <div class="mt-20 pr-20 d-flex a-center" style="display: none;">
        <h5 style="white-space: nowrap">Sales Invoice Number</h5>
        <kendo-textbox class="ml-10 w-25"></kendo-textbox>
        <button kendoButton class="rf-button blue-button f-size-150">Submit</button>
    </div>

    <div class="mt-20" style="display: none;">
        <fieldset class="mb-20 fieldset">
            <legend class="font-size-xs">Attachments</legend>
            <div class="text-right">
                <kendo-label
                    text="Attach Documents (Sales Receipts ,Sales Invoice, Signed Sales Agreemet or .msg of STARS Approval)"></kendo-label>
            </div>
            <div class="fileuploadContainer">
                <p class="text">Drop Files or Click to Upload</p>
                <input class="fileupload" type="file" #fileInput accept="accptedFilesStr" />
            </div>
        </fieldset>
    </div>

    <!-- Request Flow -->
    <section class="request-flow" *ngIf="displayRequestFlow">
        <h6 class="font-size-base">Request flow</h6>
        <div class="rf-card-parent">
            <!-- Created -->
            <div class="mt-10 mb-20 rf-card card-completed">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">CR</div>
                    </div>
                    <div class="rf-details">
                        <p>Created</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Created]!.createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Created]!.createdDateTime
                                | date:'dd-MM-yyyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Created].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Created].status===''?'--':currentRequestFlow.flow[requestFlowStepsConst.Created].status}}
                            </p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">{{currentRequestFlow.flow[requestFlowStepsConst.Created].comment}}
                            </p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- Endorse -->
            <div class="mt-10 mb-20 rf-card"
                [class]="(currentStepIndex===requestFlowStepsConst.Endorse) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.Endorse]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.Endorse]!.status===stepsStatusConst.Recalled)?'card-rejectedOrRecalled':'card-completed')">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">EN</div>
                    </div>
                    <div class="rf-details">
                        <p>Endorse</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Endorse]!.createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Endorse]!.createdDateTime
                                | date:'dd-MM-yyyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Endorse].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Endorse].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.Endorse].status : '--'}}
                            </p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.Endorse].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.Endorse].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">{{currentRequestFlow.flow[requestFlowStepsConst.Endorse].comment}}
                            </p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- Finance Update -->
            <div class="mt-10 mb-20 rf-card"
                [class]="(currentStepIndex===requestFlowStepsConst.Finance_Update) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.Finance_Update]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.Finance_Update]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.Finance_Update]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">FI</div>
                    </div>
                    <div class="rf-details">
                        <p>Finance Update</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Finance_Update].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Finance_Update].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Finance_Update].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Finance_Update].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.Finance_Update].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <!-- <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5"></p>
                        </kendo-gridlayout-item> -->
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- Finance Review -->
            <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                [class]="(currentStepIndex===requestFlowStepsConst.Finance_Review) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.Finance_Review]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.Finance_Review]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.Finance_Review]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">FR</div>
                    </div>
                    <div class="rf-details">
                        <p>Finance Review</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Finance_Review].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Finance_Review].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Finance_Review].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Finance_Review].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.Finance_Review].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.Finance_Review].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.Finance_Review].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Finance_Review].comment}}</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- DOA Approval -->
            <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                [class]="(currentStepIndex===requestFlowStepsConst.DOA_Approval) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">DO</div>
                    </div>
                    <div class="rf-details">
                        <p>DOA Approval</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.DOA_Approval].comment}}</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- JOA Approval -->
            <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                [class]="(currentStepIndex===requestFlowStepsConst.JOA_Approval) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">JO</div>
                    </div>
                    <div class="rf-details">
                        <p>JOA Approval</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.JOA_Approval].comment}}</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- Transfer in SAP -->
            <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                [class]="(currentStepIndex===requestFlowStepsConst.Transfer_in_SAP) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">TR</div>
                    </div>
                    <div class="rf-details">
                        <p>Transfer in SAP</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].comment}}</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- Disposed -->
            <div class="mt-10 mb-20 rf-card"
                [class]="(currentStepIndex===requestFlowStepsConst.Disposed) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.Disposed]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.Disposed]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.Disposed]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">DI</div>
                    </div>
                    <div class="rf-details">
                        <p>Disposed</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Disposed].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Disposed].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Disposed].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">{{currentRequestFlow.flow[requestFlowStepsConst.Disposed].status
                                !== '' ?currentRequestFlow.flow[requestFlowStepsConst.Disposed].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.Disposed].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.Disposed].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Disposed].comment}}</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- Bill of Sale -->
            <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                [class]="(currentStepIndex===requestFlowStepsConst.Bill_of_Sale) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">BI</div>
                    </div>
                    <div class="rf-details">
                        <p>Bill of Sale</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Bill_of_Sale].comment}}</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- Removal Assignment -->
            <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                [class]="(currentStepIndex===requestFlowStepsConst.Removal_Assignment) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">RA</div>
                    </div>
                    <div class="rf-details">
                        <p>Removal Assignment</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Removal_Assignment].comment}}</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- Removed from SAP (GOMBU) / Completed (ABU) -->
            <div class="mt-10 mb-20 rf-card"
                [class]="(currentStepIndex===requestFlowStepsConst.Removed_from_SAP) ? 'card-inprogress':
            ((currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP]!.status===stepsStatusConst.Rejected || currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP]!.isApproved?'card-completed':'card-pending'))">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile" *ngIf="isGOMBU">RS</div>
                        <div class="rf-profile" *ngIf="!isGOMBU">CM</div>
                    </div>
                    <div class="rf-details">
                        <p *ngIf="isGOMBU">Removed from SAP</p>
                        <p *ngIf="!isGOMBU">Completed</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            {{currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP].createdBy}}
                            <Small
                                style="font-weight: 400; margin-left: 5px">{{currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP].createdDateTime
                                | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP].assignedUser}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP].status !== ''
                                ?currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP].status : '--'}}</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                            *ngIf="currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP].comment !== '' && currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP].comment!==null">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">
                                {{currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP].comment}}</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- End -->
            <div class="mt-10 mb-20 rf-card"
                *ngIf='!currentRequestFlow.flow[requestFlowStepsConst.Removed_from_SAP]!.isApproved' [class]="(currentStepIndex===requestFlowStepsConst.Removed_from_SAP) ? 'card-inprogress':
            'card-pending'">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">$</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Request Flow - History -->
    <div class="recalledRejectedRFTitle mt-20" *ngIf="oldRequestFlow.length > 0 && displayRequestFlow">Rejected/Recalled
        request flows
    </div>
    <section class="pannel-expand" *ngIf="oldRequestFlow.length > 0 && displayRequestFlow">
        <kendo-panelbar>
            <kendo-panelbar-item *ngFor="let requestFlow of oldRequestFlow; let itemIndex=index">
                <ng-template kendoPanelBarItemTitle>
                    <div class="requestFlowTitle">Request flow {{requestFlow.requestFlowNumber}}</div>
                </ng-template>
                <ng-template kendoPanelBarContent>
                    <div class="pl-35">
                        <section class="ml-20">
                            <div class="rf-card-parent">
                                <!-- Created -->
                                <div class="mt-10 mb-20 rf-card card-completed">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">CR</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>Created</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Created]!.createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Created]!.createdDateTime
                                                    | date:'dd-MM-yyyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Created].assignedUser}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Created].status===''?'--':requestFlow.flow[requestFlowStepsConst.Created].status}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Created].comment}}</p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- Endorse -->
                                <div class="mt-10 mb-20 rf-card"
                                    [class]="(requestFlow.flow[requestFlowStepsConst.Endorse]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.Endorse]!.status===stepsStatusConst.Recalled)?'card-rejectedOrRecalled':'card-completed'">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">EN</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>Endorse</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Endorse]!.createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Endorse]!.createdDateTime
                                                    | date:'dd-MM-yyyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Endorse].assignedUser}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Endorse].status !== ''
                                                    ?requestFlow.flow[requestFlowStepsConst.Endorse].status : '--'}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.Endorse].comment !== '' && requestFlow.flow[requestFlowStepsConst.Endorse].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Endorse].comment}}
                                                </p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- Finance Update -->
                                <div class="mt-10 mb-20 rf-card"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.Finance_Update]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.Finance_Update]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.Finance_Update]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">FI</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>Finance Update</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Finance_Update].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Finance_Update].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Finance_Update].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Finance_Update].status !==
                                                    ''
                                                    ?requestFlow.flow[requestFlowStepsConst.Finance_Update].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <!-- <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5"></p>
                                            </kendo-gridlayout-item> -->
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- Finance Review -->
                                <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.Finance_Review]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.Finance_Review]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.Finance_Review]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">FR</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>Finance Review</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Finance_Review].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Finance_Review].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Finance_Review].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Finance_Review].status !==
                                                    ''
                                                    ?requestFlow.flow[requestFlowStepsConst.Finance_Review].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.Finance_Review].comment !== '' && requestFlow.flow[requestFlowStepsConst.Finance_Review].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Finance_Review].comment}}
                                                </p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- DOA Approval -->
                                <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.DOA_Approval]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.DOA_Approval]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.DOA_Approval]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">DO</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>DOA Approval</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.DOA_Approval].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.DOA_Approval].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.DOA_Approval].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.DOA_Approval].status !== ''
                                                    ?requestFlow.flow[requestFlowStepsConst.DOA_Approval].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.DOA_Approval].comment !== '' && requestFlow.flow[requestFlowStepsConst.DOA_Approval].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.DOA_Approval].comment}}</p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- JOA Approval -->
                                <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.JOA_Approval]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.JOA_Approval]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.JOA_Approval]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">JO</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>JOA Approval</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.JOA_Approval].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.JOA_Approval].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.JOA_Approval].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.JOA_Approval].status !== ''
                                                    ?requestFlow.flow[requestFlowStepsConst.JOA_Approval].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.JOA_Approval].comment !== '' && requestFlow.flow[requestFlowStepsConst.JOA_Approval].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.JOA_Approval].comment}}</p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- Transfer in SAP -->
                                <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">TR</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>Transfer in SAP</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].status !==
                                                    ''
                                                    ?requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].comment !== '' && requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Transfer_in_SAP].comment}}
                                                </p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- Disposed -->
                                <div class="mt-10 mb-20 rf-card"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.Disposed]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.Disposed]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.Disposed]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">DI</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>Disposed</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Disposed].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Disposed].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Disposed].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Disposed].status
                                                    !== '' ?requestFlow.flow[requestFlowStepsConst.Disposed].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.Disposed].comment !== '' && requestFlow.flow[requestFlowStepsConst.Disposed].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Disposed].comment}}</p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- Bill of Sale -->
                                <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.Bill_of_Sale]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.Bill_of_Sale]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.Bill_of_Sale]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">BI</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>Bill of Sale</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Bill_of_Sale].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Bill_of_Sale].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Bill_of_Sale].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Bill_of_Sale].status !== ''
                                                    ?requestFlow.flow[requestFlowStepsConst.Bill_of_Sale].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.Bill_of_Sale].comment !== '' && requestFlow.flow[requestFlowStepsConst.Bill_of_Sale].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Bill_of_Sale].comment}}</p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- Removal Assignment -->
                                <div class="mt-10 mb-20 rf-card" *ngIf="isGOMBU"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.Removal_Assignment]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.Removal_Assignment]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.Removal_Assignment]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">RA</div>
                                        </div>
                                        <div class="rf-details">
                                            <p>Removal Assignment</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Removal_Assignment].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Removal_Assignment].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Removal_Assignment].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Removal_Assignment].status
                                                    !== ''
                                                    ?requestFlow.flow[requestFlowStepsConst.Removal_Assignment].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.Removal_Assignment].comment !== '' && requestFlow.flow[requestFlowStepsConst.Removal_Assignment].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Removal_Assignment].comment}}
                                                </p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- Removed from SAP (GOMBU) / Completed (ABU) -->
                                <div class="mt-10 mb-20 rf-card"
                                    [class]="((requestFlow.flow[requestFlowStepsConst.Removed_from_SAP]!.status===stepsStatusConst.Rejected || requestFlow.flow[requestFlowStepsConst.Removed_from_SAP]!.status=== stepsStatusConst.Recalled)?'card-rejectedOrRecalled':(requestFlow.flow[requestFlowStepsConst.Removed_from_SAP]!.isApproved?'card-completed':'card-pending'))">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile" *ngIf="isGOMBU">RS</div>
                                            <div class="rf-profile" *ngIf="!isGOMBU">CM</div>
                                        </div>
                                        <div class="rf-details">
                                            <p *ngIf="isGOMBU">Removed from SAP</p>
                                            <p *ngIf="!isGOMBU">Completed</p>
                                            <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg"
                                                alt="" />
                                            <p>
                                                {{requestFlow.flow[requestFlowStepsConst.Removed_from_SAP].createdBy}}
                                                <Small
                                                    style="font-weight: 400; margin-left: 5px">{{requestFlow.flow[requestFlowStepsConst.Removed_from_SAP].createdDateTime
                                                    | date:'dd-mm-yyy hh:mm:ss a'}}</Small>
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']"
                                            [cols]="['auto', 'auto']" [gap]="5">
                                            <kendo-gridlayout-item [col]="1" [row]="1">
                                                <p class="deatil-data pl-5">Assigned To</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Removed_from_SAP].assignedUser}}
                                                </p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="2" [row]="1">
                                                <p class="deatil-data pl-5">Status</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Removed_from_SAP].status
                                                    !== ''
                                                    ?requestFlow.flow[requestFlowStepsConst.Removed_from_SAP].status :
                                                    '--'}}</p>
                                            </kendo-gridlayout-item>
                                            <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2"
                                                *ngIf="requestFlow.flow[requestFlowStepsConst.Removed_from_SAP].comment !== '' && requestFlow.flow[requestFlowStepsConst.Removed_from_SAP].comment!==null">
                                                <p class="deatil-data pl-5">Comment added</p>
                                                <p class="head-data pl-5">
                                                    {{requestFlow.flow[requestFlowStepsConst.Removed_from_SAP].comment}}
                                                </p>
                                            </kendo-gridlayout-item>
                                        </kendo-gridlayout>
                                    </div>
                                </div>
                                <!-- End -->
                                <div class="mt-10 mb-20 rf-card card-pending">
                                    <div class="d-flex a-center mt-5 mb-5">
                                        <div class="rf-profile-bg">
                                            <div class="rf-profile">$</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </ng-template>
            </kendo-panelbar-item>
        </kendo-panelbar>
    </section>
    <br />
    <br />
    <br />
    <br />

    <section class="request-flow" style="display: none;">
        <h6 class="font-size-base">Request flow</h6>
        <div class="rf-card-parent">
            <!-- card-completed-->
            <div class="mt-10 mb-20 rf-card card-completed">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">CR</div>
                    </div>
                    <div class="rf-details">
                        <p>Created</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            Pradeep Erumalla
                            <Small style="font-weight: 400; margin-left: 5px">14-09-2023 06:50:25</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">Pradeep Erumalla</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">Karratha Distribution</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">Drilling and Completions</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- card-inprogress-->
            <div class="mt-10 mb-20 rf-card card-inprogress">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">CR</div>
                    </div>
                    <div class="rf-details">
                        <p>Created</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            Pradeep Erumalla
                            <Small style="font-weight: 400; margin-left: 5px">14-09-2023 06:50:25</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">Pradeep Erumalla</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">Karratha Distribution</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">Drilling and Completions</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- card-pending-->
            <div class="mt-10 mb-20 rf-card card-pending">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">CR</div>
                    </div>
                    <div class="rf-details">
                        <p>Created</p>
                        <img src="../../../../../assets/icons/Completed_line_with_arrow_.svg" alt="" />
                        <p>
                            Pradeep Erumalla
                            <Small style="font-weight: 400; margin-left: 5px">14-09-2023 06:50:25</Small>
                        </p>
                    </div>
                </div>
                <div>
                    <kendo-gridlayout class="overview-layout" [rows]="['auto', 'auto']" [cols]="['auto', 'auto']"
                        [gap]="5">
                        <kendo-gridlayout-item [col]="1" [row]="1">
                            <p class="deatil-data pl-5">Assigned To</p>
                            <p class="head-data pl-5">Pradeep Erumalla</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="2" [row]="1">
                            <p class="deatil-data pl-5">Status</p>
                            <p class="head-data pl-5">Karratha Distribution</p>
                        </kendo-gridlayout-item>
                        <kendo-gridlayout-item [col]="1" [row]="2" [colSpan]="2">
                            <p class="deatil-data pl-5">Comment added</p>
                            <p class="head-data pl-5">Drilling and Completions</p>
                        </kendo-gridlayout-item>
                    </kendo-gridlayout>
                </div>
            </div>
            <!-- End -->
            <div class="mt-10 mb-20 rf-card card-pending">
                <div class="d-flex a-center mt-5 mb-5">
                    <div class="rf-profile-bg">
                        <div class="rf-profile">$</div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <kendo-dialog class="dialog-box" *ngIf="opened" [minWidth]="250" [width]="800">
        <div class="float-right">
            <i class="fa fa-times close-icon" (click)="close('cancel')" aria-hidden="true"></i>
        </div>
        <div class="font-size-s font-boder mb-10 " style="color: #1975a3;"> Request Header</div>
        <div class="ml-20 mt-20 mb-20">
            <div class="mb-10">
                <p class="head-data pl-5">Title</p>
                <p class="deatil-data pl-5">{{ requestDetails?.title }}</p>
            </div>
            <div class="mb-10">
                <p class="head-data pl-5">Asset</p>
                <p class="deatil-data pl-5">{{ requestDetails?.assetName }}</p>
            </div>
            <div class="mb-10">
                <p class="head-data pl-5">Asset Location</p>
                <p class="deatil-data pl-5">{{ requestDetails?.locationName }}</p>
            </div>
            <div class="mb-10">
                <p class="head-data pl-5">AFE Cost Code</p>
                <p class="deatil-data pl-5">{{ requestDetails?.afecostCode }}</p>
            </div>
            <div class="mb-10">
                <p class="head-data pl-5">Department</p>
                <p class="deatil-data pl-5">{{ requestDetails?.departmentName }}</p>
            </div>
            <div class="mb-10">
                <p class="head-data pl-5">Disposal Method</p>
                <p class="deatil-data pl-5"> {{requestDetails?.disposalMethodName}} </p>
            </div>
            <div class="mb-10">
                <p class="head-data pl-5">Description</p>
                <p class="deatil-data pl-5">{{ requestDetails?.description }}</p>
            </div>
            <div class="mb-10">
                <p class="head-data pl-5">Justification</p>
                <p class="deatil-data pl-5">{{ requestDetails?.justification }}</p>
            </div>
        </div>
    </kendo-dialog>

    <kendo-dialog class="dialog-box" *ngIf="isAddWBSCodeWindowOpen" [minWidth]="250" [width]="800">
        <div class="float-right">
            <i class="fa fa-times close-icon" (click)="exitUpdateWBSCodeDialog()" aria-hidden="true"></i>
        </div>
        <div class="font-size-s font-boder mb-10 " style="color: #1975a3;"> Updating WBS Code for
            {{requestDetails?.trackingNumber}}</div>
        <div class="ml-20 mt-20 mb-20">
            <div class="mb-10">
                <h6 class="font-size-xs">Comment<span style="color: red;"> *</span></h6>
                <kendo-textarea class="c-textarea" [rows]="3" [(ngModel)]="comment"></kendo-textarea>
            </div>
            <div class="mb-10 d-flex">
                <h6 class="font-size-xs w-15 mt-10">WBS Code</h6>
                <kendo-textbox class="w-25" [(ngModel)]="wbscode"></kendo-textbox>
            </div>
            <fieldset class="mb-20 fieldset">
                <legend class="font-size-xs">Attachments</legend>
                <div class="fileuploadContainer">
                    <p class="text">Drop Files or Click to Upload</p>
                    <input class="fileupload" type="file" #fileInput (change)="onFilesUpload($event)"
                        accept="accptedFilesStr" formControlName="attachment" />
                </div>
                <!--  file details grid -->
                <kendo-grid *ngIf="attachmentFileDetails && attachmentFileDetails.length" #myGrid
                    [data]="attachmentFileDetails" [hideHeader]="true" class="list-container" scrollable="none"
                    [style.width.px]="510">
                    <kendo-grid-column>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <button kendoButton (click)="downloadFile(rowIndex)" look="flat" [icon]="'download'"
                                class="download-button"></button>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [width]="400">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span>{{ dataItem.fileName }}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <button (click)="removeFile(rowIndex)" class="actionBTN">
                                <span class="material-icons md-18">delete_forever</span>
                            </button>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </fieldset>
            <div class="ml-250 mt-20 mb-20">
                <button kendoButton class="border-button f-size-150" (click)="exitUpdateWBSCodeDialog()"> Cancel
                </button>
                <button kendoButton class="ml-20 f-size-150" [ngClass]="true ? 'primary-button' : 'disabled-button'"
                    (click)="submitWBSCode()">
                    Submit
                </button>
            </div>
        </div>
    </kendo-dialog>
</section>