import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-config-role',
  templateUrl: './config-role.component.html',
  styleUrls: ['./config-role.component.scss'],
})
export class ConfigRoleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
