<div class="iris-department-container j-c-s m-container">
  <h1 class="mb-20 setup-heading">IRIS Departments</h1>
  <div class="mr-20 float-right">
    <button kendoButton class="border-button ml-20 f-size-150" (click)="departmentActions('export', grid)">
      Export
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="departmentActions('import', [])">
      Import
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="departmentActions('addNewDepartment', [])">
      Add new Department
    </button>
  </div>
  <section class="manage-contentbox">
    <!-- Filter actions -->
    <section class="mt-20 d-flex">
      <div class="mt-20 pr-20 d-flex w-55">
        <kendo-textbox [(ngModel)]="searchedText" (click)="textSearched($event)"></kendo-textbox>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="searchResetGridActions('search')" >
          Search
        </button>
        <button
          kendoButton class="border-button ml-20 f-size-150" (click)="searchResetGridActions('reset')">
          Reset
        </button>
      </div>
    </section>

    <!-- toggle active/inactive departments-->
    <section class="toggle-active-inactive">
      <div class="d-flex j-c-s w-45 mt-10 a-center">
        <p class="mt-10">Show Inactive Departments</p>
        <kendo-switch class="mt-10 mr-40" [(ngModel)]="isInactiveChecked" 
          (click)="showHideActiveNInactiveDepartments()">
        </kendo-switch>
      </div>
    </section>

    <!-- Table data -->
    <section class="mt-10">
      <kendo-grid
        [kendoGridBinding]="gridData"
        [sortable]="true"
        [resizable]="true"
        #grid="kendoGrid"
      >
        <kendo-grid-excel [fetchData]="commonService.exportGridData(this.gridData, '', 'asc')"
          [fileName]="fileName">
          <kendo-excelexport-column field="label" title="Label">
          </kendo-excelexport-column>
          <kendo-excelexport-column field="isActive" title="Is Active">
          </kendo-excelexport-column>
        </kendo-grid-excel>

        <kendo-grid-column field="label" title="Label" [width]="60">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="Javascript:void(0);" (click)="departmentDetails(dataItem)">
              {{ dataItem.label }}
            </a>
          </ng-template>
        </kendo-grid-column>
        
        <kendo-grid-column
          field="isActive"
          title="Is Active"
          [width]="30"
          [style]="{ 'padding-left': '30px' }"
        >
        </kendo-grid-column>
        <kendo-grid-column
          field="order"
          title="Order"
          [width]="10"
          class="a-center"
          [style]="{ 'padding-left': '30px' }"
        >
        </kendo-grid-column>
      </kendo-grid>

      <!-- total data count -->
      <p class="grid-length mt-20">{{ gridData.length }} records</p>
    </section>
  </section>
</div>
