import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { IRISDOALevelsService } from '../../../services/iris-doa-levels/irisdoalevels.service';
import { IRISDOALevelDto } from '../../../models/iris-doa-Level.model';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { IRISDOALevelsConst } from 'src/app/core/constants/MMS_BackOffice_Constants/iris-doa-levels/iris-doa-levels.constants';
import { BusinessUnitService } from '../../../services/business-unit-service/business-unit.service';
import { BusinessUnitDto } from '../../../models/bussinessunits.model';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-iris-doa-level-detail',
  templateUrl: './iris-doa-level-detail.component.html',
  styleUrls: ['./iris-doa-level-detail.component.scss']
})
export class IRISDOALevelDetailComponent {
  isNew: boolean = true;
  id: number = 0;
  label: string = '';
  doaLevel: number = 0;
  isActive: boolean = true;
  heading: string = 'New DOA Level';
  userMailID: string = JSON.parse(sessionStorage.getItem('userEmailID')!);
  doaLevelItem: IRISDOALevelDto = new IRISDOALevelDto();
  searchedText: string = '';
  requiredLabel: boolean = false;
  doaLevel_Lebel_AmmountListByBU: any[] = [];
  defaulCurrencytItem = { key: '--- Select Currency ---' };
  currency: string = this.defaulCurrencytItem.key;
  currencyList: any = IRISDOALevelsConst.currencyList;
  requiredCurrency: boolean = false;
  defaulBUtItem = { name: '--- Select Business Unit ---', id: 0 };
  BUId: number = 0;
  buName: string = '';
  buList: BusinessUnitDto[] = [];
  requiredBU: boolean = false;
  constructor(private commonService: CommonService, private router: Router, private route: ActivatedRoute,
    private irisDOALevelsService: IRISDOALevelsService, private buService: BusinessUnitService, private titleService: Title) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.isNew = stateData['isNew'];
      this.id = stateData['id'];
      if (!this.isNew) {
        this.label = stateData['label'];
        this.doaLevel = stateData['doaLevel'];
        this.currency = stateData['currency'];
        this.buName = stateData['buName'];
        this.isActive = stateData['isActive'];
      }
      this.searchedText = stateData['searchedText'];
      sessionStorage.setItem('DOALevelDetail', JSON.stringify(stateData));
    }
    else {
      let sessionData: any = JSON.parse(sessionStorage.getItem('DOALevelDetail')!);
      if (!this.commonService.isEmptyOrUndefined(sessionData)) {
        this.isNew = sessionData['isNew'];
        this.id = sessionData['id'];
        if (!this.isNew) {
          this.label = sessionData['label'];
          this.doaLevel = sessionData['doaLevel'];
          this.currency = sessionData['currency'];
          this.buName = sessionData['buName'];
          this.isActive = sessionData['isActive'];
        }
        this.searchedText = sessionData['searchedText'];
      }
    }
  }
  ngOnInit(): void {
    if (!this.isNew) {
      this.titleService.setTitle(this.label);
    }
    this.getDOALevelList();
    this.getBUList();
    if (!this.isNew) {
      this.heading = this.label;
      this.getCurrentDOALevel();
    }
  }
  getBUList() {
    this.buService.getAllBUNames().subscribe((data: BusinessUnitDto[]) => {
      this.buList = data;
    })
  }
  getCurrentDOALevel() {
    this.irisDOALevelsService.getIRISDOALevelbyId(this.id).subscribe((currentDOALevel) => {
      this.doaLevelItem = { ...currentDOALevel };
    })
  }
  getDOALevelList() {
    this.irisDOALevelsService.getIRISDOALevelList().subscribe((doaLevels: IRISDOALevelDto[]) => {
      if (doaLevels.length > 0) {
        this.doaLevel_Lebel_AmmountListByBU = doaLevels.map((level: IRISDOALevelDto) => { return { label: level.label, doaLevel: level.doaLevel, businessUnitId: level.businessUnitId } });
      }
    });
  }
  onAmmountChange() {
    if (this.doaLevel < 0) {
      this.doaLevel = 0;
    }
  }

  action(actionName: string) {
    if (actionName === 'cancel') {
      this.navBack();
    }
    else if (actionName === 'save') {
      if (this.isValidForm() === false) {
        return;
      }
      this.doaLevel_Lebel_AmmountListByBU = this.doaLevel_Lebel_AmmountListByBU.filter((level: IRISDOALevelDto) => (level.businessUnitId === this.BUId));
      let labels: any[] = this.doaLevel_Lebel_AmmountListByBU.filter((item: any) => (item.label.toLowerCase() === this.label.toLowerCase()));
      let ammounts: any[] = this.doaLevel_Lebel_AmmountListByBU.filter((item: any) => (item.doaLevel === this.doaLevel));
      if (this.isNew) {
        if (labels.length > 0) {
          this.commonService.showNotificationMessage(IRISDOALevelsConst.DOALevelLabelExistsMsg, 'error', 'center');
        }
        else if (ammounts.length > 0) {
          this.commonService.showNotificationMessage(IRISDOALevelsConst.DOALevelAmmountExistsMsg, 'error', 'center');
        }
        else {
          this.saveNewDOALevel();
        }
      }
      else {
        if (labels.length > 0 && this.label !== this.doaLevelItem.label) {
          this.commonService.showNotificationMessage(IRISDOALevelsConst.DOALevelLabelExistsMsg, 'error', 'center');
        }
        else if (ammounts.length > 0 && this.doaLevel !== this.doaLevelItem.doaLevel) {
          this.commonService.showNotificationMessage(IRISDOALevelsConst.DOALevelAmmountExistsMsg, 'error', 'center');
        }
        else {
          this.saveExistingDOALevel();
        }
      }
    }
  }
  isValidForm(): boolean {
    this.requiredLabel = false;
    this.requiredCurrency = false;
    this.requiredBU = false;
    let isValidForm: boolean = true;
    if (this.label === '') {
      this.requiredLabel = true;
      isValidForm = false;
    }
    if (this.currency === this.defaulCurrencytItem.key) {
      this.requiredCurrency = true;
      isValidForm = false;
    }
    if (this.isNew && this.BUId === this.defaulBUtItem.id) {
      this.requiredBU = true;
      isValidForm = false;
    }
    return isValidForm;
  }
  saveNewDOALevel() {
    this.doaLevelItem = this.setDefaultDOALevel();
    this.doaLevelItem.label = this.label;
    this.doaLevelItem.doaLevel = this.doaLevel;
    this.doaLevelItem.currancy = this.currency;
    this.doaLevelItem.businessUnitId = this.BUId;
    //this.doaLevelItem.businessUnitName = this.buList.filter((bu: BusinessUnitDto) => (bu.id === this.BUId))[0].name;
    this.doaLevelItem.isActive = this.isActive;
    this.irisDOALevelsService.addNewIRISDOALevel(this.doaLevelItem).subscribe((result: IRISDOALevelDto) => {
      this.commonService.showNotificationMessage(IRISDOALevelsConst.AddedDOALevelMsg, 'success', 'center');
      this.navBack();
    });

  }
  saveExistingDOALevel() {
    this.doaLevelItem.label = this.label;
    this.doaLevelItem.doaLevel = this.doaLevel;
    this.doaLevelItem.currancy = this.currency;
    this.doaLevelItem.isActive = this.isActive;
    this.doaLevelItem.modifiedBy = this.userMailID;
    this.doaLevelItem.modifiedDateTime = new Date;
    this.irisDOALevelsService.updateIRISDOALevel(this.doaLevelItem).subscribe((result: IRISDOALevelDto) => {
      this.commonService.showNotificationMessage(IRISDOALevelsConst.UpdatedDOALevelMsg, 'success', 'center');
      this.navBack();
    });
  }
  setDefaultDOALevel(): IRISDOALevelDto {
    let doaLevel: IRISDOALevelDto = new IRISDOALevelDto();
    doaLevel.id = 0;
    doaLevel.createdBy = this.userMailID;
    doaLevel.createdDateTime = new Date;
    return doaLevel;
  }
  navBack() {
    this.router.navigate([`../${BackOfficeRoutingConstants.IRISDOALevelsPath}`], { state: { searchedText: this.searchedText }, relativeTo: this.route });
  }
}
