export const IRISDOALevelsConst = {
    ApiPath: 'api/IRISDOALevel',
    Import: '/Import',
    getDoaApprovalMatrix: '/GetDoaApprovalMatrix',
    AddedDOALevelMsg: 'DOA Level added successfully',
    UpdatedDOALevelMsg: 'DOA Level updated successfully',
    DOALevelLabelExistsMsg: 'DOA Level Label already exists for this BU',
    DOALevelAmmountExistsMsg: 'DOA Level Ammount already exists for this BU',
    currencyList: [
        { key: 'AUD' },
        { key: 'USD' }
    ]
}