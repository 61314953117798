<div>
  <app-header *ngIf="isAuthenticated && is_User" (loadContent)="loadApplication($event)" (isMMSUser)="isMMSUSer($event)" (isAdmin)="isAdmin($event)"> </app-header>

  <!-- Body -->
  <section *ngIf="isAuthenticated  && is_User" class="m-100">
    <!-- left nav -->
    <nav class="sideNav">
      <div>
      <div class="sItem"
        [ngStyle]="{ 'background-color': activeTab === 'MMS' ? '#D2E6FA' : '' }"
      >
        <!-- <a (click)="activeTab = 'MMS'" routerLink="{{ RoutingConstant.MMS }}"> -->
        <a (click)="navigateToMMSPortal()">
          <div class="sideIcon">
            <img src="./../assets/icons/MMS.svg" alt="MMS" alt="MMS Dashboard"/>
          </div>
          <p>MMS</p>
        </a>
      </div>

      <div class="sItem"
        [ngStyle]="{'background-color': activeTab === 'MMS_IRIS' ? '#D2E6FA' : ''}"
      >
        <a (click)="activeTab = 'MMS_IRIS'" routerLink="{{ RoutingConstant.IRIS }}">
          <div class="sideIcon">
            <img src="./../assets/icons/IRIS_icon.svg" alt="IRIS" alt="IRIS Dashboard"/>
          </div>
          <p>IRIS</p>
        </a>
      </div>

      <div class="sItem"
        [ngStyle]="{
          'background-color': activeTab === 'AllIRIS' ? '#D2E6FA' : '',
          display: activeTab === 'MMS' ? 'none' : ''
        }"
      >
        <a (click)="activeTab = 'AllIRIS'" routerLink="{{ RoutingConstant.All_IRIS }}">
          <div class="sideIcon">
            <img src="./../assets/icons/AllNCRs.svg" alt="All IRIS" alt="ALL IRIS Dashboard"/>
          </div>
          <p>All IRIS</p>
        </a>
      </div>
    </div>
      <div class="sidenav-bottom">
        <!-- <div *ngIf="isAdmin()"   -->
        <div class="sItem c-mb-30"
          [ngStyle]="{
            'background-color': activeTab === 'MMS_BackOffice' ? '#D2E6FA' : '',
            display: activeTab === 'MMS' ? 'none' : ''
          }"
        >
          <a (click)="activeTab = 'MMS_BackOffice'" routerLink="{{ RoutingConstant.Setup }}">
            <div class="sideIcon">
              <img src="./../assets/icons/setup.svg" width="36px" alt="MMS BackOffice"/>
            </div>
            <p class="GothamBook">Setup</p>
          </a>
        </div>

        <div class="sItem"
          [ngStyle]="{
            'background-color': activeTab === 'userguide' ? '#D2E6FA' : '',
            display: activeTab === 'MMS' ? 'none' : ''
          }"
        >
          <a (click)="activeTab = 'userguide'" routerLink="{{ RoutingConstant.User_Guide }}">
            <div class="sideIcon">
              <img src="./../assets/icons/AllNCRs.svg" alt="User Guide" alt="User Guide"/>
            </div>
            <p>User Guide</p>
          </a>
        </div>
      </div>
    </nav>

    <!-- Right content -->
    <div class="main-content-box" *ngIf="isAuthenticated">
      <router-outlet></router-outlet>
    </div>
  </section>

  <app-invalid-permissions *ngIf="!is_User || !isAuthenticated"></app-invalid-permissions>
</div>
