import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserDto } from '../model/mmsUser/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  mmsAPIUrl: string = environment.MMS.apiUrl;
  apiPathGetByCai: string = 'api/User/GetByCai';
  apiPath: string = 'api/User';

  constructor(private http: HttpClient) {

  }
  getUserByCAI(cai: string) {
    return this.http.get<UserDto>(this.mmsAPIUrl + this.apiPathGetByCai + '/' + cai);
  }

  getUsers() {
    return this.http.get<UserDto[]>(this.mmsAPIUrl + this.apiPath);
  }
}
