import { Component, OnInit } from '@angular/core';
import { MMSGroupDto } from '../../models/mms-group.model';
import { MmsGroupService } from '../../services/mms-group/mms-group.service';
import { CommonService } from 'src/app/core/services/common.service';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {
  searchedText: string = '';
  checked: boolean = false;
  mmsGroupList: MMSGroupDto[] = [];
  gridData: MMSGroupDto[] = [];
  fileName: string = '';
  userMailID: string = JSON.parse(sessionStorage.getItem('userEmailID')!);
  importedGroupList: MMSGroupDto[] = []
  constructor(private mmsGroupService: MmsGroupService, public commonService: CommonService, private dialogService: DialogService,
    private router: Router, private route: ActivatedRoute) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.searchedText = stateData['searchedText'];
    }
  }

  ngOnInit(): void {
    this.getMMSGroupList();
  }

  getMMSGroupList() {
    this.mmsGroupService.getMMSGroupList().subscribe((groups: MMSGroupDto[]) => {
      this.mmsGroupList = groups;
      this.getGridData(this.checked);
    });
  }

  getGridData(checked: boolean) {
    this.gridData = this.commonService.setupGobalSearch(this.mmsGroupList, checked, 'label', this.searchedText)
  }

  exportExcel(grid: GridComponent): void {
    let currentDate = new Date;
    let _date: string = this.commonService.formatDateTo_yyyymmdd(currentDate);
    let _time: string = this.commonService.formatDateTo_HHmmss(currentDate);
    this.fileName = `Groups-${_date}-${_time}.xlsx`;
    grid.saveAsExcel();
  }
  importExcel() {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === 'Save') {
        this.importedGroupList = [];
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let errorMessage: string = '';
        let validData: boolean = true;
        let rowNum: number = 1;

        for (let row of arr) {
          let group: MMSGroupDto = this.setDefaultGroup();
          rowNum++;
          if (this.commonService.isEmptyOrUndefined(row['Group'])) {
            errorMessage = `Excel read error in row ${rowNum}: Group label required.`;
            validData = false;
            break;
          }
          else if (this.isduplicateGroups(row['Group'].toString())) {
            errorMessage = `Excel read error in row ${rowNum}: Group already exists.`;
            validData = false;
            break;
          }
          else if (this.isduplicateGroupsInExcel(row['Group'].toString())) {
            errorMessage = `Excel read error in row ${rowNum}: Group already exists in Excel File.`;
            validData = false;
            break;
          }
          else {
            group.label = row['Group'].toString();
          }
          if (this.commonService.isEmptyOrUndefined(row['Active'])) {
            group.isActive = false;
          }
          else if (typeof row['Active'] !== 'boolean') {
            errorMessage = `Excel read error in row ${rowNum}: Column 'Active' has an invalid type. Expected a 'Boolean' but received a '${typeof row['Active']}'.`;
            validData = false;
            break;
          }
          else {
            group.isActive = row['Active'];
          }
          if (this.commonService.isEmptyOrUndefined(row['Display Order'])) {
            group.order = 0;
          }
          else if (typeof row['Display Order'] !== 'number') {
            errorMessage = `Excel read error in row ${rowNum}: Column 'Display Order' has an invalid type. Expected a 'Int' but received a '${typeof row['Display Order']}'.`;
            validData = false;
            break;
          }
          else {
            group.order = row['Display Order'];
          }
          this.importedGroupList.push(group);
        }
        if (!validData) {
          this.commonService.showNotificationMessage(errorMessage, 'error', 'center');
        }
        else {
          this.mmsGroupService
            .importExcelData(this.importedGroupList)
            .subscribe((response: any[]) => {
              if (response) {
                this.getMMSGroupList()
                const msg = `New Group(s) added successfully`;
                this.commonService.showNotificationMessage(msg, 'success', 'center');
              }
            });
        }
      }
    });
  }

  setDefaultGroup(): MMSGroupDto {
    let group: MMSGroupDto = new MMSGroupDto();
    group.id = 0;
    group.isIRIS = true;
    group.businessUnitId = 1;
    group.mmsSubGroups = [];
    group.assetList = [];
    group.mmsGroupSharedEmails = [];
    group.createdBy = this.userMailID;
    group.modifiedBy = this.userMailID;
    group.createdDateTime = new Date
    return group;
  }

  isduplicateGroups(groupLabel: string) {
    let group: MMSGroupDto[] = this.mmsGroupList.filter((x: MMSGroupDto) => (x.label?.toLowerCase() === groupLabel.toLowerCase()));
    if (group.length > 0) {
      return true;
    }
    return false;
  }

  isduplicateGroupsInExcel(groupLabel: string) {
    let excelGroup: MMSGroupDto[] = this.importedGroupList.filter((x: MMSGroupDto) => (x.label?.toLowerCase() === groupLabel.toLowerCase()));
    if (excelGroup.length > 0) {
      return true;
    }
    return false;
  }

  addEdiGroup(isNew: boolean, id: number, label: string, order: number, isActive: boolean) {
    this.router.navigate([`../${BackOfficeRoutingConstants.MMSGroupDetails}`], { state: { isNew: isNew, id: id, label: label, order: order, isActive: isActive, searchedText: this.searchedText }, relativeTo: this.route })
  }
  resetGrid() {
    this.searchedText = '';
    this.getGridData(this.checked);
  }
}
