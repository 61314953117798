import { BaseDto } from "./base.model";

export class IRISDOALevelDto extends BaseDto {
    id!: number;
    label!: string;
    doaLevel!: number;
    currancy!: string;
    businessUnitId?: number;
    businessUnitName?: string;
    doaLevelRangeLeft?: string;
    doaLevelRangeRight?: string;
}