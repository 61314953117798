<div class="iris-doa-approval-container j-c-s m-container">
    <h1 class="setup-heading">IRIS DOA Approval Matrix</h1>
    <div class="mr-20 float-right">
        <button kendoButton class=" ml-20 f-size-150" 
        [ngClass]="!isAddNewBTNDisabled ? 'border-button' : 'disabled-button'"
        [disabled]="isAddNewBTNDisabled" (click)="addNewLevel()">
            Add New Level
        </button>
    </div>

    <section class="manage-contentbox">
        <!-- Filter actions -->
        <section class="mt-20 d-flex">
            <div class="mt-20 pr-20 d-flex w-55">
                <kendo-textbox [(ngModel)]="searchedText" (click)="getGridData(checked)"></kendo-textbox>
                <button kendoButton class="primary-button ml-20 f-size-150" (click)="searchResetGridActions('search')">
                    Search
                </button>
                <button kendoButton class="border-button ml-20 f-size-150" (click)="searchResetGridActions('reset')">
                    Reset
                </button>
            </div>
        </section>
    <!-- toggle active/inactive assets-->
    <section class="toggle-active-inactive">
        <div class="d-flex j-c-s w-45 mt-10 a-center">
          <p class="mt-10">Show Inactive DOA Level Types</p>
          <kendo-switch [(ngModel)]="checked" class="mt-10 mr-40" (valueChange)="getGridData($event)">
          </kendo-switch>
        </div>
      </section>

        <!-- Table data -->
        <section class="mt-10">
            <kendo-grid [kendoGridBinding]="gridData" [resizable]="true" [pageable]="true" [pageSize]="50" #grid="kendoGrid"
            [sortable]="true">
                <kendo-grid-column field="label" title="Label" [width]="20">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a href="Javascript:void(0);" (click)="doaApprovalDetails(dataItem)">
                            {{ dataItem.label }}
                        </a>
                    </ng-template>
                </kendo-grid-column>
                <!-- DOA Threshold -->
                <kendo-grid-column field="doaThreshold" title="DOA Threshold" [width]="14" class="a-center">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        ${{dataItem.doaThreshold}} {{dataItem.currency}}
                    </ng-template>
                </kendo-grid-column>
                <!-- DOA Approver -->
                <kendo-grid-column field="doaApprover" title="DOA Approver" [width]="12" class="a-center">
                </kendo-grid-column>
                <!-- Is JDE Only -->
                <kendo-grid-column field="isJDEOnly" title="Is JDE Only" [width]="8" class="a-center">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem.isJDEOnly === null ? false : dataItem.isJDEOnly}}
                    </ng-template>
                </kendo-grid-column>
                <!-- assets -->
                <kendo-grid-column field="assets" title="Assets" [width]="46" class="a-center">
                </kendo-grid-column>
            </kendo-grid>

            <!-- total data count -->
            <p class="grid-length mt-20">{{ gridData.length }} records</p>
        </section>
    </section>
</div>