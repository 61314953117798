<div class="iris-locationDetail-container j-c-s main-container-padding">
  <h1 class="mb-20 setup-heading">{{heading}}</h1>
  <div class="mr-20 float-right">
    <button kendoButton class="primary-button ml-20 f-size-150" [disabled]="!isFormValid"
      (click)="action('save')">Save</button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">Cancel</button>
  </div>
  <section class="mt-20">
    <!-- Label -->
    <div class="mt-20 pr-20 w-40">
      <kendo-label> Location Name </kendo-label>
      <kendo-textbox [maxlength]="150" [(ngModel)]="locationName"
        (keyup)="addEditDepartmentName($event)"></kendo-textbox>
    </div>
    <!-- Business Unit -->
    <div class="example-wrapper mt-10 w-40">
      <kendo-label> Business Unit </kendo-label> <br>
      <kendo-dropdownlist [data]="BULists" textField="name" valueField="id"
        (selectionChange)="selectAssignToGroup($event)" [defaultItem]="defaultAssignBUName" [valuePrimitive]="true">
      </kendo-dropdownlist>
      <i class="fa fa-times close-icon dropdown-add-icon" *ngIf="!isLineSelected" aria-hidden="true"></i>
    </div>
    <!-- Is Active -->
    <div class="mt-10 pr-15 w-25">
      <kendo-label [for]="select"> Is Active </kendo-label> <br />
      &nbsp;&nbsp;<input type="checkbox" [(ngModel)]="isActive" #select kendoCheckBox />
    </div>
    <!-- Order -->
    <div class="mt-10 pr-15 w-25">
      <kendo-label> Order </kendo-label>
      <kendo-textbox [maxlength]="10" [(ngModel)]="order"></kendo-textbox>
    </div>
  </section>
</div>