<div class="content-top d-flex a-center" style="padding-top: 20px !important;">
    <h1>
        <a class="c-left" (click)="commonService.goBack()">
            <span class="fa fa-fw fa-angle-left"></span>
        </a>
        IRIS Request {{trackingNumber}}
    </h1>
</div>

<section class="iris-item-approval-contentbox">
    <!-- Table data -->
    <section class="mt-20 m-container requestGrid" id="overviewPage" *ngIf="gridData.length">
        <kendo-grid [data]="gridData" #grid="kendoGrid">
            <kendo-grid-column field="index" title="Line Items" [width]="6"
                [style]="{ 'text-align': 'right', 'padding-right': '30px' }"></kendo-grid-column>
            <kendo-grid-column field="itemNumber" title="Item Number" [width]="10"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="itemDescription" title="Item Description" [width]="18"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="manufacturerName" title="Manufacturer Name" [width]="15"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="manufacturerPartNumber" title="Manufacturer Part Number" [width]="14"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="inventLabel" title="Inventory Condition" [width]="9"
                [style]="{ 'padding-left': '12px' }"></kendo-grid-column>
            <kendo-grid-column field="unitCost" title="Unit Cost(AUD)" [width]="9"
                [style]="{ 'text-align': 'right', 'padding-right': '24px' }"></kendo-grid-column>
            <kendo-grid-column field="itemQty" title="Order Qty" [width]="9"
                [style]="{ 'text-align': 'right', 'padding-right': '24px' }"></kendo-grid-column>
            <kendo-grid-column field="estimatedCost" title="Estimated  Cost (AUD)" [width]="10"
                [style]="{ 'text-align': 'right', 'padding-right': '32px' }"></kendo-grid-column>
        </kendo-grid>
    </section>

    <!-- Grid data counts details -->
    <section class="mt-20">
        <p class="counter_message mt-20">{{gridData.length}} record</p>
        <kendo-gridlayout class=" w-75 mt-20" [rows]="['auto']" [cols]="[ 155,250,230]" [gap]="5">
            <kendo-gridlayout-item [col]="1" [row]="1" class="text-right">
                <p class="head-data">Number of Line Items</p>
                <p class="deatil-data">{{gridData.length}}</p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [col]="2" [row]="1" class="text-right">
                <p class="head-data">Total Estimated Stock Value (AUD)</p>
                <p class="deatil-data ">{{totalestimatedCost}}</p>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item [col]="3" [row]="1" class="ml-20">
                <p class="head-data">Commercial Approval Needed</p>
                <p class="deatil-data">TODO</p>
            </kendo-gridlayout-item>
        </kendo-gridlayout>
    </section>
</section>