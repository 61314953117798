import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';
import { InputFieldsConstants } from 'src/app/core/constants/input-fields.constants';
import { AssetCodeFunctionDto } from '../../../models/code-functions.model';
import { AssetCodeFunctionService } from '../../../services/asset-code-functions/asset-code-function.service';
import { BackOfficeAssetDto } from '../../../models/asset-group.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-asset-code-function-details',
  templateUrl: './asset-code-function-details.component.html',
  styleUrls: ['./asset-code-function-details.component.scss'],
})
export class AssetCodeFunctionDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('multiselect', { static: true }) public multiselect: any;
  heading: string = 'New Asset Code Function';
  isFormValid: boolean = false;
  groupsNameSelected: BackOfficeAssetDto[] = [];
  selectedGroups: AssetCodeFunctionDto[] = [];
  codeFunctionId!: number;
  groupsList: BackOfficeAssetDto[] = [];
  branch!: string | any;
  branchDesp!: string;
  company!: number;
  locations!: string;
  asset!: string;
  assetDesp!: string;
  assetFunction!: string;
  isActive!: boolean;
  businessUnitId!: number;
  order!: number;
  createdBy!: string;
  modifiedBy!: string;
  createdDateTime!: string;
  modifiedDateTime!: string;
  actionStatus!: string;
  AGCList: { branch: string | undefined; asset: string | undefined; }[] = [];
  prevBranch: string = '';
  prevAsset: string = '';
  constructor(
    private router: Router,
    private location: Location,
    private commonService: CommonService,
    private codeFunctionService: AssetCodeFunctionService,
    private titleService: Title
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      if (stateData['actionName'] === 'edit') {
        this.bindAssetValue(stateData['assetGroup'], stateData['assetGroupAPI']);
      }
      this.actionStatus = stateData['actionName'];
      this.codeFunctionId = stateData['id'];
      this.heading = stateData['branch'] ? stateData['branch'] : this.heading;
      this.branch = stateData['branch'];
      this.branchDesp = stateData['branchDescription'];
      this.company = stateData['company'];
      this.locations = stateData['location'];
      this.asset = stateData['asset'];
      this.assetDesp = stateData['assetDescription'];
      this.assetFunction = stateData['assetFunction'];
      this.isActive = stateData['isActive'];
      this.businessUnitId = stateData['businessUnitId'];
      this.order = stateData['order'];
      this.createdBy = stateData['createdBy'];
      this.modifiedBy = stateData['modifiedBy'];
      this.createdDateTime = stateData['createdDateTime'];
      this.modifiedDateTime = stateData['modifiedDateTime'];
      this.groupsList = stateData['assetGroupAPI'];
      sessionStorage.setItem('codeFunctionDetails', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('codeFunctionDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.actionStatus = sessionState?.actionName;
        this.heading = sessionState?.heading;
        this.branch = sessionState?.branch;
        this.branchDesp = sessionState?.branchDesp;
        this.company = sessionState?.company;
        this.locations = sessionState?.location;
        this.asset = sessionState?.asset;
        this.assetDesp = sessionState?.assetDesp;
        this.assetFunction = sessionState?.assetFunction;
        this.selectedGroups = sessionState?.selectedGroups;
        this.isActive = sessionState?.isActive;
        this.businessUnitId = sessionState?.businessUnitId;
        this.order = sessionState?.order;
        this.createdBy = sessionState?.createdBy;
        this.modifiedBy = sessionState?.modifiedBy;
        this.createdDateTime = sessionState?.createdDateTime;
        this.modifiedDateTime = sessionState?.modifiedDateTime;
      }
    }
  }

  ngOnInit() {
    if (this.actionStatus === 'edit') {
      this.titleService.setTitle(this.branch);
    }
    this.getAssetCodeFunctionRecords();
    if (this.branch) {
      this.isFormValid = true;
    }
    if (this.actionStatus === 'edit') {
      this.prevBranch = this.branch;
      this.prevAsset = this.asset;
    }
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('codeFunctionDetails');
  }

  getAssetCodeFunctionRecords(): void {
    this.codeFunctionService
      .getAssetCodeFunctionGrid()
      .subscribe((data: AssetCodeFunctionDto[]) => {
        this.AGCList = data.map((agc: AssetCodeFunctionDto) => { return { branch: agc.branch, asset: agc.asset }; })
      });
  }

  // to bind asset group names
  bindAssetValue(records: any, apiRecords: any) {
    if (records) {
      const selectedGroup = records?.split(',');
      apiRecords.filter((ele: any) => {
        for (let x of selectedGroup) {
          if (
            ele.label
              .toLowerCase()
              .toString()
              .includes(x.toLowerCase().toString())
          ) {
            this.selectedGroups.push(ele);
          }
        }
      });
    } else {
      this.selectedGroups = [];
    }
  }

  // enable disable save button
  addBrachName(event: any): void {
    if (event.target.value.trim()) {
      this.isFormValid = true;
    } else {
      this.isFormValid = false;
    }
  }

  action(actionType: string) {
    switch (actionType) {
      case 'cancel': {
        this.location.back();
        break;
      }
      case 'save': {
        if (this.isDupliacateAGC()) {
          this.commonService.showNotificationMessage('Asset Group Code already exists', 'error', 'center');
        }
        else {
          this.addNewCodeFunction();
          break;
        }
      }
    }
  }

  isDupliacateAGC(): boolean {
    let agcList: { branch: string | undefined; asset: string | undefined; }[] = this.AGCList.filter((agc: { branch: string | undefined; asset: string | undefined; }) =>
      (agc.branch!.trim().toLowerCase() === this.branch.trim().toLowerCase() && agc.asset?.trim().toLowerCase() === this.asset.trim().toLowerCase()));
    if (agcList.length > 0 && this.actionStatus === 'edit' && (this.prevBranch.trim().toLowerCase() !== this.branch.trim().toLowerCase() || this.prevAsset.trim().toLowerCase() !== this.asset.trim().toLowerCase())) {
      return true;
    }
    if (agcList.length > 0 && this.actionStatus === 'create') {
      return true;
    }
    return false;
  }
  // to get the id's of selected asset-group
  selectedGroupId(event: any) {
    this.groupsNameSelected = event.map((ele: BackOfficeAssetDto) => {
      return ele['id']?.toString();
    });
  }

  // to Create || Edit asset location
  addNewCodeFunction() {
    const Tdate = new Date();
    const currentDate: any = new Date(Tdate + 'Z');
    const fName = JSON.parse(sessionStorage.getItem('firstName')!);
    const lName = JSON.parse(sessionStorage.getItem('lastName')!);
    const userName = `${fName} ${lName}`;
    const payload: AssetCodeFunctionDto = {
      id: this.codeFunctionId,
      branch: this.branch,
      branchDescription: this.branchDesp,
      company: this.company,
      location: this.locations,
      asset: this.asset,
      assetDescription: this.assetDesp,
      assetFunction: this.assetFunction,
      assetGroup: this.groupsNameSelected ? this.groupsNameSelected : [],
      isActive: this.isActive,
      businessUnitId: this.businessUnitId,
      order: this.order,
      createdBy: userName,
      createdDateTime: this.createdDateTime ? this.createdDateTime : currentDate,
      modifiedBy: userName,
      modifiedDateTime: currentDate,
    };
    switch (this.actionStatus) {
      case 'create': {
        this.commonService.removeKey(payload, 'id');
        this.codeFunctionService
          .createNewCodeFunction(payload)
          .subscribe((data: AssetCodeFunctionDto) => {
            if (data) {
              this.successUpdateMSG();
              this.location.back();
            }
          });
        break;
      }
      case 'edit': {
        this.codeFunctionService
          .editCodeFunction(payload, this.codeFunctionId)
          .subscribe((data: AssetCodeFunctionDto) => {
            if (data) {
              this.successUpdateMSG();
              this.location.back();
            }
          });
      }
    }
  }

  successUpdateMSG() {
    const msg = `Asset Code Function '${this.branch}'`;
    const state = this.actionStatus == 'create' ? 'create' : 'edit';
    this.commonService.successUpdateMSG(msg, state);
  }
}
