import { Injectable } from '@angular/core';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { ManageNotificationDto } from '../../models/manage-notification.model';
import { Observable } from 'rxjs';
import { ManageNotificationsConst } from 'src/app/core/constants/MMS_BackOffice_Constants/manage-notifications/manage-notifications.constants';

@Injectable({
  providedIn: 'root'
})
export class ManageNotificationsService {

  constructor(private utilityService: HttpUtilityService) {
  }

  getManageNotifocationItemId(id: number): Observable<ManageNotificationDto> {
    return this.utilityService.get(ManageNotificationsConst.apiPath + `/${id}`);
  }

  updateManageNotification(userId: number, mn: ManageNotificationDto): Observable<ManageNotificationDto> {
    return this.utilityService.put(ManageNotificationsConst.apiPath + `/${userId}`, mn);
  }
}
