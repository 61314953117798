import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { KendoModule } from 'src/app/modules/kendo.module';
import { HelpGettingStartedComponent } from '../components/help-getting-started/help-getting-started/help-getting-started.component';
import { HelpCreateRequestComponent } from '../components/help-create-request/help-create-request/help-create-request.component';
import { HelpTrackRequestComponent } from '../components/help-track-request/help-track-request/help-track-request.component';
import { UserGuideHeaderComponent } from '../components/sub-components/user-guide-header/user-guide-header.component';
import { SystemUsageGuideComponent } from '../components/sub-components/system-usage-guide/system-usage-guide.component';
import { ContactSupportComponent } from '../components/sub-components/contact-support/contact-support.component';

@NgModule({
    declarations: [
        HelpGettingStartedComponent,
        HelpCreateRequestComponent,
        HelpTrackRequestComponent,
        UserGuideHeaderComponent,
        SystemUsageGuideComponent,
        ContactSupportComponent
    ],
    imports: [
        BrowserModule,
        RouterModule,
        HttpClientModule,
        KendoModule,
    ],
    exports: [
        HelpGettingStartedComponent,
        HelpCreateRequestComponent,
        HelpTrackRequestComponent
    ],
    providers: [],
})
export class UserGuideModule { }
