import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingConstant } from 'src/app/core/constants/modules-routing.constants';
import { ProfileType } from 'src/app/core/model/graph.models';
import { GlobalAuthService } from 'src/app/core/services/global-auth.service';

@Component({
  selector: 'mms-dashboard',
  templateUrl: './mms-dashboard.component.html',
  styleUrls: ['./mms-dashboard.component.scss'],
})
export class MmsDashboardComponent implements OnInit {
  firstName: string = '';
  lastName: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private globalAuthService: GlobalAuthService
  ) {}

  ngOnInit() {
    let fname = JSON.parse(sessionStorage.getItem('firstName')!);
    let lname = JSON.parse(sessionStorage.getItem('lastName')!);
    if (fname && lname) {
      this.firstName = fname;
      this.lastName = lname;
    } else {
      this.getLoggedInUserData();
    }
  }

  getLoggedInUserData(): void {
    this.globalAuthService.profile.subscribe((profile: ProfileType | any) => {
      this.firstName = profile['givenName']!;
      this.lastName = profile['surname']!;
    });
  }

  navigateToIRIS() {
    const url = RoutingConstant.IRIS;
    this.router.navigate([`../${url}`], { relativeTo: this.route });
  }
}
