<div class="logout-container">
  <div class="logo">
    <img src="../../../../assets/icons/ChevronLogo-logout.png" alt="">
  </div>
  <div class="text">
    <h2>You have successfully signed out of your application.</h2>
    <p class="navigation-link">
      You may close this window, or 
      <span>click here to return to your application.</span>
    </p>
  </div>
  <div></div>
</div>
