<h2 class="heading content-top">All IRIS</h2>

<section class="all-iris order-component">
    <ng-template #template let-anchor>
      <span>{{ anchor.nativeElement.innerText }}</span>
    </ng-template>
    <div class="grid-box" kendoTooltip showOn="none" [tooltipTemplate]="template" filter=".k-grid td"
      (mouseover)="showTooltip($event)">
      <kendo-grid [data]="gridView" [loading]="loadingPanelVisible" [resizable]="true" [filterable]="true"
        [groupable]="true" [group]="groups" (groupChange)="groupChange($event)" [pageSize]="pageSize" [skip]="skip"
        [filter]="filters" [sortable]="true" [pageable]="{
    buttonCount: buttonCount,
    info: info,
    pageSizes: pageSizes,
    previousNext: previousNext }" [sortable]="{
    allowUnsort: allowUnsort,
    mode: multiple ? 'multiple' : 'single'
    }" [sort]="sort" (dataStateChange)="dataStateChange($event)" class="kendo-grid">

        <ng-template kendoGridToolbarTemplate>
          <button class="all-iris-button mr-10" ><i class="k-icon k-font-icon k-i-save mr-5"></i> Save Config</button>
          <button class="all-iris-button mr-10" ><i class="fas fa-eraser mr-5"></i> Reset Config</button>
          <button class="all-iris-button mr-10" ><i class="fa fa-sort-alpha-down-alt mr-5"></i> Clear Sort</button>
          <button class="all-iris-button mr-10" ><i class="k-icon k-font-icon k-i-filter mr-5"></i> Clear Filters</button>
          <kendo-grid-spacer></kendo-grid-spacer>
          <button type="button" class="kendo-button all-iris-button"
            [ngClass]="{'excelData' : totalRecords > 0 , 'noExcelData': totalRecords ==0}" kendoGridExcelCommand
            icon="file-excel">
            Export to Excel
          </button>
        </ng-template>
        <kendo-grid-column [width]="150" field="TrackingNumber" title="IRIS ID">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="javascriipt:void();" (click)="manage(dataItem.TrackingNumber)">{{dataItem.TrackingNumber}}</a>
          </ng-template>
        </kendo-grid-column>
       
        <kendo-grid-column [width]="150"  field="Title" title="Title ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="Asset" title="Asset">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="DepartmentName" title="Department">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="IRISMaterialType" title="IRIS Material Type ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="JDEDocumentNumber" title="JDE Document Number">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="DisposalMethod" title="Disposal Method">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="RequestState" title="Status ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="RequestState" title="Total Estimated Cost">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="storageLocation" title="Recovered  Amount">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="storageLocationDF" title="Recovery Percentage">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="AssetLocation" title="Asset Location">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="valuationTypeDF" title="Auctioneer Name ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="Justification" title="Justification ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="Description" title="Description ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" field="CreatedByName" title="Created By ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
     
        <kendo-grid-column [width]="150" field="assignedTo" title="Assigned To ">
          <ng-template class="filtercell" kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
              <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
              <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
            </kendo-grid-string-filter-cell>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [width]="150" filter="date" field="CreatedDateTime" title="Created">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
              <kendo-filter-lte-operator></kendo-filter-lte-operator>
            </kendo-grid-date-filter-cell>
          </ng-template>
        </kendo-grid-column>
        



        <kendo-grid-excel fileName="AllRequests.xlsx" [fetchData]="allData"></kendo-grid-excel>
      </kendo-grid>


    </div>

  </section>