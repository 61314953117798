<div class="iris-asset-joa-threshold-container j-c-s m-container">
  <h1 class="setup-heading">IRIS Asset JOA Threshold Limit</h1>

  <div class="mr-20 float-right">
    <button kendoButton class="border-button ml-20 f-size-150" (click)="addNewLavel()"
      [ngClass]="!isAddNewBTNDisabled ? 'border-button' : 'disabled-button'" [disabled]="isAddNewBTNDisabled">
      Add new Level
    </button>
  </div>

  <section class="manage-contentbox">
    <!-- Filter actions -->
    <section class="mt-20 d-flex">
      <div class="mt-20 pr-20 d-flex w-55">
        <kendo-textbox [(ngModel)]="searchedText" (click)="textSearched($event)"></kendo-textbox>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="searchResetGridActions('search')">
          Search
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="searchResetGridActions('reset')">
          Reset
        </button>
      </div>
    </section>

    <!-- toggle active/inactive JOA-->
    <!-- <section class="toggle-active-inactive">
      <div class="d-flex j-c-s w-45 mt-20 a-center">
        <p class="mt-10">Show Inactive DOA Level Types</p>
        <kendo-switch [(ngModel)]="checked" class="mt-10 mr-40" (click)="showHideActiveNInactiveJOA($event)">
        </kendo-switch>
      </div>
    </section> -->
    <br />
    <!-- Table data -->
    <section class="mt-10">
      <kendo-grid [kendoGridBinding]="gridData" #grid="kendoGrid" [sortable]="true" [resizable]="true">
        <!-- label -->
        <kendo-grid-column field="label" title="Label" [width]="20">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="Javascript:void(0);" (click)="joaThresholdDetails(dataItem)">
              {{ dataItem.label }}
            </a>
          </ng-template>
        </kendo-grid-column>
        <!-- BU -->
        <kendo-grid-column field="businessUnitId" title="Business Unit" [width]="20">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.businessUnit.name}}
          </ng-template>
        </kendo-grid-column>
        <!-- JOA threshold -->
        <kendo-grid-column field="assetJoatThresholds.joAmount" title="JOA Threshold" [width]="20"
          [style]="{ 'text-align': 'right', 'padding-right': '2.25rem' }">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p *ngIf="dataItem?.assetJoatThresholds[0]">${{ dataItem?.assetJoatThresholds[0]?.joAmount
              }}({{dataItem?.assetJoatThresholds[0]?.currancy}})</p>
            <p *ngIf="!dataItem?.assetJoatThresholds[0]">$0</p>
          </ng-template>
        </kendo-grid-column>
        <!-- commercial approver -->
        <kendo-grid-column field="commercialUser" title="Commercial Approver" [width]="30">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p>{{ dataItem?.commercialUser ? dataItem?.commercialUser : '-' }}</p>
          </ng-template>
        </kendo-grid-column>
        <!-- waiver -->
        <kendo-grid-column field="isWaiverinPlace" title="Waiver" [width]="10"
          [style]="{ 'text-align': 'center', 'padding-right': '3.25rem' }">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p>{{ dataItem?.assetJoatThresholds[0]?.isWaiverinPlace == true ? "Yes" : "No" }}</p>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <!-- total data count -->
      <p class="grid-length mt-20">{{ gridData.length }} records</p>
    </section>
  </section>
</div>