import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DialogUploadComponent } from 'src/app/core/components/dialog-upload/dialog-upload.component';
import { CommonService } from 'src/app/core/services/common.service';
import { IRISDOALevelDto } from '../../models/iris-doa-Level.model';
import { IRISDOALevelsService } from '../../services/iris-doa-levels/irisdoalevels.service';
import { BackOfficeRoutingConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/mms-backOffice.constants';
import { BusinessUnitDto } from '../../models/bussinessunits.model';
import { BusinessUnitService } from '../../services/business-unit-service/business-unit.service';

@Component({
  selector: 'app-iris-doa-levels',
  templateUrl: './iris-doa-levels.component.html',
  styleUrls: ['./iris-doa-levels.component.scss']
})
export class IrisDoaLevelsComponent {
  searchedText: string = '';
  checked: boolean = false;
  fileName: string = 'IRIS DOA Table.xlsx';
  irisDOALevels: IRISDOALevelDto[] = [];
  gridData: IRISDOALevelDto[] = [];
  userMailID: string = JSON.parse(sessionStorage.getItem('userEmailID')!);
  importedDOALevelsList: IRISDOALevelDto[] = []
  buList: BusinessUnitDto[] = [];
  buShortNamesList: string[] = [];
  currencyList: string[] = ['AUD', 'USD'];
  constructor(private irisDOALevelsService: IRISDOALevelsService, public commonService: CommonService, private dialogService: DialogService,
    private router: Router, private route: ActivatedRoute, private buService: BusinessUnitService) {
    let stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.searchedText = stateData['searchedText'];
    }
  }

  ngOnInit(): void {
    this.getDOALevelsList();
    this.getBUList();
  }

  getDOALevelsList() {
    this.irisDOALevelsService.getIRISDOALevelList().subscribe((doaLevels: IRISDOALevelDto[]) => {
      this.irisDOALevels = doaLevels;
      this.getGridData(this.checked);
    });
  }
  getGridData(checked: boolean) {
    this.gridData = this.commonService.setupGobalSearch(this.irisDOALevels, checked, 'label', this.searchedText);
    this.sortData();
    this.setDOALevelRange();
  }
  getBUList() {
    this.buService.getAllBUNames().subscribe((data: BusinessUnitDto[]) => {
      this.buList = data;
      this.buShortNamesList = data.map((bu: BusinessUnitDto) => { return bu.shortName! });
    });
  }
  sortData() {
    this.gridData = this.gridData.sort((a, b) => {
      if (a.doaLevel < b.doaLevel) {
        return -1;
      }
      if (a.doaLevel > b.doaLevel) {
        return 1;
      }
      return 0;
    })
    this.gridData = this.gridData.sort((a, b) => {
      if (a.businessUnitId! < b.businessUnitId!) {
        return -1;
      }
      if (a.businessUnitId! > b.businessUnitId!) {
        return 1;
      }
      return 0;
    });
  }
  setDOALevelRange() {
    let index: number = -1;
    let buID: number = -1;
    for (let level of this.gridData) {
      index++;
      if (level.businessUnitId !== buID) {
        level.doaLevelRangeLeft = `-${level.doaLevel}`;
        level.doaLevelRangeRight = `${level.doaLevel}`;
        buID = level.businessUnitId!;
      }
      else if (this.gridData[index - 1].doaLevel === 0) {
        level.doaLevelRangeLeft = `-${level.doaLevel}`;
        level.doaLevelRangeRight = `${level.doaLevel}`;
      }
      else {
        level.doaLevelRangeLeft = `-${level.doaLevel} to -${this.gridData[index - 1].doaLevel + 1}`;
        level.doaLevelRangeRight = `${this.gridData[index - 1].doaLevel + 1} to ${level.doaLevel}`;
      }
    }
  }
  exportExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  importExcel() {
    const dialog: DialogRef = this.dialogService.open({
      content: DialogUploadComponent
    });
    dialog.result.subscribe((r: any) => {
      if (r.action === 'Save') {
        this.importedDOALevelsList = []
        let arr = JSON.parse(r.value).Sheet1 as Array<any>;
        let errorMessage: string = '';
        let validData: boolean = true;
        let rowNum: number = 1;
        for (let row of arr) {
          let doaLevel: IRISDOALevelDto = this.setDefaultDOALevel();
          rowNum++;
          if (this.commonService.isEmptyOrUndefined(row['BU Name'])) {
            errorMessage = `Excel read error in row ${rowNum}: BU Name required.`;
            validData = false;
            break;
          }
          else if (!this.isValidBU(row['BU Name'].toString())) {
            errorMessage = `Excel read error in row ${rowNum}: Invalid BU Name.`;
            validData = false;
            break;
          }
          else {
            doaLevel.businessUnitId = this.buList.filter((bu: BusinessUnitDto) => (bu.shortName === this.getCode(row['BU Name'].toString())))[0].id;
          }
          if (this.commonService.isEmptyOrUndefined(row['Label'])) {
            errorMessage = `Excel read error in row ${rowNum}: DOA Level label required.`;
            validData = false;
            break;
          }
          else if (this.isduplicateLabel(row['Label'].toString(), doaLevel.businessUnitId!)) {
            errorMessage = `Excel read error in row ${rowNum}: DOA Level label already exists.`;
            validData = false;
            break;
          }
          else if (this.isduplicateLabel_Excel(row['Label'].toString(), doaLevel.businessUnitId!)) {
            errorMessage = `Excel read error in row ${rowNum}: DOA Level label already exists in Excel.`;
            validData = false;
            break;
          }
          else {
            doaLevel.label = row['Label'].toString();
          }
          if (this.commonService.isEmptyOrUndefined(row['DOA Level'])) {
            doaLevel.doaLevel = 0;
          }
          else if (typeof row['DOA Level'] !== 'number') {
            errorMessage = `Excel read error in row ${rowNum}: Column 'DOA Level' has an invalid type. Expected a 'Int' but received a '${typeof row['Display Order']}'.`;
            validData = false;
            break;
          }
          else {
            doaLevel.doaLevel = row['DOA Level'];
          }
          if (!this.commonService.isNAN(doaLevel.doaLevel)) {
            if (this.isduplicateAmmount(doaLevel.doaLevel, doaLevel.businessUnitId!)) {
              errorMessage = `Excel read error in row ${rowNum}: DOA Level Ammount already exists.`;
              validData = false;
              break;
            }
            else if (this.isduplicateAmmount_Excel(doaLevel.doaLevel, doaLevel.businessUnitId!)) {
              errorMessage = `Excel read error in row ${rowNum}: DOA Level Ammount already exists in Excel.`;
              validData = false;
              break;
            }
          }
          if (this.commonService.isEmptyOrUndefined(row['Currency'])) {
            errorMessage = `Excel read error in row ${rowNum}: Currency required.`;
            validData = false;
            break;
          }
          else if (!this.currencyList.includes(row['Currency'].toString())) {
            errorMessage = `Excel read error in row ${rowNum}: Currency should be ${this.currencyList.join(' or ')}.`;
            validData = false;
            break;
          }
          else {
            doaLevel.currancy = row['Currency'].toString();
          }
          if (this.commonService.isEmptyOrUndefined(row['Is Active'])) {
            doaLevel.isActive = false;
          }
          else if (typeof row['Is Active'] !== 'boolean') {
            errorMessage = `Excel read error in row ${rowNum}: Column 'Is Active' has an invalid type. Expected a 'Boolean' but received a '${typeof row['Is Active']}'.`;
            validData = false;
            break;
          }
          else {
            doaLevel.isActive = row['Is Active'];
          }
          this.importedDOALevelsList.push(doaLevel);
        }
        if (!validData) {
          this.commonService.showNotificationMessage(errorMessage, 'error', 'center');
        }
        else {
          this.irisDOALevelsService
            .importExcelData(this.importedDOALevelsList)
            .subscribe((response: any[]) => {
              if (response) {
                this.getDOALevelsList()
                const msg = `New DOA Level(s) added successfully`;
                this.commonService.showNotificationMessage(msg, 'success', 'center');
              }
            });
        }
      }
    });
  }
  getCode(buName: string) {
    return buName.split('-')[0].trim();
  }
  isValidBU(buName: string) {
    let code = this.getCode(buName);
    if (code) {
      if (this.buShortNamesList.includes(code)) {
        return true;
      }
    }
    return false;
  }
  
  setDefaultDOALevel(): IRISDOALevelDto {
    let doaLevel: IRISDOALevelDto = new IRISDOALevelDto();
    doaLevel.id = 0;
    doaLevel.order = 0;
    doaLevel.createdBy = this.userMailID;
    doaLevel.createdDateTime = new Date;
    return doaLevel;
  }

  isduplicateLabel(doaLevelLabel: string, buId: number) {
    let doaLevels: IRISDOALevelDto[] = this.irisDOALevels.filter((x: IRISDOALevelDto) => (x.businessUnitId === buId && x.label.toLowerCase() === doaLevelLabel.toLowerCase()));
    if (doaLevels.length > 0) {
      return true;
    }
    return false;
  }
  isduplicateAmmount(ammount: number, buId: number) {
    let doaLevels: IRISDOALevelDto[] = this.irisDOALevels.filter((x: IRISDOALevelDto) => (x.businessUnitId === buId && x.doaLevel === ammount));
    if (doaLevels.length > 0) {
      return true;
    }
    return false;
  }
  isduplicateLabel_Excel(doaLevelLabel: string, buId: number) {
    let doaLevels_excel: IRISDOALevelDto[] = this.importedDOALevelsList.filter((x: IRISDOALevelDto) => (x.businessUnitId === buId && x.label.toLowerCase() === doaLevelLabel.toLowerCase()));
    if (doaLevels_excel.length > 0) {
      return true;
    }
    return false;
  }
  isduplicateAmmount_Excel(ammount: number, buId: number) {
    let doaLevels_excel: IRISDOALevelDto[] = this.importedDOALevelsList.filter((x: IRISDOALevelDto) => (x.businessUnitId === buId && x.doaLevel === ammount));
    if (doaLevels_excel.length > 0) {
      return true;
    }
    return false;
  }
  addEdiDOALevel(isNew: boolean, id: number, label: string, doaLevel: number, currency: string, buName: string, isActive: boolean) {
    this.router.navigate([`../${BackOfficeRoutingConstants.IRISDOALevelDetailPath}`], { state: { isNew: isNew, id: id, label: label, doaLevel: doaLevel, isActive: isActive, currency: currency, buName: buName, searchedText: this.searchedText }, relativeTo: this.route })
  }
  resetGrid() {
    this.searchedText = '';
    this.getGridData(this.checked);
  }
}
