import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IRISRequestConstants, InventoryConditionConstants } from 'src/app/core/constants/IRIS_Constants/iris-requester/iris-requester.constants';
import { CommonService } from 'src/app/core/services/common.service';
import { InventoryConditionDto } from '../../models/iris-request-workflow/iris-request.model';
import { LineDetailsDto } from '../../models/iris-request-workflow/line-item.model';
import { IrisRequestService } from '../../services/IRIS-Request/iris-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IRISRoutingConstants } from 'src/app/core/constants/IRIS_Constants/IRIS-components-routing-constants';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { MaterialManagementInventoryDto } from '../../models/iris-request-workflow/material-management-inventory.model';

@Component({
  selector: 'app-add-new-item',
  templateUrl: './add-new-item.component.html',
  styleUrls: ['./add-new-item.component.scss'],
})
export class AddNewItemComponent implements OnInit {
  public virtual: any = {
    itemHeight: 50,
  };
  materialTypeList: InventoryConditionDto[] = InventoryConditionConstants;
  despLength: string | any = '';
  totalEstimateCost: number | string = '';
  isMaterialError: boolean = false;
  isDespError: boolean = false;
  isManuError: boolean = false;
  isManuPartError: boolean = false;
  isCostError: boolean = false;
  isQuantityError: boolean = false;
  hasError: boolean = false;
  ismaterialTypeError: boolean = false;
  requestId!: number;
  requestLineID!: number;
  currentStatus: string = '';
  trackingNumber!: string;
  selectedMaterialType!: number;
  selectedDisposalMethod!: number;
  selectedDisposalMethodName!: string;
  itemNumberName: string = '';
  itemNumberNamePlaceholder: string = '';
  attachmentFileDetails: any = [];
  financialMatAdjustments_IO_ED: boolean = false;
  materialNumberListSource: string[] = [];
  materialNumberList: string[] = [];
  @ViewChild("autocomplete", { static: false }) public autocomplete!: AutoCompleteComponent;
  addIemForm = new FormGroup({
    generalMaterial: new FormControl(<string | any>'', Validators.required),
    description: new FormControl(<string | any>'', Validators.required),
    manufacturer: new FormControl(<string | any>'', Validators.required),
    inventoryCond: new FormControl(<string | undefined>'', Validators.required),
    manufacturerPartNum: new FormControl(<any>'', Validators.required),
    unitCost: new FormControl(<number | undefined>0, Validators.required),
    itemQuantity: new FormControl(<number | undefined>0, Validators.required),
  });

  constructor(
    public commonService: CommonService,
    private irisRequestService: IrisRequestService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (stateData['actionStatus'] === 'edit') {
      this.currentStatus = 'edit';
      if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
        this.totalEstimateCost = stateData['estimatedCost'];
        this.requestLineID = stateData['requestLineID'];
        this.requestId = stateData['requestId'];
        this.despLength = stateData['itemDescription'];
        this.selectedMaterialType = stateData['selectedMaterialTypeID'];
        this.selectedDisposalMethod = stateData['selectedDisposalMethodID'];
        this.trackingNumber = stateData['trackingNumber'];
        this.addIemForm.setValue({
          generalMaterial: stateData['itemNumber'],
          description: stateData['itemDescription'],
          manufacturer: stateData['manufacturerName'],
          inventoryCond: stateData['inventoryConditionId'],
          manufacturerPartNum: stateData['manufacturerPartNumber'],
          unitCost: stateData['unitCost'],
          itemQuantity: stateData['itemQty'],
        });
        sessionStorage.setItem('requestLineDetails', JSON.stringify(stateData));
      } else {
        let sessionState = JSON.parse(sessionStorage.getItem('requestLineDetails')!);
        if (!this.commonService.isEmptyOrUndefined(sessionState)) {
          this.totalEstimateCost = sessionState?.estimatedCost;
          this.requestLineID = sessionState?.requestLineID;
          this.selectedMaterialType = sessionState?.selectedMaterialTypeID;
          this.selectedDisposalMethod = sessionState?.selectedDisposalMethodID;
          this.trackingNumber = sessionState?.trackingNumber;
          this.addIemForm.patchValue({
            generalMaterial: sessionState?.itemNumber,
            description: sessionState?.itemDescription,
            manufacturer: sessionState?.manufacturerName,
            inventoryCond: sessionState?.inventoryConditionId,
            manufacturerPartNum: sessionState?.manufacturerPartNumber,
            unitCost: sessionState?.unitCost,
            itemQuantity: sessionState?.itemQty,
          });
        }
      }
    } else {
      this.currentStatus = 'create';
      this.requestId = stateData['requestId'];
      this.selectedMaterialType = stateData['selectedMaterialTypeID'];
      this.selectedDisposalMethod = stateData['selectedDisposalMethodID'];
      this.selectedDisposalMethodName = stateData['selectedDisposalMethodName'];
      this.trackingNumber = stateData['trackingNumber'];
    }
    this.setHeadingAndPlaceholderForItemNumberInputField();
  }
  ngOnInit(): void {
    if (this.selectedMaterialType === 4 && this.selectedDisposalMethodName !== 'Other') {
      this.financialMatAdjustments_IO_ED = true;
      this.addIemForm.controls['inventoryCond'].clearValidators();
      this.getMaterialNumberList();
    }
  }

  getMaterialNumberList() {
    this.irisRequestService.getAllMaterialNumbers().subscribe((materialNumbers: string[]) => {
      this.materialNumberListSource = materialNumbers
      this.materialNumberList = materialNumbers;
    });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('requestLineDetails');
  }

  // to set dynamic input form-field for Item_Number based on selected Material Type
  setHeadingAndPlaceholderForItemNumberInputField() {
    switch (this.selectedMaterialType) {
      case 1: {
        this.itemNumberName = IRISRequestConstants.generalMaterialHeading;
        this.itemNumberNamePlaceholder = IRISRequestConstants.generalMaterialHeading;
        break;
      }
      case 2: {
        this.itemNumberName = IRISRequestConstants.fixedAssetHeading;
        this.itemNumberNamePlaceholder = IRISRequestConstants.fixedAssetPlaceholder;
        break;
      }
      case 4: {
        if (this.selectedDisposalMethodName === 'Other') {
          this.itemNumberName = IRISRequestConstants.financialAdjustmentHeading;
          this.itemNumberNamePlaceholder = IRISRequestConstants.financialAdjustmentPlaceholder;
        }
        else {
          this.itemNumberName = IRISRequestConstants.itemNumberHeading;
          this.itemNumberNamePlaceholder = IRISRequestConstants.itemNumberPlaceholder;
        }

        break;
      }
      default: {
        this.itemNumberName = IRISRequestConstants.generalMaterialHeading;
        this.itemNumberNamePlaceholder = IRISRequestConstants.generalMaterialHeading;
        break;
      }
    }
  }

  onItemNumberChange(itemNumber: string) {
    if (itemNumber.length >= 3) {
      this.materialNumberList = this.materialNumberListSource.filter((Matnum: string) => (Matnum.includes(itemNumber.toLowerCase())));
    }
    else {
      this.autocomplete.toggle(false);
    }
  }
  onItemNumberClick(itemNumber: string) {
    let list: string[] = this.materialNumberListSource.filter((Matnum: string) => (Matnum.includes(itemNumber.toLowerCase())));
    if (list.length === 1) {
      this.irisRequestService.getMaterialInfo(itemNumber).subscribe((materialManagementInvItem: MaterialManagementInventoryDto) => {
        console.log(materialManagementInvItem);
        this.addIemForm.controls.description.patchValue(materialManagementInvItem.materialDescription);
        this.addIemForm.controls.manufacturer.patchValue('');
        this.addIemForm.controls.manufacturerPartNum.patchValue(materialManagementInvItem.materialPartNumber);
        this.addIemForm.controls.unitCost.patchValue(Number(materialManagementInvItem.unitPrice));
      });
    }
  }
  // create and cancel actions
  action(actionType: string) {
    switch (actionType) {
      case 'cancel': {
        this.commonService.goBack();
        break;
      }
      case 'save': {
        this.createItem();
        break;
      }
    }
  }

  // to create new line item
  createItem() {
    if (this.addIemForm.valid) {
      this.clearError();
      this.addItem();
    } else {
      const msg = IRISRequestConstants.itemFormsError;
      this.commonService.showNotificationMessage(msg, 'error', 'center');
      this.checkFormValidation();
    }
  }

  createPayloadData() {
    let requestDetails = JSON.parse(sessionStorage.getItem('requestDetails')!);
    let userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
    let generalMaterial = this.addIemForm.controls['generalMaterial'].value;
    let description = this.addIemForm.controls['description'].value;
    let manufacturer = this.addIemForm.controls['manufacturer'].value;
    let manufacturerPartNum = this.addIemForm.controls['manufacturerPartNum'].value;
    let inventoryCond: number = this.financialMatAdjustments_IO_ED ? this.materialTypeList[0].id : <any>(this.addIemForm.controls['inventoryCond'].value);
    let unitCost = <number>this.addIemForm.controls['unitCost'].value;
    let itemQuantity = <number>this.addIemForm.controls['itemQuantity'].value;
    let formData: any = new FormData();
    if (this.currentStatus !== 'create') {
      formData.append('id', this.requestLineID!);
    }
    formData.append('requestId', this.requestId!);
    formData.append('itemNumber', generalMaterial!);
    formData.append('itemDescription', description!);
    formData.append('manufacturerName', manufacturer);
    formData.append('manufacturerPartNumber', manufacturerPartNum);
    formData.append('inventoryConditionId', inventoryCond);
    formData.append('itemQty', Number(itemQuantity));
    formData.append('unitCost', Number(unitCost));
    formData.append('estimatedCost', Number(this.totalEstimateCost));
    formData.append('irismaterialTypeId', Number(this.selectedMaterialType));
    formData.append('disposalMethodId', Number(this.selectedDisposalMethod));
    formData.append('createdBy', this.currentStatus === 'create' ? userMailID : requestDetails.createdBy);
    formData.append('createdDateTime', requestDetails.createdDateTime);
    formData.append('modifiedBy', userMailID);
    if (this.attachmentFileDetails.length) {
      for (let attachment of this.attachmentFileDetails!) {
        formData.append('attachments', attachment.file);
      }
    }
    return formData;
  }

  addItem() {
    const payload = this.createPayloadData();
    const url = IRISRoutingConstants.ItemAction;
    switch (this.currentStatus) {
      case 'create': {
        //  this.commonService.removeKey(payload, 'id');
        this.irisRequestService
          .addNewItem(payload)
          .subscribe((data: LineDetailsDto) => {
            if (data) {
              this.commonNavigationPath();
            }
          });
        break;
      }
      case 'edit': {
        this.irisRequestService
          .editLineItem(payload, this.requestLineID)
          .subscribe((data: any) => {
            if (data) {
              this.commonNavigationPath();
            }
          });
        break;
      }
    }
  }

  // to validate forms fields
  checkFormValidation(): boolean {
    if (this.addIemForm.get('generalMaterial')?.status === 'INVALID') {
      this.addIemForm.get('generalMaterial')?.markAsTouched();
      this.isMaterialError = true;
      this.hasError = true;
    }
    if (this.addIemForm.get('description')?.status === 'INVALID') {
      this.addIemForm.get('description')?.markAsTouched();
      this.isDespError = true;
      this.hasError = true;
    }
    if (this.addIemForm.get('manufacturer')?.status === 'INVALID') {
      this.addIemForm.get('manufacturer')?.markAsTouched();
      this.isManuError = true;
      this.hasError = true;
    }
    if (this.addIemForm.get('manufacturerPartNum')?.status === 'INVALID') {
      this.addIemForm.get('manufacturerPartNum')?.markAsTouched();
      this.isManuPartError = true;
      this.hasError = true;
    }
    if (this.addIemForm.get('inventoryCond')?.status === 'INVALID') {
      this.addIemForm.get('inventoryCond')?.markAsTouched();
      this.ismaterialTypeError = true;
      this.hasError = true;
    }
    if (this.addIemForm.get('unitCost')?.status === 'VALID') {
      if (Number(this.addIemForm.get('unitCost')?.value) === 0) {
        this.addIemForm.get('unitCost')?.markAsTouched();
        this.isCostError = true;
        this.hasError = true;
      } else {
        this.isCostError = false;
        this.hasError = false;
      }
    }
    if (this.addIemForm.get('itemQuantity')?.status === 'VALID') {
      if (Number(this.addIemForm.get('itemQuantity')?.value) === 0) {
        this.addIemForm.get('itemQuantity')?.markAsTouched();
        this.isQuantityError = true;
        this.hasError = true;
      } else {
        this.isQuantityError = false;
        this.hasError = false;
      }
    }
    if (this.hasError == true) {
      return false;
    }
    return true;
  }

  // remove validation
  clearError() {
    this.isMaterialError = false;
    this.isDespError = false;
    this.isManuError = false;
    this.isManuPartError = false;
    this.isCostError = false;
    this.isQuantityError = false;
  }

  // to calculate total estimate cost
  calculateEstimatedValue(event: any) {
    const unitPrice = <number>this.addIemForm.controls['unitCost'].value;
    if (unitPrice) {
      this.totalEstimateCost = Number(unitPrice * event.target.value);
    } else {
      this.totalEstimateCost = '';
    }
  }

  // get per unit cost- to calculate estimate cost
  getUnitCost(event: any) {
    const itemQuantity = <number>this.addIemForm.controls['itemQuantity'].value;
    if (itemQuantity) {
      this.totalEstimateCost = Number(itemQuantity * event.target.value);
    } else {
      this.totalEstimateCost = '';
    }
  }

  commonNavigationPath() {
    const url = IRISRoutingConstants.ItemAction;
    let navigate = this.router.navigate([`../${url}`], {
      state: {
        requestID: this.requestId,
        trackingNumber: this.trackingNumber,
      },
      relativeTo: this.route,
    });
    return navigate;
  }
}
