import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssetCodeFunctionConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/asset-code-functions/code-functions.constants';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { AssetCodeFunctionDto } from '../../models/code-functions.model';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';

@Injectable({
  providedIn: 'root',
})
export class AssetCodeFunctionService extends HttpAbstractService<AssetCodeFunctionDto> {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    super(http);
  }

  // to get the asset-code-function lists
  getAssetCodeFunctionGrid(): Observable<AssetCodeFunctionDto[]> {
    return this.httpUtilityService.get(`${AssetCodeFunctionConstants.codeFunction}`);
  }

  // to create new code-function
  createNewCodeFunction(data: AssetCodeFunctionDto): Observable<AssetCodeFunctionDto> {
    return this.httpUtilityService.post(`${AssetCodeFunctionConstants.codeFunction}`, data);
  }

  // to edit existing code-function
  editCodeFunction(data: AssetCodeFunctionDto, id: number): Observable<AssetCodeFunctionDto> {
    return this.httpUtilityService.put(`${AssetCodeFunctionConstants.codeFunction}/${id}`, data);
  }

  // to upload new code-function data from excel
  importExcelData(data: AssetCodeFunctionDto[]) {
    return this.httpUtilityService.post(`${AssetCodeFunctionConstants.importCodeFunction}`, data);
  }
}
