import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { SupplyBaseSpecialistConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/supply-base-specialist/supply-base-specialist.constants';
import { IRISSupplySpecialistGridDTO } from '../../models/iris-supply-base-specialist.model';
import { AssetMaterialCoordinatorsDTO } from '../../models/asset-Material-Coordinators.model';

@Injectable({
  providedIn: 'root',
})
export class IrisSupplyBaseSpecialistService extends HttpAbstractService<IRISSupplySpecialistGridDTO> {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    super(http);
  }

  // get supply-base-specialist data
  getSupplyBaseSpecialistGridData(): Observable<IRISSupplySpecialistGridDTO[]> {
    return this.httpUtilityService.get(`${SupplyBaseSpecialistConstants.supplyBaseApi}`);
  }
  
  // get supply-base-specialist data by ID
  getSupplyBaseSpecialistByID(id:number): Observable<IRISSupplySpecialistGridDTO[]> {
    return this.httpUtilityService.get(`${SupplyBaseSpecialistConstants.supplySpecialistById}/${id}`);
  }

  // edit|update supply-base-specialist 
  editSupplyBaseSpecialist(data: AssetMaterialCoordinatorsDTO, id: number): Observable<AssetMaterialCoordinatorsDTO> {
    return this.httpUtilityService.put(`${SupplyBaseSpecialistConstants.supplySpecialistById}/${id}`, data);
  }
}
