import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { IRISRoutingConstants } from 'src/app/core/constants/IRIS_Constants/IRIS-components-routing-constants';
import { BackOfficeAssetDto, SelectedGroupDTO, } from 'src/app/feature/setup/models/asset-group.model';
import { AssetLocationGridDTO } from 'src/app/feature/setup/models/asset-locations.model';
import { IRISDepartmentsGridDTO } from 'src/app/feature/setup/models/iris-departments.model';
import { AssetLocationService } from 'src/app/feature/setup/services/asset-locations/asset-location.service';
import { GroupService } from 'src/app/feature/setup/services/groups/group.service';
import { IrisDepartmetsService } from 'src/app/feature/setup/services/iris-department/iris-departmets.service';
import { CommonService } from 'src/app/core/services/common.service';
import { IRISRequestDto } from '../../models/iris-request-workflow/iris-request.model';
import { IrisRequestService } from '../../services/IRIS-Request/iris-request.service';
import { SharedConst } from 'src/app/core/constants/shared.constants';
import { environment } from 'src/environments/environment';
import { RoutingConstant } from 'src/app/core/constants/modules-routing.constants';
import { Title } from '@angular/platform-browser';
import { IRISRequestStateID } from 'src/app/core/constants/Request_State_Constants_ID\'s/requestStateID.constants';

@Component({
  selector: 'app-manage-iris',
  templateUrl: './manage-iris.component.html',
  styleUrls: ['./manage-iris.component.scss'],
  providers: [DatePipe],
})
export class ManageIrisComponent implements OnInit {
  header: string = 'Create New IRIS Request';
  justification: any = '';
  description: any = '';
  title!: any;
  irisRequestID!: number;
  actionName: string = '';
  trackingNo!: string;
  createdBy!: string;
  createdDateTime!: string;
  groupSelected: SelectedGroupDTO = {};
  departmentSelected: SelectedGroupDTO = {};
  locationSelected: SelectedGroupDTO = {};
  listOfGroups: BackOfficeAssetDto[] = [];
  listOfDepartments: IRISDepartmentsGridDTO[] = [];
  listOfLocations: AssetLocationGridDTO[] = [];
  requestStateId!: number
  createIRISForm = new FormGroup({
    title: new FormControl(<string | undefined>'', Validators.required),
    description: new FormControl(<string | undefined>'', Validators.required),
    justification: new FormControl(<string | undefined>'', Validators.required),
    assetGroup: new FormControl(Array<string | undefined>(''), Validators.required),
    department: new FormControl(Array<string | undefined>(''), Validators.required),
    locations: new FormControl(Array<string | undefined>(''), Validators.required),
  });
  public defaultDropdownName: SelectedGroupDTO = {
    id: 0,
    label: '',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private groupService: GroupService,
    private irisDepartmetsService: IrisDepartmetsService,
    private locationService: AssetLocationService,
    public commonService: CommonService,
    private irisRequestService: IrisRequestService,
    private titleService: Title
  ) {
    this.getDepartmentsLocationsGroupsRecords();
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (stateData && stateData['actionName'] === 'edit') {
      if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
        this.actionName = stateData['actionName'];
        this.irisRequestID = stateData['id'];
        this.title = stateData['title'];
        this.description = stateData['description'];
        this.justification = stateData['justification'];
        this.trackingNo = stateData['trackingNo'];
        this.requestStateId = stateData['requestStateId'];
        this.createdBy = stateData['createdBy'];
        this.createdDateTime = stateData['createdDateTime'];
        sessionStorage.setItem('IRISRequestEditDetails', JSON.stringify(stateData));
        this.bindSelectedAssetName(stateData['assetId']);
        this.bindSelectedLocationName(stateData['locationId']);
        this.bindSelectedDepartmentName(stateData['departmentId']);
      } else {
        let sessionState = JSON.parse(sessionStorage.getItem('IRISRequestEditDetails')!);
        if (!this.commonService.isEmptyOrUndefined(sessionState)) {
          this.actionName = sessionState.actionName;
          this.irisRequestID = sessionState.id;
          this.trackingNo = sessionState.trackingNo;
          this.title = sessionState.title;
          this.description = sessionState.description;
          this.requestStateId = sessionState.requestStateId;
          this.justification = sessionState.justification;
          this.bindSelectedAssetName(sessionState.assetId);
          this.bindSelectedLocationName(sessionState.locationId);
          this.bindSelectedDepartmentName(sessionState.departmentId);
        }
      }
      this.header = `Edit IRIS Request ${this.trackingNo}`;
    } else {
      this.actionName = 'create';
      this.requestStateId = IRISRequestStateID.openStateID;
      this.title = '';
      this.description = '';
      this.justification = '';
    }
  }

  ngOnInit() {
    if (this.actionName === 'edit') {
      this.titleService.setTitle('Edit IRIS');
    }
    if (!this.isIRES_Requestor()) {
      window.location.href = environment.azureAd.redirectUri;
    }
  }

  isIRES_Requestor(): boolean {
    let roles: string = !this.commonService.isNullOrEmptyOrUndefined(this.commonService.getUserRoles()) ? this.commonService.getUserRoles() : '';
    if (roles !== '') {
      let index = roles.split(',').indexOf('IRISRequestor');
      if (index >= 0) {
        return true;
      }
    }
    return false;
  }

  // get Departmnets, Locations and Groups records to bind in dropdown
  async getDepartmentsLocationsGroupsRecords() {
    let group = this.groupService.getIrisGroupsList();
    let department = this.irisDepartmetsService.getIrisDepartmentRecord();
    let location = this.locationService.getAssetLocationGrid();
    await forkJoin([group, department, location]).subscribe(
      ([groups, departments, locations]) => {
        sessionStorage.setItem('AssetList', JSON.stringify(groups));
        sessionStorage.setItem('departmentsList', JSON.stringify(departments));
        sessionStorage.setItem('locationsList', JSON.stringify(locations));
        this.listOfGroups = groups;
        this.listOfDepartments = departments;
        this.listOfLocations = locations;
      }
    );
  }

  // bind selected asset group value
  bindSelectedAssetName(id: number | undefined): void {
    let assetRecords: IRISRequestDto[] = JSON.parse(sessionStorage.getItem('AssetList')!);
    for (let x of assetRecords) {
      if (x['id'] == id) {
        this.groupSelected = {
          id: x['id'],
          label: String(x['label']),
        };
      }
    }
  }

  // bind selected location value
  bindSelectedLocationName(id: number | undefined): void {
    let locationRecords: IRISRequestDto[] = JSON.parse(sessionStorage.getItem('locationsList')!);
    for (let x of locationRecords) {
      if (x['id'] == id) {
        this.locationSelected = {
          id: x['id'],
          label: String(x['label']),
        };
      }
    }
  }

  // bind selected department value
  bindSelectedDepartmentName(id: number | undefined): void {
    let departmentsRecords: IRISRequestDto[] = JSON.parse(sessionStorage.getItem('departmentsList')!);
    for (let x of departmentsRecords) {
      if (x['id'] == id) {
        this.departmentSelected = {
          id: x['id'],
          label: String(x['label']),
        };
      }
    }
  }

  // action buttons: Cancel|Create|Edit
  actions(actionName: string) {
    switch (actionName) {
      case 'cancel': {
        this.commonService.goBack();
        break;
      }
      case 'create': {
        this.createUpdateIRISRequest('create');
        break;
      }
      case 'edit': {
        this.createUpdateIRISRequest('edit');
        break;
      }
    }
  }

  // create payload for create and edit actions
  createPayloadData() {
    const Tdate = new Date();
    const currentDate: any = new Date(Tdate + 'Z');
    let userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
    let loggedUserID = JSON.parse(sessionStorage.getItem('loggedUserID')!);
    let selectedGroup: any = this.createIRISForm.controls['assetGroup'].value;
    let selectedDepartment: any = this.createIRISForm.controls['department'].value;
    let selectedLocations: any = this.createIRISForm.controls['locations'].value;
    let justification: any = this.createIRISForm.controls['justification'].value;
    let title: any = this.createIRISForm.controls['title'].value;
    let description: any = this.createIRISForm.controls['description'].value;
    let payload: IRISRequestDto = {
      id: this.actionName === 'edit' ? this.irisRequestID : 0,
      trackingNumber: this.actionName === 'edit' ? this.trackingNo : '',
      order: 1,
      isActive: true,
      createdBy: this.actionName === 'edit' ? this.createdBy : userMailID,
      createdDateTime: this.actionName === 'edit' ? this.createdDateTime : currentDate,
      modifiedBy: userMailID,
      modifiedDateTime: currentDate,
      requestType: 7,
      requestStateId: this.requestStateId,
      justification: justification,
      description: description,
      title: title,
      departmentId: selectedDepartment?.id,
      assetLocationId: selectedLocations?.id,
      assetId: selectedGroup?.id,
      userOwner: loggedUserID,
    };
    return payload;
  }

  /**
   * requestStateId:
   * 1: open
   * 2:
   * 3:
   */

  //  to create|edit IRIS Request
  createUpdateIRISRequest(state: string) {
    let payload = this.createPayloadData();
    const url = IRISRoutingConstants.IRISOverview;
    switch (state) {
      case 'create': {
        this.commonService.removeKey(payload, 'id');
        this.commonService.removeKey(payload, 'trackingNumber');
        if (this.createIRISForm.valid) {
          this.irisRequestService
            .createIRISRequest(payload)
            .subscribe((data: any) => {
              if (data) {
                this.commonNavigationPath('toCreate', true, data);
              }
            });
        }
        break;
      }
      case 'edit': {
        this.irisRequestService
          .editupdateRequest(payload, this.irisRequestID)
          .subscribe((data: IRISRequestDto) => {
            if (data) {
              this.commonNavigationPath('toViewEdit', false, data);
              const msg = `${this.trackingNo} ${SharedConst.editRequestMSG}`;
              this.commonService.showNotificationMessage(msg, 'success', 'center');
            }
          });
        break;
      }
    }
  }
  isValidForm(): boolean {
    let isValid: boolean = this.isValidDropdown('assetGroup') && this.isValidDropdown('department') &&
      this.isValidDropdown('locations');
    return isValid;
  }
  isValidDropdown(dropdownName: string): boolean {
    let _isValid: boolean = true;
    const value = this.createIRISForm.get(dropdownName)?.value as { id?: number };
    if (value && typeof value === 'object') {
      if (Object.keys(value).length === 0) {
        _isValid = false;
      }
      else if (value.id === null) {
        _isValid = false;
      }
    }
    return _isValid;
  }

  // common path to navigate on Overview Page
  commonNavigationPath(action: string, isCreated: boolean, data: IRISRequestDto) {
    const url = IRISRoutingConstants.IRISOverview;
    let navigate = this.router.navigate([`../${url}`], {
      state: {
        actionToDo: action,
        isCreate: isCreated,
        requestID: data.id,
        trackingNumber: data?.trackingNumber,
        createdBy: data?.createdBy,
        createdDateTime: data?.createdDateTime,
      },
      relativeTo: this.route,
    });
    return navigate;
  }
}
