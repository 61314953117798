<h2 class="heading fixed-top"></h2>
<section class="userguide-content-box">
    <div class="TitleContainer">
        <div>
            <h1>Hello there, Welcome to MMS IRIS Support</h1>
            <h1>How can we help you today?</h1>
        </div>
        <div>
            <img src="./../../../../../assets/Images/Header.svg" alt="">
        </div>
    </div>

    <div class="card">
        <a href="">
            <div class="LinkCard">
                <img src="./../../../../../assets/Images/Createirisrequest.svg" alt="">
                <h4>How to create an IRIS Request</h4>
            </div>
        </a>
        <a href="">
            <div class="LinkCard">
                <img src="./../../../../../assets/Images/TrackIrisRequest.svg" alt="">
                <h4>How to track your Request</h4>
            </div>
        </a>
    </div>
    <div class="UsageGuideHeader">
        System Usage Guide
    </div>
    <div class="ContentParentContainer">

        <!-- Summary Container -->
        <div class="SummaryContainer">
            <div class="panelbar-wrapper">
                <kendo-panelbar class="border-none">
                    <kendo-panelbar-item >
                        <ng-template kendoPanelBarItemTitle class="SubHeader">
                            <h4>Getting Started with IRIS</h4></ng-template>
                        <kendo-panelbar-item title="About IRIS"></kendo-panelbar-item>
                        <kendo-panelbar-item title="Accessing the Tool"></kendo-panelbar-item>
                        <kendo-panelbar-item title="How Does IRIS Work"></kendo-panelbar-item>
                        <kendo-panelbar-item title="My IRIS"></kendo-panelbar-item>
                    </kendo-panelbar-item>
                    <kendo-panelbar-item >
                        <ng-template kendoPanelBarItemTitle class="SubHeader">
                            <h4>Creating a New IRIS Request</h4></ng-template>
                        <kendo-panelbar-item title="Process Overview"></kendo-panelbar-item>
                        <kendo-panelbar-item title="Filling in the Details"></kendo-panelbar-item>
                        <kendo-panelbar-item title="Selecting the Material Type"></kendo-panelbar-item>
                        <kendo-panelbar-item title="Proposing a Disposal Method"></kendo-panelbar-item>
                        <kendo-panelbar-item title="Adding Attachments"></kendo-panelbar-item>
                        <kendo-panelbar-item title="Selecting Technical Reviewer and Technical Endorser"></kendo-panelbar-item>
                    </kendo-panelbar-item>
                    <kendo-panelbar-item >
                        <ng-template kendoPanelBarItemTitle class="SubHeader">
                            <h4>Tracking your Request</h4></ng-template>
                        <kendo-panelbar-item title="IRIS Screen"></kendo-panelbar-item>
                        <kendo-panelbar-item title="Request Status"></kendo-panelbar-item>
                        <kendo-panelbar-item title="Approval Flow"></kendo-panelbar-item>
                    </kendo-panelbar-item>
                </kendo-panelbar>
            </div>
        </div>


        <!-- Content Container -->
        <div class="ContentContainer">
            <div class="Header">Getting Started with IRIS</div>
            <div class="SubHeader">About IRIS</div>
            <p>Investment Recovery (IR) is the process used to manage the disposal of Chevron property, plant and
                equipment (“PP&amp;E”) and materials and supplies (“M&amp;S”) that are no longer required by their
                primary owner or user group. The objectives of the investment recovery process include:</p>
            <div class="SubHeader">Accessing the Tool</div>
            <p>
                You can access the tool via the following URL:
            </p>
            <a href="/MMS_IRIS/">https://abu-xapps-dev.chevron.digital/MMS_IRIS</a>
            <p>
                The system needs no sign-in, providing you’re using a Chevron device.<br>
                If you require a privileged level of access, please contact the system administrator. <br>
                If you need to provide access for an external vendor without a CAI, please contact to assist in the
                creation of a login.<br>
                Once they’ve been added, they should be able to access the system from the url provided above.
            </p>
            <div class="SubHeader">How Does IRIS Work</div>
            <div class="images">
                <img width="100%" src="../../../../../assets/Images/UserGuide/HowDoseItWork.svg" alt="">
            </div>
        </div>

        <!-- Contact Parent Container -->
        <div class="ContactParentContainer">
            <div class="ContactContainer">
                <div class="SubHeader">Facing Issues? Contact Support</div>
                <div class="small">If you are having any criticial process issues or need support in using the system,
                    please contact the system administrator using the details given below</div>
                <div class="contact" style="margin-top: 10px;">IRIS Coordinator</div>
                <div class="contact">abuirc@chevron.com</div>
            </div>
        </div>
    </div>
</section>