<h2 class="heading fixed-top"></h2>
<section class="userguide-content-box">
    <app-user-guide-header></app-user-guide-header>
    <div class="UsageGuideHeader">
        System Usage Guide
    </div>
    <div class="ContentParentContainer">

        <!-- Summary Container -->
        <app-system-usage-guide (HelpGettingStarted)="scrollTo_HelpGettingStarted($event)" ></app-system-usage-guide>

        <!-- Content Container -->
        <div class="ContentContainer">
            <div class="Header">Getting Started with IRIS</div>
            <div class="SubHeader" #about_iris>About IRIS</div>
            <p>Investment Recovery (IR) is the process used to manage the disposal of Chevron property, plant and
                equipment (“PP&amp;E”) and materials and supplies (“M&amp;S”) that are no longer required by their
                primary owner or user group. The objectives of the investment recovery process include:</p>
            <ul>
                <li>Improve space utilisation</li>
                <li>Partial recovery of costs on idle assets</li>
                <li>Reduce Inventory levels</li>
                <li>Improve short term cash flow</li>
                <li>Reduce Warehouse operating costs</li>
                <li>Tax minimisation</li>
            </ul>
            <p>
                When considering the disposal (sale, scrap, donation) of PP&E or M&S, contact the Materials and
                Logistics Team to seek guidance on how to proceed before any discussions or negotiations with a
                potential 3rd party recipient. The IRC will assist in outlining the appropriate disposals pathway as
                well as ensuring all segregation of duties and compliance requirements are maintained.
            </p>
            <div class="SubHeader" #accessing_tool>Accessing the Tool</div>
            <p>
                You can access the tool via the following URL:
            </p>
            <a href="/MMS_IRIS/">{{url}}</a>
            <p>
                The system needs no sign-in, providing you’re using a Chevron device.<br>
                If you require a privileged level of access, please contact the system administrator. <br>
                If you need to provide access for an external vendor without a CAI, please contact to assist in the
                creation of a login.<br>
                Once they’ve been added, they should be able to access the system from the url provided above.
            </p>
            <div class="SubHeader" #how_does_iris_work>How Does IRIS Work</div>
            <div>
                <img src="./../../../../../assets/Images/how_does_iris_work.PNG" alt="">
            </div>
            <div class="SubHeader" #my_iris id="my_iris">My IRIS</div>
            <p>The My IRIS screen is where you will get a complete overview of all the requests raised by you and the
                various statuses depending on where they are within the request flow.You can click on IRIS ID to view
                more details for that request.</p>
            <div>
                <img src="./../../../../../assets/Images/My_IRIS.PNG" alt="">
            </div>
        </div>

        <!-- Contact Parent Container -->
        <app-contact-support></app-contact-support>
    </div>
</section>