import { BaseDto } from './base.model';
import { BusinessUnitDto } from './bussinessunits.model';
import { CommercialApprovarMMSUserDTO } from './commercial-approvar-MMSUser.model';

export class JOAThresholGridDTO {
  assetMaterialCoordinators?: [] | undefined;
  businessUnitId?: number | undefined;
  commercialUser?: string | undefined;
  coordinatorUser?: string | undefined;
  createdBy?: string | undefined;
  createdDateTime?: string | undefined;
  id?: number | undefined;
  isActive?: boolean | undefined | null;
  label?: string | undefined;
  modifiedBy?: string | undefined;
  modifiedDateTime?: string | undefined;
  order?: number | undefined;
  userDetailsDto?: [] | undefined;
  assetJoatThresholds?: CommercialApprovarMMSUserDTO[];
  isWaiverinPlace?: boolean | undefined;
  joAmount?: number | undefined;
  businessUnit!: BusinessUnitDto;
}
export class AssetJOAtThresholdDto extends BaseDto {
  id!: number;
  assetGroupId!: number;
  joAmount?: number;
  commercialApprovarMMSUserID?: number;
  isWaiverinPlace!: boolean;
  currancy?: string;
  commercialApprovarUserList!: UserDetailsDto[];
  businessUnitId!: number;
  businessUnitName!: string;
}

export class UserDetailsDto {
  id!: number;
  username!: string;
}