<div class="contentBox setup-page">
  <h1 class="font-size-h4 secondary-color">MMS Back Office</h1>
  <h2 class="font-size-h6 bold mt-20 borderMarginPadding">List Management</h2>
  <p class="mb-15 f-16">Please choose from the below lists to manage:</p>
  <ul class="list">
    <li>
      <a (click)="navigate('/MMS_BackOffice/AssetCodeFunctions')">
        Asset Code Functions
      </a>
    </li>
    <li>
      <a (click)="navigate('/MMS_BackOffice/AssetLocations')">
        Asset Locations
      </a>
    </li>
    <li><a (click)="navigate('/MMS_BackOffice/MMSGroup')"> Group </a></li>
    <li>
      <a (click)="navigate('/MMS_BackOffice/IRISAssetJOAThreshold')">
        IRIS Asset JOA Threshold
      </a>
    </li>
    <li>
      <a (click)="navigate('/MMS_BackOffice/IRISAssetMaterialCoOrdinator')">
        IRIS Supply Base Specialist
      </a>
    </li>
    <li>
      <a (click)="navigate('/MMS_BackOffice/IRISAuctioneers')">
        IRIS Auctioneers
      </a>
    </li>
    <li>
      <a (click)="navigate('/MMS_BackOffice/IRISDepartments')">
        IRIS Department
      </a>
    </li>
    <li><a (click)="navigate('/MMS_BackOffice/IRISDOAApproval')"> IRIS DOA Approver </a></li>
    <li><a (click)="navigate('/MMS_BackOffice/IRISDOALevels')"> IRIS DOA levels </a></li>
    <li><a (click)="navigate('/MMS_BackOffice/ManageNotifications')"> Manage Notifications </a></li>
  </ul>

</div>
