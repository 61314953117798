<div class="iris-locationDetail-container j-c-s main-container-padding">
    <h1 class="mb-20 setup-heading">{{heading}}</h1>
    <div class="mr-20 float-right">
        <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">Cancel</button>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="action('save')">Save</button>
    </div>
    <section class="mt-20">
        <!-- Label -->
        <div class="mt-20 w-100 d-flex">
            <div class="w-15">
                <kendo-label>Label <span style="color:red"> *</span></kendo-label>
                <br />
                <kendo-formerror *ngIf="requiredLabel">Required field!</kendo-formerror>
            </div>
            <div class="w-25"><kendo-textbox [required]="true" [maxlength]="50" [(ngModel)]="label"></kendo-textbox>
            </div>
        </div>
        <!-- Order -->
        <div class="mt-10 w-100 d-flex">
            <div class="w-15"><kendo-label> Order </kendo-label></div>
            <div class="w-25"><kendo-textbox [(ngModel)]="order"></kendo-textbox></div>
        </div>
        <!-- Is Active -->
        <div class="mt-10 w-100 d-flex">
            <div class="w-15"><kendo-label [for]="select"> Is Active </kendo-label></div>
            <div class="w-25"><input type="checkbox" [(ngModel)]="isActive" #select kendoCheckBox /></div>
        </div>
    </section>
    <!-- Sub Groups-->
    <section *ngIf="isNew" class="mt-20">
        <h6 class="font-size-h6">Sub Groups</h6>
        <div>
            <table class="group-table float-left" [class.onEdit]="isOnEdit">
                <thead>
                    <tr>
                        <th>Label</th>
                        <th style="width: 75px;"> Active</th>
                        <th style="width: 200px;"> Order</th>
                    </tr>
                </thead>
                <tbody *ngFor="let subGr of subGroups; let i=index">
                    <tr class=" group-items" [class.selected]="i===selectedSubGroup" (click)="onEditSubGroup(i)">
                        <td>
                            <kendo-textbox class="text-box" [maxlength]="50" [(ngModel)]="subGr.label"></kendo-textbox>
                        </td>
                        <td><input type="checkbox" #select kendoCheckBox [(ngModel)]="subGr.isActive" /></td>
                        <td><kendo-numerictextbox class="text-box" [format]="'0'" [decimals]="0" [(ngModel)]="subGr.order" [min]="0"
                                [spinners]="false"></kendo-numerictextbox></td>
                    </tr>
                    <tr class="group-items controls" *ngIf="i===selectedSubGroup">
                        <td colspan="3" style="position: relative;">
                            <div class="ControlActions">
                                <a class="SaveRowAction" (click)="submitSubGroup(i)"><i class="fa fa-check"></i></a>
                                <a class="CancelRowAction" (click)="exitSubGroup(i)"><i class="fa fa-times"></i></a>
                                <a class="DeleteRowAction" (click)="deleteSubGroup(i)"><i class="fas fa-trash"></i></a>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="3">
                            <div class="create-new-sub" (click)="addSubGroup()"><i class="fas fa-plus-circle"
                                    aria-hidden="true"></i> Create New Sub Group </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>
    <!-- Assets: Shared Email Addresses / Associated Users ...-->
    <section *ngIf="!isNew" class="pannel-expand mt-20">
        <kendo-panelbar>
            <kendo-panelbar-item *ngFor="let item of assetsSharedEmails; let itemIndex=index">
                <ng-template kendoPanelBarItemTitle>
                    <div class="assetTitle">{{item.title}}</div>
                </ng-template>
                <ng-template kendoPanelBarContent>
                    <div class="pl-35">
                        <!-- Assets: Shared Email Addresses-->
                        <div class="shared-email-address">Shared Email Addresses</div>
                        <div class="mt-10 w-100 d-flex">
                            <div class="w-50">
                                <kendo-textbox [maxlength]="256" [(ngModel)]="item.sharedEmail"></kendo-textbox>
                                <br />
                                <kendo-formerror *ngIf="!item.isValidEmail">Email expected!</kendo-formerror>
                            </div>
                            <div class="w-15">
                                <button kendoButton class="primary-button ml-30 f-size-150 emailbtn"
                                    (click)="AddSharedEmail(itemIndex,item.assetId,item.sharedEmail)">Add Email</button>
                            </div>
                        </div>
                        <br />
                        <kendo-grid [data]="item.sharedEmails" [style.width]="'99%'">
                            <kendo-grid-column title="Shared Email" field="sharedEmail"
                                class="w-50"></kendo-grid-column>
                            <kendo-grid-column title="" class="w-25">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <i class="primary-color fas fa-trash" (click)="deleteEmail(dataItem.id)"></i>
                                </ng-template>
                            </kendo-grid-column>
                        </kendo-grid>
                        <!-- Associated Users ...-->
                        <div class="shared-email-address">Associated Users ...</div>
                        <kendo-grid [style.width]="'99%'">
                            <kendo-grid-column title="Username"></kendo-grid-column>
                            <kendo-grid-column title="Name"></kendo-grid-column>
                            <kendo-grid-column title="Email"></kendo-grid-column>
                            <kendo-grid-column title="Mobile Phone"></kendo-grid-column>
                            <kendo-grid-column title="Is Active"></kendo-grid-column>
                        </kendo-grid>
                        <br />
                    </div>
                </ng-template>
            </kendo-panelbar-item>
        </kendo-panelbar>
    </section>
</div>