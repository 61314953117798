<div class="endorserActionConfirmationComponent">
    <div class="text-right" style="cursor: pointer;"><a (click)="close('no')"><i class="fa fa-times"></i></a>
    </div>
    <h2 class="page-title"> {{endorserActionName}} {{trackingNumber}} </h2>
 
    <!-- form fields -->
    <div class="mt-15">
        <form [formGroup]="endorserActionsForm">
            <!-- comment input field -->
            <div>
                <kendo-label [for]="comment" text="Comment">
                    <span class="mandatory"> * </span>
                </kendo-label>
                <kendo-textarea class="c-textarea" #comment [rows]="4" formControlName="comment"></kendo-textarea>
            </div>

            <!-- attachment input field -->
            <div class="pt-10 mt-10">
                <fieldset class="mb-20 fieldset" id="fieldset">
                    <legend class="font-size-xs">Attachments</legend>
                    <div class="fileuploadContainer">
                        <p class="text">Drop Files or Click to Upload</p>
                        <input class="fileupload" type="file" #fileInput (change)="onFilesUpload($event)"
                            accept="accptedFilesStr" formControlName="attachment" />
                    </div>
                    <!--  file details grid -->
                    <kendo-grid *ngIf="attachmentFileDetails && attachmentFileDetails.length" #myGrid
                        [data]="attachmentFileDetails" [hideHeader]="true" class="list-container" scrollable="none"
                        [style.width.px]="510">
                        <kendo-grid-column>
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <button kendoButton (click)="downloadFile(rowIndex)" look="flat" [icon]="'download'"
                                    class="download-button"></button>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column [width]="400">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span>{{ dataItem.fileName }}</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column>
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <button (click)="removeFile(rowIndex)" class="actionBTN">
                                    <span class="material-icons md-18">delete_forever</span>
                                </button>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </fieldset>
            </div>
        </form>
    </div>

    <!-- Action buttons -->
    <div class="actionBTNs">
        <button kendoButton class="secondary-border-button f-size-150 noButton" (click)="close('no')"> Cancel
        </button>
        <button kendoButton class="primary-button ml-10 f-size-150" (click)="submit()">
            Submit
        </button>
    </div>
</div>