import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { AssetJOAThresholdConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/asset-JOA-Threshold-limit/asset-joa-threshold-limit.constants';
import { AssetJOAtThresholdDto, JOAThresholGridDTO, UserDetailsDto } from '../../models/JOA-threshold-limit.model';

@Injectable({
  providedIn: 'root',
})
export class IrisAssetJoaThresholdLimitService extends HttpAbstractService<JOAThresholGridDTO> {
  constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
    super(http);
  }

  // get iris-asset-joa-threshold-limit data
  getJOAThresholdLimitGrid(): Observable<JOAThresholGridDTO[]> {
    return this.httpUtilityService.get(`${AssetJOAThresholdConstants.JOAThresholdAPI + AssetJOAThresholdConstants.getAssetList}`);
  }

  // get iris-asset-joa-threshold-limit data
  getJOAThresholdById(id: number): Observable<AssetJOAtThresholdDto> {
    return this.httpUtilityService.get(`${AssetJOAThresholdConstants.JOAThresholdAPI}/${id}`);
  }

  // edit|update joa-threshold-limit
  addJOAThreshold(data: AssetJOAtThresholdDto): Observable<AssetJOAtThresholdDto> {
    return this.httpUtilityService.post(`${AssetJOAThresholdConstants.JOAThresholdAPI}`, data);
  }

  // edit|update joa-threshold-limit
  editJOAThreshold(data: AssetJOAtThresholdDto, id: number): Observable<AssetJOAtThresholdDto> {
    return this.httpUtilityService.put(`${AssetJOAThresholdConstants.JOAThresholdAPI}/${id}`, data);
  }

  getCommercialUsers(): Observable<UserDetailsDto[]> {
    return this.httpUtilityService.get(`${AssetJOAThresholdConstants.JOAThresholdAPI + AssetJOAThresholdConstants.getCommercialUsersList}`)
  }
}
