<kendo-appbar class="app-head">

  <kendo-appbar-section>
    <div class="logo">
      <img src="./../assets/icons/CVX_Logo_Corp_Flat_Rev_RGB.png" alt="">
    </div>
  </kendo-appbar-section>

  <kendo-appbar-spacer></kendo-appbar-spacer>
  <kendo-appbar-section class="actions mr-20">
    <div class="logoutButton" (click)="navigate()"><a> <span class="fa fa-fw fa-sign-in"></span> log In</a></div>
  </kendo-appbar-section>

</kendo-appbar>
<br />
<br />
<section class="invalid-permissions">
  <h1 class="heading "><i class="fa fa-fw fa-info-circle"></i> Invalid Permissions</h1>
  <p class="m-0 mt-20"> You don't have permissions to access this screen. </p>
  <p class="m-0 mb-10">Please contact the administration team.</p>
  <a href="/">Click here to continue</a>
</section>