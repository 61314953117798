import { IRISDashboardGridDTO } from "./iris-dashboard.constants";


export const dashboardMockData = [
  {
    id: "IRIS000092",
    item: "Testing",
    status: "open",
    assignedToUserName: "Pradeep.Erumalla@chevron.com",
    assignedToUserEmail: "Pradeep Erumalla",
    createdByEmail: "Pradeep.Erumalla@chevron.com",
    createdByUserName: "Pradeep Erumalla",
    createdOn: "2023-09-11T12:32:53.437",
    age: "0 Days"
  },
  {
    id: "IRIS000093",
    item: "Testing",
    status: "Pending Auction",
    assignedToUserName: "Pradeep.Erumalla@chevron.com",
    assignedToUserEmail: "Pradeep Erumalla",
    createdByEmail: "Pradeep.Erumalla@chevron.com",
    createdByUserName: "Pradeep Erumalla",
    createdOn: "2023-09-11T12:32:53.437",
    age: "0 Days"
  }

];

export const requestFlowMockData = [
  {
    requestFlowNumber: 1,
    flow: [
      {
        id: 7,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: true,
        state: 6,
        stateName: 'Draft',
        comment: 'Added Line item',
      },
      {
        id: 8,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Approved',
        isApproved: true,
        state: 15,
        stateName: 'Pending Endorsment',
        comment: 'Approved Endorsement',
      },
      {
        id: 9,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Rejected',
        isApproved: false,
        state: 19,
        stateName: 'Pending Finance Update',
        comment: 'Reject',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 27,
        stateName: 'Pending Finance Review',
        comment: '',
      },
      {
        id: 10,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 14,
        stateName: 'Pending DOA Review',
        comment: '',
      },
      {
        id: 11,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 28,
        stateName: 'Pending JOA Review',
        comment: '',
      },
      {
        id: 12,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 29,
        stateName: 'Pending Transfer in SAP',
        comment: '',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 18,
        stateName: 'Disposed',
        comment: '',
      },
      {
        id: 7,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 6,
        stateName: 'Pending Bill of Sale',
        comment: '',
      },
      {
        id: 8,
        createdBy: 'null',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 30,
        stateName: 'Pending Removal Assignment',
        comment: '',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 31,
        stateName: 'Pending Removed from SAP',
        comment: '',
      }
    ]
  },
  {
    requestFlowNumber: 2,
    flow: [
      {
        id: 7,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: true,
        state: 6,
        stateName: 'Draft',
        comment: 'Added Line item',
      },
      {
        id: 8,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Recalled',
        isApproved: false,
        state: 15,
        stateName: 'Pending Endorsment',
        comment: 'Rejected Endorsement',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 19,
        stateName: 'Pending Finance Update',
        comment: '',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 27,
        stateName: 'Pending Finance Review',
        comment: '',
      },
      {
        id: 10,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 14,
        stateName: 'Pending DOA Review',
        comment: '',
      },
      {
        id: 11,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 28,
        stateName: 'Pending JOA Review',
        comment: '',
      },
      {
        id: 12,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 29,
        stateName: 'Pending Transfer in SAP',
        comment: '',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 18,
        stateName: 'Disposed',
        comment: '',
      },
      {
        id: 7,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 6,
        stateName: 'Pending Bill of Sale',
        comment: '',
      },
      {
        id: 8,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 30,
        stateName: 'Pending Removal Assignment',
        comment: '',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 31,
        stateName: 'Pending Removed from SAP',
        comment: '',
      }
    ]
  },
  {
    requestFlowNumber: 0,
    flow: [
      {
        id: 7,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: true,
        state: 6,
        stateName: 'Draft',
        comment: 'Added Line item',
      },
      {
        id: 8,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Approved',
        isApproved: true,
        state: 15,
        stateName: 'Pending Endorsment',
        comment: 'Approved',
      },
      {
        id: 9,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Approved',
        isApproved: true,
        state: 19,
        stateName: 'Pending Finance Update',
        comment: 'Finance update approved',
      },
      {
        id: 9,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Approved',
        isApproved: true,
        state: 27,
        stateName: 'Pending Finance Review',
        comment: 'Approved Finance Review',
      },
      {
        id: 10,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Approved',
        isApproved: true,
        state: 14,
        stateName: 'Pending DOA Review',
        comment: 'DOA Approved',
      },
      {
        id: 11,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Approved',
        isApproved: true,
        state: 28,
        stateName: 'Pending JOA Review',
        comment: 'Approved JOA',
      },
      {
        id: 12,
        createdBy: 'Sarra Essouri Hattab',
        createdDateTime: new Date(),
        assignedUser: 'Pandari Goud Methri',
        status: 'Approved',
        isApproved: true,
        state: 29,
        stateName: 'Pending Transfer in SAP',
        comment: 'Approved Transfer in SAP',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 18,
        stateName: 'Disposed',
        comment: '',
      },
      {
        id: 7,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 6,
        stateName: 'Pending Bill of Sale',
        comment: '',
      },
      {
        id: 8,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 30,
        stateName: 'Pending Removal Assignment',
        comment: '',
      },
      {
        id: 9,
        createdBy: '',
        createdDateTime: null,
        assignedUser: 'Pandari Goud Methri',
        status: '',
        isApproved: false,
        state: 31,
        stateName: 'Pending Removed from SAP',
        comment: '',
      }
    ]
  }
]
