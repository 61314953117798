import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { KendoModule } from 'src/app/modules/kendo.module';
import { IrisDashboardComponent } from '../components/iris-dashboard/iris-dashboard.component';
import { ManageIrisComponent } from '../components/manage-iris/manage-iris.component';
import { IRISOverviewComponent } from '../components/irisoverview/irisoverview.component';
import { ItemApprovalComponent } from '../components/irisoverview/item-approval/item-approval.component';
import { IrisActionComponent } from '../components/irisoverview/iris-action/iris-action.component';
import { OnlynumberDirective } from '../directives/onlynumber.directive';
import { AgePipe } from 'src/app/core/pipe/age.pipe';
import { RejectApproveRecallPopupComponent } from '../components/reject-approve-recall-popup/reject-approve-recall-popup.component';
import { AddNewItemComponent } from '../components/add-new-item/add-new-item.componentl';
import { BulkItemAdditionComponent } from '../components/bulk-item-addition/bulk-item-addition.component';

@NgModule({
  declarations: [
    IrisDashboardComponent,
    ManageIrisComponent,
    IRISOverviewComponent,
    ItemApprovalComponent,
    IrisActionComponent,
    AddNewItemComponent,
    AgePipe,
    OnlynumberDirective,
    RejectApproveRecallPopupComponent,
    BulkItemAdditionComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    KendoModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    IrisDashboardComponent,
    ManageIrisComponent,
    IRISOverviewComponent,
    ItemApprovalComponent,
    IrisActionComponent,
    AddNewItemComponent,
    BulkItemAdditionComponent
  ],
  providers: [],
})
export class IRISModule { }
