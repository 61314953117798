<div class="add_new_item_container">
  <div class="content-top-action d-flex a-center j-c-s jcsb mb-20">
    <h1><a class="c-left"><span class="fa fa-fw fa-angle-left" (click)="commonService.goBack()"></span></a>
      IRIS Request {{trackingNumber}}
      <span class="fa fa-fw fa-angle-right"></span>
    </h1>
    <!-- Action buttons -->
    <div class="float-right">
      <button kendoButton class="border-button ml-20 f-size-150" (click)="commonService.goBack()">
        Cancel
      </button>
      <button kendoButton class="primary-button ml-20 f-size-150" (click)="createItem()">
        Save
      </button>
    </div>
  </div>

  <form [formGroup]="addIemForm">
    <!-- Item Details -->
    <fieldset class="fieldset">
      <legend class="fieldset-legend">Item Details</legend>
      <!-- General Material -->
      <div class="mt-20 w-40">
        <kendo-label [text]="itemNumberName">
          <span class="mandatoryIcon" *ngIf="!financialMatAdjustments_IO_ED">*</span>
        </kendo-label>
        <kendo-textbox *ngIf="!financialMatAdjustments_IO_ED" [placeholder]="itemNumberNamePlaceholder"
          formControlName="generalMaterial" [required]="isMaterialError"
          [ngClass]="{'input-error': addIemForm.controls.generalMaterial.status == 'INVALID' && (addIemForm?.get('generalMaterial')?.dirty || addIemForm?.get('generalMaterial')?.touched)}"></kendo-textbox>
        <kendo-autocomplete #autocomplete [virtual]="virtual" *ngIf="financialMatAdjustments_IO_ED" [data]="materialNumberList" [filterable]="true"
          (filterChange)="onItemNumberChange($event)" [placeholder]="itemNumberNamePlaceholder"
          formControlName="generalMaterial" (valueChange)="onItemNumberClick($event)" [required]="isMaterialError"
          [ngClass]="{'input-error': addIemForm.controls.generalMaterial.status == 'INVALID' && (addIemForm?.get('generalMaterial')?.dirty || addIemForm?.get('generalMaterial')?.touched)}"></kendo-autocomplete>
        <div *ngIf="isMaterialError">
          <kendo-formerror>Required field!</kendo-formerror>
        </div>
      </div>
      <!-- Item Description -->
      <div class="mt-15 w-40">
        <kendo-label text="Item Description">
          <span class="mandatoryIcon">*</span>
        </kendo-label>
        <kendo-textarea [rows]="5" [maxlength]="500" resizable="none" formControlName="description"
          [(ngModel)]="despLength" [required]="isDespError" [ngClass]="{'input-error': addIemForm.controls.description.status == 'INVALID' 
          && (addIemForm?.get('description')?.dirty || addIemForm?.get('description')?.touched)}"></kendo-textarea>
        <kendo-formerror *ngIf="isDespError">Please enter a item description</kendo-formerror>
        <p class="Character-count" id="count">Character count: {{ 100 - despLength?.length}}</p>
      </div>

      <div class="d-flex mt-15">
        <!-- Manufacturer Name -->
        <div class="w-40 mr-20">
          <kendo-label text="Manufacturer Name">
            <span class="mandatoryIcon">*</span>
          </kendo-label>
          <kendo-textbox formControlName="manufacturer" [required]="isManuError" [ngClass]="{'input-error': addIemForm.controls.manufacturer.status == 'INVALID' 
          && (addIemForm?.get('manufacturer')?.dirty || addIemForm?.get('manufacturer')?.touched)}"></kendo-textbox>
          <kendo-formerror *ngIf="isManuError">Please enter a OEM name</kendo-formerror>
        </div>
        <!-- Manufacturer Part Number -->
        <div class="w-40 ml-20">
          <kendo-label text="Manufacturer Part Number">
            <span class="mandatoryIcon">*</span>
          </kendo-label>
          <kendo-textbox formControlName="manufacturerPartNum" [required]="isManuPartError"
            [ngClass]="{'input-error': addIemForm.controls.manufacturerPartNum.status == 'INVALID' 
          && (addIemForm?.get('manufacturerPartNum')?.dirty || addIemForm?.get('manufacturerPartNum')?.touched)}"></kendo-textbox>
          <kendo-formerror *ngIf="isManuPartError">Please enter a OEM part number</kendo-formerror>
        </div>
      </div>
      <!-- Inventory Condition -->
      <div class="mt-15 w-40" *ngIf="!financialMatAdjustments_IO_ED">
        <kendo-label text="Inventory Condition">
          <span class="mandatoryIcon">*</span>
        </kendo-label>
        <kendo-dropdownlist class="mt-5" [data]="materialTypeList" formControlName="inventoryCond"
          [defaultItem]="{ label: 'Select Material Type', id: null }" [textField]="'label'" [valueField]="'id'"
          [valuePrimitive]="true" [required]="ismaterialTypeError" [ngClass]="{'input-error': addIemForm.controls.inventoryCond.status == 'INVALID' 
          && (addIemForm?.get('inventoryCond')?.dirty || addIemForm?.get('inventoryCond')?.touched)}">
        </kendo-dropdownlist>
        <kendo-formerror *ngIf="isCostError">Please select material type </kendo-formerror>
      </div>
    </fieldset>

    <!--- Recovery Details -->
    <fieldset class="fieldset" style="margin-top: 3px;">
      <legend class="fieldset-legend">Recovery Details</legend>
      <div class="d-flex mt-15">
        <!-- Unit Cost -->
        <div class="w-40 mr-20">
          <kendo-label text="Unit Cost">
            <span class="mandatoryIcon">*</span>
          </kendo-label>
          <kendo-textbox formControlName="unitCost" [required]="isCostError" [ngClass]="{'input-error': (addIemForm.controls.unitCost.status == 'VALID' && addIemForm.controls.unitCost.value == 0) 
          && (addIemForm?.get('unitCost')?.dirty || addIemForm?.get('unitCost')?.touched)}"
            (keyup)="getUnitCost($event)" onlyNumbersFields></kendo-textbox>
          <kendo-formerror *ngIf="isCostError">Please enter cost per unit</kendo-formerror>
        </div>
        <!-- Item Quantity -->
        <div class="w-40 ml-20">
          <kendo-label text="Item Quantity">
            <span class="mandatoryIcon">*</span>
          </kendo-label>
          <kendo-textbox formControlName="itemQuantity" [required]="isQuantityError" [ngClass]="{'input-error': (addIemForm.controls.itemQuantity.status == 'VALID' && addIemForm.controls.itemQuantity.value == 0)
          && (addIemForm?.get('itemQuantity')?.dirty || addIemForm?.get('itemQuantity')?.touched)}"
            (keyup)="calculateEstimatedValue($event)" onlyNumbersFields>
          </kendo-textbox>
          <kendo-formerror *ngIf="isQuantityError"> Please enter a positive quantity </kendo-formerror>
        </div>
      </div>
      <!-- Total Estimated Cost -->
      <div class="w-40 mt-15">
        <kendo-label text="Total Estimated Cost"> </kendo-label>
        <kendo-textbox required [(ngModel)]="totalEstimateCost" [ngModelOptions]="{standalone: true}"
          [disabled]="true"></kendo-textbox>
      </div>
    </fieldset>
  </form>
</div>
