import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IRISAuctioneersGridDTO } from '../../../models/iris-auctioneers.model';
import { IrisAuctioneersService } from '../../../services/iris-auctioneer/iris-auctioneers.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-iris-auctioneers-details',
  templateUrl: './iris-auctioneers-details.component.html',
  styleUrls: ['./iris-auctioneers-details.component.scss'],
  providers: [DatePipe]
})
export class IRISAuctioneersDetailsComponent implements OnInit, OnDestroy {
  detailsHeading: string = '';
  auctioneerName!: string;
  contactName!: string;
  email!: string;
  auctioneerAddress!: string;
  JDEAddress!: number;
  createdBy!: string;
  createdDate: string = '';
  actionStatus!: string;
  modifiedBy!: string;
  auctioneerID!: number;
  isActive!: boolean;
  order!: number;
  hasAuctioneerNameError: boolean = false;
  hasContactNameError: boolean = false;
  hasEmailError: boolean = false;
  prevAuctioneerName: string = '';
  prevEmail: string = '';
  hasInvalidEmailError: boolean = false;
  auctioneerForm = new FormGroup({
    auctioneerName: new FormControl(<string | undefined>(''), Validators.required),
    contactName: new FormControl(<string | undefined>(''), Validators.required),
    email: new FormControl(<string | undefined>(''), [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), Validators.required]),
    auctioneerAddress: new FormControl(<string | undefined>('')),
  })
  auctioneerList: { auctioneerName: string | undefined; email: string | undefined; }[] = [];

  constructor(
    private router: Router,
    private commonService: CommonService,
    private location: Location,
    private auctioneersService: IrisAuctioneersService,
    public datepipe: DatePipe,
    private titleService: Title
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    const heading = 'New IRIS Auctioneer'
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.actionStatus = stateData['actionName'];
      this.detailsHeading = stateData['actionName'] == 'edit' ? stateData['auctioneerName'] : heading;
      this.auctioneerID = stateData['id'];
      this.auctioneerName = stateData['auctioneerName'];
      this.contactName = stateData['contactName'];
      this.email = stateData['email'];
      this.auctioneerAddress = stateData['auctioneerAddress'];
      this.JDEAddress = stateData['jdeAddressBookNumber'];
      this.isActive = stateData['isActive'];
      this.order = stateData['order'];
      this.createdBy = stateData['createdBy'];
      this.createdDate = stateData['createdDateTime'];
      this.modifiedBy = stateData['modifiedBy'];
      sessionStorage.setItem('IRISAuctioneersDetails', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('IRISAuctioneersDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.actionStatus = sessionState.actionName;
        this.auctioneerName = sessionState.auctioneerName;
        this.contactName = sessionState.contactName;
        this.email = sessionState.email;
        this.auctioneerAddress = sessionState.auctioneerAddress;
        this.JDEAddress = sessionState.jdeAddressBookNumber;
        this.createdBy = sessionState.createdBy;
        this.createdDate = sessionState.createdDate;
        this.modifiedBy = sessionState.modifiedBy;
      }
    }
  }

  ngOnInit() {
    if (this.actionStatus === 'edit') {
      this.titleService.setTitle(this.email);
    }
    this.auctioneersRecords();
    if (this.actionStatus === 'edit') {
      this.prevAuctioneerName = this.auctioneerName;
      this.prevEmail = this.email;
    }
  }
  auctioneersRecords() {
    this.auctioneersService
      .getIRISAuctioneersGrid()
      .subscribe((data: IRISAuctioneersGridDTO[]) => {
        if (data) {
          this.auctioneerList = data.map((auc: IRISAuctioneersGridDTO) => { return { auctioneerName: auc.auctioneerName, email: auc.email } });
        }
      });
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('IRISAuctioneersDetails');
  }

  // get auctioneer-form-values 
  get af() { return this.auctioneerForm.controls; }

  // action buttons save || cancel
  action(actionType: string) {
    switch (actionType) {
      case 'cancel': {
        this.location.back();
        break;
      }
      case 'save': {
        this.createEditAuctioneer();
        break;
      }
    }
  }

  // to Create | Edit auctioneer
  createEditAuctioneer() {
    this.cleareError();
    const Tdate = new Date();
    const currentDate: any = new Date(Tdate + 'Z');
    const userName = this.commonService.getUserFullName();
    const createdBy: string = this.createdBy ? this.createdBy : userName;
    const payload: IRISAuctioneersGridDTO = {
      id: this.auctioneerID,
      auctioneerAddress: this.auctioneerAddress,
      auctioneerName: this.auctioneerName,
      contactName: this.contactName,
      email: this.email,
      isActive: this.isActive,
      order: this.order,
      createdBy: createdBy,
      createdDateTime: currentDate,
      modifiedBy: userName,
    };
    if (this.auctioneerForm.status === 'INVALID') {
      this.checkFormValidation();
    } else {
      this.cleareError();
      switch (this.actionStatus) {
        case 'create': {
          if (this.isDuplicateAuctioneerName()) {
            this.commonService.showNotificationMessage('Auctioneer Name already Exists.', 'error', 'center');
          }
          else if (this.isDuplicateAuctioneerEmail()) {
            this.commonService.showNotificationMessage('Auctioneer Email already Exists.', 'error', 'center');
          }
          else if (this.auctioneerForm.valid) {
            this.commonService.removeKey(payload, 'id');
            this.auctioneersService
              .createIRISAuctioneers(payload)
              .subscribe((data: IRISAuctioneersGridDTO) => {
                if (data) {
                  this.commonService.successUpdateMSG('IRIS Auctioneers', 'create');
                  this.location.back();
                }
              });
          }
          break;
        }
        case 'edit': {
          if (this.isDuplicateAuctioneerName()) {
            this.commonService.showNotificationMessage('Auctioneer Name already Exists.', 'error', 'center');
          }
          else if (this.isDuplicateAuctioneerEmail()) {
            this.commonService.showNotificationMessage('Auctioneer Email already Exists.', 'error', 'center');
          }
          else {
            this.auctioneersService
              .editIRISAuctioneers(payload, this.auctioneerID)
              .subscribe((data: IRISAuctioneersGridDTO) => {
                if (data) {
                  this.commonService.successUpdateMSG('IRIS Auctioneers', 'edit');
                  this.location.back();
                }
              });
          }
        }
      }
    }
  }

  isDuplicateAuctioneerName() {
    let auctioneers: { auctioneerName: string | undefined; email: string | undefined; }[] = this.auctioneerList.filter((item: { auctioneerName: string | undefined; email: string | undefined; }) =>
      (item.auctioneerName?.toLowerCase() === this.auctioneerName?.toLowerCase()));
    if (this.actionStatus === 'edit' && auctioneers.length > 0 && this.prevAuctioneerName !== this.auctioneerName) {
      return true;
    }
    if (this.actionStatus === 'create' && auctioneers.length > 0) {
      return true;
    }
    return false;
  }

  isDuplicateAuctioneerEmail() {
    let auctioneers: { auctioneerName: string | undefined; email: string | undefined; }[] = this.auctioneerList.filter((item: { auctioneerName: string | undefined; email: string | undefined; }) =>
      (item.email?.toLowerCase() === this.email.toLowerCase()));
    if (this.actionStatus === 'edit' && auctioneers.length > 0 && this.prevEmail !== this.email) {
      return true;
    }
    if (this.actionStatus === 'create' && auctioneers.length > 0) {
      return true;
    }
    return false;
  }

  checkFormValidation(): boolean {
    if (this.auctioneerForm.get('auctioneerName')?.status === "INVALID") {
      this.auctioneerForm.get('auctioneerName')?.markAsTouched();
      this.hasAuctioneerNameError = true;
    }
    if (this.auctioneerForm.get('contactName')?.status === "INVALID") {
      this.auctioneerForm.get('contactName')?.markAsTouched();
      this.hasContactNameError = true;
    }
    if (this.auctioneerForm.get('email')?.status === "INVALID") {
      if (this.auctioneerForm.get('email')?.value === "") {
        this.hasEmailError = true;
        this.hasInvalidEmailError = false;
      } else {
        this.auctioneerForm.get('email')?.markAsTouched();
        this.hasInvalidEmailError = true;
        this.hasEmailError = false;
      }
    }
    return true
  }

  cleareError() {
    this.hasAuctioneerNameError = false;
    this.hasContactNameError = false;
    this.hasEmailError = false;
  }
}
