import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-help-getting-started',
  templateUrl: './help-getting-started.component.html',
  styleUrls: ['./help-getting-started.component.scss']
})
export class HelpGettingStartedComponent implements AfterViewInit {
  @ViewChild('about_iris') about_irisElement!: ElementRef;
  @ViewChild('accessing_tool') accessing_toolElement!: ElementRef;
  @ViewChild('how_does_iris_work') how_does_iris_workElement!: ElementRef;
  @ViewChild('my_iris') my_irisElement!: ElementRef;
  url: string = environment.azureAd.redirectUri + '/MMS_IRIS';
  fragment: string = '';

  constructor(private router: Router, private commonService: CommonService) {
    let state: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(state)) {
      this.fragment = state['fragment'];
    }
  }

  ngAfterViewInit() {
    if (this.fragment !== '') {
      this.scrollTo_HelpGettingStarted(this.fragment);
      this.fragment = '';
    }
  }

  scrollTo_HelpGettingStarted(fragment: string) {
    switch (fragment) {
      case 'about_iris': {
        if (this.about_irisElement && this.about_irisElement.nativeElement) {
          this.about_irisElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'accessing_tool': {
        if (this.accessing_toolElement && this.accessing_toolElement.nativeElement) {
          this.accessing_toolElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'how_does_iris_work': {
        if (this.how_does_iris_workElement && this.how_does_iris_workElement.nativeElement) {
          this.how_does_iris_workElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
      case 'my_iris': {
        if (this.my_irisElement && this.my_irisElement.nativeElement) {
          this.my_irisElement.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
        break;
      }
    }
  }
}
