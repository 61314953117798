<div class="iris-locationDetail-container j-c-s main-container-padding">
  <h1 class="mb-20 setup-heading">{{heading}}</h1>
  <div class="mr-20 float-right">
    <button kendoButton class="primary-button ml-20 f-size-150" [disabled]="!isFormValid" (click)="action('save')">
      Save
    </button>
    <button kendoButton class="border-button ml-20 f-size-150" (click)="action('cancel')">
      Cancel
    </button>
  </div>

  <!-- form fields -->
  <section class="mt-20">
    <!-- Branch -->
    <div class="mt-20 w-30">
      <kendo-label> Branch </kendo-label>
      <kendo-textbox [maxlength]="150" [(ngModel)]="branch" (keyup)="addBrachName($event)"></kendo-textbox>
    </div>
    <!-- Branch Description -->
    <div class="mt-10 w-30">
      <kendo-label> Branch Description </kendo-label>
      <kendo-textbox [maxlength]="150" [(ngModel)]="branchDesp"></kendo-textbox>
    </div>
    <!-- Company -->
    <div class="mt-10 w-30">
      <kendo-label> Company </kendo-label>
      <kendo-textbox [maxlength]="150" [(ngModel)]="company"></kendo-textbox>
    </div>
    <!-- Location -->
    <div class="mt-10 w-30">
      <kendo-label> Location </kendo-label>
      <kendo-textbox [maxlength]="150" [(ngModel)]="locations"></kendo-textbox>
    </div>
    <!-- Asset -->
    <div class="mt-10 w-30">
      <kendo-label> Asset </kendo-label>
      <kendo-textbox [maxlength]="150" [(ngModel)]="asset"></kendo-textbox>
    </div>
    <!-- Asset Description -->
    <div class="mt-10 w-30">
      <kendo-label> Asset Description </kendo-label>
      <kendo-textbox [maxlength]="150" [(ngModel)]="assetDesp"></kendo-textbox>
    </div>
    <!-- Asset Function -->
    <div class="mt-10 w-30">
      <kendo-label> Asset Function </kendo-label>
      <kendo-textbox [maxlength]="150" [(ngModel)]="assetFunction"></kendo-textbox>
    </div>
    <!-- Asset Groups -->
    <div class="mt-10 w-30">
      <kendo-label> Asset Groups </kendo-label>
      <kendo-multiselect [data]="groupsList" #multiselect id="multiselect" placeholder="-- Select Group --" class="mt-5"
        valueField="id" [filterable]="true" [(ngModel)]="selectedGroups" textField="label"
        (valueChange)="selectedGroupId($event)">
        <ng-template kendoMultiSelectItemTemplate let-dataItem>
          <span class="autoCompleteItem">
            <button class="autoCompleteItem" [disabled]="false">
              {{ dataItem.label }}
            </button>
          </span>
        </ng-template>
      </kendo-multiselect>
    </div>
  </section>
</div>
