import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { DoaLevelApprovalMatrixDto } from '../../models/iris-doa-approval.model';
import { IRISDOALevelsConst } from 'src/app/core/constants/MMS_BackOffice_Constants/iris-doa-levels/iris-doa-levels.constants';

@Injectable({
  providedIn: 'root'
})
export class IrisDoaApprovalService {

  constructor(private httpUtilityService: HttpUtilityService) { }

  getDoaApprovalMatrix(): Observable<DoaLevelApprovalMatrixDto[]> {
    return this.httpUtilityService.getAll(IRISDOALevelsConst.ApiPath + IRISDOALevelsConst.getDoaApprovalMatrix);
  }
}
