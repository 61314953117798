import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/core/services/common.service';
import { IrisAssetJoaThresholdLimitService } from '../../../services/asset-joa-threshold-limit/iris-asset-joa-threshold-limit.service';
import { AssetJOAtThresholdDto, JOAThresholGridDTO, UserDetailsDto } from '../../../models/JOA-threshold-limit.model';
import { AssetJOAThresholdConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/asset-JOA-Threshold-limit/asset-joa-threshold-limit.constants';
import { BusinessUnitDto } from '../../../models/bussinessunits.model';
import { BusinessUnitService } from '../../../services/business-unit-service/business-unit.service';
import { BackOfficeAssetDto } from '../../../models/asset-group.model';
import { GroupService } from '../../../services/groups/group.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-iris-asset-joa-threshold-limit-detail',
  templateUrl: './iris-asset-joa-threshold-limit-detail.component.html',
  styleUrls: ['./iris-asset-joa-threshold-limit-detail.component.scss'],
})
export class IRISAssetJoaThresholdLimitDetailComponent implements OnInit, OnDestroy {
  joaThresholdName: string = '';
  heading: string = 'New Asset JOA Threshold';
  selectedThresholdID: number = 0;
  joaAmount: number = 0;
  currency: string = 'AUD';
  isWaiver: boolean = false;
  commercialUser: string = '';
  BUId: number = 0;
  buName: string = '';
  buList: BusinessUnitDto[] = [];
  public defaultApprover: UserDetailsDto = {
    id: 0,
    username: '-',
  };
  commercialApproverDto: UserDetailsDto = this.defaultApprover;
  selectedApprover: number = 0;
  requiredApprover: boolean = false;
  joaDropdownData!: any;
  currencyList: any = AssetJOAThresholdConstants.currencyList;
  defaulCurrencytItem = { key: '--- Select Currency ---' };
  requiredCurrency: boolean = false;
  requiredLabel: boolean = false;
  requiredBU: boolean = false;
  public defaulBUtItem = { name: '--- Select Business Unit ---', id: 0 };
  groupsList: BackOfficeAssetDto[] = [];
  assetGroupsbyBUList: BackOfficeAssetDto[] = [];
  AssetId: number = 0;
  defaulAssettItem = { label: '--- Select Asset Group ---', id: 0 };
  requiredAsset: boolean = false;
  assetJOAThresholdData: { businessUnitId: number, assetGroupId: number }[] = [];
  prevBUId: number = 0;
  prevCommercialApprover: number = 0;
  assetJOAThresholdItem: AssetJOAtThresholdDto = new AssetJOAtThresholdDto();
  commercialApproverList: UserDetailsDto[] = [];
  constructor(
    private router: Router,
    private commonService: CommonService,
    private location: Location,
    private joaThresholdService: IrisAssetJoaThresholdLimitService,
    private buService: BusinessUnitService,
    private groupService: GroupService,
    private titleService: Title
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.selectedThresholdID = stateData['id'];
      if (this.selectedThresholdID > 0) {
        this.prevBUId = stateData['businessUnitId'];
        this.joaThresholdName = stateData['label'];
        this.commercialUser = stateData['commercialUser'];
        this.heading = `Asset JOA Threshold for ${this.joaThresholdName}`;
      }
      sessionStorage.setItem('JOAThresholdDetails', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('JOAThresholdDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.selectedThresholdID = sessionState.id;
        if (this.selectedThresholdID > 0) {
          this.prevBUId = sessionState.businessUnitId;
          this.joaThresholdName = sessionState.joaThresholdName;
          this.commercialUser = sessionState.commercialUser;
          this.heading = `Asset JOA Threshold for ${this.joaThresholdName}`;
        }
      }
    }
  }

  ngOnInit() {
    if (this.selectedThresholdID > 0) {
      this.titleService.setTitle(this.joaThresholdName);
    }
    this.getAssetJOARecords();
    this.getCommercialApproverList();
    if (this.selectedThresholdID) {
      this.getJOAThresholdData();
    }
    else {
      this.getBUList();
      this.getGroupList();
    }
  }
  getAssetJOARecords() {
    this.joaThresholdService
      .getJOAThresholdLimitGrid()
      .subscribe((data: JOAThresholGridDTO[]) => {
        data.map((a) => {
          this.assetJOAThresholdData.push({ businessUnitId: a.businessUnitId!, assetGroupId: a.id! });
        })
      });
  }
  getCommercialApproverList() {
    this.joaThresholdService.getCommercialUsers().subscribe((data: UserDetailsDto[]) => {
      this.commercialApproverList = data;
    })
  }
  getJOAThresholdData() {
    this.joaThresholdService
      .getJOAThresholdById(this.selectedThresholdID)
      .subscribe((data: AssetJOAtThresholdDto) => {
        this.assetJOAThresholdItem = { ...data };
        this.joaAmount = data.joAmount ? data.joAmount : 0;
        this.currency = data.currancy ? data.currancy : 'AUD';
        this.isWaiver = data.isWaiverinPlace;
        this.selectedApprover = data.commercialApprovarMMSUserID ? data.commercialApprovarMMSUserID : this.defaultApprover.id;
        let commercialApprover = this.commercialApproverList.filter((comApprover: UserDetailsDto) => (comApprover.id === this.selectedApprover));
        this.commercialApproverDto = {
          id: this.selectedApprover,
          username: commercialApprover ? commercialApprover[0].username : this.defaultApprover.username
        }
        this.prevCommercialApprover = data.commercialApprovarMMSUserID ? data.commercialApprovarMMSUserID : 0;
      });
  }
  getBUList() {
    this.buService.getAllBUNames().subscribe((data: BusinessUnitDto[]) => {
      this.buList = data;
    })
  }
  getGroupList() {
    this.groupService.getIrisGroupsList().subscribe((list: BackOfficeAssetDto[]) => {
      this.groupsList = list;
    });
  }
  onBuChange() {
    if (this.BUId !== 0) {
      this.assetGroupsbyBUList = this.groupsList.filter((asset: BackOfficeAssetDto) => (asset.businessUnitId === this.BUId));
    }
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('JOAThresholdDetails');
  }


  action(actionType: string) {
    switch (actionType) {
      case 'cancel': {
        this.location.back();
        break;
      }
      case 'save': {
        if (this.isValidForm() === false) {
          return;
        }
        if (this.selectedThresholdID) {
          this.editJOAThreshold();
        }
        else {
          if (this.isDuplicates()) {
            this.commonService.showNotificationMessage('Asset JOA Threshold already exists.', 'error', 'center');
            return;
          }
          else {
            this.AddNewAssetJOAThreshold();
          }
        }
        break;
      }
    }
  }

  isValidForm(): boolean {
    this.requiredBU = false;
    this.requiredAsset = false;
    this.requiredCurrency = false;
    this.requiredApprover = false;
    let isValidForm: boolean = true;
    if (this.selectedThresholdID === 0) {
      if (this.BUId === this.defaulBUtItem.id) {
        this.requiredBU = true;
        isValidForm = false;
      }
      if (this.AssetId === this.defaulAssettItem.id) {
        this.requiredAsset = true;
        isValidForm = false;
      }
    }
    if (this.currency === this.defaulCurrencytItem.key) {
      this.requiredCurrency = true;
      isValidForm = false;
    }
    if (this.selectedApprover === 0) {
      this.requiredApprover = true;
      isValidForm = false;
    }
    return isValidForm;
  }

  isDuplicates() {
    let filteredAssetJOAThresholdData = this.assetJOAThresholdData.filter((data: { businessUnitId: number; assetGroupId: number; }) =>
      (data.businessUnitId === this.BUId && data.assetGroupId === this.AssetId));
    if (filteredAssetJOAThresholdData.length > 0 && this.selectedThresholdID === 0) {
      return true;
    }
    return false;
  }
  AddNewAssetJOAThreshold() {
    let assetJOAtThreshold: AssetJOAtThresholdDto = new AssetJOAtThresholdDto();
    assetJOAtThreshold.id = 0;
    assetJOAtThreshold.assetGroupId = this.AssetId;
    assetJOAtThreshold.joAmount = this.joaAmount;
    assetJOAtThreshold.currancy = this.currency;
    assetJOAtThreshold.businessUnitId = this.BUId;
    assetJOAtThreshold.isWaiverinPlace = this.isWaiver;
    assetJOAtThreshold.createdDateTime = new Date();
    assetJOAtThreshold.createdBy = this.commonService.getUserEmailId();
    assetJOAtThreshold.isActive = true;
    assetJOAtThreshold.order = 0;
    assetJOAtThreshold.commercialApprovarMMSUserID = this.selectedApprover;
    this.joaThresholdService.addJOAThreshold(assetJOAtThreshold).subscribe((data: AssetJOAtThresholdDto) => {
      this.commonService.showNotificationMessage('New Asset JOA threshold added sucessfully.', 'success', 'center')
      this.location.back();
    })
  }
  // to select commercial-approver
  approverSelected(event: UserDetailsDto) {
    this.selectedApprover = event.id;
  }

  // to create | edit JOA-Threshold
  editJOAThreshold() {
    const payload: AssetJOAtThresholdDto = {
      id: this.assetJOAThresholdItem.id,
      assetGroupId: this.assetJOAThresholdItem.assetGroupId,
      joAmount: this.joaAmount,
      currancy: this.currency,
      commercialApprovarMMSUserID: this.selectedApprover,
      isWaiverinPlace: this.isWaiver,
      modifiedBy: this.commonService.getUserEmailId(),
      modifiedDateTime: new Date(),
      order: this.assetJOAThresholdItem.order,
      businessUnitId: this.prevBUId,
      commercialApprovarUserList: [],
      businessUnitName: '',
      isActive: this.assetJOAThresholdItem.isActive,
      createdDateTime: this.assetJOAThresholdItem.createdDateTime,
      createdBy: this.assetJOAThresholdItem.createdBy
    };
    this.joaThresholdService
      .editJOAThreshold(payload, this.selectedThresholdID)
      .subscribe((data: AssetJOAtThresholdDto) => {
        if (data) {
          const msg = `Asset JOA threshold '${this.joaThresholdName}'`;
          this.commonService.successUpdateMSG(msg, 'edit');
          this.location.back();
        }
      });
  }

  onAmmountChange() {
    if (this.joaAmount < 0) {
      this.joaAmount = 0;
    }
  }
}
