import { IRISCreatedStatus } from '../../model/common/dashboard-iris_created_status.model';

export const IRISCreatedByMeStatusRecords: IRISCreatedStatus[] = [
  {
    name: 'Draft',
    iconTrue: 'assets/icons/draftselected.svg',
    isconFalse: 'assets/icons/draftdeselected.svg',
    count: 0,
  },
  //   {
  //     name: 'Submitted',
  //     iconTrue: '../../../../../assets/icons/openselected.svg',
  //     isconFalse: '../../../../../assets/icons/opendeselected.svg',
  //     count: 0,
  //   },
  //   {
  //     name: 'Pending Auction',
  //     iconTrue: '../../../../../assets/icons/completeselected.svg',
  //     isconFalse: '../../../../../assets/icons/completedeselected.svg',
  //     count: 0,
  //   },
  //   {
  //     name: 'Disposed',
  //     iconTrue: '../../../../../assets/icons/completeselected.svg',
  //     isconFalse: '../../../../../assets/icons/completedeselected.svg',
  //     count: 0,
  //   },
];
