import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IRISDepartmentsConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/IRIS-Department/iris-department.constants';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { IRISDepartmentsGridDTO } from '../../models/iris-departments.model';

@Injectable({
  providedIn: 'root',
})
export class IrisDepartmetsService {
  constructor(
    public http: HttpClient,
    public httpUtilityService: HttpUtilityService
  ) {}

  // to get the departments list
  getIrisDepartmentRecord(): Observable<IRISDepartmentsGridDTO[]> {
    return this.httpUtilityService.get(`${IRISDepartmentsConstants.getIrisDepartment}`);
  }

  // to create new departments
  createNewIrisDepartment(data: IRISDepartmentsGridDTO): Observable<IRISDepartmentsGridDTO> {
    return this.httpUtilityService.post(`${IRISDepartmentsConstants.getIrisDepartment}`, data);
  }

  // to edit existing departments 
  editIrisDepartment(data: IRISDepartmentsGridDTO, id: number): Observable<IRISDepartmentsGridDTO> {
    return this.httpUtilityService.put(`${IRISDepartmentsConstants.getIrisDepartment}/${id}`, data);
  }

  // to download the grid data in excel-file
  importExcelData(data: IRISDepartmentsGridDTO[]) {
    return this.httpUtilityService.post(`${IRISDepartmentsConstants.importDepartment}`, data);
  }
}
