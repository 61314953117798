export const FileConstant = {
  acceptedFiles: [
    '.jpg',
    '.jpeg',
    '.png',
    '.gif',
    '.xlsx',
    '.xls',
    '.doc',
    '.docx',
    '.pdf',
    '.txt',
  ],
};
