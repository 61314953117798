<div class="iris-auctioneers-container j-c-s m-container">
  <h1 class="mb-20 setup-heading">IRIS Auctioneers</h1>
  <div class="mr-20 float-right">
    <button kendoButton class="border-button ml-20 f-size-150" (click)="createNewAuctioneers()">
      Create a new IRIS Auctioneers
    </button>
  </div>
  <section class="manage-contentbox">
    <!-- Filter actions -->
    <section class="mt-20 d-flex">
      <div class="mt-20 pr-20 d-flex w-55">
        <kendo-textbox [(ngModel)]="searchedText" (click)="textSearched($event)"></kendo-textbox>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="searchResetGridActions('search')">
          Search
        </button>
        <button kendoButton class="border-button ml-20 f-size-150" (click)="searchResetGridActions('reset')">
          Reset
        </button>
      </div>
    </section>

    <!-- Table data -->
    <section class="mt-10">
      <kendo-grid [kendoGridBinding]="gridData" #grid="kendoGrid" [sortable]="true" [sortable]="true">
        <!-- auctioneer Name -->
        <kendo-grid-column field="auctioneerName" title="Auctioneer Name" [width]="36">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a (click)="auctioneersDetails(dataItem)"> {{ dataItem.auctioneerName }} </a>
          </ng-template>
        </kendo-grid-column>
        <!-- email -->
        <kendo-grid-column field="email" title="Email" [width]="26" class="a-center">
          <ng-template kendoGridCellTemplate let-dataItem>
            <a href="mailto:{{ dataItem.email }}"> {{ dataItem.email }} </a>
          </ng-template>
        </kendo-grid-column>
        <!-- contact Name -->
        <kendo-grid-column field="contactName" title="Created By" [width]="20" class="a-center"> </kendo-grid-column>
        <!-- created-date-time -->
        <kendo-grid-column field="createdDateTime" title="Created Date" [width]="18" class="a-center">
        </kendo-grid-column>
      </kendo-grid>

      <!-- total data count -->
      <p class="grid-length mt-20">{{ gridData.length }} records</p>
    </section>
  </section>
</div>