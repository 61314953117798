import { AttachmentsDTO } from "../common_constants/attachments.constants";

export class LineDetailsDto {
  id?: number;
  createdBy?: string;
  createdDateTime?: string;
  modifiedBy?: string;
  modifiedDateTime?: string;
  itemDescription?: string;
  requestId?: number;
  unitCost?: number;
  itemQty?: number;
  estimatedCost?: number | string;
  manufacturerPartNumber?: string;
  manufacturerName?: string;
  inventoryConditionId?: number | any;
  itemNumber?: string;
  index?: number;
  inventLabel?: string;
  totalCalculatedCost?: number;
  irismaterialTypeId?: number;
  disposalMethodId?: number;
  wbscode?: string;
  comment?: string;
  attachments?: AttachmentsDTO[] = [];
}