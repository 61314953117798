import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { AssetLocationGridDTO } from '../../models/asset-locations.model';
import { AssetLocationEndPointsDTO } from 'src/app/core/constants/MMS_BackOffice_Constants/asset-locations/asset-location.constants';

@Injectable({
  providedIn: 'root'
})

export class AssetLocationService {
  constructor(
    public http: HttpClient,
    public httpUtilityService: HttpUtilityService
  ) {}

  // to get the asset-locations lists 
  getAssetLocationGrid(): Observable<AssetLocationGridDTO[]> {
    return this.httpUtilityService.get(`${AssetLocationEndPointsDTO.getAssetLocation}`);
  }

  // to create new location
  createNewAssetLocation(data: AssetLocationGridDTO): Observable<AssetLocationGridDTO> {
    return this.httpUtilityService.post(`${AssetLocationEndPointsDTO.getAssetLocation}`, data);
  }

  // to edit existing location
  editAssetLocation(data: AssetLocationGridDTO, id: number): Observable<AssetLocationGridDTO> {
    return this.httpUtilityService.put(`${AssetLocationEndPointsDTO.getAssetLocation}/${id}`, data);
  }

  // // to upload the grid data in excel-file
  importExcelData(data: AssetLocationGridDTO[]) {
    return this.httpUtilityService.post(`${AssetLocationEndPointsDTO.importAssetLocation}`, data);
  }
}