<div class="iris-locationDetail-container j-c-s main-container-padding">
    <h1 class="mb-20 setup-heading">My Profile</h1>
    <div class="mr-20 float-right">
        <button kendoButton class="border-button ml-20 f-size-150" (click)="action('reset')">Reset</button>
        <button kendoButton class="primary-button ml-20 f-size-150" (click)="action('save')">Save</button>
    </div>
    <section class="mt-20">
        <fieldset class="fieldset">
            <legend class="fieldset-legend">Email Notifications</legend>
            <br />
            <!-- Email Address -->
            <div class="w-100 d-flex">
                <div class="w-20">
                    <kendo-label>Email Address</kendo-label>
                    <br />
                </div>
                <div class="w-50"><kendo-textbox [required]="true" [maxlength]="256"
                        [(ngModel)]="emailId"></kendo-textbox>
                    <kendo-formerror *ngIf="!isValidEmailId">Please enter a valid notification email
                        address</kendo-formerror>
                </div>
                <div class="w-10">
                    <kendo-label>@chevron.com</kendo-label>
                    <br />
                </div>
            </div>
            <!-- Secondary Email Address -->
            <div class="mt-10 w-100 d-flex">
                <div class="w-20">
                    <kendo-label>Secondary Email Address</kendo-label>
                    <br />
                </div>
                <div class="w-50"><kendo-textbox [maxlength]="256"
                        [(ngModel)]="secondaryEmailId"></kendo-textbox>
                </div>
                <div class="w-10">
                    <kendo-label>@chevron.com</kendo-label>
                    <br />
                </div>
            </div>
            <!-- Email Toggle -->
            <div class="mt-10 w-100 d-flex">
                <div class="w-20"><kendo-label [for]="select">Send Email Notifications</kendo-label></div>
                <div class="w-50"><input type="checkbox" [(ngModel)]="emailToggle" #select kendoCheckBox /></div>
            </div>
        </fieldset>
    </section>
    <section class="mt-20">
        <fieldset class="fieldset">
            <legend class="fieldset-legend">Delegation</legend>
            <!-- delegation Toggle -->
            <div class="mt-10 w-100 d-flex">
                <div class="w-20"><kendo-label [for]="select">Delegation Active</kendo-label></div>
                <div class="w-50">
                    <kendo-switch [(ngModel)]="delegationToggle"> </kendo-switch>
                </div>
            </div>
            <div class="mt-10 w-100 d-flex">
                <div class="w-20"><kendo-label> Delegated to</kendo-label></div>
                <div class="w-50"><kendo-dropdownlist class="mt-5" [data]="delegatedList" [textField]="'name'"
                        [valueField]="'id'" [valuePrimitive]="true" required (valueChange)="delegatedSelected($event)"
                        [(ngModel)]="delegateUseId">
                    </kendo-dropdownlist>
                </div>
            </div>
        </fieldset>
    </section>
</div>