import { NgModule } from '@angular/core';
import { AllIRISModule } from '../feature/all-iris/module/allIris.module';
import { IRISModule } from '../feature/iris/module/iris.module';
import { MMSModule } from '../feature/mms/module/mms.module';
import { SetupModule } from '../feature/setup/modules/setup.module';

@NgModule({
  declarations: [],
  imports: [MMSModule, IRISModule, AllIRISModule, SetupModule],
  exports: [MMSModule, IRISModule, AllIRISModule, SetupModule],
  providers: [],
})
export class FeaturesModule {}
