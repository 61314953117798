import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IRISRoutingConstants } from 'src/app/core/constants/IRIS_Constants/IRIS-components-routing-constants';
import { IrisRequestService } from '../../services/IRIS-Request/iris-request.service';
import { IRISRequestDto, InventoryConditionDto, TechnicalEndorserDto } from '../../models/iris-request-workflow/iris-request.model';
import { CommonService } from 'src/app/core/services/common.service';
import { SharedConst } from 'src/app/core/constants/shared.constants';
import { LineDetailsDto } from '../../models/iris-request-workflow/line-item.model';
import { IRISRequestConstants, EndorserDefaultValue, InventoryConditionConstants } from 'src/app/core/constants/IRIS_Constants/iris-requester/iris-requester.constants';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestIssueDTO } from '../../models/iris-request-workflow/request-issue/request-issue.model';
import { RequestFlowStepsConst, StepsStatusConst } from 'src/app/core/constants/IRIS_Constants/request-flow/request-flow-steps.constants';

import { FileConstant } from '../../models/common_constants/file-upload-type.constants';
import { AttachmentsDTO } from '../../models/common_constants/attachments.constants';
import { requestFlowMockData } from '../../models/mock-data';
import { RejectApproveRecallPopupComponent } from '../reject-approve-recall-popup/reject-approve-recall-popup.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { RequestStatesConstants } from 'src/app/core/constants/IRIS_Constants/iris-RequestStates.constants';

@Component({
  selector: 'app-irisoverview',
  templateUrl: './irisoverview.component.html',
  styleUrls: ['./irisoverview.component.scss'],
  providers: [DatePipe]
})
export class IRISOverviewComponent implements OnInit {
  irisRequestID!: number;
  requestModifiedDateTime!: Date;
  dateFormatWithTime: string = SharedConst.dateFormatWithTime;
  gridData: LineDetailsDto[] = [];
  public opened = false;
  requestDetails!: any;
  lineID!: number;
  trackNum: string = '';
  inventoryConditionRecords: InventoryConditionDto[] = InventoryConditionConstants;
  estiCost: any = [];
  totalestimatedCost!: number;
  isGridData: boolean = false;
  currentActionToDo: string = '';
  hideDeleteSumbmitBTN!: boolean;
  hideBTNOnCancelledState!: boolean;
  hideEndorserInputField!: boolean;
  hideRejectApproveRecallBTN!: boolean;
  endorserDefaultValue: TechnicalEndorserDto = EndorserDefaultValue;
  endorserList: TechnicalEndorserDto[] = [];
  selectedEndorser!: number;
  requestIssueID!: number;
  createdBy: string = '';
  createdDateTime: string = '';
  dashboardPath = IRISRoutingConstants.IRISDashboard;
  userMailID = JSON.parse(sessionStorage.getItem('userEmailID')!);
  requestIssueForm = new FormGroup({
    endorser: new FormControl(0),
  });
  isGOMBU: boolean = false;
  displayRequestFlow: boolean = false;
  requestFlowData = requestFlowMockData;
  requestFlowStepsConst = RequestFlowStepsConst;
  stepsStatusConst = StepsStatusConst;
  currentRequestFlow: any = [];
  oldRequestFlow: any = [];
  currentStepIndex: number = 1;
  isRequestFlowRecalled: boolean = false;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<any> | any;
  isAddWBSCodeWindowOpen: boolean = false;
  acceptedFiles: string[] = FileConstant.acceptedFiles;
  accptedFilesStr: string = this.acceptedFiles.join(',');
  attachmentFileDetails: AttachmentsDTO[] = [];
  RequestStatesConstants = RequestStatesConstants;
  comment: string = '';
  wbscode: string = '';
  currentLineItem: LineDetailsDto = new LineDetailsDto();
  canApproveFinanceUpdate: boolean = false;
  constructor(
    private router: Router,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private irisRequestService: IrisRequestService,
    public datepipe: DatePipe,
    private dialogService: DialogService,
  ) {
    const stateData: any = this.router.getCurrentNavigation()?.extras.state;
    if (!this.commonService.isNullOrEmptyOrUndefined(stateData)) {
      this.irisRequestID = stateData['requestID'];
      this.trackNum = stateData['trackingNumber'];
      this.currentActionToDo = stateData['actionToDo'];
      this.createdBy = stateData['createdBy'];
      this.createdDateTime = stateData['createdDateTime'];
      sessionStorage.setItem('requestDetails', JSON.stringify(stateData));
    } else {
      let sessionState = JSON.parse(sessionStorage.getItem('requestDetails')!);
      if (!this.commonService.isEmptyOrUndefined(sessionState)) {
        this.irisRequestID = sessionState?.requestID;
        this.trackNum = sessionState?.trackingNumber;
        this.currentActionToDo = 'toViewEdit';
        this.createdBy = sessionState?.createdBy;
        this.createdDateTime = sessionState?.createdDateTime;
      }
    }
    this.isGOMBU = this.commonService.getUserBU().includes('GOMBU');
  }

  ngOnInit(): void {
    this.IRISDetails();
    this.getRequestLineGridData();
    this.getTechnicalEndorserUsers();
    this.getRequestDetailsByID();
    //this.getRequestFlow();
  }

  getRequestFlow() {
    this.currentRequestFlow = this.requestFlowData.filter((requestFlow: any) => (requestFlow.requestFlowNumber === 0))[0];
    let i = -1;
    for (let step of this.currentRequestFlow.flow) {
      i++;
      if (i === 0)
        continue;
      if (step.status === this.stepsStatusConst.Rejected || step.status === this.stepsStatusConst.Recalled) {
        this.isRequestFlowRecalled = true;
        this.currentStepIndex = -1;
        break;
      }
      if (step.status === '') {
        this.currentStepIndex = i;
        break;
      }
    }
    this.oldRequestFlow = this.requestFlowData.filter((requestFlow: any) => (requestFlow.requestFlowNumber > 0)).sort((a: any, b: any) => b.requestFlowNumber - a.requestFlowNumber);
  }
  // get details of created or existing IRIS-Request
  IRISDetails() {
    this.requestDetails = new IRISRequestDto();
    this.irisRequestService
      .getRequestByID(this.irisRequestID)
      .subscribe((requestData: IRISRequestDto) => {
        if (requestData) {
          this.requestDetails = requestData;
          this.requestModifiedDateTime = new Date(this.requestDetails.modifiedDateTime + 'Z');
          if (this.currentActionToDo === 'toCreate') {
            this.showCreateMSG(requestData.trackingNumber);
          }
          this.showHideActionsBTNBasedOnRequestState(this.requestDetails.requestStateName);
          this.getRequestFlow();
          this.displayRequestFlow = (this.requestDetails.requestStateId === 1 || (this.requestDetails.requestStateId === 6 && !this.isRequestFlowRecalled)) ? false : true;
        }
      });
  }

  showHideActionsBTNBasedOnRequestState(state: string) {
    switch (state.toLocaleLowerCase()) {
      case 'cancelled': {
        this.hideBTNOnCancelledState = true;
        this.hideDeleteSumbmitBTN = true;
        break;
      }
      case 'open': {
        this.hideDeleteSumbmitBTN = false;
        break;
      }
      case 'pending endorsement': {
        this.hideEndorserInputField = true;
        this.hideRejectApproveRecallBTN = true;
        this.hideDeleteSumbmitBTN = true;
        this.hideBTNOnCancelledState = true;
        break;
      }
      case 'pending finance update': {
        this.hideRejectApproveRecallBTN = true;
        this.hideDeleteSumbmitBTN = true;
        this.hideBTNOnCancelledState = false;
        this.hideBTNOnCancelledState = true;
        break;
      }
      case 'pending doa review': {
        this.hideRejectApproveRecallBTN = true;
        this.hideDeleteSumbmitBTN = true;
        this.hideBTNOnCancelledState = true;
        break;
      }
      case 'pending doa review': {
        this.hideRejectApproveRecallBTN = true;
        this.hideDeleteSumbmitBTN = true;
        this.hideBTNOnCancelledState = true;
        break;
      }
      case 'pending doa review': {
        this.hideRejectApproveRecallBTN = true;
        this.hideDeleteSumbmitBTN = true;
        break;
      }
    }
  }

  // to get request line items data, to show in grid
  getRequestLineGridData() {
    let i = 1;
    this.estiCost = [];
    this.irisRequestService.getRequestLineByID(this.irisRequestID).subscribe((data: LineDetailsDto[]) => {
      if (data) {
        data.map((ele: LineDetailsDto) => {
          ele.index = i++;
          this.estiCost.push(ele.estimatedCost);
          this.totalestimatedCost = this.estiCost.reduce((acc: any, current: any) => acc + current, 0);
          this.inventoryConditionRecords.filter((invet: any) => {
            if (invet['id'] === ele.inventoryConditionId) {
              return ele.inventLabel = invet['label']
            }
          })
        });
        this.gridData = data;
        if (this.checkSubmitted_AllWBSCodes(data)) {
          this.canApproveFinanceUpdate = true;
        }
      }
    })
  }

  checkSubmitted_AllWBSCodes(data: LineDetailsDto[]): boolean {
    let newData = data.filter((lineItem: LineDetailsDto) => lineItem.wbscode !== null);
    if (newData.length === data.length)
      return true;
    return false;
  }
  // get the list of endorsers
  getTechnicalEndorserUsers() {
    this.irisRequestService
      .getTechnicalEndorserUsers()
      .subscribe((data: TechnicalEndorserDto[]) => {
        if (data) {
          this.endorserList = data;
        }
      });
  }

  // request details by ID
  getRequestDetailsByID() {
    this.irisRequestService
      .getRequestIssueByID(this.irisRequestID)
      .subscribe((data: any) => {
        this.requestIssueID = data?.id;
        this.selectedEndorser = data.technicalEndorserId;
        this.requestIssueForm.get('endorser')?.setValue(<number>this.selectedEndorser);
        sessionStorage.setItem('requestIssueId', JSON.stringify(data?.id));
      });
  }

  // to edit|update IRIS Request details
  editHeader(): void {
    const url = IRISRoutingConstants.IRISCreate;
    this.router.navigate([`../${url}`], {
      state: {
        actionToDo: 'toViewEdit',
        actionName: 'edit',
        isEdit: true,
        id: this.irisRequestID,
        title: this.requestDetails['title'],
        description: this.requestDetails['description'],
        justification: this.requestDetails['justification'],
        assetId: this.requestDetails['assetId'],
        departmentId: this.requestDetails['departmentId'],
        locationId: this.requestDetails['assetLocationId'],
        trackingNo: this.requestDetails['trackingNumber'],
        requestStateId: this.requestDetails['requestStateId'],
        createdBy: this.requestDetails['createdBy'],
        createdDateTime: this.requestDetails['createdDateTime'],
      },
      relativeTo: this.route,
    });
  }

  // navigate to Item Approval page
  itemApproval() {
    const url = IRISRoutingConstants.ItemApproval;
    this.router.navigate([`../${url}`], {
      state: {
        lineItemsGridData: this.gridData,
        trackingNumber: this.requestDetails.trackingNumber
      },
      relativeTo: this.route,
    });
  }

  // navigate to Item Action page to add|edit items records
  addItemsDetail() {
    const url = IRISRoutingConstants.ItemAction;
    this.router.navigate([`../${url}`], {
      state: {
        requestID: this.irisRequestID,
        trackingNumber: this.requestDetails.trackingNumber
      },
      relativeTo: this.route,
    });
  }

  // to delete the request and set its state to CANCELLED
  deleteRequest() {
    this.irisRequestService.deleteIRISRequest(this.irisRequestID).subscribe((data: any) => {
      this.router.navigate([`../${this.dashboardPath}`], { relativeTo: this.route });
    })
  }

  // to submit the request and set its state to PENDING ENDORSEMENT
  submitRequest() {
    const Tdate = new Date();
    const currentDate: any = new Date(Tdate + 'Z');
    let payload: IRISRequestDto = {
      id: this.requestIssueID,
      requestStateId: 15,
      createdBy: this.userMailID,
      createdDateTime: currentDate,
    };
    this.irisRequestService.submitIRISRequest(this.requestIssueID, payload).subscribe((data: any) => {
      if (data) {
        this.router.navigate([`../${this.dashboardPath}`], { relativeTo: this.route });
      }
    })
  }

  // to reject the submitted Request
  rejectRequest() {
    this.endorserActionConfirmation('reject');
  }

  // to approve the submitted Request
  approveRequest() {
    this.endorserActionConfirmation('approve');
  }

  // to recall the submitted Request
  recallRequest() {
    this.endorserActionConfirmation('recall');
  }

  // user can change the Endorser of submitted IRIS_Request
  updateEndorser() {
    const Tdate = new Date();
    const currentDate: any = new Date(Tdate + 'Z');
    let endorser = <number>this.requestIssueForm.controls['endorser'].value;
    let formData: any = new FormData();
    formData.append('id', this.requestIssueID!);
    formData.append('technicalEndorserId', endorser!);
    formData.append('createdBy', this.requestDetails.createdBy);
    formData.append('createdDateTime', this.requestDetails.createdDateTime);
    formData.append('modifiedBy', this.userMailID);
    this.irisRequestService
      .updateEndorser(this.requestIssueID, formData)
      .subscribe((data: any) => {
        if (data) {
          this.router.navigate([`../${this.dashboardPath}`], { relativeTo: this.route });
        }
      });
  }

  public close(status: string): void {
    this.opened = false;
  }

  public open(): void {
    this.opened = true;
  }

  // download all the Line Items Attachments for IRIS Request
  downloadAll() {
    let date = new Date();
    const dateFormat = this.datepipe.transform(date, 'YYYY-MM-dd-HH-mm-ss');
    let zipFileName = `${this.irisRequestID}_${'_All_Attachments_'}${dateFormat}`;
    this.irisRequestService.downloadAllAttachments(this.irisRequestID).subscribe((data: any) => {
      const blob = new Blob([data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      FileSaver.saveAs(url, zipFileName)
    })
  }

  showCreateMSG(header: any) {
    const msg = `${header} ${SharedConst.createRequestMSG}`;
    this.commonService.showNotificationMessage(msg, 'success', 'center');
  }
  goBackToDashboard() {
    this.router.navigate([`../${this.dashboardPath}`], { relativeTo: this.route });
  }


  updateWBSCode(lineItemId: number) {
    this.isAddWBSCodeWindowOpen = true;
    this.currentLineItem = this.gridData.find((lineItem: LineDetailsDto) => (lineItem.id === lineItemId))!;
  }

  exitUpdateWBSCodeDialog() {
    this.isAddWBSCodeWindowOpen = false;
    this.comment = '';
    this.wbscode = '';
    this.attachmentFileDetails = [];
  }

  submitWBSCode() {
    if (this.validateWBSFields()) {
      let formData: any = new FormData();
      formData.append('requestId', this.currentLineItem.requestId!);
      formData.append('itemNumber', this.currentLineItem.itemNumber!);
      formData.append('itemDescription', this.currentLineItem.itemDescription!);
      formData.append('manufacturerName', this.currentLineItem.manufacturerName);
      formData.append('manufacturerPartNumber', this.currentLineItem.manufacturerPartNumber);
      formData.append('inventoryConditionId', this.currentLineItem.inventoryConditionId);
      formData.append('itemQty', Number(this.currentLineItem.itemQty));
      formData.append('unitCost', Number(this.currentLineItem.unitCost));
      formData.append('estimatedCost', Number(this.currentLineItem.estimatedCost));
      formData.append('irismaterialTypeId', Number(this.currentLineItem.irismaterialTypeId));
      formData.append('disposalMethodId', Number(this.currentLineItem.disposalMethodId));
      formData.append('createdBy', this.currentLineItem.createdBy);
      formData.append('createdDateTime', this.currentLineItem.createdDateTime);
      formData.append('modifiedBy', JSON.parse(sessionStorage.getItem('userEmailID')!));
      formData.append('comment', this.comment);
      formData.append('wbscode', this.wbscode);
      if (this.attachmentFileDetails.length) {
        for (let attachment of this.attachmentFileDetails!) {
          formData.append('attachments', attachment.file);
        }
      }
      this.irisRequestService
        .editLineItem(formData, this.currentLineItem.id!)
        .subscribe((data: any) => {
          if (data) {
            this.exitUpdateWBSCodeDialog();
            this.getRequestLineGridData();
          }
        });
    }
  }

  validateWBSFields(): boolean {
    if (this.comment === '') {
      this.commonService.showNotificationMessage('Please Enter comment', 'warning', 'center');
      return false;
    }
    if (this.wbscode === '') {
      this.commonService.showNotificationMessage('Please Enter WBS Code', 'warning', 'center');
      return false;
    }
    return true;
  }
  // to validate the uploaded file
  isValidFile(file: File): boolean {
    const name: string = file.name;
    const extension: string = '.' + name.split('.')[1];
    return this.acceptedFiles.includes(extension.toLocaleLowerCase());
  }

  // set the details to the attachments
  setNewAttachment(file: File): AttachmentsDTO {
    const newAttachment: AttachmentsDTO = new AttachmentsDTO();
    newAttachment.attachmentId = 0;
    newAttachment.file = file;
    newAttachment.fileName = file.name;
    newAttachment.blobUrI = '';
    newAttachment.operation = 1;
    return newAttachment;
  }

  // to uplaod an attachemnts
  onFilesUpload(event: any) {
    const files = event.target.files;
    const errorMessage: string = `${IRISRequestConstants.fileFormatErrorMSG} (${this.accptedFilesStr})`;
    if (files.length > 0) {
      if (this.isValidFile(files[0])) {
        const newAttachment: AttachmentsDTO = this.setNewAttachment(files[0]);
        this.attachmentFileDetails.push(newAttachment);
        this.fileInput.nativeElement.value = '';
      } else {
        this.commonService.showNotificationMessage(
          errorMessage,
          'warning',
          'center'
        );
      }
    }
  }

  // to remove particular attached file
  removeFile(index: number) {
    if (this.attachmentFileDetails && this.attachmentFileDetails[index]) {
      this.attachmentFileDetails!.splice(index, 1);
    }
  }

  // to download particular attached file
  downloadFile(index: number) {
    let attachment: any = this.attachmentFileDetails[index];
    const blob = new Blob([attachment.file!], { type: attachment.file!?.type });
    this.commonService.downloadFile(blob, attachment.name!);
  }
  public endorserActionConfirmation(actionName: string) {
    const dialogRef: DialogRef = this.dialogService.open({
      content: RejectApproveRecallPopupComponent,
    });
    const endorserInfo = dialogRef.content.instance as RejectApproveRecallPopupComponent;
    endorserInfo.endorserActionName = actionName;
    endorserInfo.trackingNumber = this.trackNum;
    endorserInfo.stateId = this.requestDetails.requestStateId;
    // return dialogRef.result;
    dialogRef.result.subscribe((r: any) => {
      if (r.action.toLocaleLowerCase() === 'yes') {
        this.goBackToDashboard();
      }
    })
  }
}
