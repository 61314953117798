import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DoaLevelApprovalMatrixDto } from '../../models/iris-doa-approval.model';
import { IrisDoaApprovalService } from '../../services/iris-doa-approval/iris-doa-approval.service';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-iris-doa-approval',
  templateUrl: './iris-doa-approval.component.html',
  styleUrls: ['./iris-doa-approval.component.scss'],
})
export class IrisDoaApprovalComponent implements OnInit {
  doaApprovalGridDTO: DoaLevelApprovalMatrixDto[] = [];
  gridData: DoaLevelApprovalMatrixDto[] = [];
  searchedText: string = '';
  checked: boolean = false;
  isAddNewBTNDisabled: boolean = true;

  constructor(private route: ActivatedRoute, public router: Router, private irisDoaApprovalService: IrisDoaApprovalService, public commonService: CommonService) { }

  ngOnInit() {
    this.getDOAApprovalGridData();
  }

  getDOAApprovalGridData() {
    this.irisDoaApprovalService.getDoaApprovalMatrix().subscribe((doaApprovalGridDTO: DoaLevelApprovalMatrixDto[]) => {
      this.doaApprovalGridDTO = Object.assign([], doaApprovalGridDTO)
      this.getGridData(this.checked);
    });

  }

  // navigate to DOA Approval detail page
  doaApprovalDetails(data: DoaLevelApprovalMatrixDto[]) {
    // const url = BackOfficeRoutingConstants.;
    // this.router.navigate([`../${url}`], {
    //   state: { data: data},
    //   relativeTo: this.route,
    // });
  }

  addNewLevel() { }

  // search the DOA Approval from grid and to clear searched filters
  searchResetGridActions(actionSeletcted: string) {
    switch (actionSeletcted) {
      case 'search': {
        this.getGridData(this.checked);
        break;
      }
      case 'reset': {
        this.searchedText = '';
        this.getGridData(this.checked);
        break;
      }
    }
  }

// filter grid-data
getGridData(checked: boolean) {
  this.gridData = this.commonService.setupGobalSearch(this.doaApprovalGridDTO, checked, 'label', this.searchedText);
}

}
