import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpAbstractService } from 'src/app/core/services/http.abstract.service';
import { HttpUtilityService } from 'src/app/core/services/http.utility.service';
import { BusinessUnitConstants } from 'src/app/core/constants/MMS_BackOffice_Constants/business-Unit/business-unit.constants';
import { BusinessUnitDto } from '../../models/bussinessunits.model';

@Injectable({
    providedIn: 'root',
})
export class BusinessUnitService extends HttpAbstractService<BusinessUnitDto> {
    constructor(http: HttpClient, public httpUtilityService: HttpUtilityService) {
        super(http);
    }

  //   to get business units records
    getAllBUNames(): Observable<BusinessUnitDto[]> {
        return this.getAll(BusinessUnitConstants.getBusinessUnit);
    }
    // getBUById(id:number){
    //     return this.get(BusinessUnitConstants.getBusinessUnit + '/' + id);
    // }
    // saveBU(data: BusinessUnitDto) {
    //     return this.post(BusinessUnitConstants.getBusinessUnit, data);
    // }
    // UpdateBU(data: BusinessUnitDto) {
    //     return this.put(BusinessUnitConstants.getBusinessUnit, data.id, data);
    // }
    // importExcelData(data: BusinessUnitDto[]) {
    //     return this.postAll(BusinessUnitConstants.getBusinessUnit + SharedConst.importApiName, data);
    // }
}